import { ETableCellVariant } from '~types/tables';
import { Link } from 'react-router-dom';
import { color, font } from '~styles/styles';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export type TableCellProps = {
    children: React.ReactNode | React.ReactNode[];
    link?: string;
    variant?: ETableCellVariant;
};

export default function TableCell(props: TableCellProps): JSX.Element {
    const { variant, children, link } = props;
    return (
        <StyledTableCell className={clsx(variant)}>
            {link ? <CellLink to={link}>{children}</CellLink> : children}
        </StyledTableCell>
    );
}

const StyledTableCell = styled.td`
    padding: 0.75rem;

    font-size: ${font.size.normal};
    font-weight: ${font.weight.regular};
    line-height: ${font.lineHeight.normal};

    border: 1px solid ${color.greyLight};

    &.${ETableCellVariant.NUMBER} {
        text-align: right;
    }

    &.${ETableCellVariant.PRICE} {
        text-align: right;
        width: 90px;
    }

    &.${ETableCellVariant.DATE} {
        text-align: left;
        width: 120px;
    }
`;

const CellLink = styled(Link)`
    color: inherit;
    text-decoration: none;

    &:active,
    &:visited {
        color: inherit;
    }

    &:hover {
        text-decoration: underline;
    }
`;
