import { EModal } from '~constants/modal';
import { useBookingQuery } from '@makespace/graphql-generated/generated/webapp/hooks';
import BookingCreateUpdate from '~components/schema/bookings/BookingCreateUpdate';
import BookingCreateUpdateFormProvider from '~components/schema/bookings/BookingCreateUpdateFormProvider';
import Loader from '~components/Loader';
import Modal, { EModalSize } from '~components/modal/Modal';
import React, { useRef } from 'react';
import useQueryParams from '~hooks/useQueryParams';
import type { BookingQueryParams } from '~schema/booking';

export default function BookingModal(): JSX.Element {
    const queryParams = useQueryParams<BookingQueryParams>();
    const formRef = useRef<HTMLFormElement>(null);

    const {
        data: { booking } = {},
        loading,
        error,
    } = useBookingQuery({
        variables: {
            id: queryParams.id ?? 'missing-booking-id',
        },
        skip: !queryParams.id,
    });

    return (
        <Modal
            header={'Update Booking'}
            size={EModalSize.LARGE}
            modal={EModal.BOOKING_UPDATE}
            scrollable={true}>
            {loading ? (
                <Loader />
            ) : booking ? (
                <BookingCreateUpdateFormProvider
                    formRef={formRef}
                    initialValues={{
                        _id: booking._id,
                        dateFrom: queryParams.dateFrom ?? booking.dateFrom,
                        dateTo: queryParams.dateTo ?? booking.dateTo,
                        space: queryParams.space ?? booking.space,
                        spaceType: queryParams.spaceType ?? booking.spaceType,
                        spaceTypeVariant: queryParams.spaceTypeVariant ?? booking.spaceTypeVariant,
                        user: queryParams.user ?? booking.user?._id,
                    }}
                    booking={booking}>
                    <BookingCreateUpdate originalBooking={booking} />
                </BookingCreateUpdateFormProvider>
            ) : null}
        </Modal>
    );
}
