import { Link, LinkProps } from 'react-router-dom';
import Button, { ButtonProps } from '~components/clickables/Button';
import React from 'react';

type LinkButtonProps = Pick<LinkProps, 'to'> & ButtonProps;

export default function LinkButton(props: LinkButtonProps): JSX.Element {
    const { to, ...buttonProps } = props;
    return (
        <Link to={to}>
            <Button {...buttonProps} />
        </Link>
    );
}
