import { EModal, EModalReturn } from '~constants/modal';
import { FormControllerProvider } from '~context/FormController';
import { appendQueryParams } from '~utils/urls';
import { useModal } from '~context/Modal';
import Modal, { EModalSize } from '~components/modal/Modal';
import React, { useRef } from 'react';
import UserCreateForm from '~components/schema/users/UserCreateForm';
import UserCreateFormProvider from '~components/schema/users/UserCreateFormProvider';
import useQueryParams from '~hooks/useQueryParams';

type UserCreateModalQueryParams = {
    userCreateModalReturn?: EModalReturn;
};

export default function UserCreateModal(): JSX.Element {
    const formRef = useRef<HTMLFormElement>(null);
    const { userCreateModalReturn } = useQueryParams<UserCreateModalQueryParams>();
    const { closeModal } = useModal();

    const onCreate = (userId: string) => {
        console.log('User created', userId);
        switch (userCreateModalReturn) {
            case EModalReturn.PARAMS: {
                appendQueryParams({ user: userId });
                closeModal(EModal.USER_CREATE);
                break;
            }
            default: {
                break;
            }
        }
    };

    return (
        <Modal
            header={'Create User'}
            size={EModalSize.MEDIUM}
            modal={EModal.USER_CREATE}
            scrollable={true}>
            <FormControllerProvider edit={true}>
                <UserCreateFormProvider formRef={formRef} onCreate={onCreate}>
                    <UserCreateForm />
                </UserCreateFormProvider>
            </FormControllerProvider>
        </Modal>
    );
}
