import { H1, H2, H3, H4, H5 } from '~components/Headings';
import Divider, { EDividerSpacing } from '~components/Divider';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';

export enum EFormSectionLevel {
    FOURTH = 'fourth',
    SECOND = 'second',
    THIRD = 'third',
    TOP = 'top',
}

export type FormSectionProps = {
    children: React.ReactNode | React.ReactNode[];
    heading?: string;
    headingActions?: React.ReactNode | React.ReactNode[];
    level?: EFormSectionLevel;
};

export default function FormSection(props: FormSectionProps): JSX.Element {
    const { heading, children, level = EFormSectionLevel.TOP, headingActions } = props;

    const HeadingText = useMemo(() => {
        switch (level) {
            case EFormSectionLevel.TOP:
                return <H2>{heading}</H2>;
            case EFormSectionLevel.SECOND:
                return <H3>{heading}</H3>;
            case EFormSectionLevel.THIRD:
                return <H4>{heading}</H4>;
            case EFormSectionLevel.FOURTH:
                return <H5>{heading}</H5>;
            default:
                return <H1>{heading}</H1>;
        }
    }, [heading, level]);

    return (
        <Section>
            {heading ? (
                <Heading>
                    <span className={'content'}>
                        <span className={'title'}>{HeadingText}</span>
                        <span className={'actions'}>{headingActions}</span>
                    </span>
                    <Divider spacing={EDividerSpacing.NONE} />
                </Heading>
            ) : null}
            {children}
        </Section>
    );
}

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    margin: 0 0 2rem 0;
    width: 100%;
`;

const Heading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0;
    width: 100%;

    & .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        flex: 1;
        &. title {
            flex: 1;
        }

        & .actions {
            flex: 0;
        }
    }
`;
