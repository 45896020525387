import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { MouseEvent, MutableRefObject, ReactNode, RefObject } from 'react';

type UseRelativeOverlayProps = {
    startOpen?: boolean;
};

type UseRelativeOverlayResponse<
    ContainerElement = HTMLDivElement,
    ContentElement = HTMLDivElement,
> = {
    containerRef: MutableRefObject<ContainerElement | null>;
    contentRef: MutableRefObject<ContentElement | null>;
    hideOverlay: () => void;
    open?: boolean;
    setOpen: (open: boolean) => void;
    showOverlay: () => void;
};

export function useRelativeOverlay<
    ContainerElement = HTMLDivElement,
    ContentElement = HTMLDivElement,
>(props?: UseRelativeOverlayProps): UseRelativeOverlayResponse<ContainerElement, ContentElement> {
    const { startOpen = false } = props || {};
    const [open, setOpen] = useState<boolean>(startOpen);
    const containerRef = useRef<ContainerElement | null>(null);
    const contentRef = useRef<ContentElement | null>(null);

    const showOverlay = () => {
        setOpen(true);
    };
    const hideOverlay = () => {
        setOpen(false);
    };

    return {
        open,
        setOpen,
        containerRef,
        contentRef,
        showOverlay,
        hideOverlay,
    };
}

export const RelativeOverlayContainer = styled.div`
    position: relative;
    overflow: visible;
`;

export type RelativeOverlayContentProps<ContentElement = HTMLDivElement> = {
    children: ReactNode | ReactNode[];
    className?: string;
    forwardRef: RefObject<ContentElement>;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    onClose?: () => void;
    open?: boolean;
};

export function RelativeOverlayContent<ContentElement extends HTMLDivElement>(
    props: RelativeOverlayContentProps<ContentElement>,
): JSX.Element | null {
    const { open, children, forwardRef, onClose, onClick, className } = props;
    const [position, setPosition] = useState<
        | {
              above?: boolean;
              right?: boolean;
          }
        | undefined
    >({});

    const handleOverlayContainerClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onClick?.(event);
    };

    const handleClickCatcherClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onClose?.();
    };

    useEffect(() => {
        if (!open) return setPosition(undefined);
        const rect = forwardRef.current?.getBoundingClientRect();
        const above = rect ? rect.bottom > window.innerHeight : false;
        const right = rect ? rect.right > window.innerWidth : false;
        setPosition({
            above,
            right,
        });
    }, [forwardRef, open]);

    return open ? (
        <>
            <RelativeOverlayContentContainer
                ref={forwardRef}
                className={clsx(className, { visible: !!position }, position)}
                onClick={handleOverlayContainerClick}>
                {children}
            </RelativeOverlayContentContainer>
            <RelativeOverlayContentClickCatcher onClick={handleClickCatcherClick} />
        </>
    ) : null;
}

const RelativeOverlayContentContainer = styled.div`
    label: RelativeOverlayContentContainer;
    position: absolute;
    z-index: 9999;
    white-space: nowrap;
    width: auto;
    min-width: 100%;
    top: 100%;
    left: 0;
    visibility: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);

    &.visible {
        visibility: visible;
    }

    &.above {
        top: auto;
        bottom: 100%;
    }
    &.right {
        left: auto;
        right: 0;
    }
`;

const RelativeOverlayContentClickCatcher = styled.span`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.01);
    z-index: 9998;
`;
