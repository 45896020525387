import * as yup from 'yup';
import { EBookingDayOfWeek } from '@makespace/graphql-generated/generated/webapp/schemas';
import { validateDateString, validateObjectIdString, validateTimeString } from '~utils/validators';

export const yupIsoDateString = yup.string().test('valid-iso-date', validateDateString);
export const yupTimeString = yup.string().test('valid-time-string', validateTimeString);
export const yupDayOfWeek = yup.string().oneOf(Object.values(EBookingDayOfWeek)).required();
export const yupObjectId = yup
    .string()
    .test('valid-objectId-string', validateObjectIdString)
    .required();
