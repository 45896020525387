import { EIconColor, EIconSize } from '~components/icons/Icon';
import { NavLink } from 'react-router-dom';
import { color, gradient, space } from '~styles/styles';
import Divider, { EDividerColor, EDividerSpacing } from '~components/Divider';
import IconAlert from '~components/icons/IconAlert';
import IconCalendar from '~components/icons/IconCalendar';
import IconConsumable from '~components/icons/IconConsumable';
import IconHome from '~components/icons/IconHome';
import IconIn from '~components/icons/IconIn';
import IconIngredient from '~components/icons/IconIngredient';
import IconInventory from '~components/icons/IconInventory';
import IconProduct from '~components/icons/IconProduct';
import IconSpace from '~components/icons/IconSpace';
import IconSupplier from '~components/icons/IconSupplier';
import IconTag from '~components/icons/IconTag';
import IconUser from '~components/icons/IconUser';
import React from 'react';
import routePaths, { EAppRoutes, cleanPath } from '~constants/routePaths';
import styled from '@emotion/styled';

export default function AppNavigation(): JSX.Element {
    return (
        <AppNavigationContainer>
            <AppNavLink to={cleanPath(routePaths.dashboard.base)}>
                <IconHome size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavDivider spacing={EDividerSpacing.NONE} color={EDividerColor.DARK} />
            <AppNavLink to={cleanPath(routePaths.orders.base)}>
                <IconAlert size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavDivider spacing={EDividerSpacing.NONE} color={EDividerColor.DARK} />
            <AppNavLink to={cleanPath(routePaths.products.base)}>
                <IconProduct size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavLink to={cleanPath(routePaths.consumables.base)}>
                <IconConsumable size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavLink to={cleanPath(routePaths.ingredients.base)}>
                <IconIngredient size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavDivider spacing={EDividerSpacing.NONE} color={EDividerColor.DARK} />
            <AppNavLink to={cleanPath(routePaths.inventory.base)}>
                <IconInventory size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavLink to={cleanPath(routePaths.inventoryOrders.base)}>
                <IconIn size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavDivider spacing={EDividerSpacing.NONE} color={EDividerColor.DARK} />
            <AppNavLink to={cleanPath(routePaths.suppliers.base)}>
                <IconSupplier size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavDivider spacing={EDividerSpacing.NONE} color={EDividerColor.DARK} />
            <AppNavLink to={cleanPath(routePaths.spaceTypes.base)}>
                <IconAlert size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavLink to={cleanPath(routePaths[EAppRoutes.SPACES].base)}>
                <IconSpace size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavDivider spacing={EDividerSpacing.NONE} color={EDividerColor.DARK} />
            <AppNavLink to={cleanPath(routePaths[EAppRoutes.BOOKINGS].base)}>
                <IconCalendar size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavDivider spacing={EDividerSpacing.NONE} color={EDividerColor.DARK} />
            <AppNavLink to={cleanPath(routePaths.media.base)}>
                <IconTag size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavLink to={cleanPath(routePaths.tags.base)}>
                <IconTag size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
            <AppNavLink to={cleanPath(routePaths[EAppRoutes.USERS].base)}>
                <IconUser size={EIconSize.LARGE} color={EIconColor.INHERIT} />
            </AppNavLink>
        </AppNavigationContainer>
    );
}

const AppNavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    flex: 0;
    gap: ${space.gap.small};
    border-right: 1px solid ${color.greyLight};
    position: relative;
    background: ${gradient.purpleBlack};
`;

const AppNavLink = styled(NavLink)`
    padding: ${space.padding.small} ${space.padding.normal};
    cursor: pointer;
    position: relative;
    color: ${color.white}80;

    & svg,
    & path {
        cursor: pointer;
    }

    &.active {
        color: ${color.white};
    }
    &:hover {
        color: ${color.white};
    }
`;

const AppNavDivider = styled(Divider)`
    width: calc(100% - ${space.padding.medium});
`;
