import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import FormControl, { FormControlProps } from '~components/form/FormControl';
import IconCross from '~components/icons/IconCross';
import React from 'react';
import TextArea, { TextAreaProps } from '~components/form/TextArea';

type ControlledTextAreaProps<TFieldValues extends FieldValues = FieldValues> = Pick<
    ControllerProps<TFieldValues>,
    'control' | 'name'
> &
    FormControlProps &
    TextAreaProps & {
        border?: boolean;
        clearable?: boolean;
        showError?: boolean;
    };

export default function ControlledTextArea<TFieldValues extends FieldValues = FieldValues>(
    props: ControlledTextAreaProps<TFieldValues>,
): JSX.Element {
    const {
        control,
        name,
        label,
        id = randomId(),
        required,
        clearable,
        icon,
        showError,
        border,
        ...inputProps
    } = props;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) =>
                label || showError ? (
                    <FormControl id={id} required={required} label={label} error={error}>
                        <TextArea
                            id={id}
                            required={required}
                            error={!!error}
                            {...inputProps}
                            {...field}
                        />
                    </FormControl>
                ) : (
                    <TextArea
                        id={id}
                        required={required}
                        error={!!error}
                        showBorder={border}
                        icon={
                            clearable && field.value !== '' ? (
                                <IconCross onClick={() => field.onChange('')} />
                            ) : (
                                icon
                            )
                        }
                        {...inputProps}
                        {...field}
                    />
                )
            }
        />
    );
}

const randomId = () => 'id_' + Math.floor(Math.random() * 10000000).toString();
