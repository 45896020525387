import React, { useEffect } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum EClickCatcherVariant {
    CLEAR = 'clear',
    DARK = 'dark',
    LIGHT = 'light',
    NORMAL = 'normal',
}

type ClickCatcherProps = {
    onClick: () => void;
    variant?: EClickCatcherVariant;
};

export default function ClickCatcher(props: ClickCatcherProps): JSX.Element {
    const { onClick, variant = EClickCatcherVariant.NORMAL } = props;

    useEffect(() => {
        return () => {};
    }, []);

    return <ClickCatcherContainer className={clsx(variant)} onClick={onClick} />;
}

const ClickCatcherContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0;

    &.${EClickCatcherVariant.CLEAR} {
        background: transparent;
    }
    &.${EClickCatcherVariant.NORMAL} {
        background: rgba(0, 0, 0, 0.01);
    }
    &.${EClickCatcherVariant.DARK} {
        backdrop-filter: blur(4px);
        background: rgba(0, 0, 0, 0.5);
    }
    &.${EClickCatcherVariant.LIGHT} {
        background: rgba(255, 255, 255, 0.1);
    }
`;
