import { useCountries } from '~hooks/useCountries';
import { useFormContext } from 'react-hook-form';
import { useFormController } from '~context/FormController';
import { useTranslation } from 'react-i18next';
import ContactAddressForm from '~components/schema/contact/ContactAddressForm';
import ContactMethodsForm from '~components/schema/contact/ContactMethodForm';
import ControlledInput from '~components/inputs/ControlledInput';
import ControlledSelect from '~components/inputs/ControlledSelect';
import FormRow from '~components/form/FormRow';
import FormSection from '~components/form/FormSection';
import React from 'react';
import type { GiBusinessCreateUpdate } from '@makespace/graphql-generated/generated/webapp/schemas';
import type { ObjectWithBusiness } from '~types/forms';

type BusinessFormProps = {
    business?: GiBusinessCreateUpdate;
    path: string;
};

export default function BusinessForm(props: BusinessFormProps): JSX.Element {
    const { path = 'business' } = props;
    const { t } = useTranslation();
    const { control } = useFormContext<ObjectWithBusiness>();
    const { edit } = useFormController();

    const { countryOptions } = useCountries({});

    return (
        <FormSection heading={t('component.businessForm.heading') ?? ''}>
            <FormRow variant={'row'}>
                <ControlledInput
                    control={control}
                    name={`${path}.name`}
                    label={t('component.businessForm.field.name.label')}
                    placeholder={t('component.businessForm.field.name.placeholder') ?? ''}
                    readOnly={!edit}
                />
                <ControlledSelect
                    control={control}
                    name={`${path}.country`}
                    options={countryOptions}
                    readOnly={!edit}
                    label={t('component.contactAddressForm.field.country.label')}
                />
            </FormRow>
            <FormRow variant={'row'}>
                <ControlledInput
                    control={control}
                    name={`${path}.registrationNumber`}
                    label={t('component.businessForm.field.registrationNumber.label')}
                    placeholder={
                        t('component.businessForm.field.registrationNumber.placeholder') ?? ''
                    }
                    readOnly={!edit}
                />
                <ControlledInput
                    control={control}
                    name={`${path}.taxNumber`}
                    label={t('component.businessForm.field.taxNumber.label')}
                    placeholder={t('component.businessForm.field.taxNumber.placeholder') ?? ''}
                    readOnly={!edit}
                />
            </FormRow>
            <ContactMethodsForm name={`${path}.contact.methods`} />
            <ContactAddressForm path={`${path}.contact.address`} />
        </FormSection>
    );
}
