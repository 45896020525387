import { H1 } from '~components/Headings';
import { space } from '~styles/styles';
import React from 'react';
import styled from '@emotion/styled';

export type PageHeaderProps = {
    actions?: React.ReactNode | React.ReactNode[];
    children?: React.ReactNode | React.ReactNode[];
    heading?: string;
};

export function PageHeader(props: PageHeaderProps): JSX.Element {
    const { heading, actions, children } = props;

    return (
        <PageHeaderContainer>
            <Heading>
                <H1>{heading}</H1>
                {actions ? <HeadingActions>{actions}</HeadingActions> : null}
            </Heading>
            <HeadingContent>{children}</HeadingContent>
        </PageHeaderContainer>
    );
}

const PageHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: ${space.gap.medium};
`;

const Heading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${space.gap.medium};

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        margin: 0;
    }
`;

const HeadingActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: ${space.gap.small};
`;

const HeadingContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${space.gap.medium};
`;
