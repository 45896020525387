export function validateDateString(dateString?: string) {
    return dateString ? !isNaN(Date.parse(dateString)) : false;
}

export function validateTimeString(timeNumber?: string) {
    if (timeNumber === undefined || timeNumber.length !== 5) return false;
    const [hours, minutes] = timeNumber.split(':');
    return parseInt(hours) < 24 && parseInt(minutes) <= 59;
}

export function validateObjectIdString(objectId?: string) {
    return objectId ? !!objectId.match(/^[0-9a-fA-F]{24}$/) : false;
}

export function validatePostalCode(postalCode?: string) {
    return postalCode ? !!postalCode.match(/[^0-9]{4,6}/) : false;
}

export function validatePhoneNumber(phoneNumber?: string) {
    return phoneNumber ? !!phoneNumber.match(/^(\+)?(\d{1,2})?(\d{3,4})?(\d{3})?(\d+)?/) : false;
}
