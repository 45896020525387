import { color } from '~styles/styles';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum EIconSize {
    LARGE = 's-large',
    NORMAL = 's-normal',
    SMALL = 's-small',
    TINY = 's-tiny',
    TRIM = 's-trim',
}

export enum EIconColor {
    ACTIVE = 'c-active',
    BLACK = 'c-black',
    DISABLED = 'c-disabled',
    ERROR = 'c-error',
    INHERIT = 'c-inherit',
    NORMAL = 'c-normal',
    WHITE = 'c-white',
}

export const IconColors: Record<EIconColor, string> = {
    [EIconColor.WHITE]: color.white,
    [EIconColor.BLACK]: color.black,
    [EIconColor.NORMAL]: color.black,
    [EIconColor.INHERIT]: 'currentColor',
    [EIconColor.DISABLED]: color.grey,
    [EIconColor.ERROR]: color.red,
    [EIconColor.ACTIVE]: color.black,
};

export type IconProps = {
    className?: string;
    color?: EIconColor;
    icon?: React.ReactNode;
    onClick?: () => void;
    size?: EIconSize;
    tabIndex?: number;
};

export default function Icon(props: IconProps): JSX.Element {
    const {
        color = EIconColor.NORMAL,
        size = EIconSize.NORMAL,
        icon,
        className,
        onClick,
        tabIndex,
    } = props;
    return (
        <StyledIcon
            onClick={onClick}
            tabIndex={tabIndex}
            className={clsx('icon', color, size, className, {
                clickable: !!onClick,
            })}>
            {icon}
        </StyledIcon>
    );
}

const StyledIcon = styled.span`
    color: inherit;
    font-size: 0;
    line-height: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & svg {
        width: 1rem;
        height: 1rem;
        & path {
            transform: scale(1);
        }
    }

    &.clickable {
        cursor: pointer;
    }

    &.${EIconColor.WHITE} svg {
        fill: ${IconColors[EIconColor.WHITE]};
    }
    &.${EIconColor.BLACK} svg {
        fill: ${IconColors[EIconColor.BLACK]};
    }
    &.${EIconColor.NORMAL} svg {
        fill: ${IconColors[EIconColor.NORMAL]};
    }
    &.${EIconColor.INHERIT} svg {
        fill: ${IconColors[EIconColor.INHERIT]};
    }
    &.${EIconColor.DISABLED} svg {
        fill: ${IconColors[EIconColor.DISABLED]};
    }
    &.${EIconColor.ERROR} svg {
        fill: ${IconColors[EIconColor.ERROR]};
    }
    &.${EIconColor.ACTIVE} svg {
        fill: ${IconColors[EIconColor.ACTIVE]};
    }

    &.${EIconSize.TINY} {
        width: 1rem;
        height: 1rem;
        & svg {
            width: 1rem;
            height: 1rem;
            & path {
                transform: scale(0.75);
                transform-origin: 50% 50%;
            }
        }
    }
    &.${EIconSize.SMALL} {
        width: 1rem;
        height: 1rem;
        & svg {
            width: 1rem;
            height: 1rem;

            & path {
                transform: scale(1);
            }
        }
    }
    &.${EIconSize.NORMAL} {
        width: 2rem;
        height: 2rem;

        & svg {
            width: 1rem;
            height: 1rem;

            & path {
                transform: scale(1);
            }
        }
    }
    &.${EIconSize.LARGE} {
        width: 2rem;
        height: 2rem;

        & svg {
            width: 1.5rem;
            height: 1.5rem;

            & path {
                transform: scale(1.5);
            }
        }
    }
    &.${EIconSize.TRIM} {
        width: 1rem;
        height: 1rem;

        & svg {
            width: 1rem;
            height: 1rem;

            & path {
                transform: scale(1);
            }
        }
    }
`;
