import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import FormControl, { FormControlProps } from '~components/form/FormControl';
import IconCross from '~components/icons/IconCross';
import Input, { InputProps } from '~components/form/Input';
import React from 'react';

export type ControlledInputProps<TFieldValues extends FieldValues = FieldValues> = Pick<
    ControllerProps<TFieldValues>,
    'control' | 'name'
> &
    FormControlProps &
    InputProps & {
        clearable?: boolean;
        showError?: boolean;
    };

export default function ControlledInput<TFieldValues extends FieldValues = FieldValues>(
    props: ControlledInputProps<TFieldValues>,
): JSX.Element {
    const {
        control,
        name,
        label,
        id = randomId(),
        required,
        clearable,
        icon,
        showError,
        className,
        ...inputProps
    } = props;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...fieldProps }, fieldState: { error } }) =>
                label || showError ? (
                    <FormControl
                        id={id}
                        required={required}
                        label={label}
                        error={error}
                        className={className}>
                        <Input
                            id={id}
                            required={required}
                            error={!!error}
                            forwardRef={ref}
                            {...inputProps}
                            {...fieldProps}
                        />
                    </FormControl>
                ) : (
                    <Input
                        id={id}
                        required={required}
                        className={className}
                        error={!!error}
                        icon={
                            clearable && fieldProps.value !== '' ? (
                                <IconCross onClick={() => fieldProps.onChange('')} />
                            ) : (
                                icon
                            )
                        }
                        forwardRef={ref}
                        {...inputProps}
                        {...fieldProps}
                    />
                )
            }
        />
    );
}

const randomId = () => 'id_' + Math.floor(Math.random() * 10000000).toString();
