import { ApolloProvider } from '@apollo/client';
import { AuthenticationProvider } from '~context/Authentication';
import { BrowserRouter } from 'react-router-dom';
import { LanguageProvider } from '~context/Language';
import { ModalProvider } from '~context/Modal';
import { PUBLIC_AUTH_URI } from '~constants/auth';
import AppRoutes from './AppRoutes';
import React from 'react';
import apolloClient from '~apolloClient';

export default function App(): JSX.Element {
    return (
        <ApolloProvider client={apolloClient}>
            <AuthenticationProvider authUrl={PUBLIC_AUTH_URI}>
                <BrowserRouter>
                    <LanguageProvider>
                        <ModalProvider>
                            <AppRoutes />
                        </ModalProvider>
                    </LanguageProvider>
                </BrowserRouter>
            </AuthenticationProvider>
        </ApolloProvider>
    );
}
