import * as Types from './schemas';

export type FBookingFragment = {
    __typename?: 'Booking';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    bookingNumber: string;
    dateFrom: Types.Scalars['Date']['output'];
    dateTo: Types.Scalars['Date']['output'];
    description?: string | null;
    name?: string | null;
    notes?: Array<string> | null;
    status: Types.EBookingStatus;
    space?: Types.Scalars['ObjectId']['output'] | null;
    spaceType: Types.Scalars['ObjectId']['output'];
    spaceTypeVariant: Types.Scalars['ObjectId']['output'];
    user: {
        __typename?: 'User';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        email: string;
        firstName: string;
        lastName: string;
        nationality?: string | null;
        passportNumber?: string | null;
        roles?: Array<Types.EUserRole> | null;
        updatedAt: Types.Scalars['Date']['output'];
        business?: {
            __typename?: 'Business';
            country: string;
            name: string;
            registrationNumber?: string | null;
            taxNumber?: string | null;
            contact?: {
                __typename?: 'BusinessContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        } | null;
        category: {
            __typename?: 'UserCategory';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            roles: Array<Types.EUserRole>;
        };
        contact?: {
            __typename?: 'UserContact';
            address?: Array<{
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            }> | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        } | null;
    };
};

export type FBookingAvailabilityFragment = {
    __typename?: 'BookingAvailability';
    availability: Types.EBookingAvailability;
    reason?: Types.EBookingUnavailabilityReason | null;
};

export type FBookingChoiceFragment = {
    __typename?: 'BookingChoice';
    dateFrom: Types.Scalars['Date']['output'];
    dateTo: Types.Scalars['Date']['output'];
    space?: string | null;
    spaceType: string;
    spaceTypeVariant: string;
};

export type FBookingOptionLimitsFragment = {
    __typename?: 'BookingOptionLimits';
    increment: number;
    max: number;
    min: number;
};

export type FBookingOptionRatesFragment = {
    __typename?: 'BookingOptionRates';
    max: number;
    min: number;
    price?: number | null;
    rate?: number | null;
};

export type FBookingOptionFragment = {
    __typename?: 'BookingOption';
    period?: Types.EBookingPeriod | null;
    limits?: {
        __typename?: 'BookingOptionLimits';
        increment: number;
        max: number;
        min: number;
    } | null;
    rates: Array<{
        __typename?: 'BookingOptionRates';
        max: number;
        min: number;
        price?: number | null;
        rate?: number | null;
    }>;
};

export type FBookingConfigurationFragment = {
    __typename?: 'BookingConfiguration';
    availabilityDates: Array<Types.Scalars['DateRange']['output']>;
    availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
    availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
    bookingOptions: Array<{
        __typename?: 'BookingOption';
        period?: Types.EBookingPeriod | null;
        limits?: {
            __typename?: 'BookingOptionLimits';
            increment: number;
            max: number;
            min: number;
        } | null;
        rates: Array<{
            __typename?: 'BookingOptionRates';
            max: number;
            min: number;
            price?: number | null;
            rate?: number | null;
        }>;
    }>;
    userCategories: Array<{
        __typename?: 'UserCategory';
        _id: Types.Scalars['ObjectId']['output'];
        name: string;
        roles: Array<Types.EUserRole>;
    }>;
};

export type FBusinessFragment = {
    __typename?: 'Business';
    country: string;
    name: string;
    registrationNumber?: string | null;
    taxNumber?: string | null;
    contact?: {
        __typename?: 'BusinessContact';
        address?: Array<{
            __typename?: 'ContactAddress';
            addressType: Types.EContactAddressType;
            city: string;
            country: string;
            line1: string;
            line2?: string | null;
            postalCode: string;
            state: string;
        }> | null;
        methods?: Array<{
            __typename?: 'ContactMethod';
            source: Types.EContactMethodSource;
            value: string;
        }> | null;
    } | null;
};

export type ConsumableFieldsFragment = {
    __typename?: 'Consumable';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    name: string;
    quantity: number;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type FContactMethodFragment = {
    __typename?: 'ContactMethod';
    source: Types.EContactMethodSource;
    value: string;
};

export type FContactFragment = {
    __typename?: 'Contact';
    description?: string | null;
    name: string;
    address?: {
        __typename?: 'ContactAddress';
        addressType: Types.EContactAddressType;
        city: string;
        country: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        state: string;
    } | null;
    methods?: Array<{
        __typename?: 'ContactMethod';
        source: Types.EContactMethodSource;
        value: string;
    }> | null;
};

export type FContactAddressFragment = {
    __typename?: 'ContactAddress';
    addressType: Types.EContactAddressType;
    city: string;
    country: string;
    line1: string;
    line2?: string | null;
    postalCode: string;
    state: string;
};

export type IngredientFieldsFragment = {
    __typename?: 'Ingredient';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    name: string;
    quantity: number;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type InventoryFieldsFragment = {
    __typename?: 'Inventory';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    itemType: Types.EInventoryItemType;
    quantity: number;
    volume?: number | null;
    item:
        | {
              __typename: 'Consumable';
              _id: Types.Scalars['ObjectId']['output'];
              createdAt: Types.Scalars['Date']['output'];
              updatedAt: Types.Scalars['Date']['output'];
              name: string;
              quantity: number;
              translations?: Array<{
                  __typename?: 'Translation';
                  language: Types.ELanguage;
                  name?: string | null;
                  description?: string | null;
              }> | null;
          }
        | {
              __typename: 'Ingredient';
              _id: Types.Scalars['ObjectId']['output'];
              createdAt: Types.Scalars['Date']['output'];
              updatedAt: Types.Scalars['Date']['output'];
              name: string;
              quantity: number;
              translations?: Array<{
                  __typename?: 'Translation';
                  language: Types.ELanguage;
                  name?: string | null;
                  description?: string | null;
              }> | null;
          };
};

export type InventoryItemFieldsFragment = {
    __typename?: 'InventoryItem';
    itemType: Types.EInventoryItemType;
    item:
        | {
              __typename: 'Consumable';
              _id: Types.Scalars['ObjectId']['output'];
              createdAt: Types.Scalars['Date']['output'];
              updatedAt: Types.Scalars['Date']['output'];
              name: string;
              quantity: number;
              translations?: Array<{
                  __typename?: 'Translation';
                  language: Types.ELanguage;
                  name?: string | null;
                  description?: string | null;
              }> | null;
          }
        | {
              __typename: 'Ingredient';
              _id: Types.Scalars['ObjectId']['output'];
              createdAt: Types.Scalars['Date']['output'];
              updatedAt: Types.Scalars['Date']['output'];
              name: string;
              quantity: number;
              translations?: Array<{
                  __typename?: 'Translation';
                  language: Types.ELanguage;
                  name?: string | null;
                  description?: string | null;
              }> | null;
          };
};

export type InventoryOrderFieldsFragment = {
    __typename?: 'InventoryOrder';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    status: Types.EInventoryOrderStatus;
    orderedAt: Types.Scalars['Date']['output'];
    orderItems?: Array<{
        __typename?: 'InventoryOrderItem';
        itemType: Types.EInventoryItemType;
        quantity: number;
        received?: boolean | null;
        receivedAt?: Types.Scalars['Date']['output'] | null;
        receivedQuantity?: number | null;
        item:
            | {
                  __typename: 'Consumable';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              }
            | {
                  __typename: 'Ingredient';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              };
    }> | null;
    supplier: {
        __typename?: 'Supplier';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        contacts?: Array<{
            __typename?: 'Contact';
            description?: string | null;
            name: string;
            address?: {
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            } | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    };
};

export type InventoryOrderItemFieldsFragment = {
    __typename?: 'InventoryOrderItem';
    itemType: Types.EInventoryItemType;
    quantity: number;
    received?: boolean | null;
    receivedAt?: Types.Scalars['Date']['output'] | null;
    receivedQuantity?: number | null;
    item:
        | {
              __typename: 'Consumable';
              _id: Types.Scalars['ObjectId']['output'];
              createdAt: Types.Scalars['Date']['output'];
              updatedAt: Types.Scalars['Date']['output'];
              name: string;
              quantity: number;
              translations?: Array<{
                  __typename?: 'Translation';
                  language: Types.ELanguage;
                  name?: string | null;
                  description?: string | null;
              }> | null;
          }
        | {
              __typename: 'Ingredient';
              _id: Types.Scalars['ObjectId']['output'];
              createdAt: Types.Scalars['Date']['output'];
              updatedAt: Types.Scalars['Date']['output'];
              name: string;
              quantity: number;
              translations?: Array<{
                  __typename?: 'Translation';
                  language: Types.ELanguage;
                  name?: string | null;
                  description?: string | null;
              }> | null;
          };
};

export type InventoryOrderSummaryFieldsFragment = {
    __typename?: 'InventoryOrderSummary';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    orderItemsCount: number;
    supplierId: Types.Scalars['ObjectId']['output'];
    supplierName: string;
    status: Types.EInventoryOrderStatus;
    orderedAt: Types.Scalars['Date']['output'];
};

export type FMediaFragment = {
    __typename?: 'Media';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    description?: string | null;
    mediaType: Types.EMediaType;
    name: string;
    tags?: Array<Types.Scalars['ObjectId']['output']> | null;
    metadata: {
        __typename?: 'MediaMetadata';
        duration?: number | null;
        extension: string;
        height?: number | null;
        mimeType: string;
        orientation?: Types.EMediaOrientation | null;
        size: number;
        width?: number | null;
    };
    s3: { __typename?: 'MediaS3'; key: string };
    source?: {
        __typename?: 'MediaSource';
        thumbnail?: string | null;
        small?: string | null;
        medium?: string | null;
        large?: string | null;
    } | null;
};

export type FMediaMetadataFragment = {
    __typename?: 'MediaMetadata';
    duration?: number | null;
    extension: string;
    height?: number | null;
    mimeType: string;
    orientation?: Types.EMediaOrientation | null;
    size: number;
    width?: number | null;
};

export type FMediaS3Fragment = { __typename?: 'MediaS3'; key: string };

export type MongoDocumentFieldsFragment = {};

export type OrderFieldsFragment = {
    __typename?: 'Order';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    deliveryTime: Types.Scalars['Date']['output'];
    orderNumber: string;
    status: Types.EOrderStatus;
    orderLines: Array<{
        __typename?: 'OrderLine';
        itemType: Types.EOrderLineItemType;
        item:
            | {
                  __typename: 'Product';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  active: boolean;
                  name: string;
                  price: number;
                  slug: string;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
                  tags?: Array<{
                      __typename?: 'Tag';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      schemas?: Array<Types.ETagSchema> | null;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  }> | null;
              }
            | {
                  __typename: 'ProductVariation';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  active: boolean;
                  costPrice?: number | null;
                  name: string;
                  price: number;
                  product: {
                      __typename?: 'Product';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      name: string;
                      price: number;
                      slug: string;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                      tags?: Array<{
                          __typename?: 'Tag';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          name: string;
                          schemas?: Array<Types.ETagSchema> | null;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                      }> | null;
                  };
                  recipe?: Array<{
                      __typename?: 'ProductVariationRecipeItem';
                      quantity: number;
                      ingredient: {
                          __typename?: 'Ingredient';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          name: string;
                          quantity: number;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                      };
                  }> | null;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              };
    }>;
};

export type OrderLineFieldsFragment = {
    __typename?: 'OrderLine';
    itemType: Types.EOrderLineItemType;
    item:
        | {
              __typename: 'Product';
              _id: Types.Scalars['ObjectId']['output'];
              createdAt: Types.Scalars['Date']['output'];
              updatedAt: Types.Scalars['Date']['output'];
              active: boolean;
              name: string;
              price: number;
              slug: string;
              translations?: Array<{
                  __typename?: 'Translation';
                  language: Types.ELanguage;
                  name?: string | null;
                  description?: string | null;
              }> | null;
              tags?: Array<{
                  __typename?: 'Tag';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  schemas?: Array<Types.ETagSchema> | null;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              }> | null;
          }
        | {
              __typename: 'ProductVariation';
              _id: Types.Scalars['ObjectId']['output'];
              createdAt: Types.Scalars['Date']['output'];
              updatedAt: Types.Scalars['Date']['output'];
              active: boolean;
              costPrice?: number | null;
              name: string;
              price: number;
              product: {
                  __typename?: 'Product';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  active: boolean;
                  name: string;
                  price: number;
                  slug: string;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
                  tags?: Array<{
                      __typename?: 'Tag';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      schemas?: Array<Types.ETagSchema> | null;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  }> | null;
              };
              recipe?: Array<{
                  __typename?: 'ProductVariationRecipeItem';
                  quantity: number;
                  ingredient: {
                      __typename?: 'Ingredient';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
              }> | null;
              translations?: Array<{
                  __typename?: 'Translation';
                  language: Types.ELanguage;
                  name?: string | null;
                  description?: string | null;
              }> | null;
          };
};

export type PageFieldsFragment = {
    __typename?: 'Page';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    name: string;
    slug: string;
    title: string;
};

export type ProductFieldsFragment = {
    __typename?: 'Product';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    active: boolean;
    name: string;
    price: number;
    slug: string;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
    tags?: Array<{
        __typename?: 'Tag';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        schemas?: Array<Types.ETagSchema> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type ProductVariationRecipeItemFieldsFragment = {
    __typename?: 'ProductVariationRecipeItem';
    quantity: number;
    ingredient: {
        __typename?: 'Ingredient';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    };
};

export type ProductVariationFieldsFragment = {
    __typename?: 'ProductVariation';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    active: boolean;
    costPrice?: number | null;
    name: string;
    price: number;
    product: {
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    };
    recipe?: Array<{
        __typename?: 'ProductVariationRecipeItem';
        quantity: number;
        ingredient: {
            __typename?: 'Ingredient';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            quantity: number;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        };
    }> | null;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type ProductVariationWithFullProductFieldsFragment = {
    __typename?: 'ProductVariationWithFullProduct';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    active: boolean;
    costPrice?: number | null;
    name: string;
    price: number;
    product: {
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    };
    recipe?: Array<{
        __typename?: 'ProductVariationRecipeItem';
        quantity: number;
        ingredient: {
            __typename?: 'Ingredient';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            quantity: number;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        };
    }> | null;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type FSpaceFragment = {
    __typename?: 'Space';
    _id: Types.Scalars['ObjectId']['output'];
    active: boolean;
    createdAt: Types.Scalars['Date']['output'];
    name: string;
    slug: string;
    updatedAt: Types.Scalars['Date']['output'];
    theme?: {
        __typename?: 'Theme';
        icon?: string | null;
        colorPrimary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
        colorSecondary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
    } | null;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type FSpaceTranslatedFragment = {
    __typename?: 'SpaceTranslated';
    _id: Types.Scalars['ObjectId']['output'];
    active: boolean;
    createdAt: Types.Scalars['Date']['output'];
    description?: string | null;
    name?: string | null;
    slug: string;
    updatedAt: Types.Scalars['Date']['output'];
    theme?: {
        __typename?: 'Theme';
        icon?: string | null;
        colorPrimary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
        colorSecondary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
    } | null;
};

export type FSpaceTypeFragment = {
    __typename?: 'SpaceType';
    _id: Types.Scalars['ObjectId']['output'];
    active: boolean;
    createdAt: Types.Scalars['Date']['output'];
    name: string;
    priority?: number | null;
    slug: string;
    updatedAt: Types.Scalars['Date']['output'];
    theme?: {
        __typename?: 'Theme';
        icon?: string | null;
        colorPrimary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
        colorSecondary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
    } | null;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
    variants?: Array<{
        __typename?: 'SpaceTypeVariant';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        price: number;
        priority?: number | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        bookingConfigurations?: Array<{
            __typename?: 'BookingConfiguration';
            availabilityDates: Array<Types.Scalars['DateRange']['output']>;
            availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
            availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
            bookingOptions: Array<{
                __typename?: 'BookingOption';
                period?: Types.EBookingPeriod | null;
                limits?: {
                    __typename?: 'BookingOptionLimits';
                    increment: number;
                    max: number;
                    min: number;
                } | null;
                rates: Array<{
                    __typename?: 'BookingOptionRates';
                    max: number;
                    min: number;
                    price?: number | null;
                    rate?: number | null;
                }>;
            }>;
            userCategories: Array<{
                __typename?: 'UserCategory';
                _id: Types.Scalars['ObjectId']['output'];
                name: string;
                roles: Array<Types.EUserRole>;
            }>;
        }> | null;
        spaces?: Array<{
            __typename?: 'Space';
            _id: Types.Scalars['ObjectId']['output'];
            active: boolean;
            createdAt: Types.Scalars['Date']['output'];
            name: string;
            slug: string;
            updatedAt: Types.Scalars['Date']['output'];
            theme?: {
                __typename?: 'Theme';
                icon?: string | null;
                colorPrimary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
                colorSecondary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
            } | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type FSpaceTypeTranslatedFragment = {
    __typename?: 'SpaceTypeTranslated';
    _id: Types.Scalars['ObjectId']['output'];
    active?: boolean | null;
    createdAt: Types.Scalars['Date']['output'];
    description?: string | null;
    name?: string | null;
    priority?: number | null;
    slug: string;
    updatedAt: Types.Scalars['Date']['output'];
    theme?: {
        __typename?: 'Theme';
        icon?: string | null;
        colorPrimary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
        colorSecondary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
    } | null;
    variants?: Array<{
        __typename?: 'SpaceTypeVariantTranslated';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        description?: string | null;
        name?: string | null;
        price: number;
        priority?: number | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        spaces?: Array<{
            __typename?: 'SpaceTranslated';
            _id: Types.Scalars['ObjectId']['output'];
            active: boolean;
            createdAt: Types.Scalars['Date']['output'];
            description?: string | null;
            name?: string | null;
            slug: string;
            updatedAt: Types.Scalars['Date']['output'];
            theme?: {
                __typename?: 'Theme';
                icon?: string | null;
                colorPrimary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
                colorSecondary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
            } | null;
        }> | null;
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
    }> | null;
};

export type FSpaceTypeVariantFragment = {
    __typename?: 'SpaceTypeVariant';
    _id: Types.Scalars['ObjectId']['output'];
    active: boolean;
    createdAt: Types.Scalars['Date']['output'];
    name: string;
    price: number;
    priority?: number | null;
    slug: string;
    updatedAt: Types.Scalars['Date']['output'];
    bookingConfigurations?: Array<{
        __typename?: 'BookingConfiguration';
        availabilityDates: Array<Types.Scalars['DateRange']['output']>;
        availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
        availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
        bookingOptions: Array<{
            __typename?: 'BookingOption';
            period?: Types.EBookingPeriod | null;
            limits?: {
                __typename?: 'BookingOptionLimits';
                increment: number;
                max: number;
                min: number;
            } | null;
            rates: Array<{
                __typename?: 'BookingOptionRates';
                max: number;
                min: number;
                price?: number | null;
                rate?: number | null;
            }>;
        }>;
        userCategories: Array<{
            __typename?: 'UserCategory';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            roles: Array<Types.EUserRole>;
        }>;
    }> | null;
    spaces?: Array<{
        __typename?: 'Space';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
    theme?: {
        __typename?: 'Theme';
        icon?: string | null;
        colorPrimary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
        colorSecondary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
    } | null;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type FSpaceTypeVariantNonPopulatedFragment = {
    __typename?: 'SpaceTypeVariantNotPopulated';
    _id: Types.Scalars['ObjectId']['output'];
    active: boolean;
    createdAt: Types.Scalars['Date']['output'];
    name: string;
    price: number;
    priority?: number | null;
    slug: string;
    spaces?: Array<Types.Scalars['ObjectId']['output']> | null;
    updatedAt: Types.Scalars['Date']['output'];
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
    theme?: {
        __typename?: 'Theme';
        icon?: string | null;
        colorPrimary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
        colorSecondary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
    } | null;
    bookingConfigurations?: Array<{
        __typename?: 'BookingConfiguration';
        availabilityDates: Array<Types.Scalars['DateRange']['output']>;
        availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
        availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
        bookingOptions: Array<{
            __typename?: 'BookingOption';
            period?: Types.EBookingPeriod | null;
            limits?: {
                __typename?: 'BookingOptionLimits';
                increment: number;
                max: number;
                min: number;
            } | null;
            rates: Array<{
                __typename?: 'BookingOptionRates';
                max: number;
                min: number;
                price?: number | null;
                rate?: number | null;
            }>;
        }>;
        userCategories: Array<{
            __typename?: 'UserCategory';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            roles: Array<Types.EUserRole>;
        }>;
    }> | null;
};

export type FSpaceTypeVariantTranslatedFragment = {
    __typename?: 'SpaceTypeVariantTranslated';
    _id: Types.Scalars['ObjectId']['output'];
    active: boolean;
    createdAt: Types.Scalars['Date']['output'];
    description?: string | null;
    name?: string | null;
    price: number;
    priority?: number | null;
    slug: string;
    updatedAt: Types.Scalars['Date']['output'];
    spaces?: Array<{
        __typename?: 'SpaceTranslated';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        description?: string | null;
        name?: string | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
    }> | null;
    theme?: {
        __typename?: 'Theme';
        icon?: string | null;
        colorPrimary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
        colorSecondary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
    } | null;
};

export type SupplierFieldsFragment = {
    __typename?: 'Supplier';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    name: string;
    contacts?: Array<{
        __typename?: 'Contact';
        description?: string | null;
        name: string;
        address?: {
            __typename?: 'ContactAddress';
            addressType: Types.EContactAddressType;
            city: string;
            country: string;
            line1: string;
            line2?: string | null;
            postalCode: string;
            state: string;
        } | null;
        methods?: Array<{
            __typename?: 'ContactMethod';
            source: Types.EContactMethodSource;
            value: string;
        }> | null;
    }> | null;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
    tags?: Array<{
        __typename?: 'Tag';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        schemas?: Array<Types.ETagSchema> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type TagFieldsFragment = {
    __typename?: 'Tag';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    name: string;
    schemas?: Array<Types.ETagSchema> | null;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type TagSimpleFieldsFragment = {
    __typename?: 'Tag';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    updatedAt: Types.Scalars['Date']['output'];
    name: string;
    schemas?: Array<Types.ETagSchema> | null;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type FThemeFragment = {
    __typename?: 'Theme';
    icon?: string | null;
    colorPrimary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
    colorSecondary?: { __typename?: 'Rgba'; a: number; b: number; g: number; r: number } | null;
};

export type FrRgbaFragment = { __typename?: 'Rgba'; a: number; b: number; g: number; r: number };

export type TranslationFieldsFragment = {
    __typename?: 'Translation';
    language: Types.ELanguage;
    name?: string | null;
    description?: string | null;
};

export type FUserFragment = {
    __typename?: 'User';
    _id: Types.Scalars['ObjectId']['output'];
    createdAt: Types.Scalars['Date']['output'];
    email: string;
    firstName: string;
    lastName: string;
    nationality?: string | null;
    passportNumber?: string | null;
    roles?: Array<Types.EUserRole> | null;
    updatedAt: Types.Scalars['Date']['output'];
    business?: {
        __typename?: 'Business';
        country: string;
        name: string;
        registrationNumber?: string | null;
        taxNumber?: string | null;
        contact?: {
            __typename?: 'BusinessContact';
            address?: Array<{
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            }> | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        } | null;
    } | null;
    category: {
        __typename?: 'UserCategory';
        _id: Types.Scalars['ObjectId']['output'];
        name: string;
        roles: Array<Types.EUserRole>;
    };
    contact?: {
        __typename?: 'UserContact';
        address?: Array<{
            __typename?: 'ContactAddress';
            addressType: Types.EContactAddressType;
            city: string;
            country: string;
            line1: string;
            line2?: string | null;
            postalCode: string;
            state: string;
        }> | null;
        methods?: Array<{
            __typename?: 'ContactMethod';
            source: Types.EContactMethodSource;
            value: string;
        }> | null;
    } | null;
};

export type FUserCategoryFragment = {
    __typename?: 'UserCategory';
    _id: Types.Scalars['ObjectId']['output'];
    name: string;
    roles: Array<Types.EUserRole>;
};

export type FWebProductWithVariationsFragment = {
    __typename?: 'WebProductWithVariations';
    _id: string;
    name: string;
    price: number;
    slug: string;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
    variations?: Array<{
        __typename?: 'WebProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        name: string;
        price: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type FWebProductVariationFragment = {
    __typename?: 'WebProductVariation';
    _id: Types.Scalars['ObjectId']['output'];
    name: string;
    price: number;
    translations?: Array<{
        __typename?: 'Translation';
        language: Types.ELanguage;
        name?: string | null;
        description?: string | null;
    }> | null;
};

export type BookingsQueryVariables = Types.Exact<{
    input: Types.BookingSearchInput;
}>;

export type BookingsQuery = {
    __typename?: 'Query';
    bookings?: Array<{
        __typename?: 'Booking';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        bookingNumber: string;
        dateFrom: Types.Scalars['Date']['output'];
        dateTo: Types.Scalars['Date']['output'];
        description?: string | null;
        name?: string | null;
        notes?: Array<string> | null;
        status: Types.EBookingStatus;
        space?: Types.Scalars['ObjectId']['output'] | null;
        spaceType: Types.Scalars['ObjectId']['output'];
        spaceTypeVariant: Types.Scalars['ObjectId']['output'];
        user: {
            __typename?: 'User';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            email: string;
            firstName: string;
            lastName: string;
            nationality?: string | null;
            passportNumber?: string | null;
            roles?: Array<Types.EUserRole> | null;
            updatedAt: Types.Scalars['Date']['output'];
            business?: {
                __typename?: 'Business';
                country: string;
                name: string;
                registrationNumber?: string | null;
                taxNumber?: string | null;
                contact?: {
                    __typename?: 'BusinessContact';
                    address?: Array<{
                        __typename?: 'ContactAddress';
                        addressType: Types.EContactAddressType;
                        city: string;
                        country: string;
                        line1: string;
                        line2?: string | null;
                        postalCode: string;
                        state: string;
                    }> | null;
                    methods?: Array<{
                        __typename?: 'ContactMethod';
                        source: Types.EContactMethodSource;
                        value: string;
                    }> | null;
                } | null;
            } | null;
            category: {
                __typename?: 'UserCategory';
                _id: Types.Scalars['ObjectId']['output'];
                name: string;
                roles: Array<Types.EUserRole>;
            };
            contact?: {
                __typename?: 'UserContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        };
    }> | null;
};

export type BookingQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type BookingQuery = {
    __typename?: 'Query';
    booking?: {
        __typename?: 'Booking';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        bookingNumber: string;
        dateFrom: Types.Scalars['Date']['output'];
        dateTo: Types.Scalars['Date']['output'];
        description?: string | null;
        name?: string | null;
        notes?: Array<string> | null;
        status: Types.EBookingStatus;
        space?: Types.Scalars['ObjectId']['output'] | null;
        spaceType: Types.Scalars['ObjectId']['output'];
        spaceTypeVariant: Types.Scalars['ObjectId']['output'];
        user: {
            __typename?: 'User';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            email: string;
            firstName: string;
            lastName: string;
            nationality?: string | null;
            passportNumber?: string | null;
            roles?: Array<Types.EUserRole> | null;
            updatedAt: Types.Scalars['Date']['output'];
            business?: {
                __typename?: 'Business';
                country: string;
                name: string;
                registrationNumber?: string | null;
                taxNumber?: string | null;
                contact?: {
                    __typename?: 'BusinessContact';
                    address?: Array<{
                        __typename?: 'ContactAddress';
                        addressType: Types.EContactAddressType;
                        city: string;
                        country: string;
                        line1: string;
                        line2?: string | null;
                        postalCode: string;
                        state: string;
                    }> | null;
                    methods?: Array<{
                        __typename?: 'ContactMethod';
                        source: Types.EContactMethodSource;
                        value: string;
                    }> | null;
                } | null;
            } | null;
            category: {
                __typename?: 'UserCategory';
                _id: Types.Scalars['ObjectId']['output'];
                name: string;
                roles: Array<Types.EUserRole>;
            };
            contact?: {
                __typename?: 'UserContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        };
    } | null;
};

export type BookingAvailableQueryVariables = Types.Exact<{
    input: Types.BookingAvailableInput;
}>;

export type BookingAvailableQuery = {
    __typename?: 'Query';
    bookingAvailable?: {
        __typename?: 'BookingAvailability';
        availability: Types.EBookingAvailability;
        reason?: Types.EBookingUnavailabilityReason | null;
    } | null;
};

export type BookingChoicesQueryVariables = Types.Exact<{
    input: Types.BookingChoiceInput;
}>;

export type BookingChoicesQuery = {
    __typename?: 'Query';
    bookingChoices?: Array<{
        __typename?: 'BookingChoice';
        dateFrom: Types.Scalars['Date']['output'];
        dateTo: Types.Scalars['Date']['output'];
        space?: string | null;
        spaceType: string;
        spaceTypeVariant: string;
    }> | null;
};

export type BookingCreateMutationVariables = Types.Exact<{
    input: Types.BookingCreate;
}>;

export type BookingCreateMutation = {
    __typename?: 'Mutation';
    bookingCreate?: {
        __typename?: 'Booking';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        bookingNumber: string;
        dateFrom: Types.Scalars['Date']['output'];
        dateTo: Types.Scalars['Date']['output'];
        description?: string | null;
        name?: string | null;
        notes?: Array<string> | null;
        status: Types.EBookingStatus;
        space?: Types.Scalars['ObjectId']['output'] | null;
        spaceType: Types.Scalars['ObjectId']['output'];
        spaceTypeVariant: Types.Scalars['ObjectId']['output'];
        user: {
            __typename?: 'User';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            email: string;
            firstName: string;
            lastName: string;
            nationality?: string | null;
            passportNumber?: string | null;
            roles?: Array<Types.EUserRole> | null;
            updatedAt: Types.Scalars['Date']['output'];
            business?: {
                __typename?: 'Business';
                country: string;
                name: string;
                registrationNumber?: string | null;
                taxNumber?: string | null;
                contact?: {
                    __typename?: 'BusinessContact';
                    address?: Array<{
                        __typename?: 'ContactAddress';
                        addressType: Types.EContactAddressType;
                        city: string;
                        country: string;
                        line1: string;
                        line2?: string | null;
                        postalCode: string;
                        state: string;
                    }> | null;
                    methods?: Array<{
                        __typename?: 'ContactMethod';
                        source: Types.EContactMethodSource;
                        value: string;
                    }> | null;
                } | null;
            } | null;
            category: {
                __typename?: 'UserCategory';
                _id: Types.Scalars['ObjectId']['output'];
                name: string;
                roles: Array<Types.EUserRole>;
            };
            contact?: {
                __typename?: 'UserContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        };
    } | null;
};

export type BookingUpdateMutationVariables = Types.Exact<{
    input: Types.BookingUpdate;
}>;

export type BookingUpdateMutation = {
    __typename?: 'Mutation';
    bookingUpdate?: {
        __typename?: 'Booking';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        bookingNumber: string;
        dateFrom: Types.Scalars['Date']['output'];
        dateTo: Types.Scalars['Date']['output'];
        description?: string | null;
        name?: string | null;
        notes?: Array<string> | null;
        status: Types.EBookingStatus;
        space?: Types.Scalars['ObjectId']['output'] | null;
        spaceType: Types.Scalars['ObjectId']['output'];
        spaceTypeVariant: Types.Scalars['ObjectId']['output'];
        user: {
            __typename?: 'User';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            email: string;
            firstName: string;
            lastName: string;
            nationality?: string | null;
            passportNumber?: string | null;
            roles?: Array<Types.EUserRole> | null;
            updatedAt: Types.Scalars['Date']['output'];
            business?: {
                __typename?: 'Business';
                country: string;
                name: string;
                registrationNumber?: string | null;
                taxNumber?: string | null;
                contact?: {
                    __typename?: 'BusinessContact';
                    address?: Array<{
                        __typename?: 'ContactAddress';
                        addressType: Types.EContactAddressType;
                        city: string;
                        country: string;
                        line1: string;
                        line2?: string | null;
                        postalCode: string;
                        state: string;
                    }> | null;
                    methods?: Array<{
                        __typename?: 'ContactMethod';
                        source: Types.EContactMethodSource;
                        value: string;
                    }> | null;
                } | null;
            } | null;
            category: {
                __typename?: 'UserCategory';
                _id: Types.Scalars['ObjectId']['output'];
                name: string;
                roles: Array<Types.EUserRole>;
            };
            contact?: {
                __typename?: 'UserContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        };
    } | null;
};

export type ConsumablesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ConsumablesQuery = {
    __typename?: 'Query';
    consumables?: Array<{
        __typename?: 'Consumable';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type ConsumableQueryVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type ConsumableQuery = {
    __typename?: 'Query';
    consumable?: {
        __typename?: 'Consumable';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type ConsumableCreateMutationVariables = Types.Exact<{
    input: Types.ConsumableCreate;
}>;

export type ConsumableCreateMutation = {
    __typename?: 'Mutation';
    consumableCreate?: {
        __typename?: 'Consumable';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type ConsumableUpdateMutationVariables = Types.Exact<{
    input: Types.ConsumableUpdate;
}>;

export type ConsumableUpdateMutation = {
    __typename?: 'Mutation';
    consumableUpdate?: {
        __typename?: 'Consumable';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type IngredientsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IngredientsQuery = {
    __typename?: 'Query';
    ingredients?: Array<{
        __typename?: 'Ingredient';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type IngredientQueryVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type IngredientQuery = {
    __typename?: 'Query';
    ingredient?: {
        __typename?: 'Ingredient';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type IngredientCreateMutationVariables = Types.Exact<{
    input: Types.IngredientCreate;
}>;

export type IngredientCreateMutation = {
    __typename?: 'Mutation';
    ingredientCreate?: {
        __typename?: 'Ingredient';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type IngredientUpdateMutationVariables = Types.Exact<{
    input: Types.IngredientUpdate;
}>;

export type IngredientUpdateMutation = {
    __typename?: 'Mutation';
    ingredientUpdate?: {
        __typename?: 'Ingredient';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        quantity: number;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type InventoryQueryVariables = Types.Exact<{ [key: string]: never }>;

export type InventoryQuery = {
    __typename?: 'Query';
    inventory?: Array<{
        __typename?: 'Inventory';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        itemType: Types.EInventoryItemType;
        quantity: number;
        volume?: number | null;
        item:
            | {
                  __typename: 'Consumable';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              }
            | {
                  __typename: 'Ingredient';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              };
    }> | null;
};

export type InventoryByItemQueryVariables = Types.Exact<{
    itemId: Types.Scalars['ID']['input'];
}>;

export type InventoryByItemQuery = {
    __typename?: 'Query';
    inventoryByItem?: {
        __typename?: 'Inventory';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        itemType: Types.EInventoryItemType;
        quantity: number;
        volume?: number | null;
        item:
            | {
                  __typename: 'Consumable';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              }
            | {
                  __typename: 'Ingredient';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              };
    } | null;
};

export type InventoryRecordQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type InventoryRecordQuery = {
    __typename?: 'Query';
    inventoryRecord?: {
        __typename?: 'Inventory';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        itemType: Types.EInventoryItemType;
        quantity: number;
        volume?: number | null;
        item:
            | {
                  __typename: 'Consumable';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              }
            | {
                  __typename: 'Ingredient';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              };
    } | null;
};

export type InventoryItemsQueryVariables = Types.Exact<{
    type?: Types.InputMaybe<Types.EInventoryItemType>;
}>;

export type InventoryItemsQuery = {
    __typename?: 'Query';
    inventoryItems?: Array<{
        __typename?: 'InventoryItem';
        itemType: Types.EInventoryItemType;
        item:
            | {
                  __typename: 'Consumable';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              }
            | {
                  __typename: 'Ingredient';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              };
    }> | null;
};

export type InventoryRecordCreateMutationVariables = Types.Exact<{
    input: Types.InventoryRecordCreate;
}>;

export type InventoryRecordCreateMutation = {
    __typename?: 'Mutation';
    inventoryRecordCreate?: {
        __typename?: 'Inventory';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        itemType: Types.EInventoryItemType;
        quantity: number;
        volume?: number | null;
        item:
            | {
                  __typename: 'Consumable';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              }
            | {
                  __typename: 'Ingredient';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              };
    } | null;
};

export type InventoryRecordUpdateMutationVariables = Types.Exact<{
    input: Types.InventoryRecordUpdate;
}>;

export type InventoryRecordUpdateMutation = {
    __typename?: 'Mutation';
    inventoryRecordUpdate?: {
        __typename?: 'Inventory';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        itemType: Types.EInventoryItemType;
        quantity: number;
        volume?: number | null;
        item:
            | {
                  __typename: 'Consumable';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              }
            | {
                  __typename: 'Ingredient';
                  _id: Types.Scalars['ObjectId']['output'];
                  createdAt: Types.Scalars['Date']['output'];
                  updatedAt: Types.Scalars['Date']['output'];
                  name: string;
                  quantity: number;
                  translations?: Array<{
                      __typename?: 'Translation';
                      language: Types.ELanguage;
                      name?: string | null;
                      description?: string | null;
                  }> | null;
              };
    } | null;
};

export type InventoryOrdersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type InventoryOrdersQuery = {
    __typename?: 'Query';
    inventoryOrders?: Array<{
        __typename?: 'InventoryOrderSummary';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        orderItemsCount: number;
        supplierId: Types.Scalars['ObjectId']['output'];
        supplierName: string;
        status: Types.EInventoryOrderStatus;
        orderedAt: Types.Scalars['Date']['output'];
    }> | null;
};

export type InventoryOrderQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type InventoryOrderQuery = {
    __typename?: 'Query';
    inventoryOrder?: {
        __typename?: 'InventoryOrder';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        status: Types.EInventoryOrderStatus;
        orderedAt: Types.Scalars['Date']['output'];
        orderItems?: Array<{
            __typename?: 'InventoryOrderItem';
            itemType: Types.EInventoryItemType;
            quantity: number;
            received?: boolean | null;
            receivedAt?: Types.Scalars['Date']['output'] | null;
            receivedQuantity?: number | null;
            item:
                | {
                      __typename: 'Consumable';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  }
                | {
                      __typename: 'Ingredient';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
        }> | null;
        supplier: {
            __typename?: 'Supplier';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            contacts?: Array<{
                __typename?: 'Contact';
                description?: string | null;
                name: string;
                address?: {
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                } | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            }> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
    } | null;
};

export type InventoryOrdersByItemQueryVariables = Types.Exact<{
    itemId: Types.Scalars['ID']['input'];
}>;

export type InventoryOrdersByItemQuery = {
    __typename?: 'Query';
    inventoryOrders?: Array<{
        __typename?: 'InventoryOrder';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        status: Types.EInventoryOrderStatus;
        orderedAt: Types.Scalars['Date']['output'];
        orderItems?: Array<{
            __typename?: 'InventoryOrderItem';
            itemType: Types.EInventoryItemType;
            quantity: number;
            received?: boolean | null;
            receivedAt?: Types.Scalars['Date']['output'] | null;
            receivedQuantity?: number | null;
            item:
                | {
                      __typename: 'Consumable';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  }
                | {
                      __typename: 'Ingredient';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
        }> | null;
        supplier: {
            __typename?: 'Supplier';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            contacts?: Array<{
                __typename?: 'Contact';
                description?: string | null;
                name: string;
                address?: {
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                } | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            }> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
    }> | null;
};

export type InventoryOrderCreateMutationVariables = Types.Exact<{
    input: Types.InventoryOrderCreate;
}>;

export type InventoryOrderCreateMutation = {
    __typename?: 'Mutation';
    inventoryOrderCreate?: {
        __typename?: 'InventoryOrder';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        status: Types.EInventoryOrderStatus;
        orderedAt: Types.Scalars['Date']['output'];
        orderItems?: Array<{
            __typename?: 'InventoryOrderItem';
            itemType: Types.EInventoryItemType;
            quantity: number;
            received?: boolean | null;
            receivedAt?: Types.Scalars['Date']['output'] | null;
            receivedQuantity?: number | null;
            item:
                | {
                      __typename: 'Consumable';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  }
                | {
                      __typename: 'Ingredient';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
        }> | null;
        supplier: {
            __typename?: 'Supplier';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            contacts?: Array<{
                __typename?: 'Contact';
                description?: string | null;
                name: string;
                address?: {
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                } | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            }> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
    } | null;
};

export type InventoryOrderUpdateMutationVariables = Types.Exact<{
    input: Types.InventoryOrderUpdate;
}>;

export type InventoryOrderUpdateMutation = {
    __typename?: 'Mutation';
    inventoryOrderUpdate?: {
        __typename?: 'InventoryOrder';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        status: Types.EInventoryOrderStatus;
        orderedAt: Types.Scalars['Date']['output'];
        orderItems?: Array<{
            __typename?: 'InventoryOrderItem';
            itemType: Types.EInventoryItemType;
            quantity: number;
            received?: boolean | null;
            receivedAt?: Types.Scalars['Date']['output'] | null;
            receivedQuantity?: number | null;
            item:
                | {
                      __typename: 'Consumable';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  }
                | {
                      __typename: 'Ingredient';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      name: string;
                      quantity: number;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
        }> | null;
        supplier: {
            __typename?: 'Supplier';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            contacts?: Array<{
                __typename?: 'Contact';
                description?: string | null;
                name: string;
                address?: {
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                } | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            }> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
    } | null;
};

export type MediaQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MediaQuery = {
    __typename?: 'Query';
    media: Array<{
        __typename?: 'Media';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        description?: string | null;
        mediaType: Types.EMediaType;
        name: string;
        tags?: Array<Types.Scalars['ObjectId']['output']> | null;
        metadata: {
            __typename?: 'MediaMetadata';
            duration?: number | null;
            extension: string;
            height?: number | null;
            mimeType: string;
            orientation?: Types.EMediaOrientation | null;
            size: number;
            width?: number | null;
        };
        s3: { __typename?: 'MediaS3'; key: string };
        source?: {
            __typename?: 'MediaSource';
            thumbnail?: string | null;
            small?: string | null;
            medium?: string | null;
            large?: string | null;
        } | null;
    }>;
};

export type MediaRecordQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type MediaRecordQuery = {
    __typename?: 'Query';
    mediaRecord?: {
        __typename?: 'Media';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        description?: string | null;
        mediaType: Types.EMediaType;
        name: string;
        tags?: Array<Types.Scalars['ObjectId']['output']> | null;
        metadata: {
            __typename?: 'MediaMetadata';
            duration?: number | null;
            extension: string;
            height?: number | null;
            mimeType: string;
            orientation?: Types.EMediaOrientation | null;
            size: number;
            width?: number | null;
        };
        s3: { __typename?: 'MediaS3'; key: string };
        source?: {
            __typename?: 'MediaSource';
            thumbnail?: string | null;
            small?: string | null;
            medium?: string | null;
            large?: string | null;
        } | null;
    } | null;
};

export type MediaUploadedSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type MediaUploadedSubscription = {
    __typename?: 'Subscription';
    media?: Array<{
        __typename?: 'Media';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        description?: string | null;
        mediaType: Types.EMediaType;
        name: string;
        tags?: Array<Types.Scalars['ObjectId']['output']> | null;
        metadata: {
            __typename?: 'MediaMetadata';
            duration?: number | null;
            extension: string;
            height?: number | null;
            mimeType: string;
            orientation?: Types.EMediaOrientation | null;
            size: number;
            width?: number | null;
        };
        s3: { __typename?: 'MediaS3'; key: string };
        source?: {
            __typename?: 'MediaSource';
            thumbnail?: string | null;
            small?: string | null;
            medium?: string | null;
            large?: string | null;
        } | null;
    }> | null;
};

export type OrdersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OrdersQuery = {
    __typename?: 'Query';
    orders?: Array<{
        __typename?: 'Order';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        deliveryTime: Types.Scalars['Date']['output'];
        orderNumber: string;
        status: Types.EOrderStatus;
        orderLines: Array<{
            __typename?: 'OrderLine';
            itemType: Types.EOrderLineItemType;
            item:
                | {
                      __typename: 'Product';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      name: string;
                      price: number;
                      slug: string;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                      tags?: Array<{
                          __typename?: 'Tag';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          name: string;
                          schemas?: Array<Types.ETagSchema> | null;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                      }> | null;
                  }
                | {
                      __typename: 'ProductVariation';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      costPrice?: number | null;
                      name: string;
                      price: number;
                      product: {
                          __typename?: 'Product';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          active: boolean;
                          name: string;
                          price: number;
                          slug: string;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                          tags?: Array<{
                              __typename?: 'Tag';
                              _id: Types.Scalars['ObjectId']['output'];
                              createdAt: Types.Scalars['Date']['output'];
                              updatedAt: Types.Scalars['Date']['output'];
                              name: string;
                              schemas?: Array<Types.ETagSchema> | null;
                              translations?: Array<{
                                  __typename?: 'Translation';
                                  language: Types.ELanguage;
                                  name?: string | null;
                                  description?: string | null;
                              }> | null;
                          }> | null;
                      };
                      recipe?: Array<{
                          __typename?: 'ProductVariationRecipeItem';
                          quantity: number;
                          ingredient: {
                              __typename?: 'Ingredient';
                              _id: Types.Scalars['ObjectId']['output'];
                              createdAt: Types.Scalars['Date']['output'];
                              updatedAt: Types.Scalars['Date']['output'];
                              name: string;
                              quantity: number;
                              translations?: Array<{
                                  __typename?: 'Translation';
                                  language: Types.ELanguage;
                                  name?: string | null;
                                  description?: string | null;
                              }> | null;
                          };
                      }> | null;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
        }>;
    }> | null;
};

export type OrderQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type OrderQuery = {
    __typename?: 'Query';
    order?: {
        __typename?: 'Order';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        deliveryTime: Types.Scalars['Date']['output'];
        orderNumber: string;
        status: Types.EOrderStatus;
        orderLines: Array<{
            __typename?: 'OrderLine';
            itemType: Types.EOrderLineItemType;
            item:
                | {
                      __typename: 'Product';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      name: string;
                      price: number;
                      slug: string;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                      tags?: Array<{
                          __typename?: 'Tag';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          name: string;
                          schemas?: Array<Types.ETagSchema> | null;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                      }> | null;
                  }
                | {
                      __typename: 'ProductVariation';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      costPrice?: number | null;
                      name: string;
                      price: number;
                      product: {
                          __typename?: 'Product';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          active: boolean;
                          name: string;
                          price: number;
                          slug: string;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                          tags?: Array<{
                              __typename?: 'Tag';
                              _id: Types.Scalars['ObjectId']['output'];
                              createdAt: Types.Scalars['Date']['output'];
                              updatedAt: Types.Scalars['Date']['output'];
                              name: string;
                              schemas?: Array<Types.ETagSchema> | null;
                              translations?: Array<{
                                  __typename?: 'Translation';
                                  language: Types.ELanguage;
                                  name?: string | null;
                                  description?: string | null;
                              }> | null;
                          }> | null;
                      };
                      recipe?: Array<{
                          __typename?: 'ProductVariationRecipeItem';
                          quantity: number;
                          ingredient: {
                              __typename?: 'Ingredient';
                              _id: Types.Scalars['ObjectId']['output'];
                              createdAt: Types.Scalars['Date']['output'];
                              updatedAt: Types.Scalars['Date']['output'];
                              name: string;
                              quantity: number;
                              translations?: Array<{
                                  __typename?: 'Translation';
                                  language: Types.ELanguage;
                                  name?: string | null;
                                  description?: string | null;
                              }> | null;
                          };
                      }> | null;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
        }>;
    } | null;
};

export type OrderCreateMutationVariables = Types.Exact<{
    input: Types.OrderCreate;
}>;

export type OrderCreateMutation = {
    __typename?: 'Mutation';
    orderCreate?: {
        __typename?: 'Order';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        deliveryTime: Types.Scalars['Date']['output'];
        orderNumber: string;
        status: Types.EOrderStatus;
        orderLines: Array<{
            __typename?: 'OrderLine';
            itemType: Types.EOrderLineItemType;
            item:
                | {
                      __typename: 'Product';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      name: string;
                      price: number;
                      slug: string;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                      tags?: Array<{
                          __typename?: 'Tag';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          name: string;
                          schemas?: Array<Types.ETagSchema> | null;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                      }> | null;
                  }
                | {
                      __typename: 'ProductVariation';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      costPrice?: number | null;
                      name: string;
                      price: number;
                      product: {
                          __typename?: 'Product';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          active: boolean;
                          name: string;
                          price: number;
                          slug: string;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                          tags?: Array<{
                              __typename?: 'Tag';
                              _id: Types.Scalars['ObjectId']['output'];
                              createdAt: Types.Scalars['Date']['output'];
                              updatedAt: Types.Scalars['Date']['output'];
                              name: string;
                              schemas?: Array<Types.ETagSchema> | null;
                              translations?: Array<{
                                  __typename?: 'Translation';
                                  language: Types.ELanguage;
                                  name?: string | null;
                                  description?: string | null;
                              }> | null;
                          }> | null;
                      };
                      recipe?: Array<{
                          __typename?: 'ProductVariationRecipeItem';
                          quantity: number;
                          ingredient: {
                              __typename?: 'Ingredient';
                              _id: Types.Scalars['ObjectId']['output'];
                              createdAt: Types.Scalars['Date']['output'];
                              updatedAt: Types.Scalars['Date']['output'];
                              name: string;
                              quantity: number;
                              translations?: Array<{
                                  __typename?: 'Translation';
                                  language: Types.ELanguage;
                                  name?: string | null;
                                  description?: string | null;
                              }> | null;
                          };
                      }> | null;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
        }>;
    } | null;
};

export type OrderUpdateMutationVariables = Types.Exact<{
    input: Types.OrderUpdate;
}>;

export type OrderUpdateMutation = {
    __typename?: 'Mutation';
    orderUpdate?: {
        __typename?: 'Order';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        deliveryTime: Types.Scalars['Date']['output'];
        orderNumber: string;
        status: Types.EOrderStatus;
        orderLines: Array<{
            __typename?: 'OrderLine';
            itemType: Types.EOrderLineItemType;
            item:
                | {
                      __typename: 'Product';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      name: string;
                      price: number;
                      slug: string;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                      tags?: Array<{
                          __typename?: 'Tag';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          name: string;
                          schemas?: Array<Types.ETagSchema> | null;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                      }> | null;
                  }
                | {
                      __typename: 'ProductVariation';
                      _id: Types.Scalars['ObjectId']['output'];
                      createdAt: Types.Scalars['Date']['output'];
                      updatedAt: Types.Scalars['Date']['output'];
                      active: boolean;
                      costPrice?: number | null;
                      name: string;
                      price: number;
                      product: {
                          __typename?: 'Product';
                          _id: Types.Scalars['ObjectId']['output'];
                          createdAt: Types.Scalars['Date']['output'];
                          updatedAt: Types.Scalars['Date']['output'];
                          active: boolean;
                          name: string;
                          price: number;
                          slug: string;
                          translations?: Array<{
                              __typename?: 'Translation';
                              language: Types.ELanguage;
                              name?: string | null;
                              description?: string | null;
                          }> | null;
                          tags?: Array<{
                              __typename?: 'Tag';
                              _id: Types.Scalars['ObjectId']['output'];
                              createdAt: Types.Scalars['Date']['output'];
                              updatedAt: Types.Scalars['Date']['output'];
                              name: string;
                              schemas?: Array<Types.ETagSchema> | null;
                              translations?: Array<{
                                  __typename?: 'Translation';
                                  language: Types.ELanguage;
                                  name?: string | null;
                                  description?: string | null;
                              }> | null;
                          }> | null;
                      };
                      recipe?: Array<{
                          __typename?: 'ProductVariationRecipeItem';
                          quantity: number;
                          ingredient: {
                              __typename?: 'Ingredient';
                              _id: Types.Scalars['ObjectId']['output'];
                              createdAt: Types.Scalars['Date']['output'];
                              updatedAt: Types.Scalars['Date']['output'];
                              name: string;
                              quantity: number;
                              translations?: Array<{
                                  __typename?: 'Translation';
                                  language: Types.ELanguage;
                                  name?: string | null;
                                  description?: string | null;
                              }> | null;
                          };
                      }> | null;
                      translations?: Array<{
                          __typename?: 'Translation';
                          language: Types.ELanguage;
                          name?: string | null;
                          description?: string | null;
                      }> | null;
                  };
        }>;
    } | null;
};

export type PagesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PagesQuery = {
    __typename?: 'Query';
    pages?: Array<{
        __typename?: 'Page';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        title: string;
    } | null> | null;
};

export type PageQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type PageQuery = {
    __typename?: 'Query';
    page?: {
        __typename?: 'Page';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        title: string;
    } | null;
};

export type PageBySlugQueryVariables = Types.Exact<{
    slug: Types.Scalars['String']['input'];
}>;

export type PageBySlugQuery = {
    __typename?: 'Query';
    page?: {
        __typename?: 'Page';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        title: string;
    } | null;
};

export type PageCreateMutationVariables = Types.Exact<{
    input: Types.PageCreate;
}>;

export type PageCreateMutation = {
    __typename?: 'Mutation';
    pageCreate?: {
        __typename?: 'Page';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        title: string;
    } | null;
};

export type PageUpdateMutationVariables = Types.Exact<{
    input: Types.PageUpdate;
}>;

export type PageUpdateMutation = {
    __typename?: 'Mutation';
    pageUpdate?: {
        __typename?: 'Page';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        title: string;
    } | null;
};

export type ProductsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProductsQuery = {
    __typename?: 'Query';
    products?: Array<{
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    }> | null;
};

export type ProductQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type ProductQuery = {
    __typename?: 'Query';
    product?: {
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
    variations?: Array<{
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type ProductBySlugQueryVariables = Types.Exact<{
    slug: Types.Scalars['String']['input'];
}>;

export type ProductBySlugQuery = {
    __typename?: 'Query';
    product?: {
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type ProductsByTagQueryVariables = Types.Exact<{
    tagId: Types.Scalars['ID']['input'];
}>;

export type ProductsByTagQuery = {
    __typename?: 'Query';
    productsByTag?: Array<{
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    }> | null;
};

export type ProductsByIngredientQueryVariables = Types.Exact<{
    ingredientId: Types.Scalars['ID']['input'];
}>;

export type ProductsByIngredientQuery = {
    __typename?: 'Query';
    products?: Array<{
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    }> | null;
};

export type ProductCreateMutationVariables = Types.Exact<{
    input: Types.ProductCreate;
}>;

export type ProductCreateMutation = {
    __typename?: 'Mutation';
    product?: {
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type ProductUpdateMutationVariables = Types.Exact<{
    productId: Types.Scalars['ID']['input'];
    product: Types.ProductUpdate;
    variations: Array<Types.ProductVariationCreateUpdate> | Types.ProductVariationCreateUpdate;
}>;

export type ProductUpdateMutation = {
    __typename?: 'Mutation';
    product?: {
        __typename?: 'Product';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
    variations?: Array<{
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type ProductVariationQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type ProductVariationQuery = {
    __typename?: 'Query';
    productVariation?: {
        __typename?: 'ProductVariationWithFullProduct';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type ProductVariationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProductVariationsQuery = {
    __typename?: 'Query';
    productVariations?: Array<{
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type ProductVariationsByProductQueryVariables = Types.Exact<{
    productId: Types.Scalars['ID']['input'];
}>;

export type ProductVariationsByProductQuery = {
    __typename?: 'Query';
    productVariationsByProduct?: Array<{
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type ProductVariationCreateMutationVariables = Types.Exact<{
    productId: Types.Scalars['ID']['input'];
    input: Types.ProductVariationCreateUpdate;
}>;

export type ProductVariationCreateMutation = {
    __typename?: 'Mutation';
    productVariationCreate?: {
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type ProductVariationUpdateMutationVariables = Types.Exact<{
    input: Types.ProductVariationUpdate;
}>;

export type ProductVariationUpdateMutation = {
    __typename?: 'Mutation';
    productVariationUpdate?: {
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type ProductVariationsCreateMutationVariables = Types.Exact<{
    productId: Types.Scalars['ID']['input'];
    inputs: Array<Types.ProductVariationCreateUpdate> | Types.ProductVariationCreateUpdate;
}>;

export type ProductVariationsCreateMutation = {
    __typename?: 'Mutation';
    productVariationsCreate?: Array<{
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type ProductVariationsUpdateMutationVariables = Types.Exact<{
    inputs: Array<Types.ProductVariationUpdate> | Types.ProductVariationUpdate;
}>;

export type ProductVariationsUpdateMutation = {
    __typename?: 'Mutation';
    productVariationsUpdate?: Array<{
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type ProductVariationsUpdateCreateMutationVariables = Types.Exact<{
    productId: Types.Scalars['ID']['input'];
    inputs: Array<Types.ProductVariationCreateUpdate> | Types.ProductVariationCreateUpdate;
}>;

export type ProductVariationsUpdateCreateMutation = {
    __typename?: 'Mutation';
    productVariationsUpdateCreate?: Array<{
        __typename?: 'ProductVariation';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        active: boolean;
        costPrice?: number | null;
        name: string;
        price: number;
        product: {
            __typename?: 'Product';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            active: boolean;
            name: string;
            price: number;
            slug: string;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
            tags?: Array<{
                __typename?: 'Tag';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                schemas?: Array<Types.ETagSchema> | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
        };
        recipe?: Array<{
            __typename?: 'ProductVariationRecipeItem';
            quantity: number;
            ingredient: {
                __typename?: 'Ingredient';
                _id: Types.Scalars['ObjectId']['output'];
                createdAt: Types.Scalars['Date']['output'];
                updatedAt: Types.Scalars['Date']['output'];
                name: string;
                quantity: number;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            };
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type SpacesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SpacesQuery = {
    __typename?: 'Query';
    spaces?: Array<{
        __typename?: 'Space';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type SpaceQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type SpaceQuery = {
    __typename?: 'Query';
    space?: {
        __typename?: 'Space';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type SpaceBySlugQueryVariables = Types.Exact<{
    slug: Types.Scalars['String']['input'];
}>;

export type SpaceBySlugQuery = {
    __typename?: 'Query';
    space?: {
        __typename?: 'Space';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type SpaceCreateMutationVariables = Types.Exact<{
    input: Types.SpaceCreate;
}>;

export type SpaceCreateMutation = {
    __typename?: 'Mutation';
    spaceCreate?: {
        __typename?: 'Space';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type SpaceUpdateMutationVariables = Types.Exact<{
    input: Types.SpaceUpdate;
}>;

export type SpaceUpdateMutation = {
    __typename?: 'Mutation';
    spaceUpdate?: {
        __typename?: 'Space';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type SpaceTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SpaceTypesQuery = {
    __typename?: 'Query';
    spaceTypes?: Array<{
        __typename?: 'SpaceType';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        priority?: number | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        variants?: Array<{
            __typename?: 'SpaceTypeVariant';
            _id: Types.Scalars['ObjectId']['output'];
            active: boolean;
            createdAt: Types.Scalars['Date']['output'];
            name: string;
            price: number;
            priority?: number | null;
            slug: string;
            updatedAt: Types.Scalars['Date']['output'];
            bookingConfigurations?: Array<{
                __typename?: 'BookingConfiguration';
                availabilityDates: Array<Types.Scalars['DateRange']['output']>;
                availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
                availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
                bookingOptions: Array<{
                    __typename?: 'BookingOption';
                    period?: Types.EBookingPeriod | null;
                    limits?: {
                        __typename?: 'BookingOptionLimits';
                        increment: number;
                        max: number;
                        min: number;
                    } | null;
                    rates: Array<{
                        __typename?: 'BookingOptionRates';
                        max: number;
                        min: number;
                        price?: number | null;
                        rate?: number | null;
                    }>;
                }>;
                userCategories: Array<{
                    __typename?: 'UserCategory';
                    _id: Types.Scalars['ObjectId']['output'];
                    name: string;
                    roles: Array<Types.EUserRole>;
                }>;
            }> | null;
            spaces?: Array<{
                __typename?: 'Space';
                _id: Types.Scalars['ObjectId']['output'];
                active: boolean;
                createdAt: Types.Scalars['Date']['output'];
                name: string;
                slug: string;
                updatedAt: Types.Scalars['Date']['output'];
                theme?: {
                    __typename?: 'Theme';
                    icon?: string | null;
                    colorPrimary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                    colorSecondary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                } | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
            theme?: {
                __typename?: 'Theme';
                icon?: string | null;
                colorPrimary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
                colorSecondary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
            } | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    }> | null;
};

export type SpaceTypesTranslatedQueryVariables = Types.Exact<{
    language: Types.ELanguage;
}>;

export type SpaceTypesTranslatedQuery = {
    __typename?: 'Query';
    spaceTypes?: Array<{
        __typename?: 'SpaceTypeTranslated';
        _id: Types.Scalars['ObjectId']['output'];
        active?: boolean | null;
        createdAt: Types.Scalars['Date']['output'];
        description?: string | null;
        name?: string | null;
        priority?: number | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        variants?: Array<{
            __typename?: 'SpaceTypeVariantTranslated';
            _id: Types.Scalars['ObjectId']['output'];
            active: boolean;
            createdAt: Types.Scalars['Date']['output'];
            description?: string | null;
            name?: string | null;
            price: number;
            priority?: number | null;
            slug: string;
            updatedAt: Types.Scalars['Date']['output'];
            spaces?: Array<{
                __typename?: 'SpaceTranslated';
                _id: Types.Scalars['ObjectId']['output'];
                active: boolean;
                createdAt: Types.Scalars['Date']['output'];
                description?: string | null;
                name?: string | null;
                slug: string;
                updatedAt: Types.Scalars['Date']['output'];
                theme?: {
                    __typename?: 'Theme';
                    icon?: string | null;
                    colorPrimary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                    colorSecondary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                } | null;
            }> | null;
            theme?: {
                __typename?: 'Theme';
                icon?: string | null;
                colorPrimary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
                colorSecondary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
            } | null;
        }> | null;
    }> | null;
};

export type SpaceTypeQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type SpaceTypeQuery = {
    __typename?: 'Query';
    spaceType?: {
        __typename?: 'SpaceType';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        priority?: number | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        variants?: Array<{
            __typename?: 'SpaceTypeVariant';
            _id: Types.Scalars['ObjectId']['output'];
            active: boolean;
            createdAt: Types.Scalars['Date']['output'];
            name: string;
            price: number;
            priority?: number | null;
            slug: string;
            updatedAt: Types.Scalars['Date']['output'];
            bookingConfigurations?: Array<{
                __typename?: 'BookingConfiguration';
                availabilityDates: Array<Types.Scalars['DateRange']['output']>;
                availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
                availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
                bookingOptions: Array<{
                    __typename?: 'BookingOption';
                    period?: Types.EBookingPeriod | null;
                    limits?: {
                        __typename?: 'BookingOptionLimits';
                        increment: number;
                        max: number;
                        min: number;
                    } | null;
                    rates: Array<{
                        __typename?: 'BookingOptionRates';
                        max: number;
                        min: number;
                        price?: number | null;
                        rate?: number | null;
                    }>;
                }>;
                userCategories: Array<{
                    __typename?: 'UserCategory';
                    _id: Types.Scalars['ObjectId']['output'];
                    name: string;
                    roles: Array<Types.EUserRole>;
                }>;
            }> | null;
            spaces?: Array<{
                __typename?: 'Space';
                _id: Types.Scalars['ObjectId']['output'];
                active: boolean;
                createdAt: Types.Scalars['Date']['output'];
                name: string;
                slug: string;
                updatedAt: Types.Scalars['Date']['output'];
                theme?: {
                    __typename?: 'Theme';
                    icon?: string | null;
                    colorPrimary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                    colorSecondary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                } | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
            theme?: {
                __typename?: 'Theme';
                icon?: string | null;
                colorPrimary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
                colorSecondary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
            } | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type SpaceTypeBySlugQueryVariables = Types.Exact<{
    slug: Types.Scalars['String']['input'];
}>;

export type SpaceTypeBySlugQuery = {
    __typename?: 'Query';
    spaceType?: {
        __typename?: 'SpaceType';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        priority?: number | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        variants?: Array<{
            __typename?: 'SpaceTypeVariant';
            _id: Types.Scalars['ObjectId']['output'];
            active: boolean;
            createdAt: Types.Scalars['Date']['output'];
            name: string;
            price: number;
            priority?: number | null;
            slug: string;
            updatedAt: Types.Scalars['Date']['output'];
            bookingConfigurations?: Array<{
                __typename?: 'BookingConfiguration';
                availabilityDates: Array<Types.Scalars['DateRange']['output']>;
                availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
                availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
                bookingOptions: Array<{
                    __typename?: 'BookingOption';
                    period?: Types.EBookingPeriod | null;
                    limits?: {
                        __typename?: 'BookingOptionLimits';
                        increment: number;
                        max: number;
                        min: number;
                    } | null;
                    rates: Array<{
                        __typename?: 'BookingOptionRates';
                        max: number;
                        min: number;
                        price?: number | null;
                        rate?: number | null;
                    }>;
                }>;
                userCategories: Array<{
                    __typename?: 'UserCategory';
                    _id: Types.Scalars['ObjectId']['output'];
                    name: string;
                    roles: Array<Types.EUserRole>;
                }>;
            }> | null;
            spaces?: Array<{
                __typename?: 'Space';
                _id: Types.Scalars['ObjectId']['output'];
                active: boolean;
                createdAt: Types.Scalars['Date']['output'];
                name: string;
                slug: string;
                updatedAt: Types.Scalars['Date']['output'];
                theme?: {
                    __typename?: 'Theme';
                    icon?: string | null;
                    colorPrimary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                    colorSecondary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                } | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
            theme?: {
                __typename?: 'Theme';
                icon?: string | null;
                colorPrimary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
                colorSecondary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
            } | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type SpaceTypeCreateMutationVariables = Types.Exact<{
    input: Types.SpaceTypeCreate;
}>;

export type SpaceTypeCreateMutation = {
    __typename?: 'Mutation';
    spaceTypeCreate?: {
        __typename?: 'SpaceType';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        priority?: number | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        variants?: Array<{
            __typename?: 'SpaceTypeVariant';
            _id: Types.Scalars['ObjectId']['output'];
            active: boolean;
            createdAt: Types.Scalars['Date']['output'];
            name: string;
            price: number;
            priority?: number | null;
            slug: string;
            updatedAt: Types.Scalars['Date']['output'];
            bookingConfigurations?: Array<{
                __typename?: 'BookingConfiguration';
                availabilityDates: Array<Types.Scalars['DateRange']['output']>;
                availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
                availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
                bookingOptions: Array<{
                    __typename?: 'BookingOption';
                    period?: Types.EBookingPeriod | null;
                    limits?: {
                        __typename?: 'BookingOptionLimits';
                        increment: number;
                        max: number;
                        min: number;
                    } | null;
                    rates: Array<{
                        __typename?: 'BookingOptionRates';
                        max: number;
                        min: number;
                        price?: number | null;
                        rate?: number | null;
                    }>;
                }>;
                userCategories: Array<{
                    __typename?: 'UserCategory';
                    _id: Types.Scalars['ObjectId']['output'];
                    name: string;
                    roles: Array<Types.EUserRole>;
                }>;
            }> | null;
            spaces?: Array<{
                __typename?: 'Space';
                _id: Types.Scalars['ObjectId']['output'];
                active: boolean;
                createdAt: Types.Scalars['Date']['output'];
                name: string;
                slug: string;
                updatedAt: Types.Scalars['Date']['output'];
                theme?: {
                    __typename?: 'Theme';
                    icon?: string | null;
                    colorPrimary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                    colorSecondary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                } | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
            theme?: {
                __typename?: 'Theme';
                icon?: string | null;
                colorPrimary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
                colorSecondary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
            } | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type SpaceTypeUpdateMutationVariables = Types.Exact<{
    input: Types.SpaceTypeUpdate;
}>;

export type SpaceTypeUpdateMutation = {
    __typename?: 'Mutation';
    spaceTypeUpdate?: {
        __typename?: 'SpaceType';
        _id: Types.Scalars['ObjectId']['output'];
        active: boolean;
        createdAt: Types.Scalars['Date']['output'];
        name: string;
        priority?: number | null;
        slug: string;
        updatedAt: Types.Scalars['Date']['output'];
        theme?: {
            __typename?: 'Theme';
            icon?: string | null;
            colorPrimary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
            colorSecondary?: {
                __typename?: 'Rgba';
                a: number;
                b: number;
                g: number;
                r: number;
            } | null;
        } | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        variants?: Array<{
            __typename?: 'SpaceTypeVariant';
            _id: Types.Scalars['ObjectId']['output'];
            active: boolean;
            createdAt: Types.Scalars['Date']['output'];
            name: string;
            price: number;
            priority?: number | null;
            slug: string;
            updatedAt: Types.Scalars['Date']['output'];
            bookingConfigurations?: Array<{
                __typename?: 'BookingConfiguration';
                availabilityDates: Array<Types.Scalars['DateRange']['output']>;
                availabilityDays?: Array<Types.EBookingDayOfWeek> | null;
                availabilityTimes: Array<Types.Scalars['TimeRange']['output']>;
                bookingOptions: Array<{
                    __typename?: 'BookingOption';
                    period?: Types.EBookingPeriod | null;
                    limits?: {
                        __typename?: 'BookingOptionLimits';
                        increment: number;
                        max: number;
                        min: number;
                    } | null;
                    rates: Array<{
                        __typename?: 'BookingOptionRates';
                        max: number;
                        min: number;
                        price?: number | null;
                        rate?: number | null;
                    }>;
                }>;
                userCategories: Array<{
                    __typename?: 'UserCategory';
                    _id: Types.Scalars['ObjectId']['output'];
                    name: string;
                    roles: Array<Types.EUserRole>;
                }>;
            }> | null;
            spaces?: Array<{
                __typename?: 'Space';
                _id: Types.Scalars['ObjectId']['output'];
                active: boolean;
                createdAt: Types.Scalars['Date']['output'];
                name: string;
                slug: string;
                updatedAt: Types.Scalars['Date']['output'];
                theme?: {
                    __typename?: 'Theme';
                    icon?: string | null;
                    colorPrimary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                    colorSecondary?: {
                        __typename?: 'Rgba';
                        a: number;
                        b: number;
                        g: number;
                        r: number;
                    } | null;
                } | null;
                translations?: Array<{
                    __typename?: 'Translation';
                    language: Types.ELanguage;
                    name?: string | null;
                    description?: string | null;
                }> | null;
            }> | null;
            theme?: {
                __typename?: 'Theme';
                icon?: string | null;
                colorPrimary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
                colorSecondary?: {
                    __typename?: 'Rgba';
                    a: number;
                    b: number;
                    g: number;
                    r: number;
                } | null;
            } | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type SuppliersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SuppliersQuery = {
    __typename?: 'Query';
    suppliers?: Array<{
        __typename?: 'Supplier';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        contacts?: Array<{
            __typename?: 'Contact';
            description?: string | null;
            name: string;
            address?: {
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            } | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    }> | null;
};

export type SupplierQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type SupplierQuery = {
    __typename?: 'Query';
    supplier?: {
        __typename?: 'Supplier';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        contacts?: Array<{
            __typename?: 'Contact';
            description?: string | null;
            name: string;
            address?: {
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            } | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type SupplierCreateMutationVariables = Types.Exact<{
    input: Types.SupplierCreate;
}>;

export type SupplierCreateMutation = {
    __typename?: 'Mutation';
    supplierCreate?: {
        __typename?: 'Supplier';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        contacts?: Array<{
            __typename?: 'Contact';
            description?: string | null;
            name: string;
            address?: {
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            } | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type SupplierUpdateMutationVariables = Types.Exact<{
    input: Types.SupplierUpdate;
}>;

export type SupplierUpdateMutation = {
    __typename?: 'Mutation';
    supplierUpdate?: {
        __typename?: 'Supplier';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        contacts?: Array<{
            __typename?: 'Contact';
            description?: string | null;
            name: string;
            address?: {
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            } | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        }> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        tags?: Array<{
            __typename?: 'Tag';
            _id: Types.Scalars['ObjectId']['output'];
            createdAt: Types.Scalars['Date']['output'];
            updatedAt: Types.Scalars['Date']['output'];
            name: string;
            schemas?: Array<Types.ETagSchema> | null;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    } | null;
};

export type TagQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type TagQuery = {
    __typename?: 'Query';
    tag?: {
        __typename?: 'Tag';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        schemas?: Array<Types.ETagSchema> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type TagsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TagsQuery = {
    __typename?: 'Query';
    tags?: Array<{
        __typename?: 'Tag';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        schemas?: Array<Types.ETagSchema> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type TagsBySchemaQueryVariables = Types.Exact<{
    schemas: Array<Types.ETagSchema> | Types.ETagSchema;
}>;

export type TagsBySchemaQuery = {
    __typename?: 'Query';
    tagsBySchema?: Array<{
        __typename?: 'Tag';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        schemas?: Array<Types.ETagSchema> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type TagCreateMutationVariables = Types.Exact<{
    input: Types.TagCreate;
}>;

export type TagCreateMutation = {
    __typename?: 'Mutation';
    tagCreate?: {
        __typename?: 'Tag';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        schemas?: Array<Types.ETagSchema> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type TagUpdateMutationVariables = Types.Exact<{
    input: Types.TagUpdate;
}>;

export type TagUpdateMutation = {
    __typename?: 'Mutation';
    tagUpdate?: {
        __typename?: 'Tag';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        schemas?: Array<Types.ETagSchema> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    } | null;
};

export type TagsUpdateMutationVariables = Types.Exact<{
    input: Array<Types.TagUpdate> | Types.TagUpdate;
}>;

export type TagsUpdateMutation = {
    __typename?: 'Mutation';
    tagsUpdate?: Array<{
        __typename?: 'Tag';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        updatedAt: Types.Scalars['Date']['output'];
        name: string;
        schemas?: Array<Types.ETagSchema> | null;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
    }> | null;
};

export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
    __typename?: 'Query';
    me?: {
        __typename?: 'User';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        email: string;
        firstName: string;
        lastName: string;
        nationality?: string | null;
        passportNumber?: string | null;
        roles?: Array<Types.EUserRole> | null;
        updatedAt: Types.Scalars['Date']['output'];
        business?: {
            __typename?: 'Business';
            country: string;
            name: string;
            registrationNumber?: string | null;
            taxNumber?: string | null;
            contact?: {
                __typename?: 'BusinessContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        } | null;
        category: {
            __typename?: 'UserCategory';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            roles: Array<Types.EUserRole>;
        };
        contact?: {
            __typename?: 'UserContact';
            address?: Array<{
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            }> | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        } | null;
    } | null;
};

export type UserCategoryQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type UserCategoryQuery = {
    __typename?: 'Query';
    userCategory?: {
        __typename?: 'UserCategory';
        _id: Types.Scalars['ObjectId']['output'];
        name: string;
        roles: Array<Types.EUserRole>;
    } | null;
};

export type UserCategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserCategoriesQuery = {
    __typename?: 'Query';
    userCategories?: Array<{
        __typename?: 'UserCategory';
        _id: Types.Scalars['ObjectId']['output'];
        name: string;
        roles: Array<Types.EUserRole>;
    }> | null;
};

export type UserQueryVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
}>;

export type UserQuery = {
    __typename?: 'Query';
    user?: {
        __typename?: 'User';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        email: string;
        firstName: string;
        lastName: string;
        nationality?: string | null;
        passportNumber?: string | null;
        roles?: Array<Types.EUserRole> | null;
        updatedAt: Types.Scalars['Date']['output'];
        business?: {
            __typename?: 'Business';
            country: string;
            name: string;
            registrationNumber?: string | null;
            taxNumber?: string | null;
            contact?: {
                __typename?: 'BusinessContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        } | null;
        category: {
            __typename?: 'UserCategory';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            roles: Array<Types.EUserRole>;
        };
        contact?: {
            __typename?: 'UserContact';
            address?: Array<{
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            }> | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        } | null;
    } | null;
};

export type UsersQueryVariables = Types.Exact<{
    input?: Types.InputMaybe<Types.GiUserSearch>;
}>;

export type UsersQuery = {
    __typename?: 'Query';
    users?: Array<{
        __typename?: 'User';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        email: string;
        firstName: string;
        lastName: string;
        nationality?: string | null;
        passportNumber?: string | null;
        roles?: Array<Types.EUserRole> | null;
        updatedAt: Types.Scalars['Date']['output'];
        business?: {
            __typename?: 'Business';
            country: string;
            name: string;
            registrationNumber?: string | null;
            taxNumber?: string | null;
            contact?: {
                __typename?: 'BusinessContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        } | null;
        category: {
            __typename?: 'UserCategory';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            roles: Array<Types.EUserRole>;
        };
        contact?: {
            __typename?: 'UserContact';
            address?: Array<{
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            }> | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        } | null;
    }> | null;
};

export type UserCategoryCreateMutationVariables = Types.Exact<{
    input: Types.GiUserCategoryCreate;
}>;

export type UserCategoryCreateMutation = {
    __typename?: 'Mutation';
    userCategory?: {
        __typename?: 'UserCategory';
        _id: Types.Scalars['ObjectId']['output'];
        name: string;
        roles: Array<Types.EUserRole>;
    } | null;
};

export type UserCategoryUpdateMutationVariables = Types.Exact<{
    input: Types.GiUserCategoryUpdate;
}>;

export type UserCategoryUpdateMutation = {
    __typename?: 'Mutation';
    userCategory?: {
        __typename?: 'UserCategory';
        _id: Types.Scalars['ObjectId']['output'];
        name: string;
        roles: Array<Types.EUserRole>;
    } | null;
};

export type UserCreateMutationVariables = Types.Exact<{
    input: Types.GiUserCreate;
}>;

export type UserCreateMutation = {
    __typename?: 'Mutation';
    user?: {
        __typename?: 'User';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        email: string;
        firstName: string;
        lastName: string;
        nationality?: string | null;
        passportNumber?: string | null;
        roles?: Array<Types.EUserRole> | null;
        updatedAt: Types.Scalars['Date']['output'];
        business?: {
            __typename?: 'Business';
            country: string;
            name: string;
            registrationNumber?: string | null;
            taxNumber?: string | null;
            contact?: {
                __typename?: 'BusinessContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        } | null;
        category: {
            __typename?: 'UserCategory';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            roles: Array<Types.EUserRole>;
        };
        contact?: {
            __typename?: 'UserContact';
            address?: Array<{
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            }> | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        } | null;
    } | null;
};

export type UserUpdateMutationVariables = Types.Exact<{
    input: Types.GiUserUpdate;
}>;

export type UserUpdateMutation = {
    __typename?: 'Mutation';
    user?: {
        __typename?: 'User';
        _id: Types.Scalars['ObjectId']['output'];
        createdAt: Types.Scalars['Date']['output'];
        email: string;
        firstName: string;
        lastName: string;
        nationality?: string | null;
        passportNumber?: string | null;
        roles?: Array<Types.EUserRole> | null;
        updatedAt: Types.Scalars['Date']['output'];
        business?: {
            __typename?: 'Business';
            country: string;
            name: string;
            registrationNumber?: string | null;
            taxNumber?: string | null;
            contact?: {
                __typename?: 'BusinessContact';
                address?: Array<{
                    __typename?: 'ContactAddress';
                    addressType: Types.EContactAddressType;
                    city: string;
                    country: string;
                    line1: string;
                    line2?: string | null;
                    postalCode: string;
                    state: string;
                }> | null;
                methods?: Array<{
                    __typename?: 'ContactMethod';
                    source: Types.EContactMethodSource;
                    value: string;
                }> | null;
            } | null;
        } | null;
        category: {
            __typename?: 'UserCategory';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            roles: Array<Types.EUserRole>;
        };
        contact?: {
            __typename?: 'UserContact';
            address?: Array<{
                __typename?: 'ContactAddress';
                addressType: Types.EContactAddressType;
                city: string;
                country: string;
                line1: string;
                line2?: string | null;
                postalCode: string;
                state: string;
            }> | null;
            methods?: Array<{
                __typename?: 'ContactMethod';
                source: Types.EContactMethodSource;
                value: string;
            }> | null;
        } | null;
    } | null;
};

export type WebProductsWithVariationsByTagSchemaQueryVariables = Types.Exact<{
    schemas: Array<Types.ETagSchema> | Types.ETagSchema;
}>;

export type WebProductsWithVariationsByTagSchemaQuery = {
    __typename?: 'Query';
    webProductsWithVariationsByTagSchema?: Array<{
        __typename?: 'WebProductWithVariations';
        _id: string;
        name: string;
        price: number;
        slug: string;
        translations?: Array<{
            __typename?: 'Translation';
            language: Types.ELanguage;
            name?: string | null;
            description?: string | null;
        }> | null;
        variations?: Array<{
            __typename?: 'WebProductVariation';
            _id: Types.Scalars['ObjectId']['output'];
            name: string;
            price: number;
            translations?: Array<{
                __typename?: 'Translation';
                language: Types.ELanguage;
                name?: string | null;
                description?: string | null;
            }> | null;
        }> | null;
    }> | null;
};
