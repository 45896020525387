import React from 'react';
import styled from '@emotion/styled';

export type FormActionsProps = {
    children: React.ReactNode | React.ReactNode[];
    secondary?: React.ReactNode;
};

export default function FormActions(props: FormActionsProps): JSX.Element {
    const { children, secondary } = props;

    return (
        <FormActionsContainer>
            <FormActionsSecondary>{secondary}</FormActionsSecondary>
            <FormActionsPrimary>{children}</FormActionsPrimary>
        </FormActionsContainer>
    );
}

const FormActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    gap: 1rem;
`;

export const FormActionsPrimary = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;
export const FormActionsSecondary = styled.div``;
