import {
    EBookingAvailability,
    type Space,
    type SpaceType,
    type SpaceTypeVariant,
    type User,
} from '@makespace/graphql-generated/generated/webapp/schemas';
import { color, font } from '~styles/styles';
import { isToday, isTomorrow, isYesterday, startOfTomorrow, startOfYesterday } from 'date-fns';
import { spaceTypeClassName, spaceTypeVariantClassName } from '~utils/css';
import { useCalendar } from '~context/Calendar';
import Button, { EButtonSize, EButtonVariant } from '~components/clickables/Button';
import IconAgenda from '~components/icons/IconAgenda';
import IconCalendar from '~components/icons/IconCalendar';
import IconCalendarDay from '~components/icons/IconCalendarDay';
import IconCalendarToday from '~components/icons/IconCalendarToday';
import IconCalendarWeek from '~components/icons/IconCalendarWeek';
import IconChevronLeft from '~components/icons/IconChevronLeft';
import IconChevronRight from '~components/icons/IconChevronRight';
import React, { useRef } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { CalendarEventResource, EventPropsCustom, ResourcePropsCustom } from '~types/calendar';
import type {
    EventProps,
    HeaderProps,
    ResourceHeaderProps,
    ToolbarProps,
    View,
} from 'react-big-calendar';

/*
  CustomEvent
 */

export type CustomEventProps = EventProps & {
    view?: View;
};

export function CustomEvent(props: CustomEventProps): JSX.Element {
    const { title, event } = props as EventPropsCustom;
    const resource = event?.resource as CalendarEventResource;
    const ref = useRef<HTMLDivElement>(null);
    const { showBookingInfo, hideBookingInfo } = useCalendar();

    const spaceTypeClass = spaceTypeClassName(resource?.spaceTypeId);
    const spaceTypeVariantClass = spaceTypeVariantClassName(resource?.spaceTypeVariantId);

    const titleComponent = typeof title === 'string' ? <CustomEventTitle name={title} /> : title;

    return (
        <CustomEventContentContainer
            className={clsx(spaceTypeClass, resource?.availability)}
            ref={ref}>
            <CustomEventContentTitleContainer
                // onMouseEnter={(mouseEvent) => showBookingInfo(mouseEvent, ref, event)}
                // onMouseLeave={() => hideBookingInfo()}
                className={clsx(spaceTypeVariantClass, resource?.availability)}>
                {titleComponent}
            </CustomEventContentTitleContainer>
        </CustomEventContentContainer>
    );
}

type CustomEventTitleProps = {
    name?: string;
    space?: Space | null;
    spaceType?: SpaceType | null;
    spaceTypeVariant?: SpaceTypeVariant | null;
    user?: User | null;
};

export function CustomEventTitle(props: CustomEventTitleProps) {
    const { user, spaceType, spaceTypeVariant, space, name } = props;

    const nameSplit = name ? name.replace(' | ', '|').split('|') : undefined;

    return nameSplit ? (
        <>
            {nameSplit[0] ? <b>{nameSplit[0]}</b> : null}
            {nameSplit[1] ? <small>{nameSplit[1]}</small> : null}
        </>
    ) : (
        <>
            {user ? <b>{`${user?.firstName} ${user?.lastName}`}</b> : null}
            {spaceType || spaceTypeVariant || space ? (
                <small>{space?.name ?? spaceTypeVariant?.name ?? spaceType?.name}</small>
            ) : null}
        </>
    );
}

const CustomEventContentContainer = styled.div`
    label: CustomEventContentContainer;
    flex: 1;
    margin-left: 4px;
    position: relative;
    overflow: visible;

    &.${EBookingAvailability.Unavailable} {
        background-color: ${color.red};
    }
`;

const CustomEventContentTitleContainer = styled.div`
    label: CustomEventContentTitleContainer;
    flex: 1;
    padding: 0.25rem 0.375rem;
    text-overflow: ellipsis;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    font-size: ${font.size.small};
    line-height: ${font.lineHeight.normal};
    font-weight: ${font.weight.regular};

    transition: background-color 1s ease-in-out;

    & > b:first-of-type {
        font-weight: ${font.weight.medium};
    }
    & > small:last-of-type {
        display: block;
        max-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: ${font.size.small};
    }

    &.${EBookingAvailability.Unavailable} {
        background-color: ${color.red};
        color: ${color.white};
    }

    &.${EBookingAvailability.AvailableWithApproval} {
        background-color: ${color.orange};
        color: ${color.white};
    }
`;

/*
  Header
 */

export function Header(props: HeaderProps): JSX.Element {
    const { label } = props;
    return <HeaderContainer>{label}</HeaderContainer>;
}

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
`;

/*
  Reference Header
 */

export function ResourceHeader(props: ResourceHeaderProps): JSX.Element {
    const { resource, label } = props as ResourcePropsCustom;

    return (
        <ResourceHeaderContainer className={clsx(resource.resourceId)}>
            <ResourceHeaderContent>
                <ResourceHeaderContentTitle>{label}</ResourceHeaderContentTitle>
            </ResourceHeaderContent>
        </ResourceHeaderContainer>
    );
}

const ResourceHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
`;
const ResourceHeaderContent = styled.div``;
const ResourceHeaderContentTitle = styled.div``;

/*
  Toolbar
 */

export function Toolbar(props: ToolbarProps): JSX.Element {
    const { label, onView, onNavigate, view, date } = props;

    const setView = (view: View) => {
        onView(view);
    };

    const setYesterday = () => {
        onNavigate('DATE', startOfYesterday());
        onView('day');
    };

    const setToday = () => {
        onNavigate('TODAY');
        onView('day');
    };

    const setTomorrow = () => {
        onNavigate('DATE', startOfTomorrow());
        onView('day');
    };

    return (
        <ToolbarContainer>
            <ToolbarViews>
                <Button
                    size={EButtonSize.SMALL}
                    variant={
                        view === 'month'
                            ? EButtonVariant.TOUCH_ACTIVE
                            : EButtonVariant.TOUCH_INACTIVE
                    }
                    onClick={() => setView('month')}
                    iconEnd={IconCalendar}>
                    Month
                </Button>
                <Button
                    size={EButtonSize.SMALL}
                    variant={
                        view === 'week'
                            ? EButtonVariant.TOUCH_ACTIVE
                            : EButtonVariant.TOUCH_INACTIVE
                    }
                    onClick={() => setView('week')}
                    iconEnd={IconCalendarWeek}>
                    Week
                </Button>
                <Button
                    size={EButtonSize.SMALL}
                    variant={
                        view === 'day' ? EButtonVariant.TOUCH_ACTIVE : EButtonVariant.TOUCH_INACTIVE
                    }
                    onClick={() => setView('day')}
                    iconEnd={IconCalendarDay}>
                    Day
                </Button>
                <Button
                    size={EButtonSize.SMALL}
                    variant={
                        view === 'agenda'
                            ? EButtonVariant.TOUCH_ACTIVE
                            : EButtonVariant.TOUCH_INACTIVE
                    }
                    onClick={() => setView('agenda')}
                    iconEnd={IconAgenda}>
                    Agenda
                </Button>
            </ToolbarViews>
            <ToolbarNavigation>
                <IconChevronLeft onClick={() => onNavigate('PREV')} />
                <span className={'label'}>{label}</span>
                <IconChevronRight onClick={() => onNavigate('NEXT')} />
            </ToolbarNavigation>
            <ToolbarActions>
                <Button
                    size={EButtonSize.SMALL}
                    variant={
                        isYesterday(date)
                            ? EButtonVariant.TOUCH_ACTIVE
                            : EButtonVariant.TOUCH_INACTIVE
                    }
                    onClick={setYesterday}
                    iconEnd={IconCalendarToday}>
                    Yesterday
                </Button>
                <Button
                    size={EButtonSize.SMALL}
                    variant={
                        isToday(date) ? EButtonVariant.TOUCH_ACTIVE : EButtonVariant.TOUCH_INACTIVE
                    }
                    onClick={setToday}
                    iconEnd={IconCalendarToday}>
                    Today
                </Button>
                <Button
                    size={EButtonSize.SMALL}
                    variant={
                        isTomorrow(date)
                            ? EButtonVariant.TOUCH_ACTIVE
                            : EButtonVariant.TOUCH_INACTIVE
                    }
                    onClick={setTomorrow}
                    iconEnd={IconCalendarToday}>
                    Tomorrow
                </Button>
            </ToolbarActions>
        </ToolbarContainer>
    );
}

const ToolbarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    padding: 0.5rem 0 1rem 0;
`;

const ToolbarViews = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    gap: 0.25rem;
`;

const ToolbarNavigation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 0.5rem;

    & .label {
        font-size: ${font.size.normal};
        font-weight: ${font.weight.medium};
        text-align: center;
        min-width: 15rem;
    }
`;

const ToolbarActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    gap: 0.25rem;
`;

// why again
