import { EIconColor, EIconSize } from '~components/icons/Icon';
import { Link } from 'react-router-dom';
import { border, color, font, space } from '~styles/styles';
import IconCross from '~components/icons/IconCross';
import IconHide from '~components/icons/IconHide';
import IconInvalid from '~components/icons/IconInvalid';
import IconShow from '~components/icons/IconShow';
import React, { useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { InputFormatter, InputMask } from '~types/forms';
import type { InputHTMLAttributes } from 'react';

export enum EInputVariant {
    INLINE = 'inline',
    NORMAL = 'normal',
}

export type InputLink = {
    label: string;
    to: string;
};

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
    clearable?: boolean;
    error?: boolean;
    format?: InputFormatter<string>;
    forwardRef?: React.Ref<never>;
    icon?: React.ReactNode;
    link?: InputLink;
    mask?: InputMask;
    onClear?: () => void;
    variant?: EInputVariant;
};

export default function Input(props: InputProps): JSX.Element {
    const {
        icon,
        error,
        mask,
        format,
        onChange,
        onClear,
        value,
        type,
        link,
        variant = EInputVariant.NORMAL,
        forwardRef,
        className,
        clearable,
        readOnly,
        ...remainingProps
    } = props;

    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (mask) event.target.value = event.target.value.replace(mask, '') ?? '';
        if (format) event.target.value = format(event.target.value);
        onChange?.(event);
    };

    const hasValue = value ? value !== '' : false;

    return (
        <InputContainer className={className}>
            <InputWrapper className={clsx(className, { error, readOnly })}>
                <StyledInput
                    className={clsx(variant, { error, icon })}
                    type={passwordVisible ? 'text' : type}
                    value={value}
                    ref={forwardRef}
                    onChange={handleChange}
                    readOnly={readOnly}
                    {...remainingProps}
                />
                {error || clearable || icon ? (
                    <InputIcons>
                        {icon ? icon : null}
                        {error ? <IconInvalid size={EIconSize.TRIM} /> : null}
                        {type === 'password' ? (
                            passwordVisible ? (
                                <IconHide
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    size={EIconSize.TRIM}
                                />
                            ) : (
                                <IconShow
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    size={EIconSize.TRIM}
                                />
                            )
                        ) : null}
                        {clearable && hasValue ? (
                            <IconCross
                                onClick={onClear}
                                size={EIconSize.TRIM}
                                color={EIconColor.BLACK}
                            />
                        ) : null}
                    </InputIcons>
                ) : null}
            </InputWrapper>
            {link ? (
                <LinkStyle>
                    <Link to={link.to}>{link.label}</Link>{' '}
                </LinkStyle>
            ) : null}
        </InputContainer>
    );
}

export const InputContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${space.gap.tiny};
    border-radius: 0;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    position: relative;

    border-radius: ${border.radius.small};
    border: ${border.width.thin} solid;
    border-color: ${color.grey};
    background-color: ${color.white};

    &.error {
        border-color: ${color.redLight};
    }

    &.readOnly {
        background-color: transparent;
        border-color: transparent;
    }
`;

const InputIcons = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: ${space.gap.small};
    padding-right: ${space.padding.small};
`;

export const StyledInput = styled.input`
    font-size: ${font.size.normal};
    line-height: ${font.lineHeight.normal};
    font-weight: ${font.weight.regular};
    color: ${color.linkBlack};
    padding: 0.5rem 1rem 0.5rem 1rem;
    outline: none;
    margin: 0;
    width: 100%;
    border: none;
    border-radius: 0;
    background-color: transparent;
    flex: 1;

    &.inline {
        min-width: 0;
        border: none;
        padding: 0;
        margin: 0 1rem;
    }

    &:focus {
        outline: none;
        border-color: ${color.greyDark};
    }

    &[readonly] {
        background-color: transparent;
        border-color: transparent;
        padding: 2px 0 0 0;
    }

    &[disabled] {
        color: ${color.grey};
    }

    &::placeholder {
        color: ${color.grey};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill::first-line {
        font-size: ${font.size.medium};
        line-height: ${font.lineHeight.medium};
        font-weight: ${font.weight.regular};
    }
`;

const LinkStyle = styled.div`
    font-size: ${font.size.extraSmall};
    text-align: right;
    line-height: ${font.lineHeight.small};
    padding-right: ${space.padding.small};
    a {
        color: ${color.linkTextLight};
    }
`;
