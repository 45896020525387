import {
    RelativeOverlayContainer,
    RelativeOverlayContent,
    useRelativeOverlay,
} from '~components/interactions/RelativeOverlay';
import { space } from '~styles/styles';
import { useLanguage } from '~context/Language';
import { useTranslation } from 'react-i18next';
import Flag from '~components/Flag';
import Menu, { MenuItem } from '~components/clickables/Menu';
import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { Ei18nLanguage } from '~i18n';

export default function LanguageSelector(): JSX.Element {
    const { i18n } = useTranslation();
    const { contentRef, containerRef, open, setOpen, showOverlay, hideOverlay } =
        useRelativeOverlay();
    const { languages, language: activeLanguage, changeLanguage } = useLanguage();

    const selectLanguage = useCallback(
        (language: Ei18nLanguage) => {
            changeLanguage(language);
            setOpen(false);
        },
        [changeLanguage, setOpen],
    );

    const sortedLanguages = useMemo(
        () => languages.sort((a) => (a == activeLanguage ? -1 : 0)),
        [activeLanguage, languages],
    );

    return (
        <RelativeOverlayContainer ref={containerRef}>
            <ActiveLanguage onClick={showOverlay}>
                <Flag language={activeLanguage} />
                {activeLanguage}
            </ActiveLanguage>
            <RelativeOverlayContent
                forwardRef={contentRef}
                open={open}
                onClose={hideOverlay}
                onClick={hideOverlay}>
                <Menu>
                    {sortedLanguages.map((language) => (
                        <LanguageSelect
                            key={language}
                            language={language}
                            onClick={() => selectLanguage(language)}
                            active={i18n.language === (language as string)}
                        />
                    ))}
                </Menu>
            </RelativeOverlayContent>
        </RelativeOverlayContainer>
    );
}

type LanguageSelectProps = {
    active?: boolean;
    language: Ei18nLanguage;
    onClick?: () => void;
};

function LanguageSelect(props: LanguageSelectProps): JSX.Element {
    const { language, onClick, active } = props;

    return (
        <LanguageSelectContainer onClick={onClick} className={clsx({ active })}>
            <Flag language={language} />
            {language}
        </LanguageSelectContainer>
    );
}

const ActiveLanguage = styled(MenuItem)`
    gap: ${space.gap.small};
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    text-transform: uppercase;
`;

const LanguageSelectContainer = styled(MenuItem)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${space.gap.tiny};

    cursor: pointer;
    text-transform: uppercase;
`;
