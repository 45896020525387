import { MODAL_QUERY_PARAMS } from '~constants/modal';
import { addQueryParams, readQueryParams } from '~utils/urls';
import { useNavigate } from 'react-router-dom';
import ClickCatcher, { EClickCatcherVariant } from '~components/clickables/ClickCatcher';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import ScrollLock from '~components/ScrollLock';
import clsx from 'clsx';
import styled from '@emotion/styled';
import useQueryParams from '~hooks/useQueryParams';
import type { EModal } from '~constants/modal';
import type { IconProps } from '~components/icons/Icon';

export interface IModalContext {
    closeModal: (modalName?: EModal, removeParams?: string[]) => void;
    openModal: (modalName: EModal, params?: Record<string, string | number | undefined>) => void;
}

const initialModalContext: IModalContext = {
    openModal: () => {
        throw new Error('Modal context not initialized');
    },
    closeModal: () => {
        throw new Error('Modal context not initialized');
    },
};

const ModalContext = createContext<IModalContext>(initialModalContext);
ModalContext.displayName = 'modal';

type ModalProviderProps = {
    children: React.ReactNode | React.ReactNode[];
};

function ModalProvider(props: ModalProviderProps) {
    const { children } = props;
    const navigate = useNavigate();

    const openModal = useCallback(
        (modalName: EModal, addParams?: Record<string, string | number | undefined>) => {
            const queryParams = readQueryParams(window.location.search);
            if (queryParams.modal)
                if (Array.isArray(queryParams.modal))
                    queryParams.modal = [
                        ...queryParams.modal.filter((m) => m !== modalName.toString()),
                        modalName,
                    ];
                else queryParams.modal = [queryParams.modal, modalName];
            else queryParams.modal = modalName;
            const path = addQueryParams(window.location.pathname, { ...queryParams, ...addParams });
            navigate(path);
        },
        [navigate],
    );

    const closeModal = useCallback(
        (modalName?: EModal, removeParams?: string[]) => {
            if (!modalName) return;
            const queryParams = readQueryParams(window.location.search);

            if (queryParams.view) delete queryParams.view;
            if (queryParams.modal)
                if (Array.isArray(queryParams.modal)) {
                    console.log('modal is aray', queryParams.modal, modalName?.toString());
                    queryParams.modal = queryParams.modal.filter(
                        (m) => m !== modalName?.toString(),
                    );
                } else {
                    delete queryParams.modal;
                }

            (removeParams || MODAL_QUERY_PARAMS[modalName]).forEach(
                (param) => delete queryParams[param],
            );

            const path = addQueryParams(window.location.pathname, queryParams);
            navigate(path);
        },
        [navigate],
    );

    const context = useMemo(
        () => ({
            openModal,
            closeModal,
        }),
        [openModal, closeModal],
    );

    return <ModalContext.Provider value={context}>{children}</ModalContext.Provider>;
}

function useModal(): IModalContext {
    const context = useContext(ModalContext);
    if (!context) throw new Error('useModal must be used within ModalProvider');
    return context;
}

export type ModalHandlerParams = {
    modal: string | string[];
};

export type ModalHandlerProps = {
    children: React.ReactNode;
    modal: EModal | EModal[];
};

function ModalHandler(props: ModalHandlerProps): JSX.Element | null {
    const { modal, children } = props;
    const { modal: paramModal } = useQueryParams<ModalHandlerParams>();
    const modals = Array.isArray(paramModal) ? paramModal : [paramModal];
    const paramModals = Array.isArray(modal) ? modal : [modal];
    const showModal = paramModals?.length ? paramModals.some((m) => modals.includes(m)) : false;

    return showModal ? <>{children}</> : null;
}

export enum EModalGridPosition {
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
}

type ModalGridProps = {
    modals?: [EModal, React.ReactNode][];
};

function ModalGrid(props: ModalGridProps): JSX.Element | null {
    const { modals } = props;
    const { closeModal } = useModal();
    const { modal: paramModal } = useQueryParams<ModalHandlerParams>();

    const openModals = useMemo(
        () => (paramModal ? (Array.isArray(paramModal) ? paramModal : [paramModal]) : undefined),
        [paramModal],
    );

    const activeModals = useMemo(
        () => modals?.filter((m) => openModals?.includes(m[0])),
        [modals, openModals],
    );

    const activeModalComponents = useMemo(
        () => modals?.filter((m) => openModals?.includes(m[0])).map((m) => m[1]),
        [modals, openModals],
    );
    const active = !!activeModals?.length;

    const closeAll = useCallback(() => {
        console.log('closeAll modals', activeModals);
        activeModals?.forEach((m) => closeModal(m[0]));
    }, [closeModal, activeModals]);

    return active ? (
        <ModalGridContainer className={clsx({ active })}>
            <ScrollLock />
            {activeModalComponents}
            <ClickCatcher onClick={closeAll} variant={EClickCatcherVariant.DARK} />
        </ModalGridContainer>
    ) : null;
}

const ModalGridContainer = styled.div`
    label: ModalGridContainer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: 2rem;
    padding: 2rem;

    z-index: 5000;
`;

export { ModalProvider, ModalContext, useModal, ModalHandler, ModalGrid };
