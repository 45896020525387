import { useMemo } from 'react';
import type { TimeArray } from '~components/form/TimeSelector';
import type { TimeRangeSelectValue } from '~components/form/TimeRangeSelect';

export function convertTimeStringToArray(time: string): TimeArray | undefined {
    const split = time.split(':');
    return split.length === 2 ? [parseInt(split[0]), parseInt(split[1])] : undefined;
}

export function convertTimeArrayToString(time: TimeArray): string {
    return [padTime(time[0]), padTime(time[1])].join(':');
}

export function convertDateToTimeString(date?: Date | string): string {
    return date
        ? new Date(date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
        : '';
}

export function convertDateToTimeArray(date?: Date | string): TimeArray | undefined {
    return convertTimeStringToArray(convertDateToTimeString(date));
}

export function createTimeRangeSelectValues(
    dateFrom: Date | string,
    dateTo: Date | string,
): TimeRangeSelectValue {
    const timeFrom = convertDateToTimeString(dateFrom);
    const timeTo = convertDateToTimeString(dateTo);
    return [timeFrom, timeTo] as TimeRangeSelectValue;
}

export function padTime(time: number): string {
    return time < 10 ? `0${time}` : time.toString();
}

export function replaceDateTime(date: Date | string, time: string): Date {
    return new Date(new Date(date).toString().replace(/\s\d\d:\d\d/, ` ${time}`));
}

export function sanitiseTimeString(time: string): string {
    const asArray = convertTimeStringToArray(time.replace(/[^0-9:]/g, ''));
    if (!asArray) return time;
    if (asArray?.[1] > 59) {
        asArray[0] = asArray[0] + 1;
        asArray[1] = asArray[1] - 60;
    }
    if (asArray?.[1] < 0) {
        asArray[0] = asArray[0] - 1;
        asArray[1] = asArray[1] + 60;
    }
    if (asArray?.[0] > 23) asArray[0] = asArray[0] - 24;
    if (asArray?.[0] < 0) asArray[0] = 0;
    return convertTimeArrayToString(asArray);
}
