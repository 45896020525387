import { WebAuthnLoginBeginResponse, WebAuthnRegisterBeginResponse } from '@makespace/global-types';
import { base64ToArrayBuffer } from './buffer';

export function makeCreatePublicKey(
    registerResponse: WebAuthnRegisterBeginResponse,
): PublicKeyCredentialCreationOptions {
    const { user, challenge, ...response } = registerResponse;
    console.log('makeCreatePublicKey:registerResponse', registerResponse);
    const challengeBuffer = base64ToArrayBuffer(challenge);
    return {
        ...response,
        challenge: challengeBuffer,
        user: {
            name: user.name,
            displayName: user.displayName,
            id: base64ToArrayBuffer(user.userHandle),
        },
    };
}

export function makeGetPublicKey(
    registerResponse: WebAuthnLoginBeginResponse,
): PublicKeyCredentialRequestOptions {
    const { challenge, ...response } = registerResponse;
    console.log('makeCreatePublicKey:registerResponse', registerResponse);
    const challengeBuffer = base64ToArrayBuffer(challenge);
    return {
        ...response,
        allowCredentials:
            response.allowCredentials?.map((cred) => ({
                type: cred.type,
                id: base64ToArrayBuffer(cred.id),
                transports: (cred.transports as AuthenticatorTransport[]) ?? [],
            })) ?? undefined,
        challenge: challengeBuffer,
    };
}
