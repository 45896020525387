import { useCountries } from '~hooks/useCountries';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserCategories } from '~hooks/useUserCategories';
import { useUserRoles } from '~hooks/useUserRoles';
import BusinessForm from '../business/BusinessForm';
import Button from '~components/clickables/Button';
import ContactAddressForm from '~components/schema/contact/ContactAddressForm';
import ContactMethodsForm from '~components/schema/contact/ContactMethodForm';
import ControlledInput from '~components/inputs/ControlledInput';
import ControlledSelect from '~components/inputs/ControlledSelect';
import FormActions from '~components/form/FormActions';
import FormRow from '~components/form/FormRow';
import FormSection from '~components/form/FormSection';
import React, { useEffect } from 'react';
import type { GiUserCreate } from '@makespace/graphql-generated/webapp';

export default function UserCreateForm(): JSX.Element {
    const { t } = useTranslation();
    const {
        control,
        formState: { isSubmitting, isDirty, isValid },
        reset,
        setValue,
        watch,
    } = useFormContext<GiUserCreate>();

    const { userCategoryOptions, userCategories } = useUserCategories({});
    const { userRoleOptions } = useUserRoles();
    const { countryOptions } = useCountries({});

    const category = watch(`category`);
    const formData = watch();

    useEffect(() => {
        setValue('roles', userCategories?.find((c) => c._id === category)?.roles || []);
    }, [category, setValue, userCategories]);

    const canSubmit = true; //isSubmitting || !isDirty || !isValid;

    console.log('formData', formData);

    return (
        <>
            <FormSection>
                <FormRow>
                    <ControlledInput
                        control={control}
                        label={t('component.userForm.field.firstName.label')}
                        name={'firstName'}
                    />
                    <ControlledInput
                        control={control}
                        label={t('component.userForm.field.firstName.label')}
                        name={'lastName'}
                    />
                </FormRow>
                <FormRow>
                    <ControlledSelect
                        control={control}
                        name={`nationality`}
                        options={countryOptions}
                        label={t('component.userForm.field.nationality.label')}
                    />
                    <ControlledInput
                        control={control}
                        label={t('component.userForm.field.passportNumber.label')}
                        name={'passportNumber'}
                    />
                </FormRow>
                <FormRow variant={'single'}>
                    <ControlledInput
                        control={control}
                        label={t('component.userForm.field.email.label')}
                        name={'email'}
                    />
                </FormRow>
                <FormRow>
                    <ControlledSelect
                        control={control}
                        label={t('component.userForm.field.category.label')}
                        name={'category'}
                        options={userCategoryOptions}
                    />
                    <ControlledSelect
                        control={control}
                        label={t('component.userForm.field.roles.label')}
                        multiple={true}
                        name={'roles'}
                        options={userRoleOptions}
                    />
                </FormRow>
            </FormSection>
            <ContactMethodsForm name={'contact.methods'} />
            <ContactAddressForm path={'contact.address'} />
            <BusinessForm path={'business'} />
            <FormActions>
                <Button
                    onClick={() => {
                        reset(undefined, { keepDefaultValues: true });
                    }}>
                    {t('global.button.cancel')}
                </Button>
                <Button type={'submit'} disabled={!canSubmit}>
                    {t('global.button.save')} yooo
                </Button>
            </FormActions>
        </>
    );
}
