import { EModal } from '~constants/modal';
import { ModalGrid } from '~context/Modal';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { StylesProvider } from '~context/Styles';
import { useAuthentication } from '~context/Authentication';
import BookingCreateModal from '~components/schema/bookings/BookingCreateModal';
import BookingModal from '~components/schema/bookings/BookingModal';
import InventoryPage from '~pages/inventory/InventoryPage';
import LayoutDefault from '~components/layouts/LayoutDefault';
import LayoutPublic from '~components/layouts/LayoutPublic';
import Loader, { ELoaderVariant } from '~components/Loader';
import React, { Suspense, lazy } from 'react';
import UserCreateModal from '~components/schema/users/UserCreateModal';
import UserSelectModal from '~components/schema/users/UserSelectModal';
import routePaths, { EAppRoutes } from '~constants/routePaths';

const AuthLoginPage = lazy(() => import('~pages/AuthLoginPage'));
const AuthRegisterPage = lazy(() => import('~pages/AuthRegisterPage'));
const DashboardPage = lazy(() => import('~pages/DashboardPage'));
const BookingsPage = lazy(() => import('~pages/booking/BookingsPage'));
const ConsumablesPage = lazy(() => import('~pages/consumable/ConsumablesPage'));
const IngredientsPage = lazy(() => import('~pages/ingredient/IngredientsPage'));
const InventoryOrdersPage = lazy(() => import('~pages/inventory/InventoryOrdersPage'));
const MediaPage = lazy(() => import('~pages/MediaPage'));
const OrdersPage = lazy(() => import('~pages/order/OrdersPage'));
const ProductsPage = lazy(() => import('~pages/product/ProductsPage'));
const SuppliersPage = lazy(() => import('~pages/supplier/SuppliersPage'));
const SpaceTypesPage = lazy(() => import('~pages/space/SpaceTypesPage'));
const SpacesPage = lazy(() => import('~pages/space/SpacesPage'));
const TagsPage = lazy(() => import('~pages/tag/TagsPage'));
const UsersPage = lazy(() => import('~pages/user/UsersPage'));

export default function AppRoutes(): JSX.Element {
    return (
        <StylesProvider>
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route element={<RequireUnAuthenticated />}>
                        <Route element={<LayoutPublic />}>
                            <Route
                                path={routePaths.authenticate.base}
                                element={<AuthLoginPage />}
                            />
                            <Route
                                path={routePaths.authenticate.register}
                                element={<AuthRegisterPage />}
                            />
                        </Route>
                    </Route>
                    <Route element={<RequireAuthenticated />}>
                        <Route element={<LayoutDefault />}>
                            <Route
                                path={routePaths[EAppRoutes.BOOKINGS].base}
                                element={<BookingsPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.CONSUMABLES].base}
                                element={<ConsumablesPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.INGREDIENTS].base}
                                element={<IngredientsPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.INVENTORY].base}
                                element={<InventoryPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.INVENTORY_ORDERS].base}
                                element={<InventoryOrdersPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.MEDIA].base}
                                element={<MediaPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.ORDERS].base}
                                element={<OrdersPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.PRODUCTS].base}
                                element={<ProductsPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.SUPPLIERS].base}
                                element={<SuppliersPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.SPACE_TYPES].base}
                                element={<SpaceTypesPage />}
                            />
                            <Route
                                path={routePaths[EAppRoutes.SPACES].base}
                                element={<SpacesPage />}
                            />
                            <Route path={routePaths[EAppRoutes.TAGS].base} element={<TagsPage />} />
                            <Route
                                path={routePaths[EAppRoutes.USERS].base}
                                element={<UsersPage />}
                            />
                            <Route path={routePaths.dashboard.base} element={<DashboardPage />} />
                        </Route>
                    </Route>
                    <Route path={'*'} element={<UnknownRoute />} />
                </Routes>
            </Suspense>
            <ModalGrid
                modals={[
                    [EModal.BOOKING_CREATE, <BookingCreateModal key={EModal.BOOKING_CREATE} />],
                    [EModal.BOOKING_UPDATE, <BookingModal key={EModal.BOOKING_UPDATE} />],
                    [EModal.USER_CREATE, <UserCreateModal key={EModal.USER_CREATE} />],
                    [EModal.USER_SELECT, <UserSelectModal key={EModal.USER_SELECT} />],
                ]}
            />
        </StylesProvider>
    );
}

function UnknownRoute(): JSX.Element {
    return <div>Route Unknown</div>;
}

function RequireAuthenticated(): JSX.Element {
    const { isAuthenticated, loading, error } = useAuthentication();
    const location = useLocation();
    return !isAuthenticated && loading ? (
        <p>loading...</p>
    ) : isAuthenticated ? (
        <Outlet />
    ) : error ? (
        <p>{error.message}</p>
    ) : (
        <Navigate
            to="/login"
            state={
                location.pathname === '/logout'
                    ? '/'
                    : { authedReturnTo: `${location.pathname}${location.search}` }
            }
            replace={true}
        />
    );
}

function RequireUnAuthenticated(): JSX.Element {
    const { isAuthenticated, isAuthenticationReady } = useAuthentication();
    const location = useLocation();
    const { authedReturnTo } = (location?.state as { authedReturnTo?: string }) ?? {};

    console.log('isReady', isAuthenticationReady, 'isAuthenticated', isAuthenticated);

    return !isAuthenticationReady ? (
        <Loader variant={ELoaderVariant.OVERLAY} />
    ) : isAuthenticated ? (
        <Navigate to={authedReturnTo ? authedReturnTo : '/'} />
    ) : (
        <Outlet />
    );
}
