import { SelectOption } from '~types/options';
import { Space } from '@makespace/graphql-generated/generated/webapp/schemas';
import { useLanguage } from '~context/Language';
import { useMemo } from 'react';
import { useSpacesQuery } from '@makespace/graphql-generated/generated/webapp/hooks';

export type UseSpacesProps = {
    filter?: (space: Space) => boolean;
};

export type UseSpacesResponse = {
    spaceOptions: SelectOption<string>[];
    spaces: Space[] | undefined;
};

export const useSpaces = (props: UseSpacesProps): UseSpacesResponse => {
    const { filter } = props;
    const { translate } = useLanguage();
    const { data } = useSpacesQuery();

    const spaces = useMemo(
        () => data?.spaces?.filter(filter ?? (() => true)) ?? [],
        [filter, data?.spaces],
    );

    const spaceOptions: SelectOption<string>[] = useMemo(
        () =>
            spaces
                ?.filter(filter ?? (() => true))
                .map((t) => ({ value: t._id, label: translate(t)?.name ?? t.name })) ?? [],
        [filter, spaces, translate],
    );

    return {
        spaceOptions,
        spaces,
    };
};
