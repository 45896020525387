import {
    WebAuthnLoginBeginRequest,
    WebAuthnLoginBeginResponse,
    WebAuthnLoginCompleteRequest,
    WebAuthnLoginCompleteResponse,
    WebAuthnResponseError,
} from '@makespace/global-types';
import { arrayBufferToBase64 } from '../utils/buffer';
import { makeGetPublicKey } from '../utils/publicKey';
import { useCallback } from 'react';

export type UseAuthLoginProps = {
    authUrl: string;
};

export type UseAuthLoginResponse = {
    loginBegin: (email: string) => Promise<void>;
};

export function useAuthLogin(props: UseAuthLoginProps): UseAuthLoginResponse {
    const { authUrl } = props;

    const beginLoginChallenge = useCallback(
        async (email: string) => {
            const requestBody: WebAuthnLoginBeginRequest = {
                email,
            };
            const response = await fetch(`${authUrl}/login/begin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
                credentials: 'include',
            });
            if (!response.ok) {
                const result = (await response.json()) as Partial<WebAuthnResponseError> | null;
                throw new Error(result?.error ?? result?.message ?? response.statusText);
            } else {
                return (await response.json()) as WebAuthnLoginBeginResponse;
            }
        },
        [authUrl],
    );

    const completeLoginChallenge = useCallback(
        async (email: string, credential: PublicKeyCredential) => {
            const credentialResponse = credential.response as AuthenticatorAssertionResponse;
            const rawIdBase64 = arrayBufferToBase64(credential.rawId);
            const clientDataJSONBase64 = arrayBufferToBase64(credentialResponse.clientDataJSON);
            const signatureBase64 = arrayBufferToBase64(credentialResponse.signature);
            const authenticatorDataBase64 = arrayBufferToBase64(
                credentialResponse.authenticatorData,
            );
            const userHandleBase64 = credentialResponse.userHandle
                ? arrayBufferToBase64(credentialResponse.userHandle)
                : undefined;
            const requestBody: WebAuthnLoginCompleteRequest = {
                rawId: rawIdBase64,
                response: {
                    clientDataJSON: clientDataJSONBase64,
                    signature: signatureBase64,
                    authenticatorData: authenticatorDataBase64,
                    userHandle: userHandleBase64,
                },
            };
            const response = await fetch(`${authUrl}/login/complete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
                credentials: 'include',
            });
            if (!response.ok) {
                const result = (await response.json()) as Partial<WebAuthnResponseError> | null;
                throw new Error(result?.error ?? result?.message ?? response.statusText);
            } else {
                return (await response.json()) as WebAuthnLoginCompleteResponse;
            }
        },
        [authUrl],
    );

    const getUserCredential = useCallback(
        async (publicKey: PublicKeyCredentialRequestOptions): Promise<PublicKeyCredential> => {
            const credential = (await navigator.credentials.get({
                publicKey,
            })) as PublicKeyCredential;
            if (!credential) throw new Error('No credential returned from browser');
            else return credential;
        },
        [],
    );

    const loginBegin = useCallback(
        async (email: string) => {
            try {
                const beginLoginResponse = await beginLoginChallenge(email);
                const publicKey = makeGetPublicKey(beginLoginResponse);
                const credential = await getUserCredential(publicKey);
                await completeLoginChallenge(email, credential);
            } catch (e) {
                throw e as Error;
            }
        },
        [beginLoginChallenge, completeLoginChallenge, getUserCredential],
    );

    return {
        loginBegin,
    };
}
