import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { EInputVariant } from '~components/form/Input';
import { LabelProps } from '~components/form/Label';
import FormControl, { FormControlProps } from '~components/form/FormControl';
import React, { useCallback } from 'react';
import Select, { SelectProps } from '~components/form/Select';

export type ControlledSelectProps<TFieldValues extends FieldValues = FieldValues> = Pick<
    ControllerProps<TFieldValues>,
    'control' | 'name' | 'defaultValue'
> &
    Omit<SelectProps, 'defaultValue'> &
    LabelProps & {
        formControlProps?: FormControlProps;
        label?: React.ReactNode | string;
    };

export default function ControlledSelect<TFieldValues extends FieldValues = FieldValues>(
    props: ControlledSelectProps<TFieldValues>,
): JSX.Element {
    const {
        className,
        control,
        formControlProps,
        id = randomId(),
        label,
        multiple,
        name,
        required,
        variant = EInputVariant.NORMAL,
        ...selectProps
    } = props;

    const renderSelect: ControllerProps<TFieldValues>['render'] = useCallback(
        ({ field: { ref, ...fieldProps }, fieldState }) => (
            <Select
                id={id}
                required={required}
                error={!!fieldState.error}
                multiple={multiple}
                variant={variant}
                forwardRef={ref}
                {...fieldProps}
                {...selectProps}
            />
        ),
        [id, multiple, required, selectProps, variant],
    );

    return (
        <Controller<TFieldValues>
            name={name}
            control={control}
            render={(renderProps) =>
                variant !== EInputVariant.INLINE ? (
                    <FormControl
                        id={id}
                        label={label}
                        required={required}
                        error={renderProps.fieldState.error}
                        className={className}
                        {...formControlProps}>
                        {renderSelect(renderProps)}
                    </FormControl>
                ) : (
                    renderSelect(renderProps)
                )
            }
        />
    );
}

const randomId = () => 'id_' + Math.floor(Math.random() * 10000000).toString();
