import { AuthTokenCheckResponse } from '@makespace/global-types';

export async function checkAuthHealth(authUrl: string): Promise<AuthTokenCheckResponse | null> {
    try {
        const response = await fetch(`${authUrl}/token/health`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) throw new Error('Token health check failed');

        return (await response.json()) as AuthTokenCheckResponse;
    } catch (e) {
        return null;
    }
}
