import { EUserRole } from '@makespace/graphql-generated/generated/webapp/schemas';
import { SelectOption } from '~types/options';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type UseUserRolesResponse = {
    userRoleOptions: SelectOption<EUserRole>[];
    userRoles: EUserRole[];
};

export const useUserRoles = (): UseUserRolesResponse => {
    const { t } = useTranslation();

    const userRoles = useMemo(() => Object.values(EUserRole), []);

    const userRoleOptions: SelectOption<EUserRole>[] = useMemo(
        () =>
            Object.entries(EUserRole).map(([key, value]) => ({
                value: value,
                label: t(`enum.EUserRole.${key}`) ?? value.toString(),
            })) ?? [],
        [t],
    );

    return {
        userRoleOptions,
        userRoles,
    };
};
