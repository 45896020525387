import {
    convertTimeArrayToString,
    convertTimeStringToArray,
    sanitiseTimeString,
} from '~utils/dateTime';
import React from 'react';
import TimeSelector, { ETimeSelectorDividerVariant, TimeSelectorDivider } from './TimeSelector';
import styled from '@emotion/styled';
import type { InputMask } from '~types/forms';
import type { TimeArray, TimeSelectorProps } from './TimeSelector';

export type TimeRangeSelectValue = [string | null, string | null];

export type TimeRangeSelectProps = Omit<TimeSelectorProps, 'value' | 'onChange'> & {
    mask?: InputMask;
    maxTime?: TimeArray;
    minTime?: TimeArray;
    minutesInterval?: number;
    onChange?: (time: TimeRangeSelectValue) => void;
    value?: TimeRangeSelectValue;
};

export default function TimeRangeSelect(props: TimeRangeSelectProps): JSX.Element {
    const { onChange, value, maxTime, minTime, minutesInterval } = props;

    const handleFromInputChange = (time: TimeArray) => {
        const asString = convertTimeArrayToString(time);
        const newValue = [convertTimeArrayToString(time), value?.[1] ?? ''] as TimeRangeSelectValue;
        if (value?.[1] && asString >= value?.[1]) {
            const currentTo = convertTimeStringToArray(value[1]);
            const currentFrom = value?.[0] ? convertTimeStringToArray(value[0]) : currentTo;
            const difference =
                currentTo && currentFrom
                    ? ([currentTo[0] - currentFrom[0], currentTo[1] - currentFrom[1]] as TimeArray)
                    : undefined;

            const newTo =
                currentTo && currentFrom && difference
                    ? ([time[0] + difference[0], time[1] + difference[1]] as TimeArray)
                    : undefined;

            newValue[1] = sanitiseTimeString(convertTimeArrayToString(newTo ?? time));
        }
        onChange?.(newValue);
    };

    const handleToInputChange = (time: TimeArray) => {
        const asString = convertTimeArrayToString(time);
        const newValue = [value?.[0] ?? '', convertTimeArrayToString(time)] as TimeRangeSelectValue;
        if (value?.[0] && asString <= value?.[0]) {
            const currentFrom = convertTimeStringToArray(value[0]);
            const currentTo = value?.[1] ? convertTimeStringToArray(value[1]) : currentFrom;
            const difference =
                currentTo && currentFrom
                    ? ([currentFrom[0] - currentTo[0], currentFrom[1] - currentTo[1]] as TimeArray)
                    : undefined;

            const newFrom =
                currentTo && currentFrom && difference
                    ? ([time[0] + difference[0], time[1] + difference[1]] as TimeArray)
                    : undefined;

            newValue[0] = sanitiseTimeString(convertTimeArrayToString(newFrom ?? time));
        }
        onChange?.(newValue);
    };

    const timeFromArray = convertTimeStringToArray(value?.[0] ?? '');
    const timeToArray = convertTimeStringToArray(value?.[1] ?? '');

    return (
        <TimeRangeSelectContainer>
            <TimeSelector
                maxTime={maxTime}
                minTime={minTime}
                minutesInterval={minutesInterval}
                onChange={handleFromInputChange}
                value={timeFromArray}
            />
            <TimeSelectorDivider className={ETimeSelectorDividerVariant.DASH} />
            <TimeSelector
                maxTime={maxTime}
                minTime={minTime}
                minutesInterval={minutesInterval}
                onChange={handleToInputChange}
                value={timeToArray}
            />
        </TimeRangeSelectContainer>
    );
}

export const TimeRangeSelectContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    gap: 0.5rem;
`;
