import { DAY_NUMBER_TO_DAY } from '~constants/booking';
import { ESpacePrefix } from '~types/calendar';
import { addDays } from 'date-fns';
import {
    useBookingsQuery,
    useSpaceTypesQuery,
} from '@makespace/graphql-generated/generated/webapp/hooks';
import { useMemo } from 'react';
import { useSpaces } from '~hooks/useSpaces';
import type { BookingSearchInput } from '@makespace/graphql-generated/generated/webapp/schemas';
import type { CalendarEvent } from '~types/calendar';
import type { CalendarResource } from '~constants/calendar';

export enum EEventDisplayLevel {
    SPACE = 'SPACE',
    SPACE_TYPE = 'SPACE_TYPE',
    SPACE_TYPES = 'SPACE_TYPES',
    VARIANT = 'VARIANT',
}

export type BookingDates = { dateFrom?: string; dateTo?: string };

export type UseBookingsProps = {
    dateFrom?: string;
    dateTo?: string;
    eventLevel?: EEventDisplayLevel;
    space?: string;
    spaceType?: string;
    spaceTypeVariant?: string;
    user?: string;
    userCategory?: string;
};

export type UseBookingsResponse = {
    bookingEvents: CalendarEvent[];
    calendarResources?: CalendarResource[];
};

export const useBookings = (props: UseBookingsProps): UseBookingsResponse => {
    const bookingsInput: BookingSearchInput = useMemo(() => {
        return {
            spaceType: props.spaceType,
            spaceTypeVariant: props.spaceTypeVariant,
            space: props.space,
            dateFrom: props.dateFrom,
            dateTo: props.dateTo,
            userCategory: props.userCategory,
            user: props.user,
        };
    }, [props]);

    const { spaces } = useSpaces({});

    const { data: { spaceTypes } = {} } = useSpaceTypesQuery({
        fetchPolicy: 'cache-first',
    });

    const { data: { bookings } = {} } = useBookingsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            input: bookingsInput,
        },
    });

    const activeSpaceType = useMemo(
        () => (props.spaceType ? spaceTypes?.find((s) => s._id === props.spaceType) : undefined),
        [props.spaceType, spaceTypes],
    );

    const activeSpaceTypeVariant = useMemo(
        () =>
            activeSpaceType && props.spaceTypeVariant
                ? activeSpaceType.variants?.find((v) => v._id === props.spaceTypeVariant)
                : undefined,
        [activeSpaceType, props.spaceTypeVariant],
    );

    const bookingConfigurations = useMemo(
        () => activeSpaceTypeVariant?.bookingConfigurations,
        [activeSpaceTypeVariant],
    );

    const bookingConfigAvailability = useMemo(() => {
        const events: CalendarEvent[] = [];
        // const config = bookingConfigurations?.[0];
        bookingConfigurations
            ?.filter((c) =>
                props.userCategory
                    ? c.userCategories.find((uc) => uc._id === props.userCategory)
                    : true,
            )
            .forEach((config) => {
                if (!config) return undefined;
                const dates = config?.availabilityDates[0];
                const times = config?.availabilityTimes[0];
                const days = config?.availabilityDays;
                //
                const max = 30;
                let i = 0;
                //
                let startDate = new Date(dates[0]);
                const endDate = new Date(dates[1]);
                while (i < max && startDate < endDate) {
                    if (days?.includes(DAY_NUMBER_TO_DAY[startDate.getDay()])) {
                        const start = new Date(startDate);
                        const end = new Date(startDate);
                        if (times) {
                            start.setHours(
                                parseInt(times[0].split(':')[0]),
                                parseInt(times[0].split(':')[1]),
                            );
                            end.setHours(
                                parseInt(times[1].split(':')[0]),
                                parseInt(times[1].split(':')[1]),
                            );
                        } else {
                            start.setHours(0, 0);
                            end.setHours(23, 59);
                        }
                        events.push({
                            start,
                            end,
                            title: config.userCategories?.map((c) => c.name).join(', '),
                        });
                    }

                    startDate = addDays(startDate, 1);
                    i++;
                }
            });

        return events;
    }, [bookingConfigurations, props.userCategory]);

    const bookingEvents: CalendarEvent[] = useMemo(
        () =>
            bookings?.map((b) => {
                let resourceId;
                switch (props.eventLevel) {
                    case EEventDisplayLevel.SPACE_TYPE:
                        resourceId = `${ESpacePrefix.SPACE_TYPE_VARIANT}-${b.spaceTypeVariant}`;
                        break;
                    case EEventDisplayLevel.SPACE_TYPES:
                        resourceId = `${ESpacePrefix.SPACE_TYPE}-${b.spaceType}`;
                        break;
                    default:
                        resourceId = `${ESpacePrefix.SPACE}-${b.space}`;
                        break;
                }
                return {
                    start: new Date(b.dateFrom),
                    end: new Date(b.dateTo),
                    title: b.name
                        ? joinBookingName(splitBookingName(b.name))
                        : `${b.user.firstName + ' ' + b.user.lastName} | ${spaces?.find((s) => s._id === b.space)?.name}`,
                    resourceId, // needs to be at root of event
                    resource: {
                        resourceId,
                        resourceTitle: b.name ?? 'missing-title',
                        spaceTypeId: b.spaceType,
                        spaceTypeVariantId: b.spaceTypeVariant,
                        spaceId: b.space,
                        bookingId: b._id,
                    },
                    allDay: false,
                };
            }) ?? [],
        [bookings, props.eventLevel, spaces],
    );

    const calendarResources: CalendarResource[] | undefined = useMemo(() => {
        switch (props.eventLevel) {
            case EEventDisplayLevel.SPACE_TYPES:
                return spaceTypes?.map((t) => ({ resourceId: t._id, resourceTitle: t.name })) ?? [];
            case EEventDisplayLevel.SPACE_TYPE:
                return (
                    activeSpaceType?.variants?.map((v) => ({
                        resourceId: `${ESpacePrefix.SPACE_TYPE_VARIANT}-${v._id}`,
                        resourceTitle: v.name,
                    })) ?? undefined
                );
            case EEventDisplayLevel.VARIANT:
                return (
                    activeSpaceTypeVariant?.spaces?.map((v) => ({
                        resourceId: `${ESpacePrefix.SPACE}-${v._id}`,
                        resourceTitle: v.name,
                    })) ?? undefined
                );
            case EEventDisplayLevel.SPACE:
                return undefined;
        }
    }, [activeSpaceType?.variants, activeSpaceTypeVariant?.spaces, props.eventLevel, spaceTypes]);

    return {
        bookingEvents,
        calendarResources,
    };
};

export function splitBookingName(name: string): string[] {
    return name.replace('(', '| ').replace(')', '').split('|');
}

export function joinBookingName(name: string[]): string {
    return name.join(' | ');
}
