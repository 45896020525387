import {
    ContactMethodCreateUpdate,
    EContactMethodSource,
} from '@makespace/graphql-generated/webapp';
import { ObjectWithContactMethods } from '~types/forms';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useFormController } from '~context/FormController';
import { useTranslation } from 'react-i18next';
import Button, { EButtonSize, EButtonVariant } from '~components/clickables/Button';
import ContactMethodFormRow from '~components/schema/contact/ContactMethodFormRow';
import FormSection, { FormSectionProps } from '~components/form/FormSection';
import IconCirclePlus from '~components/icons/IconCirclePlus';
import React, { useMemo } from 'react';

type ContactMethodsFormProps = Pick<FormSectionProps, 'level'> & {
    name: string;
};

const EMPTY_CONTACT_METHOD: ContactMethodCreateUpdate = {
    source: EContactMethodSource.Phone,
    value: '',
};

export default function ContactMethodsForm(props: ContactMethodsFormProps): JSX.Element {
    const { name = 'methods', ...sectionProps } = props;
    const { t } = useTranslation();
    const { control } = useFormContext<ObjectWithContactMethods>();
    const { edit } = useFormController();
    const { fields, remove, append } = useFieldArray({
        control,
        name: name as 'methods',
    });

    const actions = useMemo(
        () =>
            edit ? (
                <Button
                    iconStart={IconCirclePlus}
                    onClick={() => append(EMPTY_CONTACT_METHOD, { shouldFocus: false })}
                    size={EButtonSize.SMALL}
                    variant={EButtonVariant.TEXT}>
                    {t('component.contactMethodsForm.button.addMethod')}
                </Button>
            ) : null,
        [edit, t, append],
    );

    return (
        <FormSection
            heading={t('component.contactMethodsForm.heading') ?? ''}
            headingActions={actions}
            {...sectionProps}>
            {fields?.map((field, index) => (
                <ContactMethodFormRow
                    contactMethod={field}
                    index={index}
                    key={field.id}
                    path={name}
                    remove={() => remove(index)}
                />
            ))}
        </FormSection>
    );
}
