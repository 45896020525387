import {
    WebAuthnRegisterBeginRequest,
    WebAuthnRegisterBeginResponse,
    WebAuthnRegisterCompleteRequest,
    WebAuthnRegisterCompleteResponse,
    WebAuthnResponseError,
    WebAuthnUser,
} from '@makespace/global-types';
import { arrayBufferToBase64 } from '../utils/buffer';
import { makeCreatePublicKey } from '../utils/publicKey';
import { useCallback } from 'react';

export type UseAuthRegisterProps = {
    authUrl: string;
};

export interface IRegisterUser {
    email: string;
    firstName: string;
    lastName: string;
}

export type UseAuthRegisterResponse = {
    registerBegin: (user: IRegisterUser) => Promise<void>;
};

export function useAuthRegister(props: UseAuthRegisterProps): UseAuthRegisterResponse {
    const { authUrl } = props;

    const beginRegisterChallenge = useCallback(
        async (user: WebAuthnUser): Promise<WebAuthnRegisterBeginResponse> => {
            const requestBody: WebAuthnRegisterBeginRequest = {
                user: user,
            };
            const response = await fetch(`${authUrl}/register/begin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
                credentials: 'include',
            });
            if (!response.ok) {
                const result = (await response.json()) as Partial<WebAuthnResponseError> | null;
                throw new Error(result?.error ?? result?.message ?? response.statusText);
            } else {
                return (await response.json()) as WebAuthnRegisterBeginResponse;
            }
        },
        [authUrl],
    );

    const completeRegisterChallenge = useCallback(
        async (user: IRegisterUser, credential: PublicKeyCredential) => {
            const rawIdBase64 = arrayBufferToBase64(credential.rawId);
            const clientDataJSONBase64 = arrayBufferToBase64(credential.response.clientDataJSON);
            const attestationBase64 = arrayBufferToBase64(
                (credential.response as AuthenticatorAttestationResponse).attestationObject,
            );
            const requestBody: WebAuthnRegisterCompleteRequest = {
                user: user,
                webAuthnResponse: {
                    rawId: rawIdBase64,
                    clientDataJSON: clientDataJSONBase64,
                    attestation: attestationBase64,
                },
            };
            const response = await fetch(`${authUrl}/register/complete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
                credentials: 'include',
            });
            if (!response.ok) {
                const result = (await response.json()) as Partial<WebAuthnResponseError> | null;
                throw new Error(result?.error ?? result?.message ?? response.statusText);
            } else {
                return (await response.json()) as WebAuthnRegisterCompleteResponse;
            }
        },
        [authUrl],
    );

    const createUserCredentials = useCallback(
        async (publicKey: PublicKeyCredentialCreationOptions): Promise<PublicKeyCredential> => {
            const credential = (await navigator.credentials.create({
                publicKey,
            })) as PublicKeyCredential;
            if (!credential) throw new Error('No credential returned from browser');
            else return credential;
        },
        [],
    );

    const registerBegin = useCallback(
        async (user: IRegisterUser) => {
            try {
                const beginRegistrationResponse = await beginRegisterChallenge(user);
                const publicKey = makeCreatePublicKey(beginRegistrationResponse);
                const credential = await createUserCredentials(publicKey);
                await completeRegisterChallenge(user, credential);
            } catch (e) {
                throw e as Error;
            }
        },
        [beginRegisterChallenge, completeRegisterChallenge, createUserCredentials],
    );

    return { registerBegin };
}
