import { EDateTimeDisplay, formatDate } from '~utils/formatters';
import { ETableCellVariant } from '~types/tables';
import React from 'react';
import TableCell, { TableCellProps } from '~components/table/TableCell';

export type ControlledTableCellProps = Omit<TableCellProps, 'children'> & {
    value: string | number | undefined | null;
};

export default function ControlledTableCell(props: ControlledTableCellProps): JSX.Element {
    const { value, variant, ...remainingProps } = props;

    const displayValue = () => {
        switch (variant) {
            case ETableCellVariant.PRICE: {
                return value ? value.toString() : '';
            }
            case ETableCellVariant.DATE: {
                return formatDate(value, EDateTimeDisplay.DATA);
            }
            default: {
                return value ?? '';
            }
        }
    };

    return (
        <TableCell variant={variant} {...remainingProps}>
            {displayValue()}
        </TableCell>
    );
}
