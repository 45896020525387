import { EIconColor, EIconSize } from '~components/icons/Icon';
import { border, color, font } from '~styles/styles';
import IconChevronDown from '~components/icons/IconChevronDown';
import IconChevronUp from '~components/icons/IconChevronUp';
import React, { createRef, useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum ETimeSelectorDividerVariant {
    COLON = 'colon',
    DASH = 'dash',
}

export enum ETimeValue {
    HOUR = 'hour',
    MINUTE = 'minute',
}

export type TimeArray = [number, number];

export type TimeSelectorProps = {
    maxTime?: TimeArray;
    minTime?: TimeArray;
    minutesInterval?: number;
    onChange: (time: TimeArray) => void;
    value?: TimeArray;
};

export default function TimeSelector(props: TimeSelectorProps): JSX.Element {
    const {
        value = [0, 0],
        minutesInterval = 1,
        onChange,
        minTime = [0, 0],
        maxTime = [23, 59],
    } = props;
    const hoursRef = createRef<HTMLDivElement>();
    const minutesRef = createRef<HTMLDivElement>();

    const hours = useMemo(() => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            if (minTime && i < minTime[0]) continue;
            if (maxTime && i > maxTime[0]) continue;
            hours.push(i);
        }
        return hours;
    }, [maxTime, minTime]);

    const minutes = useMemo(() => {
        const minutes = [];
        for (let i = 0; i < 60; i += minutesInterval) {
            if (minTime && value?.[0] === minTime[0] && i < minTime[1]) continue;
            if (maxTime && value?.[0] === maxTime[0] && i > maxTime[1]) continue;
            minutes.push(i);
        }
        return minutes;
    }, [maxTime, minTime, minutesInterval, value]);

    const setHour = (hour: number) => {
        const newTime: TimeArray = [hour, value?.[1] ?? 0];
        onChange(newTime);
    };

    const setMinute = (minute: number) => {
        const newTime: TimeArray = [value?.[0] ?? 0, minute];
        onChange(newTime);
    };

    const scrollToActive = useCallback(
        (time: TimeArray) => {
            const activeHour = hoursRef.current?.querySelector(`.h${time[0]}`) as HTMLDivElement;
            const activeMinute = minutesRef.current?.querySelector(
                `.m${time[1]}`,
            ) as HTMLDivElement;
            activeHour?.scrollIntoView({ behavior: 'instant', block: 'center' });
            activeMinute?.scrollIntoView({ behavior: 'instant', block: 'center' });
        },
        [hoursRef, minutesRef],
    );

    useEffect(() => {
        if (value) scrollToActive(value);
    }, [scrollToActive, value]);

    const [nextHourIndex, previousHourIndex] = useMemo(() => {
        const currentIndex = value ? hours.indexOf(value[0]) : 0;
        const nextIndex = currentIndex + 1;
        const lastIndex = currentIndex - 1;
        return [
            hours[nextIndex] !== undefined ? nextIndex : undefined,
            hours[lastIndex] !== undefined ? lastIndex : undefined,
        ];
    }, [hours, value]);

    const [nextMinuteIndex, previousMinuteIndex] = useMemo(() => {
        const currentIndex = value ? minutes.indexOf(value[1]) : 0;
        const nextIndex = currentIndex + 1;
        const lastIndex = currentIndex - 1;
        return [
            minutes[nextIndex] !== undefined ? nextIndex : undefined,
            minutes[lastIndex] !== undefined ? lastIndex : undefined,
        ];
    }, [minutes, value]);

    const stepHours = (index: number) => {
        if (hours[index] !== undefined) setHour(hours[index]);
    };

    const stepMinutes = (index: number) => {
        if (minutes[index] !== undefined) setMinute(minutes[index]);
    };

    const isActiveHour = (hour: number) => value?.[0] === hour;
    const isActiveMinute = (minute: number) => value?.[1] === minute;

    return (
        <TimeSelectorContainer>
            <TimeValueSelector>
                <TimeSelectorButton
                    type={'button'}
                    onClick={
                        previousHourIndex !== undefined
                            ? () => stepHours(previousHourIndex)
                            : undefined
                    }>
                    <IconChevronUp
                        size={EIconSize.TINY}
                        color={
                            previousHourIndex !== undefined ? EIconColor.BLACK : EIconColor.DISABLED
                        }
                    />
                </TimeSelectorButton>
                <TimeValueList forwardRef={hoursRef}>
                    {hours.map((hour) => (
                        <TimeValue
                            key={hour}
                            onClick={() => setHour(hour)}
                            className={clsx(`h${hour}`, ETimeValue.HOUR, {
                                active: isActiveHour(hour),
                                fillZero: hour < 10,
                            })}>
                            {hour}
                        </TimeValue>
                    ))}
                </TimeValueList>
                <TimeSelectorButton
                    type={'button'}
                    onClick={
                        nextHourIndex !== undefined ? () => stepHours(nextHourIndex) : undefined
                    }>
                    <IconChevronDown
                        size={EIconSize.TINY}
                        color={nextHourIndex !== undefined ? EIconColor.BLACK : EIconColor.DISABLED}
                    />
                </TimeSelectorButton>
            </TimeValueSelector>
            <TimeSelectorDivider className={ETimeSelectorDividerVariant.COLON} />
            <TimeValueSelector>
                <TimeSelectorButton
                    type={'button'}
                    onClick={
                        previousMinuteIndex !== undefined
                            ? () => stepMinutes(previousMinuteIndex)
                            : undefined
                    }>
                    <IconChevronUp
                        size={EIconSize.TINY}
                        color={
                            previousMinuteIndex !== undefined
                                ? EIconColor.BLACK
                                : EIconColor.DISABLED
                        }
                    />
                </TimeSelectorButton>
                <TimeValueList forwardRef={minutesRef}>
                    {minutes.map((minute) => (
                        <TimeValue
                            key={minute}
                            onClick={() => setMinute(minute)}
                            className={clsx(`m${minute}`, ETimeValue.MINUTE, {
                                active: isActiveMinute(minute),
                                fillZero: minute < 10,
                            })}>
                            {minute}
                        </TimeValue>
                    ))}
                </TimeValueList>
                <TimeSelectorButton
                    type={'button'}
                    onClick={
                        nextMinuteIndex !== undefined
                            ? () => stepMinutes(nextMinuteIndex)
                            : undefined
                    }>
                    <IconChevronDown
                        size={EIconSize.TINY}
                        color={
                            nextMinuteIndex !== undefined ? EIconColor.BLACK : EIconColor.DISABLED
                        }
                    />
                </TimeSelectorButton>
            </TimeValueSelector>
        </TimeSelectorContainer>
    );
}

const TimeSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: 0;
    flex: 1;
    font-family: ${font.family.mono};
`;

const TimeValueSelector = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    flex: 1;
`;

const TimeSelectorButton = styled.button`
    border: none;
    padding: 0.125rem 0 0 0;
    width: 100%;
    margin: 0;
    background-color: #f4f4f4;
    cursor: pointer;

    &:first-of-type {
        border-radius: ${border.radius.medium} ${border.radius.medium} 0 0;
    }

    &:last-of-type {
        border-radius: 0 0 ${border.radius.medium} ${border.radius.medium};
    }
`;

type TimeValueListProps = {
    children: React.ReactNode;
    forwardRef: React.RefObject<HTMLDivElement>;
};

function TimeValueList(props: TimeValueListProps) {
    const { forwardRef } = props;
    useEffect(() => {
        const timeValueHeight =
            (forwardRef.current?.querySelector(`.list`)?.childNodes[0] as HTMLElement)
                ?.offsetHeight ?? 0;
        const selectorHeight = forwardRef.current?.offsetHeight ?? 0;
        if (forwardRef.current)
            forwardRef.current.style.padding = `${Math.ceil(
                selectorHeight / 2 - timeValueHeight / 2,
            )}px 0`;
    }, [forwardRef]);

    return (
        <TimeValueListContainer ref={props.forwardRef}>
            <div className={'list'}>{props.children}</div>
        </TimeValueListContainer>
    );
}

const TimeValueListContainer = styled.div`
    max-height: 15rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex: 1;
    background: #fcfcfc;
    border-style: solid;
    border-width: 0 1px;
    border-color: #f4f4f4;

    & .list {
        background: #ffffff;
    }

    ::-webkit-scrollbar {
        width: 0;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: transparent;
    }
`;

const TimeValue = styled.div`
    cursor: pointer;
    padding: 0.5rem;
    flex: 1;
    text-align: center;
    background: #ffffff;
    font-size: ${font.size.normal};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;

    &:hover {
        background-color: ${color.greyLight};
    }

    &.fillZero:before {
        content: '0';
    }

    &.active {
        background-color: ${color.black};
        color: ${color.white};

        &.${ETimeValue.HOUR} {
            border-radius: ${border.radius.medium} 0 0 ${border.radius.medium};
        }
        &.${ETimeValue.MINUTE} {
            border-radius: 0 ${border.radius.medium} ${border.radius.medium} 0;
        }
    }
`;

export const TimeSelectorDivider = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex: 1;
    font-weight: ${font.weight.bold};

    &.${ETimeSelectorDividerVariant.COLON}:before {
        content: ':';
        background: #ffffff;
        color: #000000;
        padding: 0;
    }
    &.${ETimeSelectorDividerVariant.DASH}:before {
        content: '-';
        background: #ffffff;
        color: #000000;
        padding: 0 0.125rem;
    }
`;
