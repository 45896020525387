export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        IProduct: ['Product'],
        IProductVariation: ['ProductVariation'],
        MongoDocument: [],
        UInventoryItem: ['Consumable', 'Ingredient'],
        UOrderLineItem: ['Product', 'ProductVariation'],
        UProductItemUnion: ['Consumable', 'Ingredient'],
    },
};
export default result;
