import { useTableControl } from '~components/table/TableControlProvider';
import Input from '~components/form/Input';
import React from 'react';
import styled from '@emotion/styled';

export default function TableControlSearch(): JSX.Element {
    const { searchBy, searchedBy } = useTableControl();
    return (
        <TableControlSearchContainer>
            <TableControlSearchInput
                value={searchedBy}
                onChange={(e) => searchBy(e.target.value)}
                placeholder={'Search'}
                clearable={true}
                onClear={() => searchBy('')}
            />
        </TableControlSearchContainer>
    );
}

const TableControlSearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const TableControlSearchInput = styled(Input)`
    width: 300px;
`;
