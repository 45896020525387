import { color, font } from '~styles/styles';
import React from 'react';
import styled from '@emotion/styled';
import type { ReactNode } from 'react';

export type MenuProps = {
    children: ReactNode | ReactNode[];
};

export default function Menu(props: MenuProps): JSX.Element {
    const { children } = props;
    return <MenuContainer>{children}</MenuContainer>;
}

export const MenuContainer = styled.div`
    label: MenuContainer;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 1);
`;

export const MenuHeader = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${color.greyLightest};
    display: flex;
    flex-direction: column;

    & p {
        margin: 0;
    }
`;

export const MenuItem = styled.button`
    display: flex;
    flex-direction: row;
    padding: 0.75rem 1.5rem;
    border: 0;
    background: transparent;
    font-size: ${font.size.normal};
    cursor: pointer;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: -0.5px;
        left: 0.75rem;
        width: calc(100% - 1.5rem);
        border-bottom: 1px solid ${color.greyLightest};
    }
    &:first-of-type:before {
        content: none;
    }
    &:hover {
        background: ${color.highlightUltraLight};
        &:before {
            content: none;
        }
        & + button:before {
            content: none;
        }
    }
    &:focus {
        background: ${color.highlightLight};
    }
`;
