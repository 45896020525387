import {
    ContactMethodCreateUpdate,
    EContactMethodSource,
} from '@makespace/graphql-generated/webapp';
import { EIconColor, EIconSize } from '~components/icons/Icon';
import { ObjectWithContactMethods } from '~types/forms';
import { SelectOption } from '~components/form/Select';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { phone as maskPhone } from '~utils/masks';
import { useFormController } from '~context/FormController';
import { useTranslation } from 'react-i18next';
import ControlledInput from '~components/inputs/ControlledInput';
import ControlledSelect from '~components/inputs/ControlledSelect';
import FormRow, { FormRowIcon } from '~components/form/FormRow';
import IconCircleCross from '~components/icons/IconCircleCross';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';

type ContactMethodFormRowProps = {
    contactMethod: ContactMethodCreateUpdate;
    index: number;
    path: string;
    remove: UseFieldArrayRemove;
};

export default function ContactMethodFormRow(props: ContactMethodFormRowProps): JSX.Element {
    const { index, contactMethod, remove, path } = props;
    const { t } = useTranslation();
    const { edit } = useFormController();
    const {
        control,
        formState: { isSubmitting },
        watch,
    } = useFormContext<ObjectWithContactMethods>();

    const contactMethodSourceOptions: SelectOption<EContactMethodSource>[] = useMemo(() => {
        return Object.entries(EContactMethodSource).map(([key, value]) => ({
            label: t(`enum.EContactMethodSource.${key}`) ?? value,
            value,
        }));
    }, [t]);

    const source = watch(`${path}.${index}.source`) as EContactMethodSource | undefined;

    return (
        <ContactMethodFormRowContainer key={`${contactMethod.source}-${index}`}>
            {edit ? (
                <FormRowIcon>
                    <IconCircleCross
                        size={EIconSize.NORMAL}
                        color={isSubmitting ? EIconColor.DISABLED : EIconColor.NORMAL}
                        onClick={!isSubmitting ? () => remove(index) : undefined}
                    />
                </FormRowIcon>
            ) : null}
            <ControlledSelect
                control={control}
                name={`${path}.${index}.source`}
                label={
                    index === 0 ? t('component.contactMethodsForm.field.source.label') : undefined
                }
                placeholder={t('component.contactMethodsForm.field.source.placeholder') ?? ''}
                options={contactMethodSourceOptions}
                disabled={isSubmitting}
                readOnly={!edit}
            />
            <ControlledInput
                control={control}
                name={`${path}.${index}.value`}
                label={
                    index === 0 ? t('component.contactMethodsForm.field.value.label') : undefined
                }
                placeholder={t('component.contactMethodsForm.field.value.placeholder') ?? ''}
                readOnly={!edit}
                mask={source === EContactMethodSource.Phone ? maskPhone : undefined}
            />
        </ContactMethodFormRowContainer>
    );
}

const ContactMethodFormRowContainer = styled(FormRow)`
    .language {
        flex: 0;
        min-width: 160px;
    }

    .name {
        flex: 0;
        min-width: 200px;
    }
`;
