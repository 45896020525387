import { AuthAccessQueryParams, AuthAccessResponse } from '@makespace/global-types';

export async function requestAccess(
    authUrl: string,
    resource: string,
    params?: AuthAccessQueryParams,
): Promise<AuthAccessResponse> {
    try {
        const url = new URL(`${authUrl}/access/${resource}`);
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                url.searchParams.append(key, value);
            });
        }
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) throw new Error('Access request failed');

        const result = (await response.json()) as AuthAccessResponse;

        if (result.redirectUrl) window.location.href = result.redirectUrl;
        return result;
    } catch (e) {
        return { message: (e as Error).message, success: false };
    }
}
