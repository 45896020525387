import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export type FormRowProps = {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    variant?: 'row' | 'column' | 'single';
};

export default function FormRow(props: FormRowProps): JSX.Element {
    const { children, variant = 'row', className } = props;
    return (
        <FormRowContainer className={clsx(variant, className)}>
            {children}
            {variant === 'single' ? <span className={'spacer'} /> : null}
        </FormRowContainer>
    );
}

const FormRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;

    &.row {
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        gap: 1rem;
    }

    &.single {
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        gap: 1rem;
    }

    &.column {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }

    .spacer {
        width: 100%;
    }
`;

export const FormRowIcon = styled.div`
    flex: 0 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
