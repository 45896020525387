import { ApolloError } from '@apollo/client';
import {
    Booking,
    BookingAvailability,
    BookingAvailableInput,
    EBookingAvailability,
} from '@makespace/graphql-generated/generated/webapp/schemas';
import { useBookingAvailableLazyQuery } from '@makespace/graphql-generated/generated/webapp/hooks';
import { useCallback } from 'react';

export type UseBookingAvailabilityProps = {
    spaceId?: string;
    spaceTypeId?: string;
    spaceVariantId?: string;
};

export type UseBookingAvailabilityResponse = {
    checkBookingPossible: (
        booking: BookingAvailableInput,
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    ) => Promise<BookingAvailability | null>;
    error?: ApolloError;
    loading: boolean;
};

export default function useBookingAvailability(
    props: UseBookingAvailabilityProps,
): UseBookingAvailabilityResponse {
    const [getBookingAvailability, { error, loading }] = useBookingAvailableLazyQuery({
        fetchPolicy: 'no-cache',
    });

    const checkBookingPossible = useCallback(
        async (booking: BookingAvailableInput) => {
            if (!booking.spaceType?.length || !booking.spaceTypeVariant?.length) return null;
            try {
                const { data: { bookingAvailable } = {} } = await getBookingAvailability({
                    variables: {
                        input: {
                            space: booking.space?.length ? booking.space : undefined,
                            spaceType: booking.spaceType,
                            spaceTypeVariant: booking.spaceTypeVariant,
                            dateFrom: booking.dateFrom,
                            dateTo: booking.dateTo,
                            user: booking.user,
                        },
                    },
                });
                return (
                    {
                        availability:
                            bookingAvailable?.availability ?? EBookingAvailability.Unavailable,
                        reason: bookingAvailable?.reason ?? undefined,
                    } ?? null
                );
            } catch (error) {
                console.error('Error checking booking availability:', error);
                return null;
            }
        },
        [getBookingAvailability],
    );

    return {
        checkBookingPossible,
        error,
        loading,
    };
}
