import { EButtonSize, EButtonVariant } from '~components/clickables/Button';
import { PageHeader } from '~components/layouts/PageHeader';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconCirclePlus from '~components/icons/IconCirclePlus';
import InventoryList from '~components/schema/inventory/InventoryList';
import LinkButton from '~components/clickables/LinkButton';
import React, { lazy } from 'react';
import routePaths, { fullPath } from '~constants/routePaths';

const InventoryRecordCreatePage = lazy(() => import('~pages/inventory/InventoryRecordCreatePage'));
const InventoryRecordPage = lazy(() => import('~pages/inventory/InventoryRecordPage'));

export default function InventoryPage(): JSX.Element {
    return (
        <Routes>
            <Route path={routePaths.inventory.create} element={<InventoryRecordCreatePage />} />
            <Route path={routePaths.inventory.inventoryRecord} element={<InventoryRecordPage />} />
            <Route index element={<InventoryOverviewPage />} />
        </Routes>
    );
}

function InventoryOverviewPage(): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <PageHeader
                heading={t('page.inventory.heading') ?? 'Products'}
                actions={
                    <LinkButton
                        to={fullPath(routePaths.inventory, 'create', {}, {})}
                        variant={EButtonVariant.TEXT}
                        size={EButtonSize.SMALL}
                        iconStart={IconCirclePlus}>
                        {t('page.inventory.action.create')}
                    </LinkButton>
                }
            />
            <InventoryList />
        </>
    );
}
