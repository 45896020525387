import { makeQueryString, readQueryParams, replaceQueryParams } from '~utils/urls';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type QueryParams = Record<string, (string | string[]) | (number | number[])>;
export type UseQueryParams<Params> = Params & {
    removeQueryParam: (param: string) => void;
    setQueryParams: (params: Partial<Params>, replace?: boolean) => void;
    updateQueryParams: (params: Partial<Params>, removeParams?: string[]) => void;
    urlParams: string;
};

export default function useQueryParams<Params extends QueryParams>(): UseQueryParams<Params> {
    const search = useLocation().search;
    const navigate = useNavigate();

    const queryParams = useMemo(() => readQueryParams<Params>(search), [search]);

    const setQueryParams = useCallback(
        (params: Partial<Params>, replace?: boolean) => {
            const fullParams = {
                ...(replace ? {} : queryParams),
                ...params,
            };

            const search = makeQueryString(fullParams);

            navigate({
                search: `?${search}`,
            });
        },
        [navigate, queryParams],
    );

    const updateQueryParams = useCallback(
        (params: Partial<Params>, removeParams?: string[]) => {
            const newParams = replaceQueryParams<Params>(params, removeParams);
            setQueryParams(newParams, true);
        },
        [setQueryParams],
    );

    const removeQueryParam = useCallback(
        (param: string) => {
            const newParams = { ...queryParams };
            delete newParams[param];
            setQueryParams(newParams, true);
        },
        [queryParams, setQueryParams],
    );

    return {
        ...(queryParams as Params),
        updateQueryParams,
        setQueryParams,
        removeQueryParam,
        urlParams: search,
    };
}

export function makeSearchString(
    params: Record<string, undefined | string | number | boolean | (string | number | boolean)[]>,
): string {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
        if (value === undefined) return;
        if (Array.isArray(value)) value.forEach((v) => searchParams.append(key, v.toString()));
        else searchParams.set(key, value.toString());
    });
    return searchParams.toString();
}
