import type { EBookingAvailability } from '@makespace/graphql-generated/webapp';
import type { Event, EventProps, ResourceHeaderProps } from 'react-big-calendar';

export enum ESpacePrefix {
    SPACE = 's',
    SPACE_TYPE = 'st',
    SPACE_TYPE_VARIANT = 'stv',
}

export type CalendarEventResource = {
    availability?: EBookingAvailability;
    bookingId?: string;
    highlight?: boolean;
    resourceId: string;
    resourceTitle: string;
    spaceId?: string | null;
    spaceTypeId?: string | null;
    spaceTypeVariantId?: string | null;
};

export type CalendarEvent = Omit<Event, 'resource' | 'title'> & {
    resource?: CalendarEventResource;
    title: string;
};

export type EventPropsCustom = EventProps & {
    resource?: CalendarEventResource;
};

export type ResourcePropsCustom = ResourceHeaderProps & {
    resource?: {
        resourceId: string;
        resourceTitle: string;
    };
};
