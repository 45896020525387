export enum EModal {
    BOOKING_CREATE = 'BOOKING_CREATE',
    BOOKING_UPDATE = 'BOOKING_UPDATE',
    USER_CREATE = 'USER_CREATE',
    USER_SELECT = 'USER_SELECT',
}

export enum EModalReturn {
    DEFAULT = 'default',
    PARAMS = 'params',
    URL = 'url',
}

export const MODAL_QUERY_PARAMS: Record<EModal, string[]> = {
    [EModal.BOOKING_CREATE]: [
        'dateFrom',
        'dateTo',
        'space',
        'spaceType',
        'spaceTypeVariant',
        'user',
    ],
    [EModal.BOOKING_UPDATE]: [
        'id',
        'dateFrom',
        'dateTo',
        'space',
        'spaceType',
        'spaceTypeVariant',
        'user',
    ],
    [EModal.USER_CREATE]: ['userId', 'userCreateModalReturn'],
    [EModal.USER_SELECT]: ['userId', 'userSelectModalReturn'],
};
