import { EIconColor, EIconSize } from '~components/icons/Icon';
import { ETableCellVariant } from '~types/tables';
import {
    ETableControlSortDirection,
    useTableControl,
} from '~components/table/TableControlProvider';
import { font } from '~styles/styles';
import IconSortAscending from '~components/icons/IconSortAscending';
import IconSortDescending from '~components/icons/IconSortDescending';
import React, { useCallback } from 'react';
import TableHeader, { TableHeaderProps } from './TableHeader';
import clsx from 'clsx';
import styled from '@emotion/styled';

type ControlledTableCellHeaderProps = TableHeaderProps & {
    groupable?: boolean;
    sortKey?: string;
    sortable?: boolean;
    variant?: ETableCellVariant;
};

export default function ControlledTableHeader(props: ControlledTableCellHeaderProps): JSX.Element {
    const { sortKey, sortable, children, groupable, variant, ...remainingProps } = props;
    const { sortBy, removeSorting, sortingBy } = useTableControl();
    const sortDirection = sortingBy.find((s) => s.sortKey === sortKey)?.direction;
    const sortIndex =
        sortingBy.length > 1 ? sortingBy.findIndex((s) => s.sortKey === sortKey) + 1 : undefined;

    const sortFn = useCallback(() => {
        if (!sortable || !sortKey) return undefined;
        switch (sortDirection) {
            case ETableControlSortDirection.DESC:
                return () => removeSorting({ sortKey, direction: ETableControlSortDirection.ASC });
            case ETableControlSortDirection.ASC:
                return () => sortBy({ sortKey, direction: ETableControlSortDirection.DESC });
            default:
                return () => sortBy({ sortKey, direction: ETableControlSortDirection.ASC });
        }
    }, [removeSorting, sortBy, sortDirection, sortKey, sortable]);

    return (
        <TableHeader variant={variant} {...remainingProps}>
            <CellInner className={clsx(variant, { sortable, groupable })}>
                <span className={clsx('label')} onClick={sortFn()}>
                    {children}
                </span>
                <span className="actions" onClick={sortFn()}>
                    {sortIndex ? <SortIndexDisplay>{sortIndex}</SortIndexDisplay> : null}
                    {sortable ? (
                        sortDirection === ETableControlSortDirection.ASC ? (
                            <IconSortAscending size={EIconSize.SMALL} color={EIconColor.ACTIVE} />
                        ) : sortDirection === ETableControlSortDirection.DESC ? (
                            <IconSortDescending size={EIconSize.SMALL} color={EIconColor.ACTIVE} />
                        ) : (
                            <IconSortAscending size={EIconSize.SMALL} color={EIconColor.DISABLED} />
                        )
                    ) : null}
                </span>
            </CellInner>
        </TableHeader>
    );
}

const CellInner = styled.span`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    &.${ETableCellVariant.PRICE}, &.${ETableCellVariant.NUMBER} {
        flex-direction: row-reverse;
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 0.25rem;
    }

    .label {
        flex: 1;
        .actions {
            display: flex;
        }
    }

    .sortIcon {
        font-size: 0;
        line-height: 0;
    }

    &.sortable {
        cursor: pointer;
    }
`;

const SortIndexDisplay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: ${font.size.extraSmall};
    line-height: ${font.lineHeight.extraSmall};
    font-weight: ${font.weight.medium};
`;
