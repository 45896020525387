import { ETableCellVariant } from '~types/tables';
import { Inventory, useInventoryQuery } from '@makespace/graphql-generated/webapp';
import {
    TableControlCells,
    TableControlHeadings,
    TableControlProvider,
} from '~components/table/TableControlProvider';
import { useLanguage } from '~context/Language';
import ControlledTable from '~components/table/ControlledTable';
import React, { useMemo } from 'react';

type InventoryData = {
    _id: string;
    createdAt: string;
    itemName: string;
    itemType: string;
    quantity: number;
    updatedAt: string;
};

const tableHeadings: TableControlHeadings<InventoryData> = {
    _id: {
        show: false,
    },
    itemName: {
        label: 'Name',
        sortable: true,
        variant: ETableCellVariant.NAME,
    },
    itemType: {
        label: 'Type',
        sortable: true,
        variant: ETableCellVariant.NAME,
    },
    quantity: {
        label: 'Quantity',
        sortable: true,
        variant: ETableCellVariant.NUMBER,
    },
    createdAt: {
        label: 'Created',
        sortable: true,
        variant: ETableCellVariant.DATE,
    },
    updatedAt: {
        label: 'Updated',
        sortable: true,
        variant: ETableCellVariant.DATE,
    },
};

const tableCells: TableControlCells<InventoryData> = {
    _id: {
        show: false,
    },
    itemName: {
        editable: false,
        variant: ETableCellVariant.NAME,
        cellProps: (data) => ({ link: data._id }),
    },
    itemType: {
        editable: false,
        variant: ETableCellVariant.NAME,
    },
    quantity: {
        editable: false,
        variant: ETableCellVariant.NUMBER,
    },
    createdAt: {
        editable: false,
        variant: ETableCellVariant.DATE,
    },
    updatedAt: {
        editable: false,
        variant: ETableCellVariant.DATE,
    },
};

export default function InventoryList(): JSX.Element {
    const { data: { inventory } = {} } = useInventoryQuery({
        fetchPolicy: 'cache-and-network',
    });
    const { translate } = useLanguage();

    const tableData: InventoryData[] = useMemo(() => {
        return (
            ((inventory?.filter((inventoryRecord) => !!inventoryRecord) as Inventory[]) ?? []).map(
                (inventoryRecord) => ({
                    _id: inventoryRecord._id,
                    itemName: translate(inventoryRecord.item)?.name ?? inventoryRecord.item.name,
                    itemType: inventoryRecord.itemType,
                    quantity: inventoryRecord.quantity,
                    createdAt: inventoryRecord.createdAt,
                    updatedAt: inventoryRecord.updatedAt,
                }),
            ) ?? []
        );
    }, [inventory, translate]);

    return (
        <TableControlProvider<InventoryData>
            headings={tableHeadings}
            cells={tableCells}
            data={tableData}
            searchable={true}>
            <ControlledTable<InventoryData> />
        </TableControlProvider>
    );
}
