export const cleanUrl = (url: string): string =>
    url
        .replaceAll('*', '')
        .replaceAll(/:[^$/]+/g, '')
        .replaceAll('//', '/')
        .replace(/\/$/, '');

export const replaceRouteParams = (route: string, params?: Record<string, string>) =>
    params
        ? Object.entries(params)
              .reduce((path, [key, value]) => path.replace(`:${key}`, value), route)
              .replace('?', '')
        : route;

export type QueryParamValue = string | number | (string | number)[];
export type QueryParams = Record<string, QueryParamValue | undefined>;

export const addQueryParams = (route: string, queryParams?: QueryParams) =>
    queryParams ? `${route}?${makeQueryString(queryParams)}`.replace(/\?$/, '') : route;

export const makeQueryString = (queryParams?: Record<string, QueryParamValue | undefined>) =>
    queryParams
        ? Object.entries(queryParams)
              .filter(
                  (
                      entry: [string, QueryParamValue | undefined],
                  ): entry is [string, QueryParamValue] => !!entry[1],
              )
              .map(
                  ([key, value]) =>
                      `${encodeURIComponent(key)}=${Array.isArray(value) ? encodeURI(value.join(',')) : encodeURIComponent(value)}`,
              )
              .join('&')
        : '';

export const updateQueryParams = (search: string, newQueryParams?: QueryParams) => {
    const currentQueryParams = readQueryParams(search);
    return { ...currentQueryParams, ...newQueryParams };
};

export const appendQueryParams = (newQueryParams?: QueryParams) => {
    const currentQueryParams = readQueryParams(window.location.search);
    window.history.pushState(
        {},
        '',
        `${window.location.pathname}?${makeQueryString({ ...currentQueryParams, ...newQueryParams })}`,
    );
};

export function replaceQueryParams<Params extends QueryParams>(
    params?: QueryParams,
    removeParams?: string[],
): Partial<Params> {
    const currentQueryParams = readQueryParams(window.location.search);
    if (removeParams) removeParams.forEach((param) => delete currentQueryParams[param]);
    return { ...currentQueryParams, ...(params || {}) } as Partial<Params>;
}

export function readQueryParams<Params extends QueryParams>(search: string): Partial<Params> {
    const searchParams = new URLSearchParams(search);
    const params: Record<string, unknown> = {};
    searchParams.forEach((value, key) => {
        params[key] = value.indexOf(',') !== -1 ? value.split(',') : value;
    });
    return params as Partial<Params>;
}
