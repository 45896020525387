import { EIconColor, EIconSize } from '~components/icons/Icon';
import { border, color, font } from '~styles/styles';
import ClickCatcher, { EClickCatcherVariant } from '~components/clickables/ClickCatcher';
import IconCheck from '~components/icons/IconCheck';
import IconChevronDown from '~components/icons/IconChevronDown';
import IconChevronUp from '~components/icons/IconChevronUp';
import Input, { EInputVariant } from '~components/form/Input';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ScrollLock from '~components/ScrollLock';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { SelectHTMLAttributes } from 'react';

const SELECT_OPEN_TAB_INDEX_BASE = 1000;
const SELECT_OPTIONS_LENGTH = 10;

export enum ESelectOptionType {
    NORMAL,
    ALL,
}

export enum ESelectOptionsPosition {
    ABOVE = 'above',
    BELOW = 'below',
}

export enum ESelectOptionsState {
    CLOSED = 'closed',
    FULL = 'full',
    OPEN = 'open',
}

export type SelectOption<ValueType = string | number> = {
    disabled?: boolean;
    label: string;
    value: ValueType;
};

export type SelectValue = string | number | (string | number)[];

export type SelectProps = Omit<
    SelectHTMLAttributes<HTMLSelectElement>,
    'onChange' | 'defaultValue'
> & {
    defaultValue?: SelectValue;
    emptyText?: string;
    error?: boolean;
    formatDisplay?: (displayValue?: string) => string | undefined;
    forwardRef?: React.Ref<never>;
    loading?: boolean;
    multiple?: boolean;
    onChange?: (options?: string | number | (string | number)[]) => void;
    options: SelectOption[];
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    search?: boolean;
    variant?: EInputVariant;
};

export default function Select(props: SelectProps): JSX.Element {
    const {
        options = [],
        onChange,
        multiple = false,
        loading = false,
        readOnly = false,
        search = true,
        placeholder,
        emptyText = 'No options',
        error,
        disabled,
        variant = EInputVariant.NORMAL,
        value,
        name,
        forwardRef,
        formatDisplay = (v) => v,
        className,
    } = props;
    const [inputValue, setInputValue] = useState<string>('');
    const [optionsState, setOptionsState] = useState<ESelectOptionsState>(
        ESelectOptionsState.CLOSED,
    );

    const open = () => {
        if (!disabled && !readOnly) setOptionsState(ESelectOptionsState.OPEN);
    };

    const close = () => {
        setOptionsState(ESelectOptionsState.CLOSED);
        setInputValue('');
    };

    const select = (event: React.MouseEvent<HTMLDivElement>, option: SelectOption) => {
        const newValue = multiple
            ? Array.isArray(value)
                ? value.concat([option.value])
                : [option.value]
            : option.value;
        onChange?.(newValue);
        if (!multiple) close();
    };

    const deselect = (event: React.MouseEvent<HTMLDivElement>, option: SelectOption) => {
        const newValue = multiple
            ? Array.isArray(value)
                ? value.filter((v) => option.value !== v)
                : undefined
            : undefined;

        onChange?.(newValue?.length ? newValue : multiple ? [] : undefined);
        if (!multiple) close();
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (search) setInputValue(event.target.value);
    };

    const valueDisplay = useMemo(
        () =>
            formatDisplay(
                (multiple ? Array.isArray(value) && !!value.length : value)
                    ? multiple
                        ? options
                              .filter((o) =>
                                  Array.isArray(value)
                                      ? value.includes(o.value)
                                      : o.value === value,
                              )
                              .map((o) => o.label)
                              .join(', ')
                        : options.find((o) => o.value === value)?.label
                    : '',
            ),
        [formatDisplay, multiple, options, value],
    );

    const showOptions = optionsState !== ESelectOptionsState.CLOSED;

    const filteredOptions = showOptions
        ? inputValue?.length
            ? options.filter(
                  (o) => o.label.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0,
              )
            : options
        : [];

    const isSelected = (option: SelectOption): boolean =>
        Array.isArray(value) ? value.includes(option.value) : option.value === value;

    return (
        <SelectContainer className={clsx(variant, className, { readOnly, showOptions })}>
            {variant == EInputVariant.INLINE ? (
                <InlineInput onClick={open}>{valueDisplay}</InlineInput>
            ) : (
                <SelectStyledInput
                    autoFocus={false}
                    onFocus={open}
                    disabled={disabled}
                    placeholder={loading ? `${placeholder || ''}...` : placeholder}
                    readOnly={readOnly}
                    onChange={handleChange}
                    value={showOptions && search ? inputValue : valueDisplay}
                    tabIndex={showOptions ? SELECT_OPEN_TAB_INDEX_BASE : undefined}
                    className={clsx(variant, { error, disabled, readOnly, showOptions })}
                    name={name}
                    forwardRef={forwardRef}
                    error={error}
                    autoComplete={'off'}
                    icon={
                        <SelectIcon onClick={showOptions ? close : open} className={clsx(variant)}>
                            {showOptions ? (
                                <IconChevronUp
                                    color={disabled ? EIconColor.DISABLED : EIconColor.INHERIT}
                                    size={EIconSize.TINY}
                                />
                            ) : (
                                <IconChevronDown
                                    color={disabled ? EIconColor.DISABLED : EIconColor.INHERIT}
                                    size={EIconSize.TINY}
                                />
                            )}
                        </SelectIcon>
                    }
                />
            )}
            <Options
                variant={variant}
                open={showOptions}
                state={optionsState}
                setState={setOptionsState}
                limit={20}>
                {filteredOptions.map((o, i) => (
                    <Option
                        key={o.value}
                        option={o}
                        onSelect={select}
                        onDeselect={deselect}
                        selected={isSelected(o)}
                        disabled={o.disabled}
                        tabIndex={SELECT_OPEN_TAB_INDEX_BASE + i}
                    />
                ))}
            </Options>
            {showOptions ? (
                <>
                    <ClickCatcher
                        onClick={close}
                        variant={
                            optionsState === ESelectOptionsState.FULL
                                ? EClickCatcherVariant.DARK
                                : EClickCatcherVariant.NORMAL
                        }
                    />
                    <ScrollLock />
                </>
            ) : null}
        </SelectContainer>
    );
}

type OptionsContainerProps = {
    children: React.ReactNode[];
    limit?: number;
    open?: boolean;
    setState: (state: ESelectOptionsState) => void;
    state: ESelectOptionsState;
    variant: EInputVariant;
};

function Options(props: OptionsContainerProps): JSX.Element {
    const { children, variant, open, state, limit = SELECT_OPTIONS_LENGTH, setState } = props;
    const optionsRef = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState<ESelectOptionsPosition>(ESelectOptionsPosition.BELOW);

    useEffect(() => {
        const height = optionsRef?.current?.offsetHeight ?? 0;
        const parentBottom = open
            ? optionsRef?.current?.parentElement?.getBoundingClientRect().bottom ?? 0
            : 0;
        setPosition(
            parentBottom + height >= window.innerHeight
                ? ESelectOptionsPosition.ABOVE
                : ESelectOptionsPosition.BELOW,
        );
    }, [open]);

    const hasMore = (children?.length ?? 0) > limit;

    const options = useMemo(() => {
        switch (state) {
            case ESelectOptionsState.CLOSED: {
                return null;
            }
            case ESelectOptionsState.OPEN: {
                return limit ? children.slice(0, limit) : children;
            }
            case ESelectOptionsState.FULL: {
                const avg = Math.ceil(children.length / 3);
                return [
                    <span key={`col-1`} className={'column'}>
                        {children.slice(0, avg)}
                    </span>,
                    <span key={`col-2`} className={'column'}>
                        {children.slice(avg, avg * 2)}
                    </span>,
                    <span key={`col-3`} className={'column'}>
                        {children.slice(avg * 2)}
                    </span>,
                ];
            }
        }
    }, [children, limit, state]);

    return (
        <OptionsContainer className={clsx(variant, position, state)} ref={optionsRef}>
            {options?.length ? (
                <div className={'list'}>{options}</div>
            ) : (
                <OptionContainer className={'info'}>Empty</OptionContainer>
            )}
            {state !== ESelectOptionsState.FULL && hasMore ? (
                <OptionContainer
                    className={'control'}
                    onClick={() => setState(ESelectOptionsState.FULL)}>
                    Show All
                </OptionContainer>
            ) : null}
        </OptionsContainer>
    );
}

export type OptionProps = Pick<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'tabIndex'
> & {
    disabled?: boolean;
    onDeselect?: (event: React.MouseEvent<HTMLDivElement>, option: SelectOption) => void;
    onSelect?: (event: React.MouseEvent<HTMLDivElement>, option: SelectOption) => void;
    option: SelectOption;
    selected?: boolean;
};

function Option(props: OptionProps): JSX.Element {
    const { onSelect, onDeselect, option, selected = false, disabled = false, tabIndex } = props;
    return (
        <OptionContainer
            className={clsx({ disabled, selected })}
            onClick={(e) => {
                if (selected) onDeselect?.(e, option);
                else onSelect?.(e, option);
            }}
            tabIndex={tabIndex}>
            {option.label}
            {selected ? <IconCheck /> : null}
        </OptionContainer>
    );
}

const SelectContainer = styled.div`
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    z-index: 0;

    &.inline {
        display: inline-flex;
        gap: 0.25rem;
        padding: 2px 0.25rem 0 0.25rem;
        border-bottom: ${border.width.thin} solid ${color.motoMatixBlueWhite};
        &:hover {
            border-color: ${color.blue};
        }
    }

    &.showOptions {
        z-index: 99997;
    }
`;

const OptionsContainer = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    border: ${border.width.thin} solid ${color.grey};
    background-color: ${color.white};
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
    padding: 0.5rem;
    outline: none;
    margin: 0;
    gap: 0.25rem;
    display: none;
    flex-direction: column;
    max-height: 25vh;

    & .list {
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        overflow-x: auto;
        gap: 0.25rem;
    }

    &.${ESelectOptionsState.CLOSED} {
        display: none;
        z-index: 0;
    }

    &.${ESelectOptionsState.OPEN} {
        display: flex;
        z-index: 99998;

        & .controls {
            position: absolute;
            top: 0;
            right: 0;
        }

        &.${ESelectOptionsPosition.ABOVE} {
            top: auto;
            bottom: calc(100% - 1px);
        }

        &.${ESelectOptionsPosition.BELOW} {
            bottom: auto;
            top: calc(100% - 1px);
        }
    }

    &.${ESelectOptionsState.FULL} {
        display: flex;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 80vh;
        max-width: 80vw;
        padding: 1rem;

        & .list {
            display: flex;
            flex-direction: row;
            align-items: stretch;

            & .column {
                flex: 1;
            }

            & > div {
                flex: 1;
            }
        }
    }

    &.inline {
        width: auto;
        left: -1.75rem;
        margin-top: 0.25rem;
    }
`;

const SelectStyledInput = styled(Input)`
    position: relative;

    &.showOptions {
        z-index: 2;
    }
`;

const InlineInput = styled.span`
    font-size: ${font.size.medium};
    font-weight: ${font.weight.medium};
    color: ${color.blue};
    cursor: pointer;
`;

const OptionContainer = styled.div`
    font-size: ${font.size.normal};
    font-weight: ${font.weight.regular};
    line-height: ${font.lineHeight.extraLarge};
    padding: 0.25rem 0.5rem;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    gap: 1rem;
    white-space: nowrap;
    border-radius: ${border.radius.small};

    &.info {
        font-size: ${font.size.small};
        font-weight: ${font.weight.regular};
        line-height: ${font.lineHeight.small};
        color: ${color.grey};
        cursor: default;
        padding: 0.25rem 1rem;
    }

    &.control {
        font-size: ${font.size.small};
        font-weight: ${font.weight.regular};
        line-height: ${font.lineHeight.small};
        color: ${color.greyDark};
        padding: 0.5rem 0.5rem 0.25rem 0.5rem;
        border-top: 1px solid ${color.greyLight}50;
        justify-content: flex-end;

        &:hover {
            color: ${color.black};
            background: transparent;
        }
    }

    &.selected {
        background: ${color.greyLight}50;
    }

    &:hover {
        background: ${color.greyLight}25;
    }
`;

const SelectIcon = styled.span`
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    color: ${color.blue};

    &.inline {
        position: static;
        transform: translateY(1px);
    }
`;
