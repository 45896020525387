import { FieldError } from 'react-hook-form';
import { border, color, font, space } from '~styles/styles';
import Error from '~components/info/ErrorAlert';
import Label, { LabelProps } from '~components/form/Label';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum EFormControlVariant {
    COLUMN = 'column',
    ROW = 'row',
}

export type FormControlProps = LabelProps & {
    children?: React.ReactNode | React.ReactNode[];
    error?: FieldError | Error;
    id?: string;
    info?: React.ReactNode | string;
    label?: React.ReactNode | string;
    variant?: EFormControlVariant;
};

export default function FormControl(props: FormControlProps): JSX.Element {
    const { id, label, required, info, children, error, className, variant } = props;

    return (
        <FormControlContainer className={clsx(variant, className)}>
            {label ? (
                <Label htmlFor={id} required={required}>
                    {label}
                </Label>
            ) : null}
            {children}
            {info ? (
                <FormControlInfo>{info}</FormControlInfo>
            ) : error ? (
                <FormControlInfo>{error.message}</FormControlInfo>
            ) : null}
        </FormControlContainer>
    );
}

const FormControlContainer = styled.div`
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    gap: ${space.gap.small};
    width: 100%;

    &.${EFormControlVariant.COLUMN} {
    }

    &.${EFormControlVariant.ROW} {
        flex-direction: row;
        align-items: center;
        justify-content: stretch;

        & label {
            flex: 0.2;
        }

        & label + div {
            flex: 1;
        }
    }
`;

const FormControlInfo = styled.p`
    font-size: ${font.size.extraSmall};
    font-weight: ${font.weight.regular};
    line-height: ${font.lineHeight.normal};
    color: ${color.linkTextLight};
    text-align: right;
    font-style: italic;
    max-width: 80%;
    margin: 0 ${border.width.normal} 0 auto;
`;
