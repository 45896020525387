import { ESpacePrefix } from '~types/calendar';

export function dashedClassName(parts: (string | number | undefined | null)[]) {
    return parts.map((p) => p?.toString()).join('-');
}

export function spaceTypeClassName(id?: string | null | undefined) {
    return dashedClassName([ESpacePrefix.SPACE_TYPE, id]);
}

export function spaceTypeVariantClassName(id?: string | null | undefined) {
    return dashedClassName([ESpacePrefix.SPACE_TYPE_VARIANT, id]);
}

export function spaceClassName(id?: string | null | undefined) {
    return dashedClassName([ESpacePrefix.SPACE, id]);
}
