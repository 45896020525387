import * as yup from 'yup';
import {
    EBookingPeriod,
    EBookingStatus,
} from '@makespace/graphql-generated/generated/webapp/schemas';
import { yupDayOfWeek, yupIsoDateString, yupObjectId, yupTimeString } from '~utils/yupCommon';
import type {
    BookingConfigurationCreateUpdate,
    BookingCreate,
    BookingOptionCreateUpdate,
    BookingOptionLimitsCreateUpdate,
    BookingOptionRatesCreateUpdate,
    BookingUpdate,
} from '@makespace/graphql-generated/generated/webapp/schemas';
import type {
    ScalarDateRangeString,
    ScalarTimeRange,
} from '@makespace/graphql-generated/types/Scalars';

export type BookingQueryParams = {
    dateFrom?: string;
    dateTo?: string;
    id?: string;
    space?: string;
    spaceType?: string;
    spaceTypeVariant?: string;
    user?: string;
};

export const BookingDateRangeSchema: yup.TupleSchema<ScalarDateRangeString> = yup
    .tuple([yupIsoDateString.nonNullable().required(), yupIsoDateString.nonNullable().required()])
    .nonNullable()
    .required();

export const BookingTimeRangeSchema: yup.TupleSchema<ScalarTimeRange> = yup
    .tuple([
        yupTimeString.nonNullable().required(),
        yupTimeString.nonNullable().required(),
        yup.string().nonNullable().required(),
    ])
    .nonNullable()
    .required();

export const BookingOptionsLimitsSchema: yup.ObjectSchema<BookingOptionLimitsCreateUpdate> = yup
    .object()
    .shape({
        increment: yup.number().required(),
        min: yup.number().required(),
        max: yup.number().required(),
    });

export const BookingOptionsRatesSchema: yup.ObjectSchema<BookingOptionRatesCreateUpdate> = yup
    .object()
    .shape({
        max: yup.number().required(),
        min: yup.number().required(),
        price: yup.number(),
        rate: yup.number(),
    });

export const BookingOptionSchema: yup.ObjectSchema<BookingOptionCreateUpdate> = yup.object().shape({
    limits: BookingOptionsLimitsSchema,
    rates: yup.array(BookingOptionsRatesSchema).required(),
    period: yup.string().oneOf(Object.values(EBookingPeriod)),
});

export const BookingConfigurationSchema: yup.ObjectSchema<BookingConfigurationCreateUpdate> = yup
    .object()
    .shape({
        availabilityDates: yup.array(BookingDateRangeSchema).required(),
        availabilityDays: yup.array(yupDayOfWeek).required(),
        availabilityTimes: yup.array(BookingTimeRangeSchema).required(),
        bookingOptions: yup.array(BookingOptionSchema).required(),
        userCategories: yup.array(yupObjectId).required(),
    });

export type BookingCreateUpdateForm = BookingCreate &
    Omit<BookingUpdate, '_id'> & {
        _id?: string | null;
    };

export const BookingCreateUpdateSchema: yup.ObjectSchema<BookingCreateUpdateForm> = yup
    .object()
    .shape({
        _id: yup.string().notRequired(),
        bookingNumber: yup.string().notRequired(),
        dateFrom: yup.string().required(),
        dateTo: yup.string().required(),
        description: yup.string().notRequired(),
        name: yup.string().notRequired(),
        notes: yup.array().of(yup.string().required()).required(),
        space: yup.string().required(),
        spaceType: yup.string().required(),
        spaceTypeVariant: yup.string().required(),
        status: yup.string().oneOf(Object.values(EBookingStatus)).required(),
        user: yup.string().required(),
        userCategory: yup.string().nonNullable(),
    });
