export type StyleFontSize = {
    extraLarge: string;
    extraSmall: string;
    huge: string;
    large: string;
    medium: string;
    normal: string;
    small: string;
    tiny: string;
};

export type StyleFontWeight = {
    black: number;
    bold: number;
    light: number;
    medium: number;
    regular: number;
    thin: number;
};

export type StyleFontFamily = {
    mono: string;
    sans: string;
};

export type StyleFont = {
    family: StyleFontFamily;
    lineHeight: StyleFontSize;
    size: StyleFontSize;
    weight: StyleFontWeight;
};

export const font: StyleFont = {
    family: {
        sans: 'proxima-nova, sans-serif',
        mono: 'Roboto Mono, monospace',
    },
    size: {
        tiny: '0.5rem',
        extraSmall: '0.75rem',
        small: '0.875rem',
        normal: '1rem',
        medium: '1.25rem',
        large: '1.5rem',
        extraLarge: '2rem',
        huge: '2.5rem',
    },
    lineHeight: {
        tiny: '0.5rem',
        extraSmall: '0.75rem',
        small: '0.875rem',
        normal: '1rem',
        medium: '1.125rem',
        large: '1.5rem',
        extraLarge: '2rem',
        huge: '2.5rem',
    },
    weight: {
        thin: 100,
        light: 200,
        regular: 400,
        medium: 500,
        bold: 600,
        black: 700,
    },
};

export type StyleColors = Record<string, string>;
export type StyleGradients = Record<string, string>;

export const color: StyleColors = {
    white: '#ffffff',
    black: '#000000',
    grey: '#dddddd',
    greyLightest: '#fafafa',
    greyLight: '#eeeeee',
    greyDark: '#8d8d8d',
    highlight: '#5e00ff',
    highlightDark: '#2a0a5c',
    highlightLight: '#e8d8fa',
    highlightUltraLight: '#f7f3fd',
    red: '#d60f08',
    orange: '#e47316',
    green: '#7ae416',
};

export const gradient: StyleGradients = {
    purpleBlack: 'linear-gradient(0deg, #000 0.21%, #4e4b69 99.79%)',
};

export type StyleBorderWidth = {
    normal: string;
    thick: string;
    thin: string;
};

export type StyleBorderRadius = {
    large: string;
    medium: string;
    small: string;
};

export type StyleBorder = {
    radius: StyleBorderRadius;
    width: StyleBorderWidth;
};

export const border: StyleBorder = {
    width: {
        thin: '1px',
        normal: '2px',
        thick: '4px',
    },
    radius: { small: '2px', medium: '4px', large: '6px' },
};

export type StyleSpaceVariants = {
    extraLarge: string;
    large: string;
    medium: string;
    normal: string;
    small: string;
    tiny: string;
};

export type StyleSpaceMargin = {
    large: string;
    medium: string;
    normal: string;
    small: string;
    tiny: string;
};

export type StyleSpace = {
    gap: StyleSpaceVariants;
    margin: StyleSpaceVariants;
    padding: StyleSpaceVariants;
};

export const space: StyleSpace = {
    padding: {
        tiny: '0.25rem',
        small: '0.5rem',
        normal: '1rem',
        medium: '1.5rem',
        large: '2rem',
        extraLarge: '4rem',
    },
    margin: {
        tiny: '0 0 0.25rem 0',
        small: '0 0 0.5rem 0',
        normal: '0 0 1rem 0',
        medium: '0 0 1.5rem 0',
        large: '0 0 2rem 0',
        extraLarge: '0 0 3rem 0',
    },
    gap: {
        tiny: '0.25rem',
        small: '0.5rem',
        normal: '1rem',
        medium: '1.5rem',
        large: '2rem',
        extraLarge: '4rem',
    },
};
