import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const FContactAddressFragmentDoc = gql`
    fragment FContactAddress on ContactAddress {
        addressType
        city
        country
        line1
        line2
        postalCode
        state
    }
`;
export const FContactMethodFragmentDoc = gql`
    fragment FContactMethod on ContactMethod {
        source
        value
    }
`;
export const FBusinessFragmentDoc = gql`
    fragment FBusiness on Business {
        country
        name
        registrationNumber
        taxNumber
        contact {
            address {
                ...FContactAddress
            }
            methods {
                ...FContactMethod
            }
        }
    }
    ${FContactAddressFragmentDoc}
    ${FContactMethodFragmentDoc}
`;
export const FUserCategoryFragmentDoc = gql`
    fragment FUserCategory on UserCategory {
        _id
        name
        roles
    }
`;
export const FUserFragmentDoc = gql`
    fragment FUser on User {
        _id
        business {
            ...FBusiness
        }
        category {
            ...FUserCategory
        }
        contact {
            address {
                ...FContactAddress
            }
            methods {
                ...FContactMethod
            }
        }
        createdAt
        email
        firstName
        lastName
        nationality
        passportNumber
        roles
        updatedAt
    }
    ${FBusinessFragmentDoc}
    ${FUserCategoryFragmentDoc}
    ${FContactAddressFragmentDoc}
    ${FContactMethodFragmentDoc}
`;
export const FBookingFragmentDoc = gql`
    fragment FBooking on Booking {
        _id
        createdAt
        updatedAt
        bookingNumber
        dateFrom
        dateTo
        description
        name
        notes
        status
        space
        spaceType
        spaceTypeVariant
        user {
            ...FUser
        }
    }
    ${FUserFragmentDoc}
`;
export const FBookingAvailabilityFragmentDoc = gql`
    fragment FBookingAvailability on BookingAvailability {
        availability
        reason
    }
`;
export const FBookingChoiceFragmentDoc = gql`
    fragment FBookingChoice on BookingChoice {
        dateFrom
        dateTo
        space
        spaceType
        spaceTypeVariant
    }
`;
export const TranslationFieldsFragmentDoc = gql`
    fragment TranslationFields on Translation {
        language
        name
        description
    }
`;
export const IngredientFieldsFragmentDoc = gql`
    fragment IngredientFields on Ingredient {
        _id
        createdAt
        updatedAt
        name
        quantity
        translations {
            ...TranslationFields
        }
    }
    ${TranslationFieldsFragmentDoc}
`;
export const ConsumableFieldsFragmentDoc = gql`
    fragment ConsumableFields on Consumable {
        _id
        createdAt
        updatedAt
        name
        quantity
        translations {
            ...TranslationFields
        }
    }
    ${TranslationFieldsFragmentDoc}
`;
export const InventoryFieldsFragmentDoc = gql`
    fragment InventoryFields on Inventory {
        _id
        createdAt
        updatedAt
        item {
            __typename
            ... on Ingredient {
                ...IngredientFields
            }
            ... on Consumable {
                ...ConsumableFields
            }
        }
        itemType
        quantity
        volume
    }
    ${IngredientFieldsFragmentDoc}
    ${ConsumableFieldsFragmentDoc}
`;
export const InventoryItemFieldsFragmentDoc = gql`
    fragment InventoryItemFields on InventoryItem {
        itemType
        item {
            __typename
            ... on Ingredient {
                ...IngredientFields
            }
            ... on Consumable {
                ...ConsumableFields
            }
        }
    }
    ${IngredientFieldsFragmentDoc}
    ${ConsumableFieldsFragmentDoc}
`;
export const InventoryOrderItemFieldsFragmentDoc = gql`
    fragment InventoryOrderItemFields on InventoryOrderItem {
        itemType
        item {
            __typename
            ... on Ingredient {
                ...IngredientFields
            }
            ... on Consumable {
                ...ConsumableFields
            }
        }
        quantity
        received
        receivedAt
        receivedQuantity
    }
    ${IngredientFieldsFragmentDoc}
    ${ConsumableFieldsFragmentDoc}
`;
export const FContactFragmentDoc = gql`
    fragment FContact on Contact {
        address {
            ...FContactAddress
        }
        description
        name
        methods {
            ...FContactMethod
        }
    }
    ${FContactAddressFragmentDoc}
    ${FContactMethodFragmentDoc}
`;
export const TagSimpleFieldsFragmentDoc = gql`
    fragment TagSimpleFields on Tag {
        _id
        createdAt
        updatedAt
        name
        schemas
        translations {
            ...TranslationFields
        }
    }
    ${TranslationFieldsFragmentDoc}
`;
export const SupplierFieldsFragmentDoc = gql`
    fragment SupplierFields on Supplier {
        _id
        createdAt
        updatedAt
        contacts {
            ...FContact
        }
        name
        translations {
            ...TranslationFields
        }
        tags {
            ...TagSimpleFields
        }
    }
    ${FContactFragmentDoc}
    ${TranslationFieldsFragmentDoc}
    ${TagSimpleFieldsFragmentDoc}
`;
export const InventoryOrderFieldsFragmentDoc = gql`
    fragment InventoryOrderFields on InventoryOrder {
        _id
        createdAt
        updatedAt
        orderItems {
            ...InventoryOrderItemFields
        }
        supplier {
            ...SupplierFields
        }
        status
        orderedAt
    }
    ${InventoryOrderItemFieldsFragmentDoc}
    ${SupplierFieldsFragmentDoc}
`;
export const InventoryOrderSummaryFieldsFragmentDoc = gql`
    fragment InventoryOrderSummaryFields on InventoryOrderSummary {
        _id
        createdAt
        updatedAt
        orderItemsCount
        supplierId
        supplierName
        status
        orderedAt
    }
`;
export const FMediaMetadataFragmentDoc = gql`
    fragment FMediaMetadata on MediaMetadata {
        duration
        extension
        height
        mimeType
        orientation
        size
        width
    }
`;
export const FMediaS3FragmentDoc = gql`
    fragment FMediaS3 on MediaS3 {
        key
    }
`;
export const FMediaFragmentDoc = gql`
    fragment FMedia on Media {
        _id
        createdAt
        updatedAt
        description
        mediaType
        metadata {
            ...FMediaMetadata
        }
        name
        s3 {
            ...FMediaS3
        }
        tags
        source {
            thumbnail
            small
            medium
            large
        }
    }
    ${FMediaMetadataFragmentDoc}
    ${FMediaS3FragmentDoc}
`;
export const MongoDocumentFieldsFragmentDoc = gql`
    fragment MongoDocumentFields on MongoDocument {
        _id
        createdAt
        updatedAt
    }
`;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on Product {
        _id
        createdAt
        updatedAt
        active
        name
        price
        slug
        translations {
            ...TranslationFields
        }
        tags {
            ...TagSimpleFields
        }
    }
    ${TranslationFieldsFragmentDoc}
    ${TagSimpleFieldsFragmentDoc}
`;
export const ProductVariationRecipeItemFieldsFragmentDoc = gql`
    fragment ProductVariationRecipeItemFields on ProductVariationRecipeItem {
        quantity
        ingredient {
            ...IngredientFields
        }
    }
    ${IngredientFieldsFragmentDoc}
`;
export const ProductVariationFieldsFragmentDoc = gql`
    fragment ProductVariationFields on ProductVariation {
        _id
        createdAt
        updatedAt
        active
        costPrice
        name
        price
        product {
            ...ProductFields
        }
        recipe {
            ...ProductVariationRecipeItemFields
        }
        translations {
            ...TranslationFields
        }
    }
    ${ProductFieldsFragmentDoc}
    ${ProductVariationRecipeItemFieldsFragmentDoc}
    ${TranslationFieldsFragmentDoc}
`;
export const OrderLineFieldsFragmentDoc = gql`
    fragment OrderLineFields on OrderLine {
        item {
            __typename
            ... on Product {
                ...ProductFields
            }
            ... on ProductVariation {
                ...ProductVariationFields
            }
        }
        itemType
    }
    ${ProductFieldsFragmentDoc}
    ${ProductVariationFieldsFragmentDoc}
`;
export const OrderFieldsFragmentDoc = gql`
    fragment OrderFields on Order {
        _id
        createdAt
        updatedAt
        deliveryTime
        orderLines {
            ...OrderLineFields
        }
        orderNumber
        status
    }
    ${OrderLineFieldsFragmentDoc}
`;
export const PageFieldsFragmentDoc = gql`
    fragment PageFields on Page {
        _id
        createdAt
        updatedAt
        name
        slug
        title
    }
`;
export const ProductVariationWithFullProductFieldsFragmentDoc = gql`
    fragment ProductVariationWithFullProductFields on ProductVariationWithFullProduct {
        _id
        createdAt
        updatedAt
        active
        costPrice
        name
        price
        product {
            ...ProductFields
        }
        recipe {
            ...ProductVariationRecipeItemFields
        }
        translations {
            ...TranslationFields
        }
    }
    ${ProductFieldsFragmentDoc}
    ${ProductVariationRecipeItemFieldsFragmentDoc}
    ${TranslationFieldsFragmentDoc}
`;
export const FrRgbaFragmentDoc = gql`
    fragment FRRgba on Rgba {
        a
        b
        g
        r
    }
`;
export const FThemeFragmentDoc = gql`
    fragment FTheme on Theme {
        colorPrimary {
            ...FRRgba
        }
        colorSecondary {
            ...FRRgba
        }
        icon
    }
    ${FrRgbaFragmentDoc}
`;
export const FBookingOptionLimitsFragmentDoc = gql`
    fragment FBookingOptionLimits on BookingOptionLimits {
        increment
        max
        min
    }
`;
export const FBookingOptionRatesFragmentDoc = gql`
    fragment FBookingOptionRates on BookingOptionRates {
        max
        min
        price
        rate
    }
`;
export const FBookingOptionFragmentDoc = gql`
    fragment FBookingOption on BookingOption {
        limits {
            ...FBookingOptionLimits
        }
        period
        rates {
            ...FBookingOptionRates
        }
    }
    ${FBookingOptionLimitsFragmentDoc}
    ${FBookingOptionRatesFragmentDoc}
`;
export const FBookingConfigurationFragmentDoc = gql`
    fragment FBookingConfiguration on BookingConfiguration {
        availabilityDates
        availabilityDays
        availabilityTimes
        bookingOptions {
            ...FBookingOption
        }
        userCategories {
            ...FUserCategory
        }
    }
    ${FBookingOptionFragmentDoc}
    ${FUserCategoryFragmentDoc}
`;
export const FSpaceFragmentDoc = gql`
    fragment FSpace on Space {
        _id
        active
        createdAt
        name
        slug
        updatedAt
        theme {
            ...FTheme
        }
        translations {
            ...TranslationFields
        }
    }
    ${FThemeFragmentDoc}
    ${TranslationFieldsFragmentDoc}
`;
export const FSpaceTypeVariantFragmentDoc = gql`
    fragment FSpaceTypeVariant on SpaceTypeVariant {
        _id
        active
        bookingConfigurations {
            ...FBookingConfiguration
        }
        createdAt
        name
        price
        priority
        slug
        updatedAt
        spaces {
            ...FSpace
        }
        theme {
            ...FTheme
        }
        translations {
            ...TranslationFields
        }
    }
    ${FBookingConfigurationFragmentDoc}
    ${FSpaceFragmentDoc}
    ${FThemeFragmentDoc}
    ${TranslationFieldsFragmentDoc}
`;
export const FSpaceTypeFragmentDoc = gql`
    fragment FSpaceType on SpaceType {
        _id
        active
        createdAt
        name
        priority
        slug
        updatedAt
        theme {
            ...FTheme
        }
        translations {
            ...TranslationFields
        }
        variants {
            ...FSpaceTypeVariant
        }
    }
    ${FThemeFragmentDoc}
    ${TranslationFieldsFragmentDoc}
    ${FSpaceTypeVariantFragmentDoc}
`;
export const FSpaceTranslatedFragmentDoc = gql`
    fragment FSpaceTranslated on SpaceTranslated {
        _id
        active
        createdAt
        description
        name
        slug
        updatedAt
        theme {
            ...FTheme
        }
    }
    ${FThemeFragmentDoc}
`;
export const FSpaceTypeVariantTranslatedFragmentDoc = gql`
    fragment FSpaceTypeVariantTranslated on SpaceTypeVariantTranslated {
        _id
        active
        createdAt
        description
        name
        price
        priority
        slug
        spaces {
            ...FSpaceTranslated
        }
        theme {
            ...FTheme
        }
        updatedAt
    }
    ${FSpaceTranslatedFragmentDoc}
    ${FThemeFragmentDoc}
`;
export const FSpaceTypeTranslatedFragmentDoc = gql`
    fragment FSpaceTypeTranslated on SpaceTypeTranslated {
        _id
        active
        createdAt
        description
        name
        priority
        slug
        theme {
            ...FTheme
        }
        updatedAt
        variants {
            ...FSpaceTypeVariantTranslated
        }
    }
    ${FThemeFragmentDoc}
    ${FSpaceTypeVariantTranslatedFragmentDoc}
`;
export const FSpaceTypeVariantNonPopulatedFragmentDoc = gql`
    fragment FSpaceTypeVariantNonPopulated on SpaceTypeVariantNotPopulated {
        _id
        active
        createdAt
        name
        price
        priority
        slug
        spaces
        updatedAt
        translations {
            ...TranslationFields
        }
        theme {
            ...FTheme
        }
        bookingConfigurations {
            ...FBookingConfiguration
        }
    }
    ${TranslationFieldsFragmentDoc}
    ${FThemeFragmentDoc}
    ${FBookingConfigurationFragmentDoc}
`;
export const TagFieldsFragmentDoc = gql`
    fragment TagFields on Tag {
        _id
        createdAt
        updatedAt
        name
        schemas
        translations {
            ...TranslationFields
        }
    }
    ${TranslationFieldsFragmentDoc}
`;
export const FWebProductVariationFragmentDoc = gql`
    fragment FWebProductVariation on WebProductVariation {
        _id
        name
        price
        translations {
            ...TranslationFields
        }
    }
    ${TranslationFieldsFragmentDoc}
`;
export const FWebProductWithVariationsFragmentDoc = gql`
    fragment FWebProductWithVariations on WebProductWithVariations {
        _id
        name
        price
        slug
        translations {
            ...TranslationFields
        }
        variations {
            ...FWebProductVariation
        }
    }
    ${TranslationFieldsFragmentDoc}
    ${FWebProductVariationFragmentDoc}
`;
export const BookingsDocument = gql`
    query Bookings($input: BookingSearchInput!) {
        bookings(input: $input) {
            ...FBooking
        }
    }
    ${FBookingFragmentDoc}
`;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingsQuery(
    baseOptions: Apollo.QueryHookOptions<Types.BookingsQuery, Types.BookingsQueryVariables> &
        ({ variables: Types.BookingsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.BookingsQuery, Types.BookingsQueryVariables>(
        BookingsDocument,
        options,
    );
}
export function useBookingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.BookingsQuery, Types.BookingsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.BookingsQuery, Types.BookingsQueryVariables>(
        BookingsDocument,
        options,
    );
}
export function useBookingsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.BookingsQuery,
        Types.BookingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.BookingsQuery, Types.BookingsQueryVariables>(
        BookingsDocument,
        options,
    );
}
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsSuspenseQueryHookResult = ReturnType<typeof useBookingsSuspenseQuery>;
export type BookingsQueryResult = Apollo.QueryResult<
    Types.BookingsQuery,
    Types.BookingsQueryVariables
>;
export const BookingDocument = gql`
    query Booking($id: ID!) {
        booking(id: $id) {
            ...FBooking
        }
    }
    ${FBookingFragmentDoc}
`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(
    baseOptions: Apollo.QueryHookOptions<Types.BookingQuery, Types.BookingQueryVariables> &
        ({ variables: Types.BookingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.BookingQuery, Types.BookingQueryVariables>(
        BookingDocument,
        options,
    );
}
export function useBookingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.BookingQuery, Types.BookingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.BookingQuery, Types.BookingQueryVariables>(
        BookingDocument,
        options,
    );
}
export function useBookingSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BookingQuery, Types.BookingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.BookingQuery, Types.BookingQueryVariables>(
        BookingDocument,
        options,
    );
}
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingSuspenseQueryHookResult = ReturnType<typeof useBookingSuspenseQuery>;
export type BookingQueryResult = Apollo.QueryResult<
    Types.BookingQuery,
    Types.BookingQueryVariables
>;
export const BookingAvailableDocument = gql`
    query BookingAvailable($input: BookingAvailableInput!) {
        bookingAvailable(input: $input) {
            ...FBookingAvailability
        }
    }
    ${FBookingAvailabilityFragmentDoc}
`;

/**
 * __useBookingAvailableQuery__
 *
 * To run a query within a React component, call `useBookingAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAvailableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingAvailableQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.BookingAvailableQuery,
        Types.BookingAvailableQueryVariables
    > &
        ({ variables: Types.BookingAvailableQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.BookingAvailableQuery, Types.BookingAvailableQueryVariables>(
        BookingAvailableDocument,
        options,
    );
}
export function useBookingAvailableLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.BookingAvailableQuery,
        Types.BookingAvailableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.BookingAvailableQuery, Types.BookingAvailableQueryVariables>(
        BookingAvailableDocument,
        options,
    );
}
export function useBookingAvailableSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.BookingAvailableQuery,
        Types.BookingAvailableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.BookingAvailableQuery,
        Types.BookingAvailableQueryVariables
    >(BookingAvailableDocument, options);
}
export type BookingAvailableQueryHookResult = ReturnType<typeof useBookingAvailableQuery>;
export type BookingAvailableLazyQueryHookResult = ReturnType<typeof useBookingAvailableLazyQuery>;
export type BookingAvailableSuspenseQueryHookResult = ReturnType<
    typeof useBookingAvailableSuspenseQuery
>;
export type BookingAvailableQueryResult = Apollo.QueryResult<
    Types.BookingAvailableQuery,
    Types.BookingAvailableQueryVariables
>;
export const BookingChoicesDocument = gql`
    query BookingChoices($input: BookingChoiceInput!) {
        bookingChoices(input: $input) {
            ...FBookingChoice
        }
    }
    ${FBookingChoiceFragmentDoc}
`;

/**
 * __useBookingChoicesQuery__
 *
 * To run a query within a React component, call `useBookingChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingChoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingChoicesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingChoicesQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.BookingChoicesQuery,
        Types.BookingChoicesQueryVariables
    > &
        ({ variables: Types.BookingChoicesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.BookingChoicesQuery, Types.BookingChoicesQueryVariables>(
        BookingChoicesDocument,
        options,
    );
}
export function useBookingChoicesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.BookingChoicesQuery,
        Types.BookingChoicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.BookingChoicesQuery, Types.BookingChoicesQueryVariables>(
        BookingChoicesDocument,
        options,
    );
}
export function useBookingChoicesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.BookingChoicesQuery,
        Types.BookingChoicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.BookingChoicesQuery, Types.BookingChoicesQueryVariables>(
        BookingChoicesDocument,
        options,
    );
}
export type BookingChoicesQueryHookResult = ReturnType<typeof useBookingChoicesQuery>;
export type BookingChoicesLazyQueryHookResult = ReturnType<typeof useBookingChoicesLazyQuery>;
export type BookingChoicesSuspenseQueryHookResult = ReturnType<
    typeof useBookingChoicesSuspenseQuery
>;
export type BookingChoicesQueryResult = Apollo.QueryResult<
    Types.BookingChoicesQuery,
    Types.BookingChoicesQueryVariables
>;
export const BookingCreateDocument = gql`
    mutation BookingCreate($input: BookingCreate!) {
        bookingCreate(input: $input) {
            ...FBooking
        }
    }
    ${FBookingFragmentDoc}
`;
export type BookingCreateMutationFn = Apollo.MutationFunction<
    Types.BookingCreateMutation,
    Types.BookingCreateMutationVariables
>;

/**
 * __useBookingCreateMutation__
 *
 * To run a mutation, you first call `useBookingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingCreateMutation, { data, loading, error }] = useBookingCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.BookingCreateMutation,
        Types.BookingCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.BookingCreateMutation, Types.BookingCreateMutationVariables>(
        BookingCreateDocument,
        options,
    );
}
export type BookingCreateMutationHookResult = ReturnType<typeof useBookingCreateMutation>;
export type BookingCreateMutationResult = Apollo.MutationResult<Types.BookingCreateMutation>;
export type BookingCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.BookingCreateMutation,
    Types.BookingCreateMutationVariables
>;
export const BookingUpdateDocument = gql`
    mutation BookingUpdate($input: BookingUpdate!) {
        bookingUpdate(input: $input) {
            ...FBooking
        }
    }
    ${FBookingFragmentDoc}
`;
export type BookingUpdateMutationFn = Apollo.MutationFunction<
    Types.BookingUpdateMutation,
    Types.BookingUpdateMutationVariables
>;

/**
 * __useBookingUpdateMutation__
 *
 * To run a mutation, you first call `useBookingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingUpdateMutation, { data, loading, error }] = useBookingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.BookingUpdateMutation,
        Types.BookingUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.BookingUpdateMutation, Types.BookingUpdateMutationVariables>(
        BookingUpdateDocument,
        options,
    );
}
export type BookingUpdateMutationHookResult = ReturnType<typeof useBookingUpdateMutation>;
export type BookingUpdateMutationResult = Apollo.MutationResult<Types.BookingUpdateMutation>;
export type BookingUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.BookingUpdateMutation,
    Types.BookingUpdateMutationVariables
>;
export const ConsumablesDocument = gql`
    query Consumables {
        consumables {
            ...ConsumableFields
        }
    }
    ${ConsumableFieldsFragmentDoc}
`;

/**
 * __useConsumablesQuery__
 *
 * To run a query within a React component, call `useConsumablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsumablesQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.ConsumablesQuery, Types.ConsumablesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ConsumablesQuery, Types.ConsumablesQueryVariables>(
        ConsumablesDocument,
        options,
    );
}
export function useConsumablesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ConsumablesQuery,
        Types.ConsumablesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ConsumablesQuery, Types.ConsumablesQueryVariables>(
        ConsumablesDocument,
        options,
    );
}
export function useConsumablesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ConsumablesQuery,
        Types.ConsumablesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.ConsumablesQuery, Types.ConsumablesQueryVariables>(
        ConsumablesDocument,
        options,
    );
}
export type ConsumablesQueryHookResult = ReturnType<typeof useConsumablesQuery>;
export type ConsumablesLazyQueryHookResult = ReturnType<typeof useConsumablesLazyQuery>;
export type ConsumablesSuspenseQueryHookResult = ReturnType<typeof useConsumablesSuspenseQuery>;
export type ConsumablesQueryResult = Apollo.QueryResult<
    Types.ConsumablesQuery,
    Types.ConsumablesQueryVariables
>;
export const ConsumableDocument = gql`
    query Consumable($id: String!) {
        consumable(id: $id) {
            ...ConsumableFields
        }
    }
    ${ConsumableFieldsFragmentDoc}
`;

/**
 * __useConsumableQuery__
 *
 * To run a query within a React component, call `useConsumableQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsumableQuery(
    baseOptions: Apollo.QueryHookOptions<Types.ConsumableQuery, Types.ConsumableQueryVariables> &
        ({ variables: Types.ConsumableQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ConsumableQuery, Types.ConsumableQueryVariables>(
        ConsumableDocument,
        options,
    );
}
export function useConsumableLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ConsumableQuery,
        Types.ConsumableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ConsumableQuery, Types.ConsumableQueryVariables>(
        ConsumableDocument,
        options,
    );
}
export function useConsumableSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ConsumableQuery,
        Types.ConsumableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.ConsumableQuery, Types.ConsumableQueryVariables>(
        ConsumableDocument,
        options,
    );
}
export type ConsumableQueryHookResult = ReturnType<typeof useConsumableQuery>;
export type ConsumableLazyQueryHookResult = ReturnType<typeof useConsumableLazyQuery>;
export type ConsumableSuspenseQueryHookResult = ReturnType<typeof useConsumableSuspenseQuery>;
export type ConsumableQueryResult = Apollo.QueryResult<
    Types.ConsumableQuery,
    Types.ConsumableQueryVariables
>;
export const ConsumableCreateDocument = gql`
    mutation ConsumableCreate($input: ConsumableCreate!) {
        consumableCreate(input: $input) {
            ...ConsumableFields
        }
    }
    ${ConsumableFieldsFragmentDoc}
`;
export type ConsumableCreateMutationFn = Apollo.MutationFunction<
    Types.ConsumableCreateMutation,
    Types.ConsumableCreateMutationVariables
>;

/**
 * __useConsumableCreateMutation__
 *
 * To run a mutation, you first call `useConsumableCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumableCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumableCreateMutation, { data, loading, error }] = useConsumableCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumableCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ConsumableCreateMutation,
        Types.ConsumableCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ConsumableCreateMutation,
        Types.ConsumableCreateMutationVariables
    >(ConsumableCreateDocument, options);
}
export type ConsumableCreateMutationHookResult = ReturnType<typeof useConsumableCreateMutation>;
export type ConsumableCreateMutationResult = Apollo.MutationResult<Types.ConsumableCreateMutation>;
export type ConsumableCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.ConsumableCreateMutation,
    Types.ConsumableCreateMutationVariables
>;
export const ConsumableUpdateDocument = gql`
    mutation ConsumableUpdate($input: ConsumableUpdate!) {
        consumableUpdate(input: $input) {
            ...ConsumableFields
        }
    }
    ${ConsumableFieldsFragmentDoc}
`;
export type ConsumableUpdateMutationFn = Apollo.MutationFunction<
    Types.ConsumableUpdateMutation,
    Types.ConsumableUpdateMutationVariables
>;

/**
 * __useConsumableUpdateMutation__
 *
 * To run a mutation, you first call `useConsumableUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumableUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumableUpdateMutation, { data, loading, error }] = useConsumableUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumableUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ConsumableUpdateMutation,
        Types.ConsumableUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ConsumableUpdateMutation,
        Types.ConsumableUpdateMutationVariables
    >(ConsumableUpdateDocument, options);
}
export type ConsumableUpdateMutationHookResult = ReturnType<typeof useConsumableUpdateMutation>;
export type ConsumableUpdateMutationResult = Apollo.MutationResult<Types.ConsumableUpdateMutation>;
export type ConsumableUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.ConsumableUpdateMutation,
    Types.ConsumableUpdateMutationVariables
>;
export const IngredientsDocument = gql`
    query Ingredients {
        ingredients {
            ...IngredientFields
        }
    }
    ${IngredientFieldsFragmentDoc}
`;

/**
 * __useIngredientsQuery__
 *
 * To run a query within a React component, call `useIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIngredientsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.IngredientsQuery, Types.IngredientsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.IngredientsQuery, Types.IngredientsQueryVariables>(
        IngredientsDocument,
        options,
    );
}
export function useIngredientsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.IngredientsQuery,
        Types.IngredientsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.IngredientsQuery, Types.IngredientsQueryVariables>(
        IngredientsDocument,
        options,
    );
}
export function useIngredientsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.IngredientsQuery,
        Types.IngredientsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.IngredientsQuery, Types.IngredientsQueryVariables>(
        IngredientsDocument,
        options,
    );
}
export type IngredientsQueryHookResult = ReturnType<typeof useIngredientsQuery>;
export type IngredientsLazyQueryHookResult = ReturnType<typeof useIngredientsLazyQuery>;
export type IngredientsSuspenseQueryHookResult = ReturnType<typeof useIngredientsSuspenseQuery>;
export type IngredientsQueryResult = Apollo.QueryResult<
    Types.IngredientsQuery,
    Types.IngredientsQueryVariables
>;
export const IngredientDocument = gql`
    query Ingredient($id: String!) {
        ingredient(id: $id) {
            ...IngredientFields
        }
    }
    ${IngredientFieldsFragmentDoc}
`;

/**
 * __useIngredientQuery__
 *
 * To run a query within a React component, call `useIngredientQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIngredientQuery(
    baseOptions: Apollo.QueryHookOptions<Types.IngredientQuery, Types.IngredientQueryVariables> &
        ({ variables: Types.IngredientQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.IngredientQuery, Types.IngredientQueryVariables>(
        IngredientDocument,
        options,
    );
}
export function useIngredientLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.IngredientQuery,
        Types.IngredientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.IngredientQuery, Types.IngredientQueryVariables>(
        IngredientDocument,
        options,
    );
}
export function useIngredientSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.IngredientQuery,
        Types.IngredientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.IngredientQuery, Types.IngredientQueryVariables>(
        IngredientDocument,
        options,
    );
}
export type IngredientQueryHookResult = ReturnType<typeof useIngredientQuery>;
export type IngredientLazyQueryHookResult = ReturnType<typeof useIngredientLazyQuery>;
export type IngredientSuspenseQueryHookResult = ReturnType<typeof useIngredientSuspenseQuery>;
export type IngredientQueryResult = Apollo.QueryResult<
    Types.IngredientQuery,
    Types.IngredientQueryVariables
>;
export const IngredientCreateDocument = gql`
    mutation IngredientCreate($input: IngredientCreate!) {
        ingredientCreate(input: $input) {
            ...IngredientFields
        }
    }
    ${IngredientFieldsFragmentDoc}
`;
export type IngredientCreateMutationFn = Apollo.MutationFunction<
    Types.IngredientCreateMutation,
    Types.IngredientCreateMutationVariables
>;

/**
 * __useIngredientCreateMutation__
 *
 * To run a mutation, you first call `useIngredientCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIngredientCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ingredientCreateMutation, { data, loading, error }] = useIngredientCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIngredientCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.IngredientCreateMutation,
        Types.IngredientCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.IngredientCreateMutation,
        Types.IngredientCreateMutationVariables
    >(IngredientCreateDocument, options);
}
export type IngredientCreateMutationHookResult = ReturnType<typeof useIngredientCreateMutation>;
export type IngredientCreateMutationResult = Apollo.MutationResult<Types.IngredientCreateMutation>;
export type IngredientCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.IngredientCreateMutation,
    Types.IngredientCreateMutationVariables
>;
export const IngredientUpdateDocument = gql`
    mutation IngredientUpdate($input: IngredientUpdate!) {
        ingredientUpdate(input: $input) {
            ...IngredientFields
        }
    }
    ${IngredientFieldsFragmentDoc}
`;
export type IngredientUpdateMutationFn = Apollo.MutationFunction<
    Types.IngredientUpdateMutation,
    Types.IngredientUpdateMutationVariables
>;

/**
 * __useIngredientUpdateMutation__
 *
 * To run a mutation, you first call `useIngredientUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIngredientUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ingredientUpdateMutation, { data, loading, error }] = useIngredientUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIngredientUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.IngredientUpdateMutation,
        Types.IngredientUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.IngredientUpdateMutation,
        Types.IngredientUpdateMutationVariables
    >(IngredientUpdateDocument, options);
}
export type IngredientUpdateMutationHookResult = ReturnType<typeof useIngredientUpdateMutation>;
export type IngredientUpdateMutationResult = Apollo.MutationResult<Types.IngredientUpdateMutation>;
export type IngredientUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.IngredientUpdateMutation,
    Types.IngredientUpdateMutationVariables
>;
export const InventoryDocument = gql`
    query Inventory {
        inventory {
            ...InventoryFields
        }
    }
    ${InventoryFieldsFragmentDoc}
`;

/**
 * __useInventoryQuery__
 *
 * To run a query within a React component, call `useInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useInventoryQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.InventoryQuery, Types.InventoryQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.InventoryQuery, Types.InventoryQueryVariables>(
        InventoryDocument,
        options,
    );
}
export function useInventoryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.InventoryQuery, Types.InventoryQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.InventoryQuery, Types.InventoryQueryVariables>(
        InventoryDocument,
        options,
    );
}
export function useInventorySuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.InventoryQuery,
        Types.InventoryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.InventoryQuery, Types.InventoryQueryVariables>(
        InventoryDocument,
        options,
    );
}
export type InventoryQueryHookResult = ReturnType<typeof useInventoryQuery>;
export type InventoryLazyQueryHookResult = ReturnType<typeof useInventoryLazyQuery>;
export type InventorySuspenseQueryHookResult = ReturnType<typeof useInventorySuspenseQuery>;
export type InventoryQueryResult = Apollo.QueryResult<
    Types.InventoryQuery,
    Types.InventoryQueryVariables
>;
export const InventoryByItemDocument = gql`
    query InventoryByItem($itemId: ID!) {
        inventoryByItem(itemId: $itemId) {
            ...InventoryFields
        }
    }
    ${InventoryFieldsFragmentDoc}
`;

/**
 * __useInventoryByItemQuery__
 *
 * To run a query within a React component, call `useInventoryByItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryByItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryByItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useInventoryByItemQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.InventoryByItemQuery,
        Types.InventoryByItemQueryVariables
    > &
        ({ variables: Types.InventoryByItemQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.InventoryByItemQuery, Types.InventoryByItemQueryVariables>(
        InventoryByItemDocument,
        options,
    );
}
export function useInventoryByItemLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.InventoryByItemQuery,
        Types.InventoryByItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.InventoryByItemQuery, Types.InventoryByItemQueryVariables>(
        InventoryByItemDocument,
        options,
    );
}
export function useInventoryByItemSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.InventoryByItemQuery,
        Types.InventoryByItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.InventoryByItemQuery, Types.InventoryByItemQueryVariables>(
        InventoryByItemDocument,
        options,
    );
}
export type InventoryByItemQueryHookResult = ReturnType<typeof useInventoryByItemQuery>;
export type InventoryByItemLazyQueryHookResult = ReturnType<typeof useInventoryByItemLazyQuery>;
export type InventoryByItemSuspenseQueryHookResult = ReturnType<
    typeof useInventoryByItemSuspenseQuery
>;
export type InventoryByItemQueryResult = Apollo.QueryResult<
    Types.InventoryByItemQuery,
    Types.InventoryByItemQueryVariables
>;
export const InventoryRecordDocument = gql`
    query InventoryRecord($id: ID!) {
        inventoryRecord(id: $id) {
            ...InventoryFields
        }
    }
    ${InventoryFieldsFragmentDoc}
`;

/**
 * __useInventoryRecordQuery__
 *
 * To run a query within a React component, call `useInventoryRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInventoryRecordQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.InventoryRecordQuery,
        Types.InventoryRecordQueryVariables
    > &
        ({ variables: Types.InventoryRecordQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.InventoryRecordQuery, Types.InventoryRecordQueryVariables>(
        InventoryRecordDocument,
        options,
    );
}
export function useInventoryRecordLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.InventoryRecordQuery,
        Types.InventoryRecordQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.InventoryRecordQuery, Types.InventoryRecordQueryVariables>(
        InventoryRecordDocument,
        options,
    );
}
export function useInventoryRecordSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.InventoryRecordQuery,
        Types.InventoryRecordQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.InventoryRecordQuery, Types.InventoryRecordQueryVariables>(
        InventoryRecordDocument,
        options,
    );
}
export type InventoryRecordQueryHookResult = ReturnType<typeof useInventoryRecordQuery>;
export type InventoryRecordLazyQueryHookResult = ReturnType<typeof useInventoryRecordLazyQuery>;
export type InventoryRecordSuspenseQueryHookResult = ReturnType<
    typeof useInventoryRecordSuspenseQuery
>;
export type InventoryRecordQueryResult = Apollo.QueryResult<
    Types.InventoryRecordQuery,
    Types.InventoryRecordQueryVariables
>;
export const InventoryItemsDocument = gql`
    query InventoryItems($type: EInventoryItemType) {
        inventoryItems(type: $type) {
            ...InventoryItemFields
        }
    }
    ${InventoryItemFieldsFragmentDoc}
`;

/**
 * __useInventoryItemsQuery__
 *
 * To run a query within a React component, call `useInventoryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useInventoryItemsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.InventoryItemsQuery,
        Types.InventoryItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.InventoryItemsQuery, Types.InventoryItemsQueryVariables>(
        InventoryItemsDocument,
        options,
    );
}
export function useInventoryItemsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.InventoryItemsQuery,
        Types.InventoryItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.InventoryItemsQuery, Types.InventoryItemsQueryVariables>(
        InventoryItemsDocument,
        options,
    );
}
export function useInventoryItemsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.InventoryItemsQuery,
        Types.InventoryItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.InventoryItemsQuery, Types.InventoryItemsQueryVariables>(
        InventoryItemsDocument,
        options,
    );
}
export type InventoryItemsQueryHookResult = ReturnType<typeof useInventoryItemsQuery>;
export type InventoryItemsLazyQueryHookResult = ReturnType<typeof useInventoryItemsLazyQuery>;
export type InventoryItemsSuspenseQueryHookResult = ReturnType<
    typeof useInventoryItemsSuspenseQuery
>;
export type InventoryItemsQueryResult = Apollo.QueryResult<
    Types.InventoryItemsQuery,
    Types.InventoryItemsQueryVariables
>;
export const InventoryRecordCreateDocument = gql`
    mutation InventoryRecordCreate($input: InventoryRecordCreate!) {
        inventoryRecordCreate(input: $input) {
            ...InventoryFields
        }
    }
    ${InventoryFieldsFragmentDoc}
`;
export type InventoryRecordCreateMutationFn = Apollo.MutationFunction<
    Types.InventoryRecordCreateMutation,
    Types.InventoryRecordCreateMutationVariables
>;

/**
 * __useInventoryRecordCreateMutation__
 *
 * To run a mutation, you first call `useInventoryRecordCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryRecordCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryRecordCreateMutation, { data, loading, error }] = useInventoryRecordCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInventoryRecordCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.InventoryRecordCreateMutation,
        Types.InventoryRecordCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.InventoryRecordCreateMutation,
        Types.InventoryRecordCreateMutationVariables
    >(InventoryRecordCreateDocument, options);
}
export type InventoryRecordCreateMutationHookResult = ReturnType<
    typeof useInventoryRecordCreateMutation
>;
export type InventoryRecordCreateMutationResult =
    Apollo.MutationResult<Types.InventoryRecordCreateMutation>;
export type InventoryRecordCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.InventoryRecordCreateMutation,
    Types.InventoryRecordCreateMutationVariables
>;
export const InventoryRecordUpdateDocument = gql`
    mutation InventoryRecordUpdate($input: InventoryRecordUpdate!) {
        inventoryRecordUpdate(input: $input) {
            ...InventoryFields
        }
    }
    ${InventoryFieldsFragmentDoc}
`;
export type InventoryRecordUpdateMutationFn = Apollo.MutationFunction<
    Types.InventoryRecordUpdateMutation,
    Types.InventoryRecordUpdateMutationVariables
>;

/**
 * __useInventoryRecordUpdateMutation__
 *
 * To run a mutation, you first call `useInventoryRecordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryRecordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryRecordUpdateMutation, { data, loading, error }] = useInventoryRecordUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInventoryRecordUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.InventoryRecordUpdateMutation,
        Types.InventoryRecordUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.InventoryRecordUpdateMutation,
        Types.InventoryRecordUpdateMutationVariables
    >(InventoryRecordUpdateDocument, options);
}
export type InventoryRecordUpdateMutationHookResult = ReturnType<
    typeof useInventoryRecordUpdateMutation
>;
export type InventoryRecordUpdateMutationResult =
    Apollo.MutationResult<Types.InventoryRecordUpdateMutation>;
export type InventoryRecordUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.InventoryRecordUpdateMutation,
    Types.InventoryRecordUpdateMutationVariables
>;
export const InventoryOrdersDocument = gql`
    query InventoryOrders {
        inventoryOrders {
            ...InventoryOrderSummaryFields
        }
    }
    ${InventoryOrderSummaryFieldsFragmentDoc}
`;

/**
 * __useInventoryOrdersQuery__
 *
 * To run a query within a React component, call `useInventoryOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useInventoryOrdersQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.InventoryOrdersQuery,
        Types.InventoryOrdersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.InventoryOrdersQuery, Types.InventoryOrdersQueryVariables>(
        InventoryOrdersDocument,
        options,
    );
}
export function useInventoryOrdersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.InventoryOrdersQuery,
        Types.InventoryOrdersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.InventoryOrdersQuery, Types.InventoryOrdersQueryVariables>(
        InventoryOrdersDocument,
        options,
    );
}
export function useInventoryOrdersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.InventoryOrdersQuery,
        Types.InventoryOrdersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.InventoryOrdersQuery, Types.InventoryOrdersQueryVariables>(
        InventoryOrdersDocument,
        options,
    );
}
export type InventoryOrdersQueryHookResult = ReturnType<typeof useInventoryOrdersQuery>;
export type InventoryOrdersLazyQueryHookResult = ReturnType<typeof useInventoryOrdersLazyQuery>;
export type InventoryOrdersSuspenseQueryHookResult = ReturnType<
    typeof useInventoryOrdersSuspenseQuery
>;
export type InventoryOrdersQueryResult = Apollo.QueryResult<
    Types.InventoryOrdersQuery,
    Types.InventoryOrdersQueryVariables
>;
export const InventoryOrderDocument = gql`
    query InventoryOrder($id: ID!) {
        inventoryOrder: inventoryOrder(id: $id) {
            ...InventoryOrderFields
        }
    }
    ${InventoryOrderFieldsFragmentDoc}
`;

/**
 * __useInventoryOrderQuery__
 *
 * To run a query within a React component, call `useInventoryOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInventoryOrderQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.InventoryOrderQuery,
        Types.InventoryOrderQueryVariables
    > &
        ({ variables: Types.InventoryOrderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.InventoryOrderQuery, Types.InventoryOrderQueryVariables>(
        InventoryOrderDocument,
        options,
    );
}
export function useInventoryOrderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.InventoryOrderQuery,
        Types.InventoryOrderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.InventoryOrderQuery, Types.InventoryOrderQueryVariables>(
        InventoryOrderDocument,
        options,
    );
}
export function useInventoryOrderSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.InventoryOrderQuery,
        Types.InventoryOrderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.InventoryOrderQuery, Types.InventoryOrderQueryVariables>(
        InventoryOrderDocument,
        options,
    );
}
export type InventoryOrderQueryHookResult = ReturnType<typeof useInventoryOrderQuery>;
export type InventoryOrderLazyQueryHookResult = ReturnType<typeof useInventoryOrderLazyQuery>;
export type InventoryOrderSuspenseQueryHookResult = ReturnType<
    typeof useInventoryOrderSuspenseQuery
>;
export type InventoryOrderQueryResult = Apollo.QueryResult<
    Types.InventoryOrderQuery,
    Types.InventoryOrderQueryVariables
>;
export const InventoryOrdersByItemDocument = gql`
    query InventoryOrdersByItem($itemId: ID!) {
        inventoryOrders: inventoryOrdersByItem(itemId: $itemId) {
            ...InventoryOrderFields
        }
    }
    ${InventoryOrderFieldsFragmentDoc}
`;

/**
 * __useInventoryOrdersByItemQuery__
 *
 * To run a query within a React component, call `useInventoryOrdersByItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryOrdersByItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryOrdersByItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useInventoryOrdersByItemQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.InventoryOrdersByItemQuery,
        Types.InventoryOrdersByItemQueryVariables
    > &
        (
            | { variables: Types.InventoryOrdersByItemQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.InventoryOrdersByItemQuery,
        Types.InventoryOrdersByItemQueryVariables
    >(InventoryOrdersByItemDocument, options);
}
export function useInventoryOrdersByItemLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.InventoryOrdersByItemQuery,
        Types.InventoryOrdersByItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.InventoryOrdersByItemQuery,
        Types.InventoryOrdersByItemQueryVariables
    >(InventoryOrdersByItemDocument, options);
}
export function useInventoryOrdersByItemSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.InventoryOrdersByItemQuery,
        Types.InventoryOrdersByItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.InventoryOrdersByItemQuery,
        Types.InventoryOrdersByItemQueryVariables
    >(InventoryOrdersByItemDocument, options);
}
export type InventoryOrdersByItemQueryHookResult = ReturnType<typeof useInventoryOrdersByItemQuery>;
export type InventoryOrdersByItemLazyQueryHookResult = ReturnType<
    typeof useInventoryOrdersByItemLazyQuery
>;
export type InventoryOrdersByItemSuspenseQueryHookResult = ReturnType<
    typeof useInventoryOrdersByItemSuspenseQuery
>;
export type InventoryOrdersByItemQueryResult = Apollo.QueryResult<
    Types.InventoryOrdersByItemQuery,
    Types.InventoryOrdersByItemQueryVariables
>;
export const InventoryOrderCreateDocument = gql`
    mutation InventoryOrderCreate($input: InventoryOrderCreate!) {
        inventoryOrderCreate(input: $input) {
            ...InventoryOrderFields
        }
    }
    ${InventoryOrderFieldsFragmentDoc}
`;
export type InventoryOrderCreateMutationFn = Apollo.MutationFunction<
    Types.InventoryOrderCreateMutation,
    Types.InventoryOrderCreateMutationVariables
>;

/**
 * __useInventoryOrderCreateMutation__
 *
 * To run a mutation, you first call `useInventoryOrderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryOrderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryOrderCreateMutation, { data, loading, error }] = useInventoryOrderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInventoryOrderCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.InventoryOrderCreateMutation,
        Types.InventoryOrderCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.InventoryOrderCreateMutation,
        Types.InventoryOrderCreateMutationVariables
    >(InventoryOrderCreateDocument, options);
}
export type InventoryOrderCreateMutationHookResult = ReturnType<
    typeof useInventoryOrderCreateMutation
>;
export type InventoryOrderCreateMutationResult =
    Apollo.MutationResult<Types.InventoryOrderCreateMutation>;
export type InventoryOrderCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.InventoryOrderCreateMutation,
    Types.InventoryOrderCreateMutationVariables
>;
export const InventoryOrderUpdateDocument = gql`
    mutation InventoryOrderUpdate($input: InventoryOrderUpdate!) {
        inventoryOrderUpdate(input: $input) {
            ...InventoryOrderFields
        }
    }
    ${InventoryOrderFieldsFragmentDoc}
`;
export type InventoryOrderUpdateMutationFn = Apollo.MutationFunction<
    Types.InventoryOrderUpdateMutation,
    Types.InventoryOrderUpdateMutationVariables
>;

/**
 * __useInventoryOrderUpdateMutation__
 *
 * To run a mutation, you first call `useInventoryOrderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryOrderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryOrderUpdateMutation, { data, loading, error }] = useInventoryOrderUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInventoryOrderUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.InventoryOrderUpdateMutation,
        Types.InventoryOrderUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.InventoryOrderUpdateMutation,
        Types.InventoryOrderUpdateMutationVariables
    >(InventoryOrderUpdateDocument, options);
}
export type InventoryOrderUpdateMutationHookResult = ReturnType<
    typeof useInventoryOrderUpdateMutation
>;
export type InventoryOrderUpdateMutationResult =
    Apollo.MutationResult<Types.InventoryOrderUpdateMutation>;
export type InventoryOrderUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.InventoryOrderUpdateMutation,
    Types.InventoryOrderUpdateMutationVariables
>;
export const MediaDocument = gql`
    query Media {
        media {
            ...FMedia
        }
    }
    ${FMediaFragmentDoc}
`;

/**
 * __useMediaQuery__
 *
 * To run a query within a React component, call `useMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaQuery({
 *   variables: {
 *   },
 * });
 */
export function useMediaQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.MediaQuery, Types.MediaQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.MediaQuery, Types.MediaQueryVariables>(MediaDocument, options);
}
export function useMediaLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.MediaQuery, Types.MediaQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.MediaQuery, Types.MediaQueryVariables>(MediaDocument, options);
}
export function useMediaSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MediaQuery, Types.MediaQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.MediaQuery, Types.MediaQueryVariables>(
        MediaDocument,
        options,
    );
}
export type MediaQueryHookResult = ReturnType<typeof useMediaQuery>;
export type MediaLazyQueryHookResult = ReturnType<typeof useMediaLazyQuery>;
export type MediaSuspenseQueryHookResult = ReturnType<typeof useMediaSuspenseQuery>;
export type MediaQueryResult = Apollo.QueryResult<Types.MediaQuery, Types.MediaQueryVariables>;
export const MediaRecordDocument = gql`
    query MediaRecord($id: ID!) {
        mediaRecord(id: $id) {
            ...FMedia
        }
    }
    ${FMediaFragmentDoc}
`;

/**
 * __useMediaRecordQuery__
 *
 * To run a query within a React component, call `useMediaRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaRecordQuery(
    baseOptions: Apollo.QueryHookOptions<Types.MediaRecordQuery, Types.MediaRecordQueryVariables> &
        ({ variables: Types.MediaRecordQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.MediaRecordQuery, Types.MediaRecordQueryVariables>(
        MediaRecordDocument,
        options,
    );
}
export function useMediaRecordLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.MediaRecordQuery,
        Types.MediaRecordQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.MediaRecordQuery, Types.MediaRecordQueryVariables>(
        MediaRecordDocument,
        options,
    );
}
export function useMediaRecordSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.MediaRecordQuery,
        Types.MediaRecordQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.MediaRecordQuery, Types.MediaRecordQueryVariables>(
        MediaRecordDocument,
        options,
    );
}
export type MediaRecordQueryHookResult = ReturnType<typeof useMediaRecordQuery>;
export type MediaRecordLazyQueryHookResult = ReturnType<typeof useMediaRecordLazyQuery>;
export type MediaRecordSuspenseQueryHookResult = ReturnType<typeof useMediaRecordSuspenseQuery>;
export type MediaRecordQueryResult = Apollo.QueryResult<
    Types.MediaRecordQuery,
    Types.MediaRecordQueryVariables
>;
export const MediaUploadedDocument = gql`
    subscription mediaUploaded {
        media: mediaUploaded {
            ...FMedia
        }
    }
    ${FMediaFragmentDoc}
`;

/**
 * __useMediaUploadedSubscription__
 *
 * To run a query within a React component, call `useMediaUploadedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMediaUploadedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaUploadedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMediaUploadedSubscription(
    baseOptions?: Apollo.SubscriptionHookOptions<
        Types.MediaUploadedSubscription,
        Types.MediaUploadedSubscriptionVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        Types.MediaUploadedSubscription,
        Types.MediaUploadedSubscriptionVariables
    >(MediaUploadedDocument, options);
}
export type MediaUploadedSubscriptionHookResult = ReturnType<typeof useMediaUploadedSubscription>;
export type MediaUploadedSubscriptionResult =
    Apollo.SubscriptionResult<Types.MediaUploadedSubscription>;
export const OrdersDocument = gql`
    query Orders {
        orders {
            ...OrderFields
        }
    }
    ${OrderFieldsFragmentDoc}
`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.OrdersQuery, Types.OrdersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.OrdersQuery, Types.OrdersQueryVariables>(OrdersDocument, options);
}
export function useOrdersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.OrdersQuery, Types.OrdersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.OrdersQuery, Types.OrdersQueryVariables>(
        OrdersDocument,
        options,
    );
}
export function useOrdersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OrdersQuery, Types.OrdersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.OrdersQuery, Types.OrdersQueryVariables>(
        OrdersDocument,
        options,
    );
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersSuspenseQueryHookResult = ReturnType<typeof useOrdersSuspenseQuery>;
export type OrdersQueryResult = Apollo.QueryResult<Types.OrdersQuery, Types.OrdersQueryVariables>;
export const OrderDocument = gql`
    query Order($id: ID!) {
        order(id: $id) {
            ...OrderFields
        }
    }
    ${OrderFieldsFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(
    baseOptions: Apollo.QueryHookOptions<Types.OrderQuery, Types.OrderQueryVariables> &
        ({ variables: Types.OrderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.OrderQuery, Types.OrderQueryVariables>(OrderDocument, options);
}
export function useOrderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.OrderQuery, Types.OrderQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.OrderQuery, Types.OrderQueryVariables>(OrderDocument, options);
}
export function useOrderSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OrderQuery, Types.OrderQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.OrderQuery, Types.OrderQueryVariables>(
        OrderDocument,
        options,
    );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderSuspenseQueryHookResult = ReturnType<typeof useOrderSuspenseQuery>;
export type OrderQueryResult = Apollo.QueryResult<Types.OrderQuery, Types.OrderQueryVariables>;
export const OrderCreateDocument = gql`
    mutation OrderCreate($input: OrderCreate!) {
        orderCreate(input: $input) {
            ...OrderFields
        }
    }
    ${OrderFieldsFragmentDoc}
`;
export type OrderCreateMutationFn = Apollo.MutationFunction<
    Types.OrderCreateMutation,
    Types.OrderCreateMutationVariables
>;

/**
 * __useOrderCreateMutation__
 *
 * To run a mutation, you first call `useOrderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCreateMutation, { data, loading, error }] = useOrderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.OrderCreateMutation,
        Types.OrderCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.OrderCreateMutation, Types.OrderCreateMutationVariables>(
        OrderCreateDocument,
        options,
    );
}
export type OrderCreateMutationHookResult = ReturnType<typeof useOrderCreateMutation>;
export type OrderCreateMutationResult = Apollo.MutationResult<Types.OrderCreateMutation>;
export type OrderCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.OrderCreateMutation,
    Types.OrderCreateMutationVariables
>;
export const OrderUpdateDocument = gql`
    mutation OrderUpdate($input: OrderUpdate!) {
        orderUpdate(input: $input) {
            ...OrderFields
        }
    }
    ${OrderFieldsFragmentDoc}
`;
export type OrderUpdateMutationFn = Apollo.MutationFunction<
    Types.OrderUpdateMutation,
    Types.OrderUpdateMutationVariables
>;

/**
 * __useOrderUpdateMutation__
 *
 * To run a mutation, you first call `useOrderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderUpdateMutation, { data, loading, error }] = useOrderUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.OrderUpdateMutation,
        Types.OrderUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.OrderUpdateMutation, Types.OrderUpdateMutationVariables>(
        OrderUpdateDocument,
        options,
    );
}
export type OrderUpdateMutationHookResult = ReturnType<typeof useOrderUpdateMutation>;
export type OrderUpdateMutationResult = Apollo.MutationResult<Types.OrderUpdateMutation>;
export type OrderUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.OrderUpdateMutation,
    Types.OrderUpdateMutationVariables
>;
export const PagesDocument = gql`
    query Pages {
        pages {
            ...PageFields
        }
    }
    ${PageFieldsFragmentDoc}
`;

/**
 * __usePagesQuery__
 *
 * To run a query within a React component, call `usePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePagesQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.PagesQuery, Types.PagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.PagesQuery, Types.PagesQueryVariables>(PagesDocument, options);
}
export function usePagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.PagesQuery, Types.PagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.PagesQuery, Types.PagesQueryVariables>(PagesDocument, options);
}
export function usePagesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PagesQuery, Types.PagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.PagesQuery, Types.PagesQueryVariables>(
        PagesDocument,
        options,
    );
}
export type PagesQueryHookResult = ReturnType<typeof usePagesQuery>;
export type PagesLazyQueryHookResult = ReturnType<typeof usePagesLazyQuery>;
export type PagesSuspenseQueryHookResult = ReturnType<typeof usePagesSuspenseQuery>;
export type PagesQueryResult = Apollo.QueryResult<Types.PagesQuery, Types.PagesQueryVariables>;
export const PageDocument = gql`
    query Page($id: ID!) {
        page(id: $id) {
            ...PageFields
        }
    }
    ${PageFieldsFragmentDoc}
`;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePageQuery(
    baseOptions: Apollo.QueryHookOptions<Types.PageQuery, Types.PageQueryVariables> &
        ({ variables: Types.PageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.PageQuery, Types.PageQueryVariables>(PageDocument, options);
}
export function usePageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.PageQuery, Types.PageQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.PageQuery, Types.PageQueryVariables>(PageDocument, options);
}
export function usePageSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PageQuery, Types.PageQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.PageQuery, Types.PageQueryVariables>(
        PageDocument,
        options,
    );
}
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageSuspenseQueryHookResult = ReturnType<typeof usePageSuspenseQuery>;
export type PageQueryResult = Apollo.QueryResult<Types.PageQuery, Types.PageQueryVariables>;
export const PageBySlugDocument = gql`
    query PageBySlug($slug: String!) {
        page: pageBySlug(slug: $slug) {
            ...PageFields
        }
    }
    ${PageFieldsFragmentDoc}
`;

/**
 * __usePageBySlugQuery__
 *
 * To run a query within a React component, call `usePageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePageBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<Types.PageBySlugQuery, Types.PageBySlugQueryVariables> &
        ({ variables: Types.PageBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.PageBySlugQuery, Types.PageBySlugQueryVariables>(
        PageBySlugDocument,
        options,
    );
}
export function usePageBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.PageBySlugQuery,
        Types.PageBySlugQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.PageBySlugQuery, Types.PageBySlugQueryVariables>(
        PageBySlugDocument,
        options,
    );
}
export function usePageBySlugSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.PageBySlugQuery,
        Types.PageBySlugQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.PageBySlugQuery, Types.PageBySlugQueryVariables>(
        PageBySlugDocument,
        options,
    );
}
export type PageBySlugQueryHookResult = ReturnType<typeof usePageBySlugQuery>;
export type PageBySlugLazyQueryHookResult = ReturnType<typeof usePageBySlugLazyQuery>;
export type PageBySlugSuspenseQueryHookResult = ReturnType<typeof usePageBySlugSuspenseQuery>;
export type PageBySlugQueryResult = Apollo.QueryResult<
    Types.PageBySlugQuery,
    Types.PageBySlugQueryVariables
>;
export const PageCreateDocument = gql`
    mutation PageCreate($input: PageCreate!) {
        pageCreate(input: $input) {
            ...PageFields
        }
    }
    ${PageFieldsFragmentDoc}
`;
export type PageCreateMutationFn = Apollo.MutationFunction<
    Types.PageCreateMutation,
    Types.PageCreateMutationVariables
>;

/**
 * __usePageCreateMutation__
 *
 * To run a mutation, you first call `usePageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pageCreateMutation, { data, loading, error }] = usePageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.PageCreateMutation,
        Types.PageCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.PageCreateMutation, Types.PageCreateMutationVariables>(
        PageCreateDocument,
        options,
    );
}
export type PageCreateMutationHookResult = ReturnType<typeof usePageCreateMutation>;
export type PageCreateMutationResult = Apollo.MutationResult<Types.PageCreateMutation>;
export type PageCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.PageCreateMutation,
    Types.PageCreateMutationVariables
>;
export const PageUpdateDocument = gql`
    mutation PageUpdate($input: PageUpdate!) {
        pageUpdate(input: $input) {
            ...PageFields
        }
    }
    ${PageFieldsFragmentDoc}
`;
export type PageUpdateMutationFn = Apollo.MutationFunction<
    Types.PageUpdateMutation,
    Types.PageUpdateMutationVariables
>;

/**
 * __usePageUpdateMutation__
 *
 * To run a mutation, you first call `usePageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pageUpdateMutation, { data, loading, error }] = usePageUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.PageUpdateMutation,
        Types.PageUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.PageUpdateMutation, Types.PageUpdateMutationVariables>(
        PageUpdateDocument,
        options,
    );
}
export type PageUpdateMutationHookResult = ReturnType<typeof usePageUpdateMutation>;
export type PageUpdateMutationResult = Apollo.MutationResult<Types.PageUpdateMutation>;
export type PageUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.PageUpdateMutation,
    Types.PageUpdateMutationVariables
>;
export const ProductsDocument = gql`
    query Products {
        products {
            ...ProductFields
        }
    }
    ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.ProductsQuery, Types.ProductsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(
        ProductsDocument,
        options,
    );
}
export function useProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsQuery, Types.ProductsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(
        ProductsDocument,
        options,
    );
}
export function useProductsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ProductsQuery,
        Types.ProductsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(
        ProductsDocument,
        options,
    );
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsSuspenseQueryHookResult = ReturnType<typeof useProductsSuspenseQuery>;
export type ProductsQueryResult = Apollo.QueryResult<
    Types.ProductsQuery,
    Types.ProductsQueryVariables
>;
export const ProductDocument = gql`
    query Product($id: ID!) {
        product(id: $id) {
            ...ProductFields
        }
        variations: productVariationsByProduct(productId: $id) {
            ...ProductVariationFields
        }
    }
    ${ProductFieldsFragmentDoc}
    ${ProductVariationFieldsFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(
    baseOptions: Apollo.QueryHookOptions<Types.ProductQuery, Types.ProductQueryVariables> &
        ({ variables: Types.ProductQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ProductQuery, Types.ProductQueryVariables>(
        ProductDocument,
        options,
    );
}
export function useProductLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductQuery, Types.ProductQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ProductQuery, Types.ProductQueryVariables>(
        ProductDocument,
        options,
    );
}
export function useProductSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductQuery, Types.ProductQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.ProductQuery, Types.ProductQueryVariables>(
        ProductDocument,
        options,
    );
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductSuspenseQueryHookResult = ReturnType<typeof useProductSuspenseQuery>;
export type ProductQueryResult = Apollo.QueryResult<
    Types.ProductQuery,
    Types.ProductQueryVariables
>;
export const ProductBySlugDocument = gql`
    query ProductBySlug($slug: String!) {
        product: productBySlug(slug: $slug) {
            ...ProductFields
        }
    }
    ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductBySlugQuery__
 *
 * To run a query within a React component, call `useProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProductBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.ProductBySlugQuery,
        Types.ProductBySlugQueryVariables
    > &
        ({ variables: Types.ProductBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ProductBySlugQuery, Types.ProductBySlugQueryVariables>(
        ProductBySlugDocument,
        options,
    );
}
export function useProductBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ProductBySlugQuery,
        Types.ProductBySlugQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ProductBySlugQuery, Types.ProductBySlugQueryVariables>(
        ProductBySlugDocument,
        options,
    );
}
export function useProductBySlugSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ProductBySlugQuery,
        Types.ProductBySlugQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.ProductBySlugQuery, Types.ProductBySlugQueryVariables>(
        ProductBySlugDocument,
        options,
    );
}
export type ProductBySlugQueryHookResult = ReturnType<typeof useProductBySlugQuery>;
export type ProductBySlugLazyQueryHookResult = ReturnType<typeof useProductBySlugLazyQuery>;
export type ProductBySlugSuspenseQueryHookResult = ReturnType<typeof useProductBySlugSuspenseQuery>;
export type ProductBySlugQueryResult = Apollo.QueryResult<
    Types.ProductBySlugQuery,
    Types.ProductBySlugQueryVariables
>;
export const ProductsByTagDocument = gql`
    query ProductsByTag($tagId: ID!) {
        productsByTag(tagId: $tagId) {
            ...ProductFields
        }
    }
    ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductsByTagQuery__
 *
 * To run a query within a React component, call `useProductsByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByTagQuery({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useProductsByTagQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.ProductsByTagQuery,
        Types.ProductsByTagQueryVariables
    > &
        ({ variables: Types.ProductsByTagQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ProductsByTagQuery, Types.ProductsByTagQueryVariables>(
        ProductsByTagDocument,
        options,
    );
}
export function useProductsByTagLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ProductsByTagQuery,
        Types.ProductsByTagQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ProductsByTagQuery, Types.ProductsByTagQueryVariables>(
        ProductsByTagDocument,
        options,
    );
}
export function useProductsByTagSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ProductsByTagQuery,
        Types.ProductsByTagQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.ProductsByTagQuery, Types.ProductsByTagQueryVariables>(
        ProductsByTagDocument,
        options,
    );
}
export type ProductsByTagQueryHookResult = ReturnType<typeof useProductsByTagQuery>;
export type ProductsByTagLazyQueryHookResult = ReturnType<typeof useProductsByTagLazyQuery>;
export type ProductsByTagSuspenseQueryHookResult = ReturnType<typeof useProductsByTagSuspenseQuery>;
export type ProductsByTagQueryResult = Apollo.QueryResult<
    Types.ProductsByTagQuery,
    Types.ProductsByTagQueryVariables
>;
export const ProductsByIngredientDocument = gql`
    query ProductsByIngredient($ingredientId: ID!) {
        products: productsByIngredient(ingredientId: $ingredientId) {
            ...ProductFields
        }
    }
    ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductsByIngredientQuery__
 *
 * To run a query within a React component, call `useProductsByIngredientQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByIngredientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByIngredientQuery({
 *   variables: {
 *      ingredientId: // value for 'ingredientId'
 *   },
 * });
 */
export function useProductsByIngredientQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.ProductsByIngredientQuery,
        Types.ProductsByIngredientQueryVariables
    > &
        (
            | { variables: Types.ProductsByIngredientQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.ProductsByIngredientQuery,
        Types.ProductsByIngredientQueryVariables
    >(ProductsByIngredientDocument, options);
}
export function useProductsByIngredientLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ProductsByIngredientQuery,
        Types.ProductsByIngredientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.ProductsByIngredientQuery,
        Types.ProductsByIngredientQueryVariables
    >(ProductsByIngredientDocument, options);
}
export function useProductsByIngredientSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ProductsByIngredientQuery,
        Types.ProductsByIngredientQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.ProductsByIngredientQuery,
        Types.ProductsByIngredientQueryVariables
    >(ProductsByIngredientDocument, options);
}
export type ProductsByIngredientQueryHookResult = ReturnType<typeof useProductsByIngredientQuery>;
export type ProductsByIngredientLazyQueryHookResult = ReturnType<
    typeof useProductsByIngredientLazyQuery
>;
export type ProductsByIngredientSuspenseQueryHookResult = ReturnType<
    typeof useProductsByIngredientSuspenseQuery
>;
export type ProductsByIngredientQueryResult = Apollo.QueryResult<
    Types.ProductsByIngredientQuery,
    Types.ProductsByIngredientQueryVariables
>;
export const ProductCreateDocument = gql`
    mutation ProductCreate($input: ProductCreate!) {
        product: productCreate(input: $input) {
            ...ProductFields
        }
    }
    ${ProductFieldsFragmentDoc}
`;
export type ProductCreateMutationFn = Apollo.MutationFunction<
    Types.ProductCreateMutation,
    Types.ProductCreateMutationVariables
>;

/**
 * __useProductCreateMutation__
 *
 * To run a mutation, you first call `useProductCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productCreateMutation, { data, loading, error }] = useProductCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ProductCreateMutation,
        Types.ProductCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.ProductCreateMutation, Types.ProductCreateMutationVariables>(
        ProductCreateDocument,
        options,
    );
}
export type ProductCreateMutationHookResult = ReturnType<typeof useProductCreateMutation>;
export type ProductCreateMutationResult = Apollo.MutationResult<Types.ProductCreateMutation>;
export type ProductCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.ProductCreateMutation,
    Types.ProductCreateMutationVariables
>;
export const ProductUpdateDocument = gql`
    mutation ProductUpdate(
        $productId: ID!
        $product: ProductUpdate!
        $variations: [ProductVariationCreateUpdate!]!
    ) {
        product: productUpdate(input: $product) {
            ...ProductFields
        }
        variations: productVariationsUpdateCreate(productId: $productId, inputs: $variations) {
            ...ProductVariationFields
        }
    }
    ${ProductFieldsFragmentDoc}
    ${ProductVariationFieldsFragmentDoc}
`;
export type ProductUpdateMutationFn = Apollo.MutationFunction<
    Types.ProductUpdateMutation,
    Types.ProductUpdateMutationVariables
>;

/**
 * __useProductUpdateMutation__
 *
 * To run a mutation, you first call `useProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productUpdateMutation, { data, loading, error }] = useProductUpdateMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      product: // value for 'product'
 *      variations: // value for 'variations'
 *   },
 * });
 */
export function useProductUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ProductUpdateMutation,
        Types.ProductUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.ProductUpdateMutation, Types.ProductUpdateMutationVariables>(
        ProductUpdateDocument,
        options,
    );
}
export type ProductUpdateMutationHookResult = ReturnType<typeof useProductUpdateMutation>;
export type ProductUpdateMutationResult = Apollo.MutationResult<Types.ProductUpdateMutation>;
export type ProductUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.ProductUpdateMutation,
    Types.ProductUpdateMutationVariables
>;
export const ProductVariationDocument = gql`
    query ProductVariation($id: ID!) {
        productVariation(id: $id) {
            ...ProductVariationWithFullProductFields
        }
    }
    ${ProductVariationWithFullProductFieldsFragmentDoc}
`;

/**
 * __useProductVariationQuery__
 *
 * To run a query within a React component, call `useProductVariationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductVariationQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.ProductVariationQuery,
        Types.ProductVariationQueryVariables
    > &
        ({ variables: Types.ProductVariationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ProductVariationQuery, Types.ProductVariationQueryVariables>(
        ProductVariationDocument,
        options,
    );
}
export function useProductVariationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ProductVariationQuery,
        Types.ProductVariationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ProductVariationQuery, Types.ProductVariationQueryVariables>(
        ProductVariationDocument,
        options,
    );
}
export function useProductVariationSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ProductVariationQuery,
        Types.ProductVariationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.ProductVariationQuery,
        Types.ProductVariationQueryVariables
    >(ProductVariationDocument, options);
}
export type ProductVariationQueryHookResult = ReturnType<typeof useProductVariationQuery>;
export type ProductVariationLazyQueryHookResult = ReturnType<typeof useProductVariationLazyQuery>;
export type ProductVariationSuspenseQueryHookResult = ReturnType<
    typeof useProductVariationSuspenseQuery
>;
export type ProductVariationQueryResult = Apollo.QueryResult<
    Types.ProductVariationQuery,
    Types.ProductVariationQueryVariables
>;
export const ProductVariationsDocument = gql`
    query ProductVariations {
        productVariations {
            ...ProductVariationFields
        }
    }
    ${ProductVariationFieldsFragmentDoc}
`;

/**
 * __useProductVariationsQuery__
 *
 * To run a query within a React component, call `useProductVariationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductVariationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.ProductVariationsQuery,
        Types.ProductVariationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.ProductVariationsQuery, Types.ProductVariationsQueryVariables>(
        ProductVariationsDocument,
        options,
    );
}
export function useProductVariationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ProductVariationsQuery,
        Types.ProductVariationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.ProductVariationsQuery, Types.ProductVariationsQueryVariables>(
        ProductVariationsDocument,
        options,
    );
}
export function useProductVariationsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ProductVariationsQuery,
        Types.ProductVariationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.ProductVariationsQuery,
        Types.ProductVariationsQueryVariables
    >(ProductVariationsDocument, options);
}
export type ProductVariationsQueryHookResult = ReturnType<typeof useProductVariationsQuery>;
export type ProductVariationsLazyQueryHookResult = ReturnType<typeof useProductVariationsLazyQuery>;
export type ProductVariationsSuspenseQueryHookResult = ReturnType<
    typeof useProductVariationsSuspenseQuery
>;
export type ProductVariationsQueryResult = Apollo.QueryResult<
    Types.ProductVariationsQuery,
    Types.ProductVariationsQueryVariables
>;
export const ProductVariationsByProductDocument = gql`
    query ProductVariationsByProduct($productId: ID!) {
        productVariationsByProduct(productId: $productId) {
            ...ProductVariationFields
        }
    }
    ${ProductVariationFieldsFragmentDoc}
`;

/**
 * __useProductVariationsByProductQuery__
 *
 * To run a query within a React component, call `useProductVariationsByProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariationsByProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariationsByProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductVariationsByProductQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.ProductVariationsByProductQuery,
        Types.ProductVariationsByProductQueryVariables
    > &
        (
            | { variables: Types.ProductVariationsByProductQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.ProductVariationsByProductQuery,
        Types.ProductVariationsByProductQueryVariables
    >(ProductVariationsByProductDocument, options);
}
export function useProductVariationsByProductLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ProductVariationsByProductQuery,
        Types.ProductVariationsByProductQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.ProductVariationsByProductQuery,
        Types.ProductVariationsByProductQueryVariables
    >(ProductVariationsByProductDocument, options);
}
export function useProductVariationsByProductSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.ProductVariationsByProductQuery,
        Types.ProductVariationsByProductQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.ProductVariationsByProductQuery,
        Types.ProductVariationsByProductQueryVariables
    >(ProductVariationsByProductDocument, options);
}
export type ProductVariationsByProductQueryHookResult = ReturnType<
    typeof useProductVariationsByProductQuery
>;
export type ProductVariationsByProductLazyQueryHookResult = ReturnType<
    typeof useProductVariationsByProductLazyQuery
>;
export type ProductVariationsByProductSuspenseQueryHookResult = ReturnType<
    typeof useProductVariationsByProductSuspenseQuery
>;
export type ProductVariationsByProductQueryResult = Apollo.QueryResult<
    Types.ProductVariationsByProductQuery,
    Types.ProductVariationsByProductQueryVariables
>;
export const ProductVariationCreateDocument = gql`
    mutation ProductVariationCreate($productId: ID!, $input: ProductVariationCreateUpdate!) {
        productVariationCreate(productId: $productId, input: $input) {
            ...ProductVariationFields
        }
    }
    ${ProductVariationFieldsFragmentDoc}
`;
export type ProductVariationCreateMutationFn = Apollo.MutationFunction<
    Types.ProductVariationCreateMutation,
    Types.ProductVariationCreateMutationVariables
>;

/**
 * __useProductVariationCreateMutation__
 *
 * To run a mutation, you first call `useProductVariationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariationCreateMutation, { data, loading, error }] = useProductVariationCreateMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductVariationCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ProductVariationCreateMutation,
        Types.ProductVariationCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ProductVariationCreateMutation,
        Types.ProductVariationCreateMutationVariables
    >(ProductVariationCreateDocument, options);
}
export type ProductVariationCreateMutationHookResult = ReturnType<
    typeof useProductVariationCreateMutation
>;
export type ProductVariationCreateMutationResult =
    Apollo.MutationResult<Types.ProductVariationCreateMutation>;
export type ProductVariationCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.ProductVariationCreateMutation,
    Types.ProductVariationCreateMutationVariables
>;
export const ProductVariationUpdateDocument = gql`
    mutation ProductVariationUpdate($input: ProductVariationUpdate!) {
        productVariationUpdate(input: $input) {
            ...ProductVariationFields
        }
    }
    ${ProductVariationFieldsFragmentDoc}
`;
export type ProductVariationUpdateMutationFn = Apollo.MutationFunction<
    Types.ProductVariationUpdateMutation,
    Types.ProductVariationUpdateMutationVariables
>;

/**
 * __useProductVariationUpdateMutation__
 *
 * To run a mutation, you first call `useProductVariationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariationUpdateMutation, { data, loading, error }] = useProductVariationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductVariationUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ProductVariationUpdateMutation,
        Types.ProductVariationUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ProductVariationUpdateMutation,
        Types.ProductVariationUpdateMutationVariables
    >(ProductVariationUpdateDocument, options);
}
export type ProductVariationUpdateMutationHookResult = ReturnType<
    typeof useProductVariationUpdateMutation
>;
export type ProductVariationUpdateMutationResult =
    Apollo.MutationResult<Types.ProductVariationUpdateMutation>;
export type ProductVariationUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.ProductVariationUpdateMutation,
    Types.ProductVariationUpdateMutationVariables
>;
export const ProductVariationsCreateDocument = gql`
    mutation ProductVariationsCreate($productId: ID!, $inputs: [ProductVariationCreateUpdate!]!) {
        productVariationsCreate(productId: $productId, inputs: $inputs) {
            ...ProductVariationFields
        }
    }
    ${ProductVariationFieldsFragmentDoc}
`;
export type ProductVariationsCreateMutationFn = Apollo.MutationFunction<
    Types.ProductVariationsCreateMutation,
    Types.ProductVariationsCreateMutationVariables
>;

/**
 * __useProductVariationsCreateMutation__
 *
 * To run a mutation, you first call `useProductVariationsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariationsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariationsCreateMutation, { data, loading, error }] = useProductVariationsCreateMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useProductVariationsCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ProductVariationsCreateMutation,
        Types.ProductVariationsCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ProductVariationsCreateMutation,
        Types.ProductVariationsCreateMutationVariables
    >(ProductVariationsCreateDocument, options);
}
export type ProductVariationsCreateMutationHookResult = ReturnType<
    typeof useProductVariationsCreateMutation
>;
export type ProductVariationsCreateMutationResult =
    Apollo.MutationResult<Types.ProductVariationsCreateMutation>;
export type ProductVariationsCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.ProductVariationsCreateMutation,
    Types.ProductVariationsCreateMutationVariables
>;
export const ProductVariationsUpdateDocument = gql`
    mutation ProductVariationsUpdate($inputs: [ProductVariationUpdate!]!) {
        productVariationsUpdate(inputs: $inputs) {
            ...ProductVariationFields
        }
    }
    ${ProductVariationFieldsFragmentDoc}
`;
export type ProductVariationsUpdateMutationFn = Apollo.MutationFunction<
    Types.ProductVariationsUpdateMutation,
    Types.ProductVariationsUpdateMutationVariables
>;

/**
 * __useProductVariationsUpdateMutation__
 *
 * To run a mutation, you first call `useProductVariationsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariationsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariationsUpdateMutation, { data, loading, error }] = useProductVariationsUpdateMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useProductVariationsUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ProductVariationsUpdateMutation,
        Types.ProductVariationsUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ProductVariationsUpdateMutation,
        Types.ProductVariationsUpdateMutationVariables
    >(ProductVariationsUpdateDocument, options);
}
export type ProductVariationsUpdateMutationHookResult = ReturnType<
    typeof useProductVariationsUpdateMutation
>;
export type ProductVariationsUpdateMutationResult =
    Apollo.MutationResult<Types.ProductVariationsUpdateMutation>;
export type ProductVariationsUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.ProductVariationsUpdateMutation,
    Types.ProductVariationsUpdateMutationVariables
>;
export const ProductVariationsUpdateCreateDocument = gql`
    mutation ProductVariationsUpdateCreate(
        $productId: ID!
        $inputs: [ProductVariationCreateUpdate!]!
    ) {
        productVariationsUpdateCreate(productId: $productId, inputs: $inputs) {
            ...ProductVariationFields
        }
    }
    ${ProductVariationFieldsFragmentDoc}
`;
export type ProductVariationsUpdateCreateMutationFn = Apollo.MutationFunction<
    Types.ProductVariationsUpdateCreateMutation,
    Types.ProductVariationsUpdateCreateMutationVariables
>;

/**
 * __useProductVariationsUpdateCreateMutation__
 *
 * To run a mutation, you first call `useProductVariationsUpdateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariationsUpdateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariationsUpdateCreateMutation, { data, loading, error }] = useProductVariationsUpdateCreateMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useProductVariationsUpdateCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ProductVariationsUpdateCreateMutation,
        Types.ProductVariationsUpdateCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ProductVariationsUpdateCreateMutation,
        Types.ProductVariationsUpdateCreateMutationVariables
    >(ProductVariationsUpdateCreateDocument, options);
}
export type ProductVariationsUpdateCreateMutationHookResult = ReturnType<
    typeof useProductVariationsUpdateCreateMutation
>;
export type ProductVariationsUpdateCreateMutationResult =
    Apollo.MutationResult<Types.ProductVariationsUpdateCreateMutation>;
export type ProductVariationsUpdateCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.ProductVariationsUpdateCreateMutation,
    Types.ProductVariationsUpdateCreateMutationVariables
>;
export const SpacesDocument = gql`
    query Spaces {
        spaces {
            ...FSpace
        }
    }
    ${FSpaceFragmentDoc}
`;

/**
 * __useSpacesQuery__
 *
 * To run a query within a React component, call `useSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpacesQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.SpacesQuery, Types.SpacesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.SpacesQuery, Types.SpacesQueryVariables>(SpacesDocument, options);
}
export function useSpacesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.SpacesQuery, Types.SpacesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.SpacesQuery, Types.SpacesQueryVariables>(
        SpacesDocument,
        options,
    );
}
export function useSpacesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpacesQuery, Types.SpacesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.SpacesQuery, Types.SpacesQueryVariables>(
        SpacesDocument,
        options,
    );
}
export type SpacesQueryHookResult = ReturnType<typeof useSpacesQuery>;
export type SpacesLazyQueryHookResult = ReturnType<typeof useSpacesLazyQuery>;
export type SpacesSuspenseQueryHookResult = ReturnType<typeof useSpacesSuspenseQuery>;
export type SpacesQueryResult = Apollo.QueryResult<Types.SpacesQuery, Types.SpacesQueryVariables>;
export const SpaceDocument = gql`
    query Space($id: ID!) {
        space(id: $id) {
            ...FSpace
        }
    }
    ${FSpaceFragmentDoc}
`;

/**
 * __useSpaceQuery__
 *
 * To run a query within a React component, call `useSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceQuery(
    baseOptions: Apollo.QueryHookOptions<Types.SpaceQuery, Types.SpaceQueryVariables> &
        ({ variables: Types.SpaceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.SpaceQuery, Types.SpaceQueryVariables>(SpaceDocument, options);
}
export function useSpaceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.SpaceQuery, Types.SpaceQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.SpaceQuery, Types.SpaceQueryVariables>(SpaceDocument, options);
}
export function useSpaceSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpaceQuery, Types.SpaceQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.SpaceQuery, Types.SpaceQueryVariables>(
        SpaceDocument,
        options,
    );
}
export type SpaceQueryHookResult = ReturnType<typeof useSpaceQuery>;
export type SpaceLazyQueryHookResult = ReturnType<typeof useSpaceLazyQuery>;
export type SpaceSuspenseQueryHookResult = ReturnType<typeof useSpaceSuspenseQuery>;
export type SpaceQueryResult = Apollo.QueryResult<Types.SpaceQuery, Types.SpaceQueryVariables>;
export const SpaceBySlugDocument = gql`
    query SpaceBySlug($slug: String!) {
        space: spaceBySlug(slug: $slug) {
            ...FSpace
        }
    }
    ${FSpaceFragmentDoc}
`;

/**
 * __useSpaceBySlugQuery__
 *
 * To run a query within a React component, call `useSpaceBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSpaceBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<Types.SpaceBySlugQuery, Types.SpaceBySlugQueryVariables> &
        ({ variables: Types.SpaceBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.SpaceBySlugQuery, Types.SpaceBySlugQueryVariables>(
        SpaceBySlugDocument,
        options,
    );
}
export function useSpaceBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.SpaceBySlugQuery,
        Types.SpaceBySlugQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.SpaceBySlugQuery, Types.SpaceBySlugQueryVariables>(
        SpaceBySlugDocument,
        options,
    );
}
export function useSpaceBySlugSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.SpaceBySlugQuery,
        Types.SpaceBySlugQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.SpaceBySlugQuery, Types.SpaceBySlugQueryVariables>(
        SpaceBySlugDocument,
        options,
    );
}
export type SpaceBySlugQueryHookResult = ReturnType<typeof useSpaceBySlugQuery>;
export type SpaceBySlugLazyQueryHookResult = ReturnType<typeof useSpaceBySlugLazyQuery>;
export type SpaceBySlugSuspenseQueryHookResult = ReturnType<typeof useSpaceBySlugSuspenseQuery>;
export type SpaceBySlugQueryResult = Apollo.QueryResult<
    Types.SpaceBySlugQuery,
    Types.SpaceBySlugQueryVariables
>;
export const SpaceCreateDocument = gql`
    mutation SpaceCreate($input: SpaceCreate!) {
        spaceCreate(input: $input) {
            ...FSpace
        }
    }
    ${FSpaceFragmentDoc}
`;
export type SpaceCreateMutationFn = Apollo.MutationFunction<
    Types.SpaceCreateMutation,
    Types.SpaceCreateMutationVariables
>;

/**
 * __useSpaceCreateMutation__
 *
 * To run a mutation, you first call `useSpaceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceCreateMutation, { data, loading, error }] = useSpaceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SpaceCreateMutation,
        Types.SpaceCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SpaceCreateMutation, Types.SpaceCreateMutationVariables>(
        SpaceCreateDocument,
        options,
    );
}
export type SpaceCreateMutationHookResult = ReturnType<typeof useSpaceCreateMutation>;
export type SpaceCreateMutationResult = Apollo.MutationResult<Types.SpaceCreateMutation>;
export type SpaceCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.SpaceCreateMutation,
    Types.SpaceCreateMutationVariables
>;
export const SpaceUpdateDocument = gql`
    mutation SpaceUpdate($input: SpaceUpdate!) {
        spaceUpdate(input: $input) {
            ...FSpace
        }
    }
    ${FSpaceFragmentDoc}
`;
export type SpaceUpdateMutationFn = Apollo.MutationFunction<
    Types.SpaceUpdateMutation,
    Types.SpaceUpdateMutationVariables
>;

/**
 * __useSpaceUpdateMutation__
 *
 * To run a mutation, you first call `useSpaceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceUpdateMutation, { data, loading, error }] = useSpaceUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SpaceUpdateMutation,
        Types.SpaceUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SpaceUpdateMutation, Types.SpaceUpdateMutationVariables>(
        SpaceUpdateDocument,
        options,
    );
}
export type SpaceUpdateMutationHookResult = ReturnType<typeof useSpaceUpdateMutation>;
export type SpaceUpdateMutationResult = Apollo.MutationResult<Types.SpaceUpdateMutation>;
export type SpaceUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.SpaceUpdateMutation,
    Types.SpaceUpdateMutationVariables
>;
export const SpaceTypesDocument = gql`
    query SpaceTypes {
        spaceTypes {
            ...FSpaceType
        }
    }
    ${FSpaceTypeFragmentDoc}
`;

/**
 * __useSpaceTypesQuery__
 *
 * To run a query within a React component, call `useSpaceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpaceTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.SpaceTypesQuery, Types.SpaceTypesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.SpaceTypesQuery, Types.SpaceTypesQueryVariables>(
        SpaceTypesDocument,
        options,
    );
}
export function useSpaceTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.SpaceTypesQuery,
        Types.SpaceTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.SpaceTypesQuery, Types.SpaceTypesQueryVariables>(
        SpaceTypesDocument,
        options,
    );
}
export function useSpaceTypesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.SpaceTypesQuery,
        Types.SpaceTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.SpaceTypesQuery, Types.SpaceTypesQueryVariables>(
        SpaceTypesDocument,
        options,
    );
}
export type SpaceTypesQueryHookResult = ReturnType<typeof useSpaceTypesQuery>;
export type SpaceTypesLazyQueryHookResult = ReturnType<typeof useSpaceTypesLazyQuery>;
export type SpaceTypesSuspenseQueryHookResult = ReturnType<typeof useSpaceTypesSuspenseQuery>;
export type SpaceTypesQueryResult = Apollo.QueryResult<
    Types.SpaceTypesQuery,
    Types.SpaceTypesQueryVariables
>;
export const SpaceTypesTranslatedDocument = gql`
    query SpaceTypesTranslated($language: ELanguage!) {
        spaceTypes: spaceTypesTranslated(language: $language) {
            ...FSpaceTypeTranslated
        }
    }
    ${FSpaceTypeTranslatedFragmentDoc}
`;

/**
 * __useSpaceTypesTranslatedQuery__
 *
 * To run a query within a React component, call `useSpaceTypesTranslatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceTypesTranslatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceTypesTranslatedQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSpaceTypesTranslatedQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.SpaceTypesTranslatedQuery,
        Types.SpaceTypesTranslatedQueryVariables
    > &
        (
            | { variables: Types.SpaceTypesTranslatedQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.SpaceTypesTranslatedQuery,
        Types.SpaceTypesTranslatedQueryVariables
    >(SpaceTypesTranslatedDocument, options);
}
export function useSpaceTypesTranslatedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.SpaceTypesTranslatedQuery,
        Types.SpaceTypesTranslatedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.SpaceTypesTranslatedQuery,
        Types.SpaceTypesTranslatedQueryVariables
    >(SpaceTypesTranslatedDocument, options);
}
export function useSpaceTypesTranslatedSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.SpaceTypesTranslatedQuery,
        Types.SpaceTypesTranslatedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.SpaceTypesTranslatedQuery,
        Types.SpaceTypesTranslatedQueryVariables
    >(SpaceTypesTranslatedDocument, options);
}
export type SpaceTypesTranslatedQueryHookResult = ReturnType<typeof useSpaceTypesTranslatedQuery>;
export type SpaceTypesTranslatedLazyQueryHookResult = ReturnType<
    typeof useSpaceTypesTranslatedLazyQuery
>;
export type SpaceTypesTranslatedSuspenseQueryHookResult = ReturnType<
    typeof useSpaceTypesTranslatedSuspenseQuery
>;
export type SpaceTypesTranslatedQueryResult = Apollo.QueryResult<
    Types.SpaceTypesTranslatedQuery,
    Types.SpaceTypesTranslatedQueryVariables
>;
export const SpaceTypeDocument = gql`
    query SpaceType($id: ID!) {
        spaceType(id: $id) {
            ...FSpaceType
        }
    }
    ${FSpaceTypeFragmentDoc}
`;

/**
 * __useSpaceTypeQuery__
 *
 * To run a query within a React component, call `useSpaceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceTypeQuery(
    baseOptions: Apollo.QueryHookOptions<Types.SpaceTypeQuery, Types.SpaceTypeQueryVariables> &
        ({ variables: Types.SpaceTypeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.SpaceTypeQuery, Types.SpaceTypeQueryVariables>(
        SpaceTypeDocument,
        options,
    );
}
export function useSpaceTypeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.SpaceTypeQuery, Types.SpaceTypeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.SpaceTypeQuery, Types.SpaceTypeQueryVariables>(
        SpaceTypeDocument,
        options,
    );
}
export function useSpaceTypeSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.SpaceTypeQuery,
        Types.SpaceTypeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.SpaceTypeQuery, Types.SpaceTypeQueryVariables>(
        SpaceTypeDocument,
        options,
    );
}
export type SpaceTypeQueryHookResult = ReturnType<typeof useSpaceTypeQuery>;
export type SpaceTypeLazyQueryHookResult = ReturnType<typeof useSpaceTypeLazyQuery>;
export type SpaceTypeSuspenseQueryHookResult = ReturnType<typeof useSpaceTypeSuspenseQuery>;
export type SpaceTypeQueryResult = Apollo.QueryResult<
    Types.SpaceTypeQuery,
    Types.SpaceTypeQueryVariables
>;
export const SpaceTypeBySlugDocument = gql`
    query SpaceTypeBySlug($slug: String!) {
        spaceType: spaceTypeBySlug(slug: $slug) {
            ...FSpaceType
        }
    }
    ${FSpaceTypeFragmentDoc}
`;

/**
 * __useSpaceTypeBySlugQuery__
 *
 * To run a query within a React component, call `useSpaceTypeBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceTypeBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceTypeBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSpaceTypeBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.SpaceTypeBySlugQuery,
        Types.SpaceTypeBySlugQueryVariables
    > &
        ({ variables: Types.SpaceTypeBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.SpaceTypeBySlugQuery, Types.SpaceTypeBySlugQueryVariables>(
        SpaceTypeBySlugDocument,
        options,
    );
}
export function useSpaceTypeBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.SpaceTypeBySlugQuery,
        Types.SpaceTypeBySlugQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.SpaceTypeBySlugQuery, Types.SpaceTypeBySlugQueryVariables>(
        SpaceTypeBySlugDocument,
        options,
    );
}
export function useSpaceTypeBySlugSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.SpaceTypeBySlugQuery,
        Types.SpaceTypeBySlugQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.SpaceTypeBySlugQuery, Types.SpaceTypeBySlugQueryVariables>(
        SpaceTypeBySlugDocument,
        options,
    );
}
export type SpaceTypeBySlugQueryHookResult = ReturnType<typeof useSpaceTypeBySlugQuery>;
export type SpaceTypeBySlugLazyQueryHookResult = ReturnType<typeof useSpaceTypeBySlugLazyQuery>;
export type SpaceTypeBySlugSuspenseQueryHookResult = ReturnType<
    typeof useSpaceTypeBySlugSuspenseQuery
>;
export type SpaceTypeBySlugQueryResult = Apollo.QueryResult<
    Types.SpaceTypeBySlugQuery,
    Types.SpaceTypeBySlugQueryVariables
>;
export const SpaceTypeCreateDocument = gql`
    mutation SpaceTypeCreate($input: SpaceTypeCreate!) {
        spaceTypeCreate(input: $input) {
            ...FSpaceType
        }
    }
    ${FSpaceTypeFragmentDoc}
`;
export type SpaceTypeCreateMutationFn = Apollo.MutationFunction<
    Types.SpaceTypeCreateMutation,
    Types.SpaceTypeCreateMutationVariables
>;

/**
 * __useSpaceTypeCreateMutation__
 *
 * To run a mutation, you first call `useSpaceTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceTypeCreateMutation, { data, loading, error }] = useSpaceTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceTypeCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SpaceTypeCreateMutation,
        Types.SpaceTypeCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.SpaceTypeCreateMutation,
        Types.SpaceTypeCreateMutationVariables
    >(SpaceTypeCreateDocument, options);
}
export type SpaceTypeCreateMutationHookResult = ReturnType<typeof useSpaceTypeCreateMutation>;
export type SpaceTypeCreateMutationResult = Apollo.MutationResult<Types.SpaceTypeCreateMutation>;
export type SpaceTypeCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.SpaceTypeCreateMutation,
    Types.SpaceTypeCreateMutationVariables
>;
export const SpaceTypeUpdateDocument = gql`
    mutation SpaceTypeUpdate($input: SpaceTypeUpdate!) {
        spaceTypeUpdate(input: $input) {
            ...FSpaceType
        }
    }
    ${FSpaceTypeFragmentDoc}
`;
export type SpaceTypeUpdateMutationFn = Apollo.MutationFunction<
    Types.SpaceTypeUpdateMutation,
    Types.SpaceTypeUpdateMutationVariables
>;

/**
 * __useSpaceTypeUpdateMutation__
 *
 * To run a mutation, you first call `useSpaceTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceTypeUpdateMutation, { data, loading, error }] = useSpaceTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceTypeUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SpaceTypeUpdateMutation,
        Types.SpaceTypeUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.SpaceTypeUpdateMutation,
        Types.SpaceTypeUpdateMutationVariables
    >(SpaceTypeUpdateDocument, options);
}
export type SpaceTypeUpdateMutationHookResult = ReturnType<typeof useSpaceTypeUpdateMutation>;
export type SpaceTypeUpdateMutationResult = Apollo.MutationResult<Types.SpaceTypeUpdateMutation>;
export type SpaceTypeUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.SpaceTypeUpdateMutation,
    Types.SpaceTypeUpdateMutationVariables
>;
export const SuppliersDocument = gql`
    query Suppliers {
        suppliers {
            ...SupplierFields
        }
    }
    ${SupplierFieldsFragmentDoc}
`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.SuppliersQuery, Types.SuppliersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.SuppliersQuery, Types.SuppliersQueryVariables>(
        SuppliersDocument,
        options,
    );
}
export function useSuppliersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.SuppliersQuery, Types.SuppliersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.SuppliersQuery, Types.SuppliersQueryVariables>(
        SuppliersDocument,
        options,
    );
}
export function useSuppliersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.SuppliersQuery,
        Types.SuppliersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.SuppliersQuery, Types.SuppliersQueryVariables>(
        SuppliersDocument,
        options,
    );
}
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersSuspenseQueryHookResult = ReturnType<typeof useSuppliersSuspenseQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<
    Types.SuppliersQuery,
    Types.SuppliersQueryVariables
>;
export const SupplierDocument = gql`
    query Supplier($id: ID!) {
        supplier(id: $id) {
            ...SupplierFields
        }
    }
    ${SupplierFieldsFragmentDoc}
`;

/**
 * __useSupplierQuery__
 *
 * To run a query within a React component, call `useSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplierQuery(
    baseOptions: Apollo.QueryHookOptions<Types.SupplierQuery, Types.SupplierQueryVariables> &
        ({ variables: Types.SupplierQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.SupplierQuery, Types.SupplierQueryVariables>(
        SupplierDocument,
        options,
    );
}
export function useSupplierLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.SupplierQuery, Types.SupplierQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.SupplierQuery, Types.SupplierQueryVariables>(
        SupplierDocument,
        options,
    );
}
export function useSupplierSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.SupplierQuery,
        Types.SupplierQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.SupplierQuery, Types.SupplierQueryVariables>(
        SupplierDocument,
        options,
    );
}
export type SupplierQueryHookResult = ReturnType<typeof useSupplierQuery>;
export type SupplierLazyQueryHookResult = ReturnType<typeof useSupplierLazyQuery>;
export type SupplierSuspenseQueryHookResult = ReturnType<typeof useSupplierSuspenseQuery>;
export type SupplierQueryResult = Apollo.QueryResult<
    Types.SupplierQuery,
    Types.SupplierQueryVariables
>;
export const SupplierCreateDocument = gql`
    mutation SupplierCreate($input: SupplierCreate!) {
        supplierCreate(input: $input) {
            ...SupplierFields
        }
    }
    ${SupplierFieldsFragmentDoc}
`;
export type SupplierCreateMutationFn = Apollo.MutationFunction<
    Types.SupplierCreateMutation,
    Types.SupplierCreateMutationVariables
>;

/**
 * __useSupplierCreateMutation__
 *
 * To run a mutation, you first call `useSupplierCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierCreateMutation, { data, loading, error }] = useSupplierCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SupplierCreateMutation,
        Types.SupplierCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SupplierCreateMutation, Types.SupplierCreateMutationVariables>(
        SupplierCreateDocument,
        options,
    );
}
export type SupplierCreateMutationHookResult = ReturnType<typeof useSupplierCreateMutation>;
export type SupplierCreateMutationResult = Apollo.MutationResult<Types.SupplierCreateMutation>;
export type SupplierCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.SupplierCreateMutation,
    Types.SupplierCreateMutationVariables
>;
export const SupplierUpdateDocument = gql`
    mutation SupplierUpdate($input: SupplierUpdate!) {
        supplierUpdate(input: $input) {
            ...SupplierFields
        }
    }
    ${SupplierFieldsFragmentDoc}
`;
export type SupplierUpdateMutationFn = Apollo.MutationFunction<
    Types.SupplierUpdateMutation,
    Types.SupplierUpdateMutationVariables
>;

/**
 * __useSupplierUpdateMutation__
 *
 * To run a mutation, you first call `useSupplierUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierUpdateMutation, { data, loading, error }] = useSupplierUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.SupplierUpdateMutation,
        Types.SupplierUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.SupplierUpdateMutation, Types.SupplierUpdateMutationVariables>(
        SupplierUpdateDocument,
        options,
    );
}
export type SupplierUpdateMutationHookResult = ReturnType<typeof useSupplierUpdateMutation>;
export type SupplierUpdateMutationResult = Apollo.MutationResult<Types.SupplierUpdateMutation>;
export type SupplierUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.SupplierUpdateMutation,
    Types.SupplierUpdateMutationVariables
>;
export const TagDocument = gql`
    query Tag($id: ID!) {
        tag(id: $id) {
            ...TagFields
        }
    }
    ${TagFieldsFragmentDoc}
`;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(
    baseOptions: Apollo.QueryHookOptions<Types.TagQuery, Types.TagQueryVariables> &
        ({ variables: Types.TagQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.TagQuery, Types.TagQueryVariables>(TagDocument, options);
}
export function useTagLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.TagQuery, Types.TagQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.TagQuery, Types.TagQueryVariables>(TagDocument, options);
}
export function useTagSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TagQuery, Types.TagQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.TagQuery, Types.TagQueryVariables>(TagDocument, options);
}
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagSuspenseQueryHookResult = ReturnType<typeof useTagSuspenseQuery>;
export type TagQueryResult = Apollo.QueryResult<Types.TagQuery, Types.TagQueryVariables>;
export const TagsDocument = gql`
    query Tags {
        tags {
            ...TagFields
        }
    }
    ${TagFieldsFragmentDoc}
`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
}
export function useTagsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.TagsQuery, Types.TagsQueryVariables>(TagsDocument, options);
}
export function useTagsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TagsQuery, Types.TagsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.TagsQuery, Types.TagsQueryVariables>(
        TagsDocument,
        options,
    );
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsSuspenseQueryHookResult = ReturnType<typeof useTagsSuspenseQuery>;
export type TagsQueryResult = Apollo.QueryResult<Types.TagsQuery, Types.TagsQueryVariables>;
export const TagsBySchemaDocument = gql`
    query TagsBySchema($schemas: [ETagSchema!]!) {
        tagsBySchema(schemas: $schemas) {
            ...TagFields
        }
    }
    ${TagFieldsFragmentDoc}
`;

/**
 * __useTagsBySchemaQuery__
 *
 * To run a query within a React component, call `useTagsBySchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsBySchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsBySchemaQuery({
 *   variables: {
 *      schemas: // value for 'schemas'
 *   },
 * });
 */
export function useTagsBySchemaQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.TagsBySchemaQuery,
        Types.TagsBySchemaQueryVariables
    > &
        ({ variables: Types.TagsBySchemaQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.TagsBySchemaQuery, Types.TagsBySchemaQueryVariables>(
        TagsBySchemaDocument,
        options,
    );
}
export function useTagsBySchemaLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.TagsBySchemaQuery,
        Types.TagsBySchemaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.TagsBySchemaQuery, Types.TagsBySchemaQueryVariables>(
        TagsBySchemaDocument,
        options,
    );
}
export function useTagsBySchemaSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.TagsBySchemaQuery,
        Types.TagsBySchemaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.TagsBySchemaQuery, Types.TagsBySchemaQueryVariables>(
        TagsBySchemaDocument,
        options,
    );
}
export type TagsBySchemaQueryHookResult = ReturnType<typeof useTagsBySchemaQuery>;
export type TagsBySchemaLazyQueryHookResult = ReturnType<typeof useTagsBySchemaLazyQuery>;
export type TagsBySchemaSuspenseQueryHookResult = ReturnType<typeof useTagsBySchemaSuspenseQuery>;
export type TagsBySchemaQueryResult = Apollo.QueryResult<
    Types.TagsBySchemaQuery,
    Types.TagsBySchemaQueryVariables
>;
export const TagCreateDocument = gql`
    mutation TagCreate($input: TagCreate!) {
        tagCreate(input: $input) {
            ...TagFields
        }
    }
    ${TagFieldsFragmentDoc}
`;
export type TagCreateMutationFn = Apollo.MutationFunction<
    Types.TagCreateMutation,
    Types.TagCreateMutationVariables
>;

/**
 * __useTagCreateMutation__
 *
 * To run a mutation, you first call `useTagCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagCreateMutation, { data, loading, error }] = useTagCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.TagCreateMutation,
        Types.TagCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.TagCreateMutation, Types.TagCreateMutationVariables>(
        TagCreateDocument,
        options,
    );
}
export type TagCreateMutationHookResult = ReturnType<typeof useTagCreateMutation>;
export type TagCreateMutationResult = Apollo.MutationResult<Types.TagCreateMutation>;
export type TagCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.TagCreateMutation,
    Types.TagCreateMutationVariables
>;
export const TagUpdateDocument = gql`
    mutation TagUpdate($input: TagUpdate!) {
        tagUpdate(input: $input) {
            ...TagFields
        }
    }
    ${TagFieldsFragmentDoc}
`;
export type TagUpdateMutationFn = Apollo.MutationFunction<
    Types.TagUpdateMutation,
    Types.TagUpdateMutationVariables
>;

/**
 * __useTagUpdateMutation__
 *
 * To run a mutation, you first call `useTagUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagUpdateMutation, { data, loading, error }] = useTagUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.TagUpdateMutation,
        Types.TagUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.TagUpdateMutation, Types.TagUpdateMutationVariables>(
        TagUpdateDocument,
        options,
    );
}
export type TagUpdateMutationHookResult = ReturnType<typeof useTagUpdateMutation>;
export type TagUpdateMutationResult = Apollo.MutationResult<Types.TagUpdateMutation>;
export type TagUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.TagUpdateMutation,
    Types.TagUpdateMutationVariables
>;
export const TagsUpdateDocument = gql`
    mutation TagsUpdate($input: [TagUpdate!]!) {
        tagsUpdate(input: $input) {
            ...TagFields
        }
    }
    ${TagFieldsFragmentDoc}
`;
export type TagsUpdateMutationFn = Apollo.MutationFunction<
    Types.TagsUpdateMutation,
    Types.TagsUpdateMutationVariables
>;

/**
 * __useTagsUpdateMutation__
 *
 * To run a mutation, you first call `useTagsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagsUpdateMutation, { data, loading, error }] = useTagsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagsUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.TagsUpdateMutation,
        Types.TagsUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.TagsUpdateMutation, Types.TagsUpdateMutationVariables>(
        TagsUpdateDocument,
        options,
    );
}
export type TagsUpdateMutationHookResult = ReturnType<typeof useTagsUpdateMutation>;
export type TagsUpdateMutationResult = Apollo.MutationResult<Types.TagsUpdateMutation>;
export type TagsUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.TagsUpdateMutation,
    Types.TagsUpdateMutationVariables
>;
export const MeDocument = gql`
    query me {
        me {
            ...FUser
        }
    }
    ${FUserFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.MeQuery, Types.MeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MeQuery, Types.MeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.MeQuery, Types.MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<Types.MeQuery, Types.MeQueryVariables>;
export const UserCategoryDocument = gql`
    query UserCategory($id: ID!) {
        userCategory(id: $id) {
            ...FUserCategory
        }
    }
    ${FUserCategoryFragmentDoc}
`;

/**
 * __useUserCategoryQuery__
 *
 * To run a query within a React component, call `useUserCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserCategoryQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.UserCategoryQuery,
        Types.UserCategoryQueryVariables
    > &
        ({ variables: Types.UserCategoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.UserCategoryQuery, Types.UserCategoryQueryVariables>(
        UserCategoryDocument,
        options,
    );
}
export function useUserCategoryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.UserCategoryQuery,
        Types.UserCategoryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.UserCategoryQuery, Types.UserCategoryQueryVariables>(
        UserCategoryDocument,
        options,
    );
}
export function useUserCategorySuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.UserCategoryQuery,
        Types.UserCategoryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.UserCategoryQuery, Types.UserCategoryQueryVariables>(
        UserCategoryDocument,
        options,
    );
}
export type UserCategoryQueryHookResult = ReturnType<typeof useUserCategoryQuery>;
export type UserCategoryLazyQueryHookResult = ReturnType<typeof useUserCategoryLazyQuery>;
export type UserCategorySuspenseQueryHookResult = ReturnType<typeof useUserCategorySuspenseQuery>;
export type UserCategoryQueryResult = Apollo.QueryResult<
    Types.UserCategoryQuery,
    Types.UserCategoryQueryVariables
>;
export const UserCategoriesDocument = gql`
    query UserCategories {
        userCategories {
            ...FUserCategory
        }
    }
    ${FUserCategoryFragmentDoc}
`;

/**
 * __useUserCategoriesQuery__
 *
 * To run a query within a React component, call `useUserCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCategoriesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.UserCategoriesQuery,
        Types.UserCategoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.UserCategoriesQuery, Types.UserCategoriesQueryVariables>(
        UserCategoriesDocument,
        options,
    );
}
export function useUserCategoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.UserCategoriesQuery,
        Types.UserCategoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.UserCategoriesQuery, Types.UserCategoriesQueryVariables>(
        UserCategoriesDocument,
        options,
    );
}
export function useUserCategoriesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.UserCategoriesQuery,
        Types.UserCategoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.UserCategoriesQuery, Types.UserCategoriesQueryVariables>(
        UserCategoriesDocument,
        options,
    );
}
export type UserCategoriesQueryHookResult = ReturnType<typeof useUserCategoriesQuery>;
export type UserCategoriesLazyQueryHookResult = ReturnType<typeof useUserCategoriesLazyQuery>;
export type UserCategoriesSuspenseQueryHookResult = ReturnType<
    typeof useUserCategoriesSuspenseQuery
>;
export type UserCategoriesQueryResult = Apollo.QueryResult<
    Types.UserCategoriesQuery,
    Types.UserCategoriesQueryVariables
>;
export const UserDocument = gql`
    query User($id: ID!) {
        user(id: $id) {
            ...FUser
        }
    }
    ${FUserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
    baseOptions: Apollo.QueryHookOptions<Types.UserQuery, Types.UserQueryVariables> &
        ({ variables: Types.UserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.UserQuery, Types.UserQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
}
export function useUserSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserQuery, Types.UserQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.UserQuery, Types.UserQueryVariables>(
        UserDocument,
        options,
    );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<Types.UserQuery, Types.UserQueryVariables>;
export const UsersDocument = gql`
    query Users($input: GIUserSearch) {
        users(input: $input) {
            ...FUser
        }
    }
    ${FUserFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
}
export function useUsersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<Types.UsersQuery, Types.UsersQueryVariables>(
        UsersDocument,
        options,
    );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<Types.UsersQuery, Types.UsersQueryVariables>;
export const UserCategoryCreateDocument = gql`
    mutation UserCategoryCreate($input: GIUserCategoryCreate!) {
        userCategory: userCategoryCreate(input: $input) {
            ...FUserCategory
        }
    }
    ${FUserCategoryFragmentDoc}
`;
export type UserCategoryCreateMutationFn = Apollo.MutationFunction<
    Types.UserCategoryCreateMutation,
    Types.UserCategoryCreateMutationVariables
>;

/**
 * __useUserCategoryCreateMutation__
 *
 * To run a mutation, you first call `useUserCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCategoryCreateMutation, { data, loading, error }] = useUserCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCategoryCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UserCategoryCreateMutation,
        Types.UserCategoryCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UserCategoryCreateMutation,
        Types.UserCategoryCreateMutationVariables
    >(UserCategoryCreateDocument, options);
}
export type UserCategoryCreateMutationHookResult = ReturnType<typeof useUserCategoryCreateMutation>;
export type UserCategoryCreateMutationResult =
    Apollo.MutationResult<Types.UserCategoryCreateMutation>;
export type UserCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.UserCategoryCreateMutation,
    Types.UserCategoryCreateMutationVariables
>;
export const UserCategoryUpdateDocument = gql`
    mutation UserCategoryUpdate($input: GIUserCategoryUpdate!) {
        userCategory: userCategoryUpdate(input: $input) {
            ...FUserCategory
        }
    }
    ${FUserCategoryFragmentDoc}
`;
export type UserCategoryUpdateMutationFn = Apollo.MutationFunction<
    Types.UserCategoryUpdateMutation,
    Types.UserCategoryUpdateMutationVariables
>;

/**
 * __useUserCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useUserCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCategoryUpdateMutation, { data, loading, error }] = useUserCategoryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCategoryUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UserCategoryUpdateMutation,
        Types.UserCategoryUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.UserCategoryUpdateMutation,
        Types.UserCategoryUpdateMutationVariables
    >(UserCategoryUpdateDocument, options);
}
export type UserCategoryUpdateMutationHookResult = ReturnType<typeof useUserCategoryUpdateMutation>;
export type UserCategoryUpdateMutationResult =
    Apollo.MutationResult<Types.UserCategoryUpdateMutation>;
export type UserCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.UserCategoryUpdateMutation,
    Types.UserCategoryUpdateMutationVariables
>;
export const UserCreateDocument = gql`
    mutation UserCreate($input: GIUserCreate!) {
        user: userCreate(input: $input) {
            ...FUser
        }
    }
    ${FUserFragmentDoc}
`;
export type UserCreateMutationFn = Apollo.MutationFunction<
    Types.UserCreateMutation,
    Types.UserCreateMutationVariables
>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UserCreateMutation,
        Types.UserCreateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.UserCreateMutation, Types.UserCreateMutationVariables>(
        UserCreateDocument,
        options,
    );
}
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<Types.UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<
    Types.UserCreateMutation,
    Types.UserCreateMutationVariables
>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($input: GIUserUpdate!) {
        user: userUpdate(input: $input) {
            ...FUser
        }
    }
    ${FUserFragmentDoc}
`;
export type UserUpdateMutationFn = Apollo.MutationFunction<
    Types.UserUpdateMutation,
    Types.UserUpdateMutationVariables
>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UserUpdateMutation,
        Types.UserUpdateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.UserUpdateMutation, Types.UserUpdateMutationVariables>(
        UserUpdateDocument,
        options,
    );
}
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<Types.UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<
    Types.UserUpdateMutation,
    Types.UserUpdateMutationVariables
>;
export const WebProductsWithVariationsByTagSchemaDocument = gql`
    query webProductsWithVariationsByTagSchema($schemas: [ETagSchema!]!) {
        webProductsWithVariationsByTagSchema(schemas: $schemas) {
            ...FWebProductWithVariations
        }
    }
    ${FWebProductWithVariationsFragmentDoc}
`;

/**
 * __useWebProductsWithVariationsByTagSchemaQuery__
 *
 * To run a query within a React component, call `useWebProductsWithVariationsByTagSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebProductsWithVariationsByTagSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebProductsWithVariationsByTagSchemaQuery({
 *   variables: {
 *      schemas: // value for 'schemas'
 *   },
 * });
 */
export function useWebProductsWithVariationsByTagSchemaQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.WebProductsWithVariationsByTagSchemaQuery,
        Types.WebProductsWithVariationsByTagSchemaQueryVariables
    > &
        (
            | {
                  variables: Types.WebProductsWithVariationsByTagSchemaQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        Types.WebProductsWithVariationsByTagSchemaQuery,
        Types.WebProductsWithVariationsByTagSchemaQueryVariables
    >(WebProductsWithVariationsByTagSchemaDocument, options);
}
export function useWebProductsWithVariationsByTagSchemaLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.WebProductsWithVariationsByTagSchemaQuery,
        Types.WebProductsWithVariationsByTagSchemaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        Types.WebProductsWithVariationsByTagSchemaQuery,
        Types.WebProductsWithVariationsByTagSchemaQueryVariables
    >(WebProductsWithVariationsByTagSchemaDocument, options);
}
export function useWebProductsWithVariationsByTagSchemaSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        Types.WebProductsWithVariationsByTagSchemaQuery,
        Types.WebProductsWithVariationsByTagSchemaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        Types.WebProductsWithVariationsByTagSchemaQuery,
        Types.WebProductsWithVariationsByTagSchemaQueryVariables
    >(WebProductsWithVariationsByTagSchemaDocument, options);
}
export type WebProductsWithVariationsByTagSchemaQueryHookResult = ReturnType<
    typeof useWebProductsWithVariationsByTagSchemaQuery
>;
export type WebProductsWithVariationsByTagSchemaLazyQueryHookResult = ReturnType<
    typeof useWebProductsWithVariationsByTagSchemaLazyQuery
>;
export type WebProductsWithVariationsByTagSchemaSuspenseQueryHookResult = ReturnType<
    typeof useWebProductsWithVariationsByTagSchemaSuspenseQuery
>;
export type WebProductsWithVariationsByTagSchemaQueryResult = Apollo.QueryResult<
    Types.WebProductsWithVariationsByTagSchemaQuery,
    Types.WebProductsWithVariationsByTagSchemaQueryVariables
>;
