import { ETableCellVariant } from '~types/tables';
import { color, font } from '~styles/styles';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export type TableHeaderProps = {
    children?: React.ReactNode | React.ReactNode[];
    variant?: ETableCellVariant;
};

export default function TableHeader(props: TableHeaderProps): JSX.Element {
    const { variant, children } = props;
    return <StyledTableHeader className={clsx(variant)}>{children}</StyledTableHeader>;
}

const StyledTableHeader = styled.td`
    padding: 0.5rem;
    border: 1px solid #fafafa;

    font-size: ${font.size.extraSmall};
    font-weight: ${font.weight.medium};
    line-height: ${font.lineHeight.small};

    border: 1px solid ${color.greyLight};

    &.${ETableCellVariant.NUMBER} {
        text-align: right;
    }

    &.${ETableCellVariant.PRICE} {
        text-align: right;
        width: 90px;
    }

    &.${ETableCellVariant.DATE} {
        text-align: left;
        width: 120px;
    }
`;
