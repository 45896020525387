import { color } from '~styles/styles';
import styled from '@emotion/styled';

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    background-color: ${color.white};
`;

export default Table;
