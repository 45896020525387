import { Outlet } from 'react-router-dom';
import { color, gradient, space } from '~styles/styles';
import Footer, { EFooterVariant } from '~components/navigation/Footer';
import LanguageSelector from '~components/LanguageSelector';
import React from 'react';
import styled from '@emotion/styled';

export default function LayoutPublic() {
    return (
        <PublicLayout>
            <Content>
                <LanguageSelector />
                <Outlet />
                <Footer variant={EFooterVariant.MINIMAL} />
            </Content>
            <Space />
        </PublicLayout>
    );
}

const PublicLayout = styled.div`
    label: PublicLayout;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: 0;
    background: ${gradient.purpleBlack};
`;

const Space = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 0;
    flex: 1 1;
    min-height: 100vh;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: ${space.gap.medium};
    padding: ${space.padding.normal} ${space.padding.normal} 0 ${space.padding.normal};
    flex-grow: 0;
    min-height: 100vh;
    min-width: 33vw;
    background: ${color.white};
    border-right: 3px solid ${color.highlightDark};
`;
