import { EBookingDayOfWeek } from '@makespace/graphql-generated/generated/webapp/schemas';

export const DAY_NUMBER_TO_DAY: EBookingDayOfWeek[] = [
    EBookingDayOfWeek.Monday,
    EBookingDayOfWeek.Tuesday,
    EBookingDayOfWeek.Wednesday,
    EBookingDayOfWeek.Thursday,
    EBookingDayOfWeek.Friday,
    EBookingDayOfWeek.Saturday,
    EBookingDayOfWeek.Sunday,
];
