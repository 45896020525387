import {
    DefaultData,
    TableControlCell,
    useTableControl,
} from '~components/table/TableControlProvider';
import ControlledTableCell from '~components/table/ControlledTableCell';
import ControlledTableHeader from '~components/table/ControlledTableHeader';
import React from 'react';
import Table from '~components/table/Table';
import TableBody from '~components/table/TableBody';
import TableHead from '~components/table/TableHead';
import TableRow from '~components/table/TableRow';

export type ControlledTableProps = {
    children?: React.ReactNode;
};

export default function ControlledTable<TData extends DefaultData>(
    props: ControlledTableProps,
): JSX.Element {
    const { children } = props;
    const { data, headings, cells, searchedData } = useTableControl<TData>();
    const headingsArray = Object.entries(headings)
        .filter(([, heading]) => heading.show !== false)
        .map(([key, heading]) => ({ key, ...heading }));

    const cellsArray = Object.entries(cells)
        .filter(([, cell]) => cell.show !== false)
        .map(([key, cell]) => ({ key, ...cell }));

    const getDataCellValue = (data: TData, key: string) => {
        return data[key as keyof TData];
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {headingsArray.map((heading) => (
                        <ControlledTableHeader
                            key={heading.key}
                            sortable={heading.sortable}
                            sortKey={heading.key}
                            variant={heading.variant}>
                            {heading.label}
                        </ControlledTableHeader>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <DataRows
                    data={searchedData?.length ? searchedData : data}
                    cellsArray={cellsArray}
                    getDataCellValue={getDataCellValue}
                />
            </TableBody>
            {children}
        </Table>
    );
}

type DataRowsProps<TData extends DefaultData> = {
    cellsArray: (TableControlCell<TData> & { key: string })[];
    data?: TData[];
    getDataCellValue: (data: TData, key: string) => TData[keyof TData];
};

function DataRows<TData extends DefaultData>(props: DataRowsProps<TData>): JSX.Element {
    const { data, cellsArray, getDataCellValue } = props;
    return (
        <>
            {data?.map((d, i) => (
                <TableRow key={i} hidden={!!d?.hidden}>
                    {cellsArray.map(({ key, variant, cellProps }) => (
                        <ControlledTableCell
                            key={key}
                            variant={variant}
                            value={getDataCellValue(d, key)}
                            {...(cellProps ? cellProps(d) : {})}
                        />
                    ))}
                </TableRow>
            ))}
        </>
    );
}
