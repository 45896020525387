import { border, color, font } from '~styles/styles';
import { useTranslation } from 'react-i18next';
import IconAlert from '~components/icons/IconAlert';
import React from 'react';
import styled from '@emotion/styled';
import type { ApolloError } from '@apollo/client';
import type { FieldError } from 'react-hook-form';

type ErrorProps = {
    error: Error | FieldError | ApolloError;
};

export default function ErrorAlert(props: ErrorProps) {
    const { error } = props;
    const { t } = useTranslation();
    return (
        <ErrorContainer>
            <IconAlert />
            <p>{t(`error.${error.message}`)}</p>
        </ErrorContainer>
    );
}

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: ${font.size.normal};
    font-weight: ${font.weight.regular};
    line-height: ${font.lineHeight.medium};
    color: ${color.linkText};
    border-radius: ${border.radius.medium};
    background-color: ${color.linkRedWhite};
    gap: 1rem;
    margin: 0.5rem 0 1rem 0;
    padding: 1rem 1rem 1rem 1.5rem;

    p {
        margin: 0.3rem 0 0 0;
    }
`;
