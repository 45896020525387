import { color, font, space } from '~styles/styles';
import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum EFooterVariant {
    MINIMAL = 'minimal',
    NORMAL = 'normal',
}

export type FooterProps = {
    variant?: EFooterVariant;
};

export default function Footer(props?: FooterProps): JSX.Element {
    const { variant = EFooterVariant.NORMAL } = props || {};
    const { t } = useTranslation();
    return <FooterContainer className={clsx(variant)}>{t('app.name')}</FooterContainer>;
}

const FooterContainer = styled.div`
    position: relative;
    background-color: ${color.black};
    color: ${color.white};
    padding: ${space.padding.large};
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    font-size: ${font.size.small};

    &.${EFooterVariant.MINIMAL} {
        background-color: transparent;
        color: ${color.black};
        flex: 0;
        width: 100%;
    }
`;
