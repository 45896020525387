import { SelectOption } from '~types/options';
import { UserCategory } from '@makespace/graphql-generated/generated/webapp/schemas';
import { useLanguage } from '~context/Language';
import { useMemo } from 'react';
import { useUserCategoriesQuery } from '@makespace/graphql-generated/generated/webapp/hooks';

export type UseUserCategoriesProps = {
    filter?: (userCatgegory: UserCategory) => boolean;
};

export type UseUserCategoriesResponse = {
    userCategories: UserCategory[] | undefined;
    userCategoryOptions: SelectOption<string>[];
};

export const useUserCategories = (props: UseUserCategoriesProps): UseUserCategoriesResponse => {
    const { filter } = props;
    const { translate } = useLanguage();
    const { data } = useUserCategoriesQuery();

    const userCategories = useMemo(
        () => data?.userCategories?.filter(filter ?? (() => true)) ?? [],
        [filter, data?.userCategories],
    );

    const userCategoryOptions: SelectOption<string>[] = useMemo(
        () =>
            userCategories
                ?.filter(filter ?? (() => true))
                .map((t) => ({ value: t._id, label: translate(t)?.name ?? t.name })) ?? [],
        [filter, userCategories, translate],
    );

    return {
        userCategoryOptions,
        userCategories,
    };
};
