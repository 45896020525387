import { ApolloError } from '@apollo/client';
import {
    BookingChoice,
    BookingChoiceInput,
} from '@makespace/graphql-generated/generated/webapp/schemas';
import {
    useBookingChoicesLazyQuery,
    useBookingChoicesQuery,
} from '@makespace/graphql-generated/generated/webapp/hooks';
import { useCallback, useMemo } from 'react';

export type UseBookingChoicesProps = {
    date?: string;
    spaceId?: string;
    spaceTypeId?: string;
    spaceTypeVariantId?: string;
    userCategory?: string;
};

export type UseBookingAvailabilityResponse = {
    bookingChoices?: BookingChoice[] | null;
    error?: ApolloError;
    getBookingChoices: (input: UseBookingChoicesProps) => Promise<BookingChoice[] | null>;
    loading: boolean;
};

export default function useBookingChoices(
    props: UseBookingChoicesProps,
): UseBookingAvailabilityResponse {
    const [getBookingChoicesLazy] = useBookingChoicesLazyQuery({});

    const bookingChoicesInput: BookingChoiceInput = useMemo(() => {
        return {
            space: props.spaceId,
            spaceType: props.spaceTypeId ?? 'missing-space-type-id',
            spaceTypeVariant: props.spaceTypeVariantId,
            date: props.date ?? new Date().toISOString(),
            userCategory: props.userCategory,
        };
    }, [
        props.date,
        props.spaceId,
        props.spaceTypeId,
        props.spaceTypeVariantId,
        props.userCategory,
    ]);

    const {
        data: { bookingChoices } = {},
        error,
        loading,
    } = useBookingChoicesQuery({
        fetchPolicy: 'no-cache',
        variables: {
            input: bookingChoicesInput,
        },
        skip: !bookingChoicesInput.spaceType || !bookingChoicesInput.spaceTypeVariant,
    });

    const getBookingChoices = useCallback(
        async (input: UseBookingChoicesProps) => {
            if (!input.spaceTypeId || !input.spaceTypeVariantId || !input.date) return null;
            try {
                const { data } = await getBookingChoicesLazy({
                    variables: {
                        input: {
                            space: input.spaceId,
                            spaceType: input.spaceTypeId,
                            spaceTypeVariant: input.spaceTypeVariantId,
                            date: input.date,
                        },
                    },
                });
                return data?.bookingChoices ?? null;
            } catch (error) {
                console.error('Error checking booking choices:', error);
                return null;
            }
        },
        [getBookingChoicesLazy],
    );

    return {
        getBookingChoices,
        bookingChoices,
        error,
        loading,
    };
}
