import { InputFormatter } from '~types/forms';
import { border, color, font } from '~styles/styles';
import React, { TextareaHTMLAttributes } from 'react';
import styled from '@emotion/styled';

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    error?: boolean;
    format?: InputFormatter<string>;
    icon?: React.ReactNode;
    showBorder?: boolean;
};

export default function TextArea(props: TextAreaProps): JSX.Element {
    const { icon, error, onChange, value, showBorder, ...remainingProps } = props;
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        onChange?.(event);
    };

    return (
        <TextAreaContainer className={'text-area-container'}>
            <StyledTextArea
                icon={!!icon || !!error}
                error={!!error}
                showBorder={showBorder}
                onChange={handleChange}
                value={value}
                {...remainingProps}
            />
            {icon ? <TextAreaIcon className={'text-area-icon'}>{icon}</TextAreaIcon> : null}
        </TextAreaContainer>
    );
}

const TextAreaIcon = styled.span`
    position: absolute;
    right: 0.5rem;
    transform: translateY(-50%);
    top: 70%;
`;

export const TextAreaContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    //padding-right: 1rem;
    gap: 8px;
`;

export const StyledTextArea = styled.textarea<{
    error?: boolean;
    icon?: boolean;
    showBorder?: boolean;
}>`
    box-sizing: border-box;
    resize: none;
    background: none;
    gap: 10px;
    height: 80px;
    left: 20px;
    top: 20px;
    font-size: ${font.size.medium};
    line-height: ${font.lineHeight.medium};
    font-weight: ${font.weight.regular};
    font-family: Roboto, Arial, sans-serif;
    border-radius: ${border.radius.medium};
    border: ${(props) => (props.showBorder ? `${border.width.normal} solid` : 'none')};
    border-color: ${(props) => (props.error ? color.redLight : color.grey)};
    color: ${color.linkBlack};
    padding: 0.75rem ${(props) => (props.icon ? '3rem' : '1rem')} 0.75rem 1rem;
    outline: none;
    margin: 0;

    &.inline {
        min-width: 0;
        border: none;
        padding: 0;
        margin: 0 1rem;
    }

    &[readonly] {
        background-color: ${color.white};
        border-color: ${color.grey};
    }

    &:focus {
        outline: none;
        border-color: ${(props) => (props.error ? color.redLight : color.greyDark)};
    }

    &::placeholder {
        color: ${color.grey};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill::first-line {
        font-size: ${font.size.medium};
        line-height: ${font.lineHeight.medium};
        font-weight: ${font.weight.regular};
    }
`;
