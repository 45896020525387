import { EMPTY_USER_INPUT, UserCreateSchema } from '~schema/user';
import { FormProvider, useForm } from 'react-hook-form';
import { safeSubmit } from '~utils/form';
import { useNavigate } from 'react-router-dom';
import { useUserCreateMutation } from '@makespace/graphql-generated/webapp';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo } from 'react';
import routePaths, { EAppRoutes, fullPath } from '~constants/routePaths';
import type { FormProviderProps } from '~types/forms';
import type { GiUserCreate } from '@makespace/graphql-generated/webapp';
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';

type UserCreateFormProviderProps = FormProviderProps & {
    onCreate?: (userId: string) => void;
};

export default function UserCreateFormProvider(
    props: UserCreateFormProviderProps,
): JSX.Element | null {
    const { children, formRef, onCreate } = props;

    const [userCreate] = useUserCreateMutation({
        refetchQueries: ['Users'],
    });
    const navigate = useNavigate();

    const defaultValues: GiUserCreate = useMemo(() => EMPTY_USER_INPUT, []);

    const resolver = useMemo(() => yupResolver(UserCreateSchema, { abortEarly: false }), []);

    const formMethods = useForm<GiUserCreate>({
        criteriaMode: 'all',
        mode: 'all',
        defaultValues,
        resolver,
    });

    const { reset, handleSubmit } = formMethods;

    const onReset = useCallback(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const onSubmit: SubmitHandler<GiUserCreate> = async (data) => {
        const userInput = data;
        try {
            const { data } = await userCreate({
                variables: {
                    input: userInput,
                },
                refetchQueries: ['Users'],
            });

            const userId = data?.user?._id ?? 'userCreate._id-missing';
            if (onCreate) onCreate(userId);
            else
                navigate(
                    fullPath(routePaths[EAppRoutes.USERS], 'user', {
                        userId,
                    }),
                );
        } catch (e) {
            console.error(e as Error);
        }
    };

    const onError: SubmitErrorHandler<GiUserCreate> = (errors) => {
        console.error('UserEditFormProvider:onError', errors);
    };

    useEffect(() => {
        reset(defaultValues, { keepDirty: true });
    }, [defaultValues, reset]);

    return (
        <FormProvider {...formMethods}>
            <form
                id={'user-create-form'}
                ref={formRef}
                onReset={onReset}
                onSubmit={safeSubmit(handleSubmit(onSubmit, onError))}>
                {children}
            </form>
        </FormProvider>
    );
}
