import { Outlet } from 'react-router-dom';
import { space } from '~styles/styles';
import AppNavigation from '~components/navigation/AppNavigation';
import Footer from '~components/navigation/Footer';
import React from 'react';
import UserNavigation from '~components/navigation/UserNavigation';
import styled from '@emotion/styled';

export default function LayoutDefault(): JSX.Element {
    return (
        <Layout>
            <Main>
                <AppNavigation />
                <Page>
                    <UserNavigation />
                    <Content>
                        <Outlet />
                    </Content>
                </Page>
            </Main>
            <Footer />
        </Layout>
    );
}

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`;

const Main = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: 0;
    flex: 1 1;
    min-height: 99vh;
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${space.gap.normal};
    padding: ${space.padding.normal} ${space.padding.extraLarge} ${space.padding.large}
        ${space.padding.extraLarge};
    flex-grow: 1;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${space.gap.medium};
    padding: ${space.padding.normal} 0;
    flex-grow: 1;
`;
