import { Ei18nLanguage } from '~i18n';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';

export type FlagProps = {
    className?: string;
    language: Ei18nLanguage;
};

export const i18nLanguageToFlag = {
    [Ei18nLanguage.EN]: new URL('~assets/flags/flag.gb.svg', import.meta.url),
    [Ei18nLanguage.TH]: new URL('~assets/flags/flag.th.svg', import.meta.url),
};

export default function Flag(props: FlagProps): JSX.Element {
    const { language, className } = props;
    const flagUrl = useMemo(() => i18nLanguageToFlag[language].toString(), [language]);

    return (
        <StyledFlag className={className}>
            <img src={flagUrl} alt={language} />
        </StyledFlag>
    );
}

const StyledFlag = styled.span`
    color: inherit;
    font-size: 0;
    line-height: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & img {
        width: 1rem;
        height: 1rem;
    }

    & svg {
        width: 1rem;
        height: auto;
    }
`;
