import { useLanguage } from '~context/Language';
import { useMemo } from 'react';
import enCountries from 'i18n-iso-countries/langs/en.json';
import i18nIsoCountries from 'i18n-iso-countries';
import type { SelectOption } from '~types/options';
import type { Space } from '@makespace/graphql-generated/generated/webapp/schemas';

export type UseCountriesProps = {
    filter?: (space: Space) => boolean;
};

export type UseCountriesResponse = {
    // countries: unknown[];
    countryOptions: SelectOption<string>[];
};

i18nIsoCountries.registerLocale(enCountries);

export const useCountries = (props: UseCountriesProps): UseCountriesResponse => {
    const { filter } = props;
    const { translate } = useLanguage();

    const countries = useMemo(
        () => i18nIsoCountries.getNames('en', { select: 'official' }) ?? {} ?? {},
        [],
    );
    const countryOptions: SelectOption<string>[] = useMemo(
        () => Object.entries(countries).map(([key, value]) => ({ value: key, label: value })) ?? [],
        [countries],
    );

    return {
        countryOptions,
        // countries,
    };
};
