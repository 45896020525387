import { CalendarProvider } from '~context/Calendar';
import { CustomEventTitle } from '~components/calendar/CalendarBigComponents';
import { EBookingAvailability, useUserQuery } from '@makespace/graphql-generated/webapp';
import { EFormControlVariant } from '~components/form/FormControl';
import { EModalReturn } from '~constants/modal';
import {
    addDays,
    addMilliseconds,
    differenceInMilliseconds,
    startOfDay,
    subMilliseconds,
} from 'date-fns';
import { createTimeRangeSelectValues, replaceDateTime } from '~utils/dateTime';
import { font } from '~styles/styles';
import { useBookings } from '~hooks/useBookings';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSpaceTypes } from '~hooks/useSpaceTypes';
import { useSpaces } from '~hooks/useSpaces';
import BookingAvailabilityDisplay from '~components/schema/bookings/BookingAvailabilityDisplay';
import BookingItem, { EBookingItemVariant } from '~components/schema/bookings/BookingItem';
import Button from '~components/clickables/Button';
import CalendarBasic from '~components/calendar/CalendarBasic';
// import CalendarBig, { ECalendarBigVariant } from '~components/calendar/CalendarBig';
import Columns, { Column, EColumnSpacing, EColumnsVariant } from '~components/layouts/Columns';
import ControlledTextArea from '~components/inputs/ControlledTextArea';
import ControlledUserSelect from '~components/inputs/ControlledUserSelect';
import Divider from '~components/Divider';
import ErrorAlert from '~components/info/ErrorAlert';
import FormActions from '~components/form/FormActions';
import FormRow from '~components/form/FormRow';
import IconChevronRight from '~components/icons/IconChevronRight';
import ParamSelect from '~components/inputs/ParamInputs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TimeRangeSelect from '~components/form/TimeRangeSelect';
import styled from '@emotion/styled';
import useBookingAvailability from '~hooks/useBookingAvailability';
import useBookingChoices from '~hooks/useBookingChoices';
import useQueryParams from '~hooks/useQueryParams';
import type { Booking, BookingAvailability } from '@makespace/graphql-generated/webapp';
import type { BookingCreateUpdateForm } from '~schema/booking';
import type { Event, SlotInfo } from 'react-big-calendar';
import type { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import type { TimeArray } from '~components/form/TimeSelector';
import type { TimeRangeSelectValue } from '~components/form/TimeRangeSelect';
import type { UseBookingsProps } from '~hooks/useBookings';
import type { Value } from 'react-calendar/src/shared/types';
//
// const DAY_NUMBER_TO_DAY: EBookingDayOfWeek[] = [
//     EBookingDayOfWeek.Monday,
//     EBookingDayOfWeek.Tuesday,
//     EBookingDayOfWeek.Wednesday,
//     EBookingDayOfWeek.Thursday,
//     EBookingDayOfWeek.Friday,
//     EBookingDayOfWeek.Saturday,
//     EBookingDayOfWeek.Sunday,
// ];
//
// enum EDisplayLevel {
//     SPACE = 'SPACE',
//     SPACE_TYPE = 'SPACE_TYPE',
//     SPACE_TYPES = 'SPACE_TYPES',
//     VARIANT = 'VARIANT',
// }

type BookingCreateUpdateProps = {
    originalBooking?: Booking;
};

export default function BookingCreateUpdate(props: BookingCreateUpdateProps): JSX.Element {
    const { originalBooking } = props;
    const { updateQueryParams } = useQueryParams();
    const { control, watch, setValue, getValues, formState } =
        useFormContext<BookingCreateUpdateForm>();
    const { isSubmitting, isDirty, isValid } = formState;
    const {
        checkBookingPossible,
        error: availabilityError,
        loading: availabilityLoading,
    } = useBookingAvailability({});
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    const [availability, setAvailability] = useState<BookingAvailability | null>(null);

    const formData = watch();

    const minDate = startOfDay(new Date());
    const currentCalendarDate = useMemo(
        () =>
            formData.dateFrom ? startOfDay(new Date(formData.dateFrom)) : startOfDay(new Date()),
        [formData.dateFrom],
    );

    const { data: { user } = {} } = useUserQuery({
        fetchPolicy: 'cache-first',
        variables: {
            id: formData.user ?? 'missing-user-id',
        },
        skip: !formData.user?.length,
    });

    const bookingsProps: UseBookingsProps = useMemo(() => {
        return {
            dateFrom: startOfDay(new Date(formData.dateFrom)).toISOString(),
            dateTo: startOfDay(addDays(new Date(formData.dateTo), 1)).toISOString(),
            space: formData.space ?? undefined,
            spaceType: formData.space ? undefined : formData.spaceType,
            spaceTypeVariant: formData.space ? undefined : formData.spaceTypeVariant,
        };
    }, [
        formData.dateFrom,
        formData.dateTo,
        formData.space,
        formData.spaceType,
        formData.spaceTypeVariant,
    ]);

    const bookingChoiceProps = useMemo(() => {
        return {
            date: currentCalendarDate.toISOString(),
            spaceId: formData.space ?? undefined,
            spaceTypeId: formData.spaceType,
            spaceTypeVariantId: formData.spaceTypeVariant,
            userCategory: user?.category?._id,
        };
    }, [
        currentCalendarDate,
        formData.space,
        formData.spaceType,
        formData.spaceTypeVariant,
        user?.category?._id,
    ]);

    const { bookingEvents } = useBookings(bookingsProps);
    const { bookingChoices } = useBookingChoices(bookingChoiceProps);

    const {
        fields: notes,
        update: updateNotes,
        remove: removeNote,
    } = useFieldArray({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name: 'notes',
        control,
    });

    const { spaceTypes, spaceTypeOptions } = useSpaceTypes({});

    const variantOptions = useMemo(() => {
        return formData.spaceType
            ? spaceTypes
                  ?.find((t) => t?._id === formData.spaceType)
                  ?.variants?.map((v) => ({ label: v.name, value: v._id })) ?? []
            : [];
    }, [formData.spaceType, spaceTypes]);

    const variantSpaceIds = useMemo(() => {
        return (
            spaceTypes
                ?.find((t) => t?._id === formData.spaceType)
                ?.variants?.find((v) => v._id === formData.spaceTypeVariant)
                ?.spaces?.map((s) => s._id) ?? []
        );
    }, [formData.spaceType, spaceTypes, formData.spaceTypeVariant]);

    const { spaceOptions } = useSpaces({ filter: (space) => variantSpaceIds.includes(space._id) });

    const selectedSpaceType = useMemo(
        () =>
            formData.spaceType ? spaceTypes?.find((s) => s._id === formData.spaceType) : undefined,
        [formData.spaceType, spaceTypes],
    );

    const selectedSpaceTypeVariant = useMemo(
        () =>
            selectedSpaceType && formData.spaceTypeVariant
                ? selectedSpaceType.variants?.find((v) => v._id === formData.spaceTypeVariant)
                : undefined,
        [selectedSpaceType, formData.spaceTypeVariant],
    );

    const selectedSpace = useMemo(
        () =>
            selectedSpaceTypeVariant && formData.space
                ? selectedSpaceTypeVariant.spaces?.find((s) => s._id === formData.space)
                : undefined,
        [formData.space, selectedSpaceTypeVariant],
    );

    useEffect(() => {
        if (
            selectedSpaceType &&
            formData.spaceTypeVariant &&
            !selectedSpaceType.variants?.find((v) => v._id === formData.spaceTypeVariant)
        ) {
            setValue('spaceTypeVariant', '', { shouldDirty: true });
            setValue('space', '', { shouldDirty: true });
        }
    }, [setValue, selectedSpaceType, formData.spaceTypeVariant]);

    useEffect(() => {
        if (
            selectedSpaceTypeVariant &&
            formData.space &&
            !selectedSpaceTypeVariant.spaces?.find((s) => s._id === formData.space)
        )
            setValue('space', '', { shouldDirty: true });
    }, [setValue, selectedSpaceTypeVariant, formData.space]);

    useEffect(() => {
        setValue(
            'name',
            `${user ? `${user?.firstName} ${user?.lastName} ` : ''}(${
                selectedSpace?.name ??
                `${selectedSpaceType?.name} ${selectedSpaceTypeVariant?.name}`
            })`,
        );
    }, [selectedSpaceType, selectedSpaceTypeVariant, selectedSpace, user, setValue]);

    const onSelectSlot = useCallback(
        (slotInfo: SlotInfo) => {
            setValue('dateFrom', slotInfo.start.toISOString());
            setValue('dateTo', slotInfo.end.toISOString());
        },
        [setValue],
    );

    const newEvent: Event = useMemo(() => {
        return {
            title: (
                <CustomEventTitle
                    user={user}
                    space={selectedSpace}
                    spaceTypeVariant={selectedSpaceTypeVariant}
                    spaceType={selectedSpaceType}
                />
            ),
            start: new Date(formData.dateFrom),
            end: new Date(formData.dateTo),
            resource: {
                spaceId: formData.space,
                spaceTypeId: formData.spaceType,
                spaceTypeVariantId: formData.spaceTypeVariant,
                highlight: true,
                availability: availability?.availability,
            },
        };
    }, [
        user,
        selectedSpace,
        selectedSpaceTypeVariant,
        selectedSpaceType,
        formData.dateFrom,
        formData.dateTo,
        formData.space,
        formData.spaceType,
        formData.spaceTypeVariant,
        availability?.availability,
    ]);

    const allEvents = useMemo(() => [newEvent], [bookingEvents, newEvent]);

    const changeEvent = useCallback(
        (event: EventInteractionArgs<Event>) => {
            const start = event.start instanceof Date ? event.start : new Date(event.start);
            const end = event.end instanceof Date ? event.end : new Date(event.end);
            setValue('dateFrom', start.toISOString());
            setValue('dateTo', end.toISOString());
        },
        [setValue],
    );

    const changeDate = useCallback(
        (value: Value) => {
            const newDateValue = Array.isArray(value) ? value[0] : value;
            if (!newDateValue) return;

            const add = newDateValue > currentCalendarDate;
            const difference = add
                ? differenceInMilliseconds(newDateValue, currentCalendarDate)
                : differenceInMilliseconds(currentCalendarDate, newDateValue);

            const newDateFrom = (add ? addMilliseconds : subMilliseconds)(
                new Date(getValues('dateFrom')),
                difference,
            ).toISOString();

            const newDateTo = (add ? addMilliseconds : subMilliseconds)(
                new Date(getValues('dateTo')),
                difference,
            ).toISOString();

            updateQueryParams({
                dateFrom: newDateFrom,
                dateTo: newDateTo,
            });
        },
        [currentCalendarDate, getValues, updateQueryParams],
    );

    const changeTime = useCallback(
        (timeRange: TimeRangeSelectValue) => {
            const newDateFrom = timeRange[0]
                ? replaceDateTime(formData.dateFrom, timeRange[0]).toISOString()
                : formData.dateFrom;
            const newDateTo = timeRange[1]
                ? replaceDateTime(formData.dateTo, timeRange[1]).toISOString()
                : formData.dateTo;

            updateQueryParams({
                dateFrom: newDateFrom,
                dateTo: newDateTo,
            });
        },
        [formData.dateFrom, formData.dateTo, updateQueryParams],
    );

    const checkAvailability = useCallback(async () => {
        const availibility = await checkBookingPossible({
            space: formData.space,
            spaceType: formData.spaceType,
            spaceTypeVariant: formData.spaceTypeVariant,
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
            user: formData.user,
        });
        setAvailability(availibility);
    }, [
        checkBookingPossible,
        formData.dateFrom,
        formData.dateTo,
        formData.space,
        formData.spaceType,
        formData.spaceTypeVariant,
        formData.user,
    ]);

    useEffect(() => {
        if (formData.spaceType) void checkAvailability();
    }, [checkAvailability, formData.spaceType]);

    const activeUserCategory = user?.category?._id;

    const activeBookingOption = useMemo(() => {
        if (activeUserCategory && selectedSpaceTypeVariant)
            return selectedSpaceTypeVariant.bookingConfigurations?.find((config) => {
                if (config.userCategories?.find((category) => category._id === activeUserCategory))
                    return config.bookingOptions.find(() => {
                        // what am i looking for here?
                    });
            });
    }, [activeUserCategory, selectedSpaceTypeVariant]);

    useEffect(() => {
        if (
            bookingChoices?.length &&
            !formState.dirtyFields.dateFrom &&
            !formState.dirtyFields.dateTo
        ) {
            console.log('bookingChoices need to be handled better', bookingChoices);
            setValue('dateFrom', bookingChoices[0].dateFrom);
            setValue('dateTo', bookingChoices[0].dateTo);
        }
    }, [bookingChoices, formState.dirtyFields.dateFrom, formState.dirtyFields.dateTo, setValue]);

    const [minTime, maxTime] = useMemo(() => {
        return [[0, 0] as TimeArray, [23, 30] as TimeArray];
    }, []);

    const timeRange = useMemo(
        () => createTimeRangeSelectValues(formData.dateFrom, formData.dateTo),
        [formData.dateFrom, formData.dateTo],
    );

    const bookingUnavailable = useMemo(
        () => availability?.availability === EBookingAvailability.Unavailable,
        [availability],
    );

    return (
        <CalendarProvider events={bookingEvents} defaultView={'day'}>
            <Columns variant={EColumnsVariant.THREE_QUARTERS_ONE_QUARTER}>
                <Column>
                    <InputSection>
                        <div className={'inputs'}>
                            <FormRow variant={'column'}>
                                <ControlledUserSelect
                                    control={control}
                                    name={'user'}
                                    multiple={false}
                                    placeholder={'User'}
                                    label={'User'}
                                    modalReturn={EModalReturn.PARAMS}
                                    formControlProps={{
                                        variant: EFormControlVariant.ROW,
                                    }}
                                />
                                <ParamSelect
                                    options={spaceTypeOptions}
                                    value={formData.spaceType}
                                    name={'spaceType'}
                                    placeholder={'Space Type'}
                                    removeParams={['spaceTypeVariant', 'space']}
                                />
                                <ParamSelect
                                    options={variantOptions}
                                    value={formData.spaceTypeVariant}
                                    name={'spaceTypeVariant'}
                                    placeholder={'Space Type Variant'}
                                    disabled={!formData.spaceType}
                                    removeParams={['space']}
                                />
                                <ParamSelect
                                    options={spaceOptions}
                                    value={formData.space ?? undefined}
                                    name={'space'}
                                    multiple={false}
                                    disabled={!formData.spaceTypeVariant}
                                    placeholder={'Space'}
                                />
                            </FormRow>
                        </div>
                        <CalendarBasicWrapper>
                            <StyledCalendarBasic
                                minDate={minDate}
                                value={currentCalendarDate}
                                onChange={changeDate}
                            />
                            <TimeRangeSelect
                                minutesInterval={15}
                                minTime={minTime}
                                maxTime={maxTime}
                                onChange={changeTime}
                                value={timeRange}
                            />
                        </CalendarBasicWrapper>
                    </InputSection>
                    <Divider />
                    <FormRow variant={'column'}>
                        <ControlledTextArea
                            control={control}
                            name={`description`}
                            label={'Description'}
                        />
                        {notes?.map((note, index) => (
                            <ControlledTextArea
                                key={index}
                                control={control}
                                name={`notes.${index}`}
                                label={'Note'}
                            />
                        ))}
                    </FormRow>
                    <BookingSection>
                        {originalBooking ? (
                            <>
                                <BookingItem
                                    booking={{ ...originalBooking, user: originalBooking.user._id }}
                                    variant={EBookingItemVariant.ORIGINAL}
                                />
                                <IconChevronRight />
                            </>
                        ) : null}
                        <BookingItem
                            booking={{
                                ...getValues(),
                                _id: originalBooking?._id ?? undefined,
                                bookingNumber: originalBooking?.bookingNumber ?? undefined,
                                createdAt: new Date().toISOString(),
                                updatedAt: new Date().toISOString(),
                            }}
                            availability={availability ?? undefined}
                            variant={EBookingItemVariant.NEW}
                        />
                    </BookingSection>
                    <Divider />
                    {availabilityError ? <ErrorAlert error={availabilityError} /> : null}
                    {availabilityLoading ? <p>Loading...</p> : null}
                    <Divider />
                    <FormActions
                        secondary={
                            availability ? (
                                <BookingAvailabilityDisplay availability={availability} />
                            ) : undefined
                        }>
                        <Button
                            type={'submit'}
                            disabled={bookingUnavailable || !isValid || isSubmitting}>
                            Save
                        </Button>
                    </FormActions>
                </Column>
                {/*<Column scrollable={false} spacing={EColumnSpacing.NONE}>*/}
                {/*<CalendarBig*/}
                {/*    events={allEvents}*/}
                {/*    backgroundEvents={bookingEvents}*/}
                {/*    // onSelectEvent={handleSelectEvent}*/}
                {/*    onSelectSlot={onSelectSlot}*/}
                {/*    onEventResize={changeEvent}*/}
                {/*    onEventDrop={changeEvent}*/}
                {/*    selectable*/}
                {/*    resizable={true}*/}
                {/*    step={15}*/}
                {/*    timeslots={4}*/}
                {/*    defaultDate={currentCalendarDate}*/}
                {/*    date={currentCalendarDate}*/}
                {/*    enableAutoScroll={true}*/}
                {/*    toolbar={false}*/}
                {/*    views={['day']}*/}
                {/*    variant={ECalendarBigVariant.INLINE}*/}
                {/*/>*/}
                {/*</Column>*/}
            </Columns>
        </CalendarProvider>
    );
}

const InputSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    gap: 1rem;

    & .inputs {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
`;

const BookingSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
`;

const CalendarColumn = styled(Column)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
`;

// const StyledCalendar = styled(CalendarBig)`
//     border: 1px solid red;
//     flex: 1;
// `;

const DateDisplay = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: ${font.size.normal};
    font-weight: ${font.weight.bold};
    flex: 0;
`;

const CalendarBasicWrapper = styled.div`
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: 1rem;
`;

const StyledCalendarBasic = styled(CalendarBasic)`
    padding: 0;
    flex: 0;
`;
