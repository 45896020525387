import { ESpacePrefix } from '~types/calendar';
import { generateRgbaContrastString, generateRgbaString } from '~utils/color';
import { useSpaceTypes } from '~hooks/useSpaceTypes';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';

type SpaceStyleRule = { backgroundColor: string; borderColor: string; color: string; id: string };

export interface IStylesContext {
    getStyleRule: (prefix: ESpacePrefix, id: string) => SpaceStyleRule | undefined;
    styleRules: SpaceStyleRule[];
}

const StylesContext = createContext<IStylesContext>({
    styleRules: [],
    getStyleRule: () => {
        throw new Error('StylesContext not initialized');
    },
});

StylesContext.displayName = 'styles';

export type StylesProviderProps = {
    children: React.ReactNode | React.ReactNode[];
};

function StylesProvider(props: StylesProviderProps) {
    const { children } = props;
    const { spaceTypes } = useSpaceTypes({});

    const styleRules: SpaceStyleRule[] = useMemo(() => {
        const rules = spaceTypes
            ?.map((spaceType) => {
                const spaceTypeRgba = spaceType?.theme?.colorPrimary ?? { r: 0, g: 0, b: 0, a: 1 };
                return [
                    {
                        borderColor: generateRgbaContrastString(spaceTypeRgba),
                        backgroundColor: generateRgbaString(spaceTypeRgba),
                        color: generateRgbaContrastString(spaceTypeRgba),
                        id: `${ESpacePrefix.SPACE_TYPE}-${spaceType._id}`,
                    },
                    ...(
                        spaceType.variants?.map((variant, index) => {
                            const spaceTypeVariantRgba =
                                variant?.theme?.colorPrimary ?? spaceTypeRgba;
                            return [
                                {
                                    borderColor: generateRgbaContrastString(spaceTypeVariantRgba),
                                    backgroundColor: generateRgbaString(spaceTypeVariantRgba),
                                    color: generateRgbaContrastString(spaceTypeVariantRgba),
                                    id: `${ESpacePrefix.SPACE_TYPE_VARIANT}-${variant._id}`,
                                },
                                ...(variant.spaces?.map((space) => ({
                                    borderColor: generateRgbaContrastString(spaceTypeVariantRgba),
                                    backgroundColor: generateRgbaString(spaceTypeVariantRgba),
                                    color: generateRgbaContrastString(spaceTypeVariantRgba),
                                    id: `${ESpacePrefix.SPACE}-${space._id}`,
                                })) ?? []),
                            ];
                        }) ?? []
                    ).flat(),
                ];
            })
            .flat();
        return rules ?? [];
    }, [spaceTypes]);

    const getStyleRule = useCallback(
        (prefix: ESpacePrefix, id: string) => {
            return styleRules.find((s) => s.id === `${prefix}-${id}`);
        },
        [styleRules],
    );

    const context: IStylesContext = useMemo(
        () => ({
            styleRules,
            getStyleRule,
        }),
        [getStyleRule, styleRules],
    );

    return (
        <StylesContext.Provider value={context}>
            <SpaceTypesStyleWrapper styleRules={styleRules}>{children}</SpaceTypesStyleWrapper>
        </StylesContext.Provider>
    );
}

function useStyles(): IStylesContext {
    const context = useContext(StylesContext);
    if (!context) throw new Error('useStyles must be used within StylesProvider');
    return context;
}

export { StylesContext, StylesProvider, useStyles };

const SpaceTypesStyleWrapper = styled.div<{
    styleRules?: SpaceStyleRule[];
}>`
    ${(props) =>
        props?.styleRules?.map(
            (rule) =>
                `.${rule.id} { color: ${rule.color}; background-color: ${rule.backgroundColor}; border-color: rgba(0,0,0,0.5); }`,
        ) ?? ''}
`;
