import { border, color, font } from '~styles/styles';
import { formatDateTime } from '~utils/formatters';
import { spaceClassName, spaceTypeClassName, spaceTypeVariantClassName } from '~utils/css';
import React, { createContext, useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { CalendarEventResource } from '~types/calendar';
import type { Event, View } from 'react-big-calendar';

export interface ICalendarContext {
    hideBookingInfo: () => void;
    setView: (view: View) => void;
    showBookingInfo: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        eventRef: React.RefObject<HTMLDivElement>,
        calendarEvent: Event,
    ) => void;
    view: View;
}

export type ActiveCalendarInfo = {
    calendarEvent: Event;
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    left: number;
    ref: React.RefObject<HTMLDivElement>;
    top: number;
    view: View;
};

const CalendarContext = createContext<ICalendarContext>({
    view: 'month',
    showBookingInfo: () => {
        throw new Error('CalendarContext not initialized');
    },
    hideBookingInfo: () => {
        throw new Error('CalendarContext not initialized');
    },
    setView: () => {
        throw new Error('CalendarContext not initialized');
    },
});

CalendarContext.displayName = 'calendar';

export type CalendarProviderProps = {
    children: React.ReactNode | React.ReactNode[];
    defaultView?: View;
    events: Event[];
};

function CalendarProvider(props: CalendarProviderProps) {
    const { children, events, defaultView } = props;
    const calendarWrapperRef = React.createRef<HTMLDivElement>();
    const [activeInfo, setActiveInfo] = useState<ActiveCalendarInfo | null>(null);
    const [view, setView] = useState<View>(defaultView ?? 'month');
    const [timer, setTimer] = useState<number | null>(null);

    const showBookingInfo = useCallback(
        (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
            eventRef: React.RefObject<HTMLDivElement>,
            calendarEvent: Event,
        ) => {
            if (timer) window.clearTimeout(timer);
            if (view === 'agenda') return;
            const rect = eventRef?.current?.getBoundingClientRect();
            const top = event.clientY;
            const left = event.clientX;

            console.log('top', top, 'left', left, calendarWrapperRef.current?.scrollTop);

            // switch (view) {
            //     case 'month':
            //         top = (rect?.top ?? 0) + (rect?.height ?? 0);
            //         left = (rect?.left ?? 0) + 4;
            //         break;
            //     case 'week':
            //     case 'day':
            //         left = (rect?.left ?? 0) + 8;
            //         break;
            // }

            setTimer(
                window.setTimeout(() => {
                    setActiveInfo({
                        event,
                        calendarEvent,
                        left: left,
                        top: top,
                        ref: eventRef,
                        view,
                    });
                }, 300),
            );
        },
        [timer, view, calendarWrapperRef],
    );

    const hideBookingInfo = useCallback(() => {
        window.clearTimeout(timer ?? undefined);
        setActiveInfo(null);
    }, [timer]);

    const context: ICalendarContext = {
        showBookingInfo,
        hideBookingInfo,
        view,
        setView,
    };

    return (
        <CalendarContext.Provider value={context}>
            <CalendarWrapper ref={calendarWrapperRef}>
                {children}
                {activeInfo ? <CalendarEventInfo {...activeInfo} /> : null}
            </CalendarWrapper>
        </CalendarContext.Provider>
    );
}

function useCalendar(): ICalendarContext {
    const context = useContext(CalendarContext);
    if (!context) throw new Error('useCalendar must be used within CalendarProvider');
    return context;
}

export { CalendarContext, CalendarProvider, useCalendar };

function CalendarEventInfo(props: ActiveCalendarInfo): JSX.Element {
    const { top, ref, left, event, view, calendarEvent } = props;
    const { showBookingInfo, hideBookingInfo } = useCalendar();

    const classNames = [
        spaceClassName((calendarEvent.resource as CalendarEventResource).spaceId),
        spaceTypeVariantClassName(
            (calendarEvent.resource as CalendarEventResource).spaceTypeVariantId,
        ),
        spaceTypeClassName((calendarEvent.resource as CalendarEventResource).spaceTypeId),
    ];

    return (
        <CalendarEventInfoContainer
            style={{ top, left }}
            onMouseEnter={() => showBookingInfo(event, ref, calendarEvent)}
            onMouseLeave={() => hideBookingInfo()}
            className={view}>
            <span className={clsx(classNames, 'arrow', view)} />
            <CalendarEventInfoContent className={clsx(classNames)}>
                <div className={clsx(classNames)}>
                    <span className={'title'}>{calendarEvent.title}</span>
                    <span>{formatDateTime(calendarEvent.start)}</span>
                    <span>{formatDateTime(calendarEvent.end)}</span>
                </div>
            </CalendarEventInfoContent>
        </CalendarEventInfoContainer>
    );
}

const CalendarEventInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: transparent !important;
    position: fixed;
    z-index: 999;

    &.week,
    &.day {
        flex-direction: column;
    }

    & .arrow {
        width: 0.75rem;
        height: 0.75rem;
        margin-left: 1rem;
        margin-bottom: -6px;
        transform: rotate(45deg);
        //box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
        z-index: 1;
        border-radius: ${border.radius.small};
        //background: white !important;
        border: 1px solid;
        border-width: 1px 0 0 1px;
    }
`;

const CalendarEventInfoContent = styled.div`
    font-size: ${font.size.small};
    //background: white !important;
    color: ${color.black};
    border-radius: ${border.radius.small};
    padding: 0.5rem 4px 4px 4px;
    min-width: 200px;
    z-index: 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    border: 1px solid;

    & > div {
        background-color: rgba(255, 255, 255, 0.85) !important;
        padding: 0.5rem;
        border-radius: ${border.radius.small};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.5rem;
        color: ${color.black} !important;
    }

    & .title {
        font-weight: ${font.weight.bold};
    }
`;

const CalendarWrapper = styled.div`
    position: relative;
`;
