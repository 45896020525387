import { EIconSize } from '~components/icons/Icon';
import {
    RelativeOverlayContainer,
    RelativeOverlayContent,
    useRelativeOverlay,
} from '~components/interactions/RelativeOverlay';
import { space } from '~styles/styles';
import { useAuthentication } from '~context/Authentication';
import IconUser from '~components/icons/IconUser';
import Menu, { MenuHeader, MenuItem } from '~components/clickables/Menu';
import React from 'react';
import styled from '@emotion/styled';

export default function UserDisplay(): JSX.Element {
    const { user, refresh, logout } = useAuthentication();
    const { open, showOverlay, hideOverlay, containerRef, contentRef } = useRelativeOverlay();

    return (
        <RelativeOverlayContainer onClick={showOverlay} ref={containerRef}>
            <ActiveUser>
                {`${user?.firstName} ${user?.lastName}`}
                <IconUser size={EIconSize.TRIM} />
            </ActiveUser>
            <UserMenu
                forwardRef={contentRef}
                open={open}
                onClose={hideOverlay}
                onClick={hideOverlay}>
                <Menu>
                    <MenuHeader>
                        <p>{user?.email}</p>
                        <p>{user?.roles?.join(',')}</p>
                    </MenuHeader>
                    <MenuItem>Profile</MenuItem>
                    <MenuItem onClick={() => void refresh()}>Refresh</MenuItem>
                    <MenuItem onClick={() => void logout()}>Logout</MenuItem>
                </Menu>
            </UserMenu>
        </RelativeOverlayContainer>
    );
}

const ActiveUser = styled(MenuItem)`
    display: flex;
    flex-direction: row;
    gap: ${space.gap.small};
    padding: 0.5rem 0.75rem;
`;

const UserMenu = styled(RelativeOverlayContent)`
    background: rgba(255, 255, 255, 1);
    left: auto;
    right: 0;
`;
