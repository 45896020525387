import { SelectOption } from '~types/options';
import { SpaceType } from '@makespace/graphql-generated/generated/webapp/schemas';
import { useLanguage } from '~context/Language';
import { useMemo } from 'react';
import { useSpaceTypesQuery } from '@makespace/graphql-generated/generated/webapp/hooks';

export type UseSpaceTypesProps = {
    filter?: (space: SpaceType) => boolean;
};

export type UseSpaceTypesResponse = {
    spaceTypeOptions: SelectOption<string>[];
    spaceTypes: SpaceType[] | undefined;
};

export const useSpaceTypes = (props: UseSpaceTypesProps): UseSpaceTypesResponse => {
    const { filter } = props;
    const { translate } = useLanguage();
    const { data } = useSpaceTypesQuery();

    const spaceTypes = useMemo(
        () => data?.spaceTypes?.filter(filter ?? (() => true)) ?? [],
        [filter, data?.spaceTypes],
    );

    const spaceTypeOptions: SelectOption<string>[] = useMemo(
        () =>
            spaceTypes
                ?.filter(filter ?? (() => true))
                .map((t) => ({ value: t._id, label: translate(t)?.name ?? t.name })) ?? [],
        [filter, spaceTypes, translate],
    );

    return {
        spaceTypeOptions,
        spaceTypes,
    };
};
