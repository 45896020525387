import { color, space } from '~styles/styles';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum EDividerSpacing {
    LARGE = 'large',
    MEDIUM = 'medium',
    NONE = 'none',
    NORMAL = 'normal',
}

export enum EDividerOrientation {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

export enum EDividerColor {
    DARK = 'dark',
    LIGHT = 'light',
    NORMAL = 'normal',
}

export type DividerProps = {
    className?: string;
    color?: EDividerColor;
    orientation?: EDividerOrientation;
    spacing?: EDividerSpacing;
};

export default function Divider(props: DividerProps): JSX.Element {
    const {
        spacing = EDividerSpacing.NORMAL,
        color = EDividerColor.NORMAL,
        orientation = EDividerOrientation.HORIZONTAL,
        className,
    } = props;
    return <DividerContainer className={clsx(spacing, color, orientation, className)} />;
}

const DividerContainer = styled.hr`
    margin: ${space.margin.normal};
    width: 100%;
    height: 1px;
    background-color: ${color.grey};
    border: none;

    &.${EDividerOrientation.HORIZONTAL} {
        &.${EDividerSpacing.NONE} {
            margin: 0;
        }

        &.${EDividerSpacing.MEDIUM} {
            margin: ${space.margin.medium};
        }
    }

    &.${EDividerOrientation.VERTICAL} {
        width: 1px;
        height: 1rem;
        margin: 0 ${space.padding.normal};

        &.${EDividerSpacing.NONE} {
            margin: 0;
        }
    }

    &.${EDividerColor.DARK} {
        background-color: ${color.black}25;
    }
`;
