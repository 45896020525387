import { EIconColor, EIconSize } from '~components/icons/Icon';
import { border, color, font } from '~styles/styles';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { ButtonHTMLAttributes } from 'react';
import type { IconProps } from '~components/icons/Icon';

export enum EButtonVariant {
    ICON = 'icon',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TEXT = 'text',
    TOGGLE_ACTIVE = 'toggleActive',
    TOGGLE_INACTIVE = 'toggleInactive',
    TOUCH_ACTIVE = 'touchActive',
    TOUCH_INACTIVE = 'touchInactive',
}

export enum EButtonSize {
    LARGE = 'large',
    NORMAL = 'normal',
    SMALL = 'small',
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
    icon?: React.ReactNode;
    iconEnd?: (props: IconProps) => React.ReactNode;
    iconStart?: (props: IconProps) => React.ReactNode;
    size?: EButtonSize;
    variant?: EButtonVariant;
};

export default function Button(props: ButtonProps): JSX.Element {
    const {
        variant = EButtonVariant.PRIMARY,
        size = EButtonSize.NORMAL,
        type = 'button',
        icon,
        iconStart,
        iconEnd,
        children,
        disabled,
        className,
        ...remainingProps
    } = props;

    const iconProps = useMemo(() => {
        const props: IconProps = {};
        if (!iconStart && !iconEnd) return props;
        switch (size) {
            case EButtonSize.SMALL:
                props.size = EIconSize.TRIM;
                break;
            default:
                props.size = EIconSize.TRIM;
                break;
        }
        props.color = EIconColor.INHERIT;
        return props;
    }, [iconEnd, iconStart, size]);

    return (
        <StyledButton
            className={clsx(variant, size, { withIcon: !!icon, disabled }, className)}
            type={type}
            disabled={disabled}
            {...remainingProps}>
            <>
                {iconStart ? iconStart(iconProps) : null}
                {variant === EButtonVariant.ICON ? (
                    icon
                ) : (
                    <span className={'label'}>{children}</span>
                )}
                {iconEnd ? iconEnd(iconProps) : null}
            </>
        </StyledButton>
    );
}

const StyledButton = styled.button`
    font-size: ${font.size.normal};
    line-height: ${font.lineHeight.normal};
    font-weight: ${font.weight.regular};
    white-space: nowrap;
    padding: 0.875rem;
    text-align: center;
    margin: 0;
    border-radius: ${border.radius.small};
    border: ${border.width.thin} solid;
    border-color: ${color.blue};
    cursor: pointer;

    display: inline-flex;
    align-items: center;
    justify-content: stretch;
    gap: 0.5em;

    transition-property: color, border-color, background-color;
    transition-duration: 0.2s;

    & .label {
        flex: 1;
        text-align: center;
    }

    &:focus {
        outline-color: ${color.greyLight};
    }

    &:disabled {
        background-color: ${color.greyLight};
        border-color: ${color.greyLight};
        color: ${color.grey};
    }

    &.${EButtonVariant.PRIMARY} {
        background-color: ${color.black};
        color: ${color.white};
        &:disabled {
            background-color: ${color.greyLight};
        }
    }

    &.${EButtonVariant.SECONDARY} {
        background-color: ${color.linkWhite};
        border-color: ${color.linkGreyLight};
        color: ${color.blue};
        &:disabled {
        }
    }

    &.${EButtonVariant.TEXT} {
        background-color: transparent;
        border-color: transparent;
        color: ${color.blue};
        &:disabled {
        }
    }

    &.${EButtonVariant.ICON}:not(withIcon) {
        padding: 0;
        border: none;
        background: transparent;

        &:disabled {
        }
    }

    &.withIcon {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        padding-right: 0.5rem;
    }

    &.${EButtonVariant.TOUCH_ACTIVE} {
        background-color: ${color.black};
        color: ${color.white};
        border-color: ${color.black};
        &:disabled {
            color: ${color.grey};
            border-color: ${color.grey};
            background-color: ${color.greyLight};
        }
    }

    &.${EButtonVariant.TOUCH_INACTIVE} {
        background-color: ${color.white};
        color: ${color.black};
        border-color: ${color.grey};
        &:disabled {
            color: ${color.grey};
            border-color: ${color.grey};
            background-color: ${color.greyLight};
        }
    }

    &.${EButtonVariant.TOGGLE_ACTIVE} {
        background-color: ${color.black};
        color: ${color.white};
        border-color: ${color.black};
        &:disabled {
            color: ${color.grey};
            border-color: ${color.grey};
            background-color: ${color.greyLight};
        }
    }

    &.${EButtonVariant.TOGGLE_INACTIVE} {
        background-color: ${color.white};
        color: ${color.black};
        border-color: ${color.grey};
        &:disabled {
            color: ${color.grey};
            border-color: ${color.grey};
            background-color: ${color.greyLight};
        }
        &:hover {
            background-color: ${color.greyLight};
        }
    }

    &.${EButtonSize.SMALL} {
        font-size: ${font.size.small};
        line-height: ${font.lineHeight.small};
        font-weight: ${font.weight.medium};
        padding: 0.5rem;
    }
`;
