import { space } from '~styles/styles';
import { useAuthentication } from '~context/Authentication';
import Divider, { EDividerOrientation, EDividerSpacing } from '~components/Divider';
import LanguageSelector from '~components/LanguageSelector';
import React from 'react';
import UserDisplay from '~components/UserDisplay';
import styled from '@emotion/styled';

export default function UserNavigation(): JSX.Element {
    const { isAuthenticated } = useAuthentication();
    return (
        <UserNavigationContainer>
            <LanguageSelector />
            <Divider orientation={EDividerOrientation.VERTICAL} spacing={EDividerSpacing.NONE} />
            {isAuthenticated ? <UserDisplay /> : null}
        </UserNavigationContainer>
    );
}

const UserNavigationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: ${space.gap.small};
`;
