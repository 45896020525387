import { default as i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '~locales/makespace.cms.en.json';
import th from '~locales/makespace.cms.th.json';

const resources = {
    en: {
        translation: en,
    },
    th: {
        translation: th,
    },
};

export enum Ei18nLanguage {
    EN = 'en',
    TH = 'th',
}

try {
    void i18n.use(initReactI18next).init({
        resources,
        fallbackLng: 'en',
        keySeparator: '.',
        interpolation: { escapeValue: false },
    });
} catch (error) {
    console.error((error as Error).message);
}

export { i18n };
