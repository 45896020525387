import { replaceQueryParams } from '~utils/urls';
import React from 'react';
import Select from '~components/form/Select';
import useQueryParams from '~hooks/useQueryParams';
import type { QueryParams } from '~hooks/useQueryParams';
import type { SelectProps, SelectValue } from '~components/form/Select';

export type ParamInputsProps = SelectProps & {
    param?: string;
    removeParams?: string[] | ((value?: SelectValue) => string[]);
};

export default function ParamSelect(props: ParamInputsProps): JSX.Element {
    const { name, param, removeParams, onChange, ...selectProps } = props;
    const { setQueryParams } = useQueryParams();

    const handleChange = (value?: SelectValue) => {
        if (!!param || !!name) {
            const params = replaceQueryParams(
                { [(param ?? name) as string]: value },
                Array.isArray(removeParams) ? removeParams : removeParams?.(value),
            );
            setQueryParams(params as QueryParams, true);
        }
        onChange?.(value);
    };

    return <Select {...selectProps} onChange={handleChange} />;
}
