import { ETableCellVariant } from '~types/tables';
import { TableCellProps } from '~components/table/TableCell';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import TableControlSearch from '~components/table/TableControlSearch';

export enum ETableControlSortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export type DefaultData = Record<string, string | number | undefined>;

export type TableControlHeadings<TData> = Record<keyof TData, TableControlIdentifier>;

export type TableControlIdentifier = Partial<TableControlHeading>;

export type TableControlHeading = {
    label?: string;
    show?: boolean;
    sortKey?: string;
    sortValue?: (data: string | number) => string | number;
    sortable?: boolean;
    variant?: ETableCellVariant;
};

export type TableControlCells<TData> = Record<keyof TData, TableControlCell<TData>>;

export type TableControlCell<TData> = {
    cellProps?: (data: TData) => Omit<TableCellProps, 'children'>;
    editable?: boolean;
    show?: boolean;
    variant?: ETableCellVariant;
};

export interface IHeadings {
    [key: string]: TableControlHeading;
}

export interface ICells {
    [key: string]: TableControlCell<DefaultData>;
}

type SortCondition = {
    direction: ETableControlSortDirection;
    sortKey: string;
};

export type TableControlContext<TData> = {
    cells: TableControlCells<TData>;
    data: TData[];
    headings: IHeadings;
    removeSorting: (by: SortCondition) => void;
    searchBy: (search: string | undefined) => void;
    searchedBy?: string;
    searchedData?: TData[];
    sortBy: (by: SortCondition) => void;
    sortingBy: SortCondition[];
};

export const TableControlContext = createContext<TableControlContext<DefaultData>>({
    headings: {},
    cells: {},
    data: [],
    sortingBy: [],
    sortBy: () => {
        throw new Error('sortBy must be implemented');
    },
    removeSorting: () => {
        throw new Error('removeSorting must be implemented');
    },
    searchBy: () => {
        throw new Error('searchBy must be implemented');
    },
});

export type TableControlProviderProps<TData> = {
    cells: TableControlCells<TData>;
    children: React.ReactNode;
    data: TData[];
    headings: IHeadings;
    searchable?: boolean;
};

export function TableControlProvider<TData extends DefaultData>(
    props: TableControlProviderProps<TData>,
): JSX.Element {
    const { headings, cells, data, children, searchable } = props;
    const [sortingBy, setSortingBy] = useState<SortCondition[]>([]);
    const [searchedBy, setSearchedBy] = useState<string | undefined>();
    const [searchedData, setSearchedData] = useState<TData[] | []>();

    const sortBy = useCallback((by: SortCondition) => {
        setSortingBy((prev) =>
            prev.find((s) => s.sortKey === by.sortKey)
                ? prev.map((s) =>
                      s.sortKey === by.sortKey
                          ? { sortKey: s.sortKey, direction: by.direction }
                          : s,
                  )
                : [...prev, by],
        );
    }, []);

    const removeSorting = useCallback((sortBy: SortCondition) => {
        setSortingBy((prev) => prev.filter((s) => s.sortKey !== sortBy.sortKey));
    }, []);

    const sortedData = useMemo(() => {
        const sorted = [...data];
        let i = sortingBy.length - 1;
        while (i >= 0) {
            const { sortKey, direction } = sortingBy[i];
            sorted.sort((a, b) => {
                const aVal = a[sortKey];
                const bVal = b[sortKey];
                if (aVal === bVal) return 0;
                if (aVal === undefined) return 1;
                if (bVal === undefined) return -1;
                if (aVal > bVal) return direction === ETableControlSortDirection.ASC ? 1 : -1;
                return direction === ETableControlSortDirection.ASC ? -1 : 1;
            });
            i--;
        }
        return sorted;
    }, [data, sortingBy]);

    const searchBy = useCallback(
        (searchTerm: string | undefined) => {
            setSearchedBy(searchTerm);
            searchTerm?.length
                ? setSearchedData(
                      data?.map((d) => {
                          const hasMatch = Object.values(d).some(
                              (value) =>
                                  value
                                      ?.toString()
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase()),
                          );
                          return hasMatch ? d : { ...d, hidden: true };
                      }),
                  )
                : setSearchedData(undefined);
        },
        [data],
    );

    const context: TableControlContext<TData> = useMemo(
        () => ({
            headings,
            cells,
            data: sortedData,
            sortBy,
            sortingBy,
            removeSorting,
            searchBy,
            searchedData,
            searchedBy,
        }),
        [
            headings,
            cells,
            sortedData,
            sortingBy,
            sortBy,
            removeSorting,
            searchBy,
            searchedData,
            searchedBy,
        ],
    );

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TableControlContext.Provider value={context}>
            {searchable ? <TableControlSearch /> : null}
            {children}
        </TableControlContext.Provider>
    );
}

export function useTableControl<TData extends DefaultData>(): TableControlContext<TData> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const context = useContext<TableControlContext<TData>>(TableControlContext);
    if (!context) throw new Error('TableControlContext must be used within a TableControlProvider');
    return context;
}
