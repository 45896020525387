import { color } from '~styles/styles';
import { useModal } from '~context/Modal';
import { useNavigate } from 'react-router-dom';
import IconCross from '~components/icons/IconCross';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { EModal } from '~constants/modal';

export enum EModalSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
}

type ModalProps = {
    children: React.ReactNode;
    closeUrl?: string;
    header: string | React.ReactNode;
    modal?: EModal;
    onClose?: () => void;
    scrollable?: boolean;
    size?: EModalSize;
};

export default function Modal(props: ModalProps): JSX.Element {
    const {
        header,
        children,
        onClose,
        closeUrl,
        size = EModalSize.MEDIUM,
        modal,
        scrollable = true,
    } = props;
    const navigate = useNavigate();
    const { closeModal } = useModal();

    const close = () => {
        if (closeUrl) navigate(closeUrl);
        else if (modal) closeModal(modal);
        else navigate(-1);
        onClose?.();
    };

    return (
        // <ModalContainer className={clsx(size)}>
        //     <ScrollLock />
        <ModalInner className={clsx(size)}>
            {header ? (
                <ModalHeader>
                    {header}
                    <IconCross onClick={close} />
                </ModalHeader>
            ) : null}
            <ModalContents className={clsx({ scrollable })}>{children}</ModalContents>
        </ModalInner>
        // <ModalClickCatcher onClick={close} />
        // </ModalContainer>
    );
}

const ModalContainer = styled.div`
    label: ModalContainer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 5000;
`;

const ModalClickCatcher = styled.div`
    label: ModalClickCatcher;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
`;

const ModalInner = styled.div`
    label: ModalInner;
    background: ${color.white};
    padding: 1rem 2rem;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 1rem;
    flex: 1;

    z-index: 1;

    &.${EModalSize.LARGE} {
        //width: 80vw;
        //height: 80vh;
        //max-width: 80vw;
        //max-height: 100%;
        //min-width: 80vw;
        //min-height: 80vh;
    }

    &.${EModalSize.MEDIUM} {
        //width: 60vw;
        //height: 60vh;
        //max-width: 60vw;
        //max-height: 100%;
        //min-width: 60vw;
        //min-height: 60vh;
    }

    &.${EModalSize.SMALL} {
        //width: 40vw;
        //height: 40vh;
        max-width: 40vw;
        //max-height: 40vh;
        //min-width: 60vw;
        //min-height: 60vh;
    }
`;

const ModalHeader = styled.h4`
    label: ModalHeader;
    padding: 0 0 0.5rem 0;
    flex: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid ${color.greyLight};
`;

const ModalContents = styled.div`
    label: ModalContents;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex: 1;

    &.scrollable {
        overflow-x: scroll;
        overflow-y: auto;
        padding-right: 0.5rem;
        padding-bottom: 1rem;

        /* width */
        ::-webkit-scrollbar {
            width: 0.125rem;
        }
        /* Track */
        ::-webkit-scrollbar-track {
            background: ${color.greyLightest};
        }
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: ${color.grey};
        }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: ${color.greyDark};
        }
    }
`;
