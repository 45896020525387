import { color, font, space } from '~styles/styles';
import styled from '@emotion/styled';

export const H1 = styled.h1`
    font-size: ${font.size.extraLarge};
    font-weight: ${font.weight.black};
    line-height: ${font.lineHeight.extraLarge};
    margin: ${space.margin.medium};
    color: ${color.black};
    &.onClick {
        cursor: pointer;
    }
`;

export const H2 = styled.h2`
    font-size: ${font.size.large};
    font-weight: ${font.weight.medium};
    line-height: ${font.lineHeight.large};
    margin: ${space.margin.small};
    color: ${color.black};
    &.onClick {
        cursor: pointer;
    }
`;

export const H3 = styled.h3`
    font-size: ${font.size.medium};
    font-weight: ${font.weight.medium};
    line-height: ${font.lineHeight.medium};
    margin: ${space.margin.small};
    color: ${color.black};
    &.onClick {
        cursor: pointer;
    }
`;

export const H4 = styled.h4`
    font-size: ${font.size.normal};
    font-weight: ${font.weight.medium};
    line-height: ${font.lineHeight.normal};
    margin: ${space.margin.tiny};
    color: ${color.black};
    &.onClick {
        cursor: pointer;
    }
`;

export const H5 = styled.h5`
    font-size: ${font.size.small};
    font-weight: ${font.weight.medium};
    line-height: ${font.lineHeight.small};
    margin: ${space.margin.tiny};
    color: ${color.black};
    &.onClick {
        cursor: pointer;
    }
`;

export const HeadingWithAction = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
`;
