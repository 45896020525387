import { EContactAddressType } from '@makespace/graphql-generated/generated/webapp/schemas';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useFormController } from '~context/FormController';
import { useTranslation } from 'react-i18next';
import Button, { EButtonSize, EButtonVariant } from '~components/clickables/Button';
import ContactAddressFormRow from './ContactAddressFormRow';
import FormSection from '~components/form/FormSection';
import IconCirclePlus from '~components/icons/IconCirclePlus';
import React, { useMemo } from 'react';
import type { ContactAddressCreateUpdate } from '@makespace/graphql-generated/generated/webapp/schemas';
import type { FormSectionProps } from '~components/form/FormSection';
import type { ObjectWithContactAddress } from '~types/forms';

type ContactAddressFormProps = Pick<FormSectionProps, 'level'> & {
    path: string;
};

const EMPTY_CONTACT_ADDRESS: ContactAddressCreateUpdate = {
    addressType: EContactAddressType.Home,
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
};

export default function ContactAddressForm(props: ContactAddressFormProps): JSX.Element {
    const { path = 'address', ...sectionProps } = props;
    const { t } = useTranslation();
    const { control } = useFormContext<ObjectWithContactAddress>();
    const { edit } = useFormController();

    const { fields, remove, append } = useFieldArray({
        control,
        name: path as 'address',
    });

    const actions = useMemo(
        () =>
            edit ? (
                <Button
                    iconStart={IconCirclePlus}
                    onClick={() => append(EMPTY_CONTACT_ADDRESS, { shouldFocus: false })}
                    size={EButtonSize.SMALL}
                    variant={EButtonVariant.TEXT}>
                    {t('component.contactAddressForm.button.addAddress')}
                </Button>
            ) : null,
        [edit, t, append],
    );

    return (
        <FormSection
            heading={t('component.contactAddressForm.heading') ?? ''}
            headingActions={actions}
            {...sectionProps}>
            {fields?.map((field, index) => (
                <ContactAddressFormRow
                    address={field}
                    index={index}
                    key={field.id}
                    path={path}
                    remove={() => remove(index)}
                />
            ))}
        </FormSection>
    );
}
