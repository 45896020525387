import * as yup from 'yup';
import { ContactAddressSchema, ContactMethodSchema } from '~schema/contact';
import type { GiBusinessCreateUpdate } from '@makespace/graphql-generated/generated/webapp/schemas';

export const BusinessSchema: yup.ObjectSchema<GiBusinessCreateUpdate> = yup.object().shape({
    name: yup.string().required(),
    country: yup
        .string()
        .required()
        .when('name', {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            is: (val?: string) => val !== undefined,
            then: yup.string().required(),
            otherwise: yup.string().notRequired(),
        }),
    registrationNumber: yup
        .string()
        .required()
        .when('name', {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            is: (val?: string) => val !== undefined,
            then: yup.string().required(),
            otherwise: yup.string().notRequired(),
        }),
    taxNumber: yup.string().notRequired(),
    contact: yup
        .object()
        .shape({
            address: yup.array().of(ContactAddressSchema).notRequired(),
            methods: yup.array().of(ContactMethodSchema).notRequired(),
        })
        .notRequired(),
});
