import {
    type Booking,
    EBookingStatus,
    useBookingCreateMutation,
    useBookingUpdateMutation,
} from '@makespace/graphql-generated/webapp';
import { BookingCreateUpdateSchema } from '~schema/booking';
import { EModal } from '~constants/modal';
import { FormProvider, useForm } from 'react-hook-form';
import { safeSubmit } from '~utils/form';
import { useModal } from '~context/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { BookingCreateUpdateForm } from '~schema/booking';
import type { FormProviderProps } from '~types/forms';
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';

type BookingCreateFormProviderProps = FormProviderProps & {
    booking?: Booking;
    initialValues?: Partial<BookingCreateUpdateForm>;
};

export default function BookingCreateUpdateFormProvider(
    props: BookingCreateFormProviderProps,
): JSX.Element | null {
    const { children, formRef, initialValues, booking } = props;

    const { closeModal } = useModal();
    const [bookingEdit] = useBookingCreateMutation({
        refetchQueries: ['Bookings'],
    });
    const [bookingUpdate] = useBookingUpdateMutation({
        refetchQueries: ['Bookings'],
    });

    const defaultValues: Partial<BookingCreateUpdateForm> = useMemo(
        () => ({
            status: EBookingStatus.Reserved,
            notes: [],
            ...initialValues,
        }),
        [initialValues],
    );

    const resolver = useMemo(
        () => yupResolver(BookingCreateUpdateSchema, { abortEarly: false }),
        [],
    );

    const formMethods = useForm<BookingCreateUpdateForm>({
        criteriaMode: 'all',
        mode: 'onSubmit',
        defaultValues,
        resolver,
    });

    const { reset, handleSubmit } = formMethods;

    const onReset = useCallback(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const onSubmit: SubmitHandler<BookingCreateUpdateForm> = async (data) => {
        const {
            _id,
            bookingNumber,
            dateFrom,
            dateTo,
            space,
            spaceType,
            spaceTypeVariant,
            status,
            user,
            name,
            notes,
            description,
        } = data;

        const isUpdating = !!_id && booking && _id === booking._id;
        try {
            if (isUpdating)
                await bookingUpdate({
                    variables: {
                        input: {
                            _id,
                            bookingNumber,
                            dateFrom,
                            dateTo,
                            description,
                            name,
                            notes,
                            space,
                            spaceType,
                            spaceTypeVariant,
                            user,
                            status,
                        },
                    },
                    refetchQueries: ['Bookings'],
                });
            else
                await bookingEdit({
                    variables: {
                        input: {
                            dateFrom,
                            dateTo,
                            description,
                            name,
                            notes,
                            space,
                            spaceType,
                            spaceTypeVariant,
                            user,
                            status,
                        },
                    },
                    refetchQueries: ['Bookings'],
                });
            closeModal(isUpdating ? EModal.BOOKING_UPDATE : EModal.BOOKING_CREATE);
        } catch (e) {
            console.error(e as Error);
        }
    };

    const onError: SubmitErrorHandler<BookingCreateUpdateForm> = (errors) => {
        console.error('BookingEditFormProvider:onError', errors);
    };

    useEffect(() => {
        reset(defaultValues, { keepDirty: false });
    }, [defaultValues, reset]);

    return (
        <FormProvider {...formMethods}>
            <form
                id={'booking-create-form'}
                ref={formRef}
                onReset={onReset}
                onSubmit={safeSubmit(handleSubmit(onSubmit, onError))}>
                {children}
            </form>
            {/*<DevTool control={formMethods.control} placement={'top-right'} />*/}
        </FormProvider>
    );
}
