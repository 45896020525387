import { Controller, type FieldValues } from 'react-hook-form';
import {
    EContactMethodSource,
    useUserCategoriesQuery,
    useUsersQuery,
} from '@makespace/graphql-generated/webapp';
import { EModal, EModalReturn } from '~constants/modal';
import { useModal } from '~context/Modal';
import { useTranslation } from 'react-i18next';
import FormControl, { EFormControlVariant } from '~components/form/FormControl';
import Input, { EInputVariant } from '~components/form/Input';
import React, { useCallback, useState } from 'react';
import randomId from '~utils/ids';
import styled from '@emotion/styled';
import type { ControlledSelectProps } from '~components/inputs/ControlledSelect';
import type { QueryHookOptions } from '@apollo/client';

enum EUserSelectView {
    LIST = 'list',
    NEW = 'new',
}

type ControlledUserSelectProps<TFieldValues extends FieldValues = FieldValues> = Omit<
    ControlledSelectProps<TFieldValues>,
    'options'
> &
    Pick<QueryHookOptions, 'initialFetchPolicy' | 'fetchPolicy'> & {
        modalReturn?: EModalReturn;
        view?: EUserSelectView;
    };

export default function ControlledUserSelect<TFieldValues extends FieldValues = FieldValues>(
    props: ControlledUserSelectProps<TFieldValues>,
): JSX.Element {
    const {
        control,
        fetchPolicy,
        formControlProps,
        id = randomId(),
        initialFetchPolicy,
        label,
        modalReturn = EModalReturn.DEFAULT,
        multiple = false,
        name,
        variant = EInputVariant.NORMAL,
        view = EUserSelectView.LIST,
        ...remainingProps
    } = props;
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [category, setCategory] = useState<string | undefined>(undefined);
    const { openModal } = useModal();

    const { data: { users } = {} } = useUsersQuery({
        initialFetchPolicy: initialFetchPolicy ?? 'cache-first',
        fetchPolicy: fetchPolicy ?? 'cache-first',
        nextFetchPolicy: 'cache-first',
        variables: {
            input: {
                searchTerm,
                categories: category ? [category] : null,
                roles: null,
            },
        },
    });

    const displayValue = useCallback(
        (value: string | undefined) => {
            const user = value ? users?.find((u) => u._id === value) : undefined;
            return user ? `${user.firstName} ${user.lastName}` : '';
        },
        [users],
    );

    return (
        <Controller<TFieldValues>
            name={name}
            control={control}
            render={({ field }) => (
                <UserSelectContainer>
                    <FormControl
                        label={label}
                        variant={EFormControlVariant.ROW}
                        {...formControlProps}>
                        <Input
                            value={displayValue(field.value)}
                            onFocus={() =>
                                openModal(EModal.USER_SELECT, {
                                    userSelectModalReturn: modalReturn,
                                })
                            }
                            variant={variant}
                            clearable={true}
                            onClear={() => setSearchTerm(undefined)}
                        />
                    </FormControl>
                </UserSelectContainer>
            )}
        />
    );
}

const UserSelectContainer = styled.div`
    position: relative;
    flex: 1;
    width: 100%;
`;
