export async function refreshAuthToken(authUrl: string): Promise<void> {
    try {
        const response = await fetch(`${authUrl}/token/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (response.ok) return;
        else throw new Error('Refresh failed');
    } catch (e) {
        console.error(e);
        throw e;
    }
}
