import { EBookingAvailability } from '@makespace/graphql-generated/generated/webapp/schemas';
import { EIconColor, EIconSize } from '~components/icons/Icon';
import { border, color, font } from '~styles/styles';
import { useTranslation } from 'react-i18next';
import IconCheck from '~components/icons/IconCheck';
import IconCross from '~components/icons/IconCross';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type { BookingAvailability } from '@makespace/graphql-generated/generated/webapp/schemas';

export type BookingAvailabilityProps = {
    availability: BookingAvailability;
};

export default function BookingAvailabilityDisplay(props: BookingAvailabilityProps): JSX.Element {
    const { availability } = props;
    const { t } = useTranslation();

    const IconComponent = useMemo(() => {
        switch (availability.availability) {
            case EBookingAvailability.Available:
                return IconCheck;
            case EBookingAvailability.AvailableWithApproval:
                return IconCheck;
            case EBookingAvailability.Unavailable:
                return IconCross;
        }
    }, [availability]);

    return (
        <BookingAvailabilityContainer className={clsx(availability.availability)}>
            <span className={'iconWrapper'}>
                <IconComponent size={EIconSize.TINY} color={EIconColor.INHERIT} />
            </span>
            <div className={'content'}>
                <span className={'availability'}>
                    {t(`enum.EBookingAvailability.${availability.availability}`)}
                </span>
                {availability.reason ? (
                    <span className={'reason'}>
                        {t(`enum.EBookingUnavailabilityReason.${availability.reason}`)}
                    </span>
                ) : null}
            </div>
        </BookingAvailabilityContainer>
    );
}

const BookingAvailabilityContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    border-radius: ${border.radius.small};
    color: ${color.white};
    font-size: ${font.size.small};
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    flex: 1;

    & .iconWrapper {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.125rem;
        background-color: ${color.white}80;
    }

    & .content {
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
    }

    & .availability {
        font-weight: ${font.weight.bold};
    }

    & .reason {
        &:before {
            content: ' - ';
        }
    }

    &.${EBookingAvailability.Available} {
        background-color: ${color.green};

        .icon {
            color: ${color.green};
        }
    }

    &.${EBookingAvailability.AvailableWithApproval} {
        background-color: ${color.orange};

        .iconWrapper {
            color: ${color.orange};
        }
    }

    &.${EBookingAvailability.Unavailable} {
        background-color: ${color.red};

        .iconWrapper {
            color: ${color.red};
        }
    }
`;
