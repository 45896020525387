import { RefObject, useEffect, useState } from 'react';

type ScrollLockProps = {
    target?: RefObject<HTMLElement>;
};

export default function ScrollLock(props: ScrollLockProps): null {
    const { target } = props;
    const [locked, setLocked] = useState(false);

    const lock = (element: HTMLElement) => {
        element.style.overflow = 'hidden';
        setLocked(true);
    };

    const unlock = (element: HTMLElement) => {
        element.style.removeProperty('overflow');
        setLocked(false);
    };

    useEffect(() => {
        if (!locked)
            if (!target) lock(document.body);
            else if (target.current) lock(target.current);

        return () => {
            if (locked)
                if (!target) unlock(document.body);
                else if (target.current) unlock(target.current);
        };
    }, [locked, target]);

    return null;
}
