import { CalendarProps, Calendar as ReactCalendar } from 'react-calendar';
import { EIconSize } from '~components/icons/Icon';
import { border, color, font } from '~styles/styles';
import IconChevronLeft from '~components/icons/IconChevronLeft';
import IconChevronRight from '~components/icons/IconChevronRight';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum ECalendarVariant {
    FLOATING = 'floating',
    INLINE = 'inline',
}

export type CalendarBasicProps = CalendarProps & {
    variant?: ECalendarVariant;
};

export default function CalendarBasic(props: CalendarBasicProps): JSX.Element {
    const { variant, ...calendarProps } = props;
    return (
        <StyledCalendar
            className={clsx(variant)}
            nextLabel={<IconChevronRight size={EIconSize.SMALL} />}
            next2Label={null}
            prevLabel={<IconChevronLeft size={EIconSize.SMALL} />}
            prev2Label={null}
            {...calendarProps}
        />
    );
}

const StyledCalendar = styled(ReactCalendar)`
    font-family: ${font.family.mono};
    font-size: ${font.size.medium};
    line-height: ${font.lineHeight.medium};
    font-weight: ${font.weight.regular};
    border-radius: ${border.radius.small};
    border-color: ${color.black};
    color: ${color.black};
    outline: none;
    margin: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
    gap: 0.5em;

    width: 320px;
    min-width: 320px;
    background: ${color.white};
    padding: 1rem 1.5rem 1.5rem 1.5rem;

    &.${ECalendarVariant.FLOATING} {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    & button {
        border: none;
        padding: 0;
        background: transparent;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-family: ${font.family.mono};
        font-size: ${font.size.normal};
        font-weight: ${font.weight.regular};
        line-height: ${font.lineHeight.normal};

        border-radius: ${border.radius.medium};
    }

    & .react-calendar__navigation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        padding: 0 0 0.5rem 0;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: calc(100% - 1rem);
            height: 1px;
            background: ${color.greyLight};
        }

        & .react-calendar__navigation__label__labelText {
            font-weight: ${font.weight.bold};
        }
    }

    & .react-calendar__month-view > div > div {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    & .react-calendar__month-view__weekdays__weekday {
        font-size: ${font.size.extraSmall};
        font-weight: ${font.weight.regular};
        line-height: ${font.lineHeight.small};

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & abbr {
            text-decoration: none;
        }
    }

    & .react-calendar__tile {
        font-size: ${font.size.normal};
        font-weight: ${font.weight.regular};
        line-height: ${font.lineHeight.normal};
        color: ${color.black};

        width: 2.5rem;
        height: 2.5rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover {
            background: ${color.blueLightest};
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
            color: ${color.black}66;
        }

        &.react-calendar__tile--active {
            background-color: ${color.black};
            color: ${color.white};
        }

        &.react-calendar__tile--range {
            background-color: ${color.black}30;
            color: ${color.black};
            border-radius: 0;
        }

        &.react-calendar__tile--rangeStart,
        &.react-calendar__tile--rangeEnd {
            background-color: ${color.black};
            color: ${color.white};
        }

        &:disabled {
            color: ${color.black}33;
            cursor: default;
        }
    }
`;
