import { EModal } from '~constants/modal';
import BookingCreateUpdate from '~components/schema/bookings/BookingCreateUpdate';
import BookingCreateUpdateFormProvider from '~components/schema/bookings/BookingCreateUpdateFormProvider';
import Modal, { EModalSize } from '~components/modal/Modal';
import React, { useRef } from 'react';
import useQueryParams from '~hooks/useQueryParams';

export type BookingCreateQueryParams = {
    dateFrom?: string;
    dateTo?: string;
    space?: string;
    spaceType?: string;
    spaceTypeVariant?: string;
    userId?: string;
};

export default function BookingCreateModal(): JSX.Element {
    const queryParams = useQueryParams<BookingCreateQueryParams>();
    const formRef = useRef<HTMLFormElement>(null);

    console.log('queryParams', queryParams);

    return (
        <Modal
            header={'Create Booking'}
            size={EModalSize.LARGE}
            modal={EModal.BOOKING_CREATE}
            scrollable={true}>
            <BookingCreateUpdateFormProvider formRef={formRef} initialValues={queryParams}>
                <BookingCreateUpdate />
            </BookingCreateUpdateFormProvider>
        </Modal>
    );
}
