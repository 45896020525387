import { EContactMethodSource } from '@makespace/graphql-generated/generated/webapp/schemas';
import { EModal, EModalReturn } from '~constants/modal';
import { appendQueryParams } from '~utils/urls';
import { border, color, font, space } from '~styles/styles';
import { useModal } from '~context/Modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useUserCategoriesQuery,
    useUsersQuery,
} from '@makespace/graphql-generated/generated/webapp/hooks';
import Button, { EButtonSize, EButtonVariant } from '~components/clickables/Button';
import FormRow from '~components/form/FormRow';
import IconCirclePlus from '~components/icons/IconCirclePlus';
import Input from '~components/form/Input';
import Modal, { EModalSize } from '~components/modal/Modal';
import React, { useCallback, useMemo, useState } from 'react';
import Select, { type SelectOption } from '~components/form/Select';
import routePaths, { EAppRoutes, fullPath } from '~constants/routePaths';
import styled from '@emotion/styled';
import useQueryParams from '~hooks/useQueryParams';
import type { User } from '@makespace/graphql-generated/generated/webapp/schemas';

type UserSelectModalQueryParams = {
    userSelectModalReturn?: EModalReturn;
};

export default function UserSelectModal(): JSX.Element {
    const { t } = useTranslation();
    const { userSelectModalReturn } = useQueryParams<UserSelectModalQueryParams>();
    const navigate = useNavigate();
    const { openModal, closeModal } = useModal();

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [category, setCategory] = useState<string | undefined>(undefined);

    const { data: { users } = {} } = useUsersQuery({
        initialFetchPolicy: 'cache-first',
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
        variables: {
            input: {
                searchTerm,
                categories: category ? [category] : null,
                roles: null,
            },
        },
    });

    const { data: { userCategories } = {} } = useUserCategoriesQuery({
        initialFetchPolicy: 'cache-first',
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    const userCategoriesOptions: SelectOption[] = useMemo(
        () => userCategories?.map((c) => ({ value: c._id, label: c.name })) ?? [],
        [userCategories],
    );

    const handleUserSelect = useCallback(
        (user: string) => {
            switch (userSelectModalReturn) {
                case EModalReturn.PARAMS:
                    appendQueryParams({ user });
                    break;
                default:
                case EModalReturn.DEFAULT:
                case EModalReturn.URL:
                    navigate(fullPath(routePaths[EAppRoutes.USERS], 'user', { userId: user }));
                    break;
            }
            closeModal(EModal.USER_SELECT);
        },
        [closeModal, navigate, userSelectModalReturn],
    );

    return (
        <Modal
            header={'Select User'}
            size={EModalSize.MEDIUM}
            modal={EModal.USER_SELECT}
            scrollable={true}>
            <UserSelectContent>
                <FormRow variant={'row'}>
                    <Input
                        type="text"
                        value={searchTerm}
                        clearable={true}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClear={() => setSearchTerm(undefined)}
                        autoFocus={true}
                    />
                    <Select
                        options={userCategoriesOptions}
                        multiple={false}
                        placeholder={'Category'}
                        value={category}
                        onChange={(category) => setCategory(category?.toString() ?? undefined)}
                    />
                    <Button
                        iconEnd={IconCirclePlus}
                        size={EButtonSize.SMALL}
                        variant={EButtonVariant.TOGGLE_INACTIVE}
                        onClick={() => {
                            closeModal(EModal.USER_SELECT);
                            openModal(EModal.USER_CREATE, {
                                userCreateModalReturn: EModalReturn.PARAMS,
                            });
                        }}>
                        New
                    </Button>
                </FormRow>
                <UserSelectList users={users ?? []} onSelect={handleUserSelect} />
            </UserSelectContent>
        </Modal>
    );
}

type UserSelectListProps = {
    onSelect: (userId: string) => void;
    users?: User[];
};

function UserSelectList(props: UserSelectListProps) {
    const { users = [], onSelect } = props;
    return (
        <UserSelectListContainer>
            <tbody>
                {users?.map((u) => (
                    <UserRow key={u._id} onClick={() => onSelect(u._id)}>
                        <td width={'35%'}>{`${u.firstName} ${u.lastName}`}</td>
                        <td width={'35%'}>{u.email}</td>
                        <td width={'15%'}>
                            <span>
                                {
                                    u.contact?.methods?.find(
                                        (m) => m.source === EContactMethodSource.Phone,
                                    )?.value
                                }
                            </span>
                        </td>
                        <td width={'15%'}>
                            <span>{u.category.name}</span>
                        </td>
                    </UserRow>
                ))}
            </tbody>
        </UserSelectListContainer>
    );
}

const UserSelectContent = styled.div`
    flex-direction: column;
    gap: ${space.padding.small};
    z-index: 1;
`;

const UserSelectListContainer = styled.table`
    border-collapse: collapse;
    width: 100%;
    margin: 1rem 0;
`;

const UserRow = styled.tr`
    cursor: pointer;
    &:hover {
        background-color: ${color.greyLightest};
    }

    & td {
        padding: ${space.padding.small} ${space.padding.small};
        font-size: ${font.size.normal};
        border-bottom: ${border.width.thin} solid ${color.greyLightest};
        max-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-of-type {
            text-align: right;
        }
    }
`;
