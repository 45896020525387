{
  "app": {
    "name": "Space Control",
    "title": "Space Control",
    "languages": {
      "en": "English",
      "th": "Thai"
    }
  },
  "page": {
    "login": {
      "heading": "Login",
      "instructions": "Welcome Back!",
      "register": "Dont have an account? ",
      "registerLink": "Register",
      "field": {
        "email": {
          "label": "Email",
          "required": "Email is required",
          "placeholder": "Email",
          "help": "Email address",
          "invalid": "Email is invalid"
        }
      },
      "button": {
        "submit": "Continue"
      }
    },
    "register": {
      "heading": "Register",
      "instructions": "To register we need a valid email address. \u2028We’ll send a code to your email that you can add in the next step.",
      "steps": {
        "email": "Enter your email address to continue.",
        "code": "Enter the code to continue",
        "name": "Please provide your name then continue."
      },
      "login": "Already have an account?  ",
      "loginLink": "Login",
      "field": {
        "email": {
          "label": "Email",
          "required": "Email is required",
          "placeholder": "Email",
          "help": "Email address",
          "invalid": "Email is invalid"
        }
      },
      "button": {
        "submit": "Continue"
      }
    },
    "bookings": {
      "heading": "Bookings"
    },
    "consumables": {
      "heading": "Consumables",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Consumable Item"
      },
      "consumable": {
        "heading": "{{consumable}}"
      },
      "field": {
        "name": {
          "label": "Name",
          "required": "Name is required",
          "placeholder": "Name",
          "help": "Name of the ingredient",
          "invalid": "Name is invalid"
        },
        "quantity": {
          "label": "Quantity",
          "placeholder": "Quantity is auto generated",
          "help": "Actual quantity of the ingredient"
        }
      }
    },
    "ingredients": {
      "heading": "Ingredients",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Ingredient"
      },
      "ingredient": {
        "heading": "{{ingredient}}",
        "details": "Details"
      },
      "field": {
        "name": {
          "label": "Name",
          "required": "Name is required",
          "placeholder": "Name",
          "help": "Name of the ingredient",
          "invalid": "Name is invalid"
        },
        "quantity": {
          "label": "Quantity",
          "placeholder": "Quantity is auto generated",
          "help": "Actual quantity of the ingredient"
        }
      }
    },
    "inventory": {
      "heading": "Inventory",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Inventory Item"
      },
      "update": {
        "heading": "Inventory: {{inventoryRecord}}"
      },
      "inventoryRecord": {
        "heading": "{{inventoryRecord}}",
        "details": "Details"
      }
    },
    "inventoryOrders": {
      "heading": "Inventory Orders",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Inventory Order"
      },
      "update": {
        "heading": "Order: {{inventoryOrder}}"
      }
    },
    "orders": {
      "heading": "Orders",
      "action": {
        "create": "Create"
      }
    },
    "products": {
      "heading": "Products",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Product"
      },
      "update": {
        "heading": "{{product}}"
      },
      "product": {
        "heading": "{{product}}",
        "details": "Details"
      },
      "field": {
        "name": {
          "label": "Name",
          "required": "Name is required",
          "placeholder": "Name",
          "help": "Name of the ingredient",
          "invalid": "Name is invalid"
        },
        "slug": {
          "label": "Slug",
          "required": "Slug is required",
          "placeholder": "slug",
          "help": "Slug of the ingredient",
          "invalid": "Slug is invalid"
        },
        "active": {
          "label": "Active",
          "info": "Product is active"
        }
      }
    },
    "suppliers": {
      "heading": "Suppliers",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Supplier"
      },
      "supplier": {
        "heading": "{{supplier}}",
        "details": "Details"
      },
      "field": {
        "active": {
          "label": "Active"
        },
        "name": {
          "label": "Name"
        }
      }
    },
    "spaceTypes": {
      "heading": "Space Types",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Space Type"
      },
      "field": {
        "active": {
          "label": "Active"
        },
        "name": {
          "label": "Name"
        },
        "priority": {
          "label": "Priority"
        },
        "slug": {
          "label": "Slug"
        }
      },
      "spaceType": {
        "heading": "{{spaceType}}",
        "variation": {
          "bookingConfigurations": {
            "heading": "Booking Configurations"
          }
        }
      }
    },
    "spaces": {
      "heading": "Spaces",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Space"
      },
      "field": {
        "active": {
          "label": "Active"
        },
        "name": {
          "label": "Name"
        },
        "priority": {
          "label": "Priority"
        },
        "slug": {
          "label": "Slug"
        }
      }
    },
    "tags": {
      "heading": "Tags",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create Tag"
      },
      "tag": {
        "heading": "{{tag}}",
        "details": "Details"
      },
      "field": {
        "name": {
          "label": "Name"
        }
      }
    },
    "users": {
      "heading": "Users",
      "action": {
        "create": "Create"
      },
      "create": {
        "heading": "Create User"
      },
      "user": {
        "heading": "{{name}}",
        "details": "Details"
      },
      "field": {
        "name": {
          "label": "Name"
        }
      }
    },
    "update": {
      "heading": "{{spaceType}}"
    }
  },
  "component": {
    "businessForm": {
      "heading": "Business",
      "field": {
        "country": {
          "label": "Country",
          "required": "Country is required",
          "placeholder": "Country",
          "help": "Business country",
          "invalid": "Country is invalid"
        },
        "name": {
          "label": "Name",
          "required": "Name is required",
          "placeholder": "Name",
          "help": "Business name",
          "invalid": "Name is invalid"
        },
        "registrationNumber": {
          "label": "Registration Number",
          "required": "Registration Number is required",
          "placeholder": "Registration Number",
          "help": "Business registration number",
          "invalid": "Registration Number is invalid"
        },
        "taxNumber": {
          "label": "Tax Number",
          "required": "Tax Number is required",
          "placeholder": "Tax Number",
          "help": "Business tax number",
          "invalid": "Tax Number is invalid"
        }
      }
    },
    "contactAddressForm": {
      "heading": "Contact Address",
      "field": {
        "addressType": {
          "label": "Address Type",
          "required": "Address Type is required",
          "placeholder": "Address Type",
          "help": "Contact Address Type",
          "invalid": "Address Type is invalid"
        },
        "line1": {
          "label": "Address Line 1",
          "required": "Address Line 1 is required",
          "placeholder": "Address Line 1",
          "help": "Contact Address Line 1",
          "invalid": "Address Line 1 is invalid"
        },
        "line2": {
          "label": "Address Line 2",
          "required": "Address Line 2 is required",
          "placeholder": "Address Line 2",
          "help": "Contact Address Line 2",
          "invalid": "Address Line 2 is invalid"
        },
        "city": {
          "label": "City",
          "required": "City is required",
          "placeholder": "City",
          "help": "Contact city",
          "invalid": "City is invalid"
        },
        "state": {
          "label": "State",
          "required": "State is required",
          "placeholder": "State",
          "help": "Contact state",
          "invalid": "State is invalid"
        },
        "postalCode": {
          "label": "Postcode",
          "required": "Postcode is required",
          "placeholder": "Postcode",
          "help": "Contact Postcode",
          "invalid": "Postcode is invalid"
        },
        "country": {
          "label": "Country",
          "required": "Country is required",
          "placeholder": "Country",
          "help": "Contact country",
          "invalid": "Country is invalid"
        }
      },
      "button": {
        "addAddress": "Add Address"
      }
    },
    "contactMethodsForm": {
      "heading": "Contact Methods",
      "field": {
        "source": {
          "label": "Source",
          "required": "Source is required",
          "placeholder": "Contact method source",
          "help": "The source of the contact method",
          "invalid": "Source is invalid"
        },
        "value": {
          "label": "Value",
          "required": "Value is required",
          "placeholder": "Value",
          "help": "Contact method value",
          "invalid": "Value is invalid"
        }
      },
      "button": {
        "addMethod": "Add Method"
      }
    },
    "translationsForm": {
      "heading": "Translations",
      "field": {
        "language": {
          "label": "Language",
          "required": "Language is required",
          "placeholder": "Language",
          "help": "Translation language",
          "invalid": "Language is invalid"
        },
        "name": {
          "label": "Name",
          "required": "Name is required",
          "placeholder": "Name",
          "help": "Translated name",
          "invalid": "Name is invalid"
        },
        "description": {
          "label": "Description",
          "required": "Description is required",
          "placeholder": "Description",
          "help": "Translated description",
          "invalid": "Description is invalid"
        }
      }
    },
    "themeForm": {
      "heading": "Theme",
      "field": {
        "colorPrimary": {
          "label": "Color Primary",
          "required": "Color Primary is required",
          "placeholder": "Color Primary",
          "help": "Color Primary language",
          "invalid": "Color Primary is invalid"
        },
        "colorSecondary": {
          "label": "Color Secondary",
          "required": "Color Secondary is required",
          "placeholder": "Color Secondary",
          "help": "Color Secondary language",
          "invalid": "Color Secondary is invalid"
        },
        "icon": {
          "label": "Icon",
          "required": "Icon is required",
          "placeholder": "Icon",
          "help": "Icon language",
          "invalid": "Icon is invalid"
        }
      }
    },
    "productVariationForm": {
      "heading": "Variations",
      "field": {
        "name": {
          "label": "Name"
        },
        "price": {
          "label": "Price"
        },
        "active": {
          "label": "Active",
          "info": "Variant is active"
        }
      },
      "button": {
        "create": "New Variant"
      }
    },
    "recipeForm": {
      "heading": "Recipe",
      "field": {
        "ingredient": {
          "label": "Ingredient"
        },
        "quantity": {
          "label": "Quantity"
        }
      }
    },
    "userForm": {
      "heading": {
        "create": "Create User",
        "update": "Update User"
      },
      "field": {
        "firstName": {
          "label": "First Name",
          "required": "First Name is required",
          "placeholder": "First Name",
          "help": "Users first name",
          "invalid": "First Name is invalid"
        },
        "lastName": {
          "label": "Last Name",
          "required": "Last Name is required",
          "placeholder": "Last Name",
          "help": "Users last name",
          "invalid": "Last Name is invalid"
        },
        "nationality": {
          "label": "Nationality",
          "required": "Nationality is required",
          "placeholder": "Nationality",
          "help": "Users nationality",
          "invalid": "Nationality is invalid"
        },
        "passportNumber": {
          "label": "Passport Number",
          "required": "Passport Number is required",
          "placeholder": "Passport Number",
          "help": "Users Passport Number",
          "invalid": "Passport Number is invalid"
        },
        "category": {
          "label": "Category",
          "required": "Category is required",
          "placeholder": "Category",
          "help": "Users Category",
          "invalid": "Category is invalid"
        },
        "roles": {
          "label": "Roles",
          "required": "Roles is required",
          "placeholder": "Roles",
          "help": "Users Roles",
          "invalid": "Roles is invalid"
        }
      }
    },
    "controlledUserCategorySelect": {
      "label": "User Category"
    },
    "controlledUserSelect": {
      "label": "User"
    },
    "controlledTagSelect": {
      "label": "Tags"
    },
    "controlledDaysOfWeekSelect": {
      "label": "Days of Week"
    },
    "controlledBookingPeriodSelect": {
      "label": "Booking Period"
    },
    "bookingConfigurationOptionsForm": {
      "field": {
        "limits": {
          "increment": {
            "label": "Increment"
          },
          "min": {
            "label": "Minimum"
          },
          "max": {
            "label": "Maximum"
          }
        }
      }
    },
    "bookingConfigurationOptionRatesForm": {
      "field": {
        "price": {
          "label": "Price"
        },
        "rate": {
          "label": "Rate"
        },
        "min": {
          "label": "Minimum"
        },
        "max": {
          "label": "Maximum"
        }
      }
    }
  },
  "global": {
    "language": {
      "EN": "English",
      "TH": "Thai"
    },
    "button": {
      "save": "Save",
      "cancel": "Cancel"
    },
    "dayOfWeek": {
      "monday": "Monday",
      "tuesday": "Tuesday",
      "wednesday": "Wednesday",
      "thursday": "Thursday",
      "friday": "Friday",
      "saturday": "Saturday",
      "sunday": "Sunday"
    },
    "bookingPeriod": {
      "MINUTE": "Minute"
    }
  },
  "enum": {
    "EContactMethodSource": {
      "Email": "Email",
      "Phone": "Phone",
      "Facebook": "Facebook",
      "Line": "Line",
      "Instagram": "Instagram",
      "Website": "Website",
      "Wechat": "WeChat",
      "Whatsapp": "WhatsApp"
    },
    "EUserRole": {
      "Admin": "Admin",
      "Staff": "Staff",
      "Guest": "Guest"
    },
    "EContactAddressType": {
      "Home": "Home",
      "Temporary": "Temporary",
      "Business": "Business",
      "Billing": "Billing",
      "Other": "Other"
    },
    "EBookingAvailability": {
      "AVAILABLE": "Available",
      "Available": "Available",
      "AVAILABLE_WITH_APPROVAL": "Available with Approval",
      "AvailableWithApproval": "Available with Approval",
      "Unavailable": "Unavailable",
      "UNAVAILABLE": "Unavailable"
    },
    "EBookingUnavailabilityReason": {
      "DateNotAvailable": "Date not available",
      "DATE_NOT_AVAILABLE": "Date not available",
      "SpacesAlreadyBooked": "Spaces already booked",
      "SPACES_ALREADY_BOOKED": "Spaces already booked",
      "SpaceAlreadyBooked": "Space already booked",
      "SPACE_ALREADY_BOOKED": "Space already booked",
      "TimeNotAvailable": "Time not available",
      "TIME_NOT_AVAILABLE": "Time not available",
      "TimeRangeExceedsAllowed": "Time range exceeds allowed",
      "TIME_RANGE_EXCEEDS_ALLOWED": "Time range exceeds allowed",
      "UserCategoryNotAllowed": "User category not allowed",
      "USER_CATEGORY_NOT_ALLOWED": "User category not allowed"
    }
  },
    "error": {
      "ASSERTION_ERROR": "Assertion error",
      "GENERAL": "General error",
      "INVALID_ORIGIN": "Invalid origin",
      "MISSING_RAW_ID": "Missing raw id",
      "MISSING_SESSION_CHALLENGE": "Missing session challenge",
      "MISSING_SESSION_ORIGIN": "Missing session origin",
      "MISSING_SESSION_USER_ID": "Missing session user id",
      "UNKNOWN_CREDENTIAL": "Account does not exist",
      "USER_CREDENTIAL_EXISTS": "User credential exists",
      "USER_EMAIL_EXISTS": "Account already exists",
      "UNKNOWN": "Unknown error",
      "USER_VALIDATION_ERROR": "User validation error"
    }
}
