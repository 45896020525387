import {
    type ContactAddressCreateUpdate,
    EContactAddressType,
} from '@makespace/graphql-generated/generated/webapp/schemas';
import { EIconColor, EIconSize } from '~components/icons/Icon';
import { postalCode } from '~utils/masks';
import { useCountries } from '~hooks/useCountries';
import { useFormContext } from 'react-hook-form';
import { useFormController } from '~context/FormController';
import { useTranslation } from 'react-i18next';
import ControlledInput from '~components/inputs/ControlledInput';
import ControlledSelect from '~components/inputs/ControlledSelect';
import Divider, { EDividerSpacing } from '~components/Divider';
import FormRow, { FormRowIcon } from '~components/form/FormRow';
import FormSection from '~components/form/FormSection';
import IconCircleCross from '~components/icons/IconCircleCross';
import React, { useMemo } from 'react';
import type { ObjectWithContactAddress } from '~types/forms';
import type { UseFieldArrayRemove } from 'react-hook-form';

type ContactAddressFormRowProps = {
    address: ContactAddressCreateUpdate;
    index: number;
    path: string;
    remove: UseFieldArrayRemove;
};

export default function ContactAddressFormRow(props: ContactAddressFormRowProps): JSX.Element {
    const { index, address, remove, path } = props;
    const { t } = useTranslation();
    const {
        control,
        formState: { isSubmitting },
    } = useFormContext<ObjectWithContactAddress>();
    const { edit } = useFormController();

    const { countryOptions } = useCountries({});

    const addressTypeOptions = useMemo(() => {
        return Object.entries(EContactAddressType).map(([key, value]) => ({
            label: t(`enum.EContactAddressType.${key}`) ?? value,
            value,
        }));
    }, [t]);

    return (
        <FormSection
            headingActions={
                edit ? (
                    <FormRowIcon>
                        <IconCircleCross
                            size={EIconSize.NORMAL}
                            color={isSubmitting ? EIconColor.DISABLED : EIconColor.NORMAL}
                            onClick={!isSubmitting ? () => remove(index) : undefined}
                        />
                    </FormRowIcon>
                ) : null
            }>
            <FormRow variant={'row'}>
                <ControlledSelect
                    control={control}
                    name={`${path}.${index}.addressType`}
                    options={addressTypeOptions}
                    readOnly={!edit}
                    label={t('component.contactAddressForm.field.addressType.label')}
                />
            </FormRow>
            <FormRow variant={'column'}>
                <ControlledInput
                    control={control}
                    name={`${path}.${index}.line1`}
                    label={t('component.contactAddressForm.field.line1.label')}
                    placeholder={t('component.contactAddressForm.field.line1.placeholder') ?? ''}
                    readOnly={!edit}
                />
                <ControlledInput
                    control={control}
                    name={`${path}.${index}.line2`}
                    label={t('component.contactAddressForm.field.line2.label')}
                    placeholder={t('component.contactAddressForm.field.line2.placeholder') ?? ''}
                    readOnly={!edit}
                />
            </FormRow>
            <FormRow variant={'row'}>
                <ControlledInput
                    control={control}
                    name={`${path}.${index}.city`}
                    label={t('component.contactAddressForm.field.city.label')}
                    placeholder={t('component.contactAddressForm.field.city.placeholder') ?? ''}
                    readOnly={!edit}
                />
                <ControlledInput
                    control={control}
                    name={`${path}.${index}.state`}
                    label={t('component.contactAddressForm.field.state.label')}
                    placeholder={t('component.contactAddressForm.field.state.placeholder') ?? ''}
                    readOnly={!edit}
                />
            </FormRow>
            <FormRow variant={'row'}>
                <ControlledSelect
                    control={control}
                    name={`${path}.${index}.country`}
                    options={countryOptions}
                    label={t('component.contactAddressForm.field.country.label')}
                    placeholder={t('component.contactAddressForm.field.country.placeholder') ?? ''}
                    readOnly={!edit}
                />
                <ControlledInput
                    control={control}
                    name={`${path}.${index}.postalCode`}
                    label={t('component.contactAddressForm.field.postalCode.label')}
                    placeholder={
                        t('component.contactAddressForm.field.postalCode.placeholder') ?? ''
                    }
                    readOnly={!edit}
                    mask={postalCode}
                />
            </FormRow>
            <Divider spacing={EDividerSpacing.NONE} />
        </FormSection>
    );
}
