export enum EDateTimeDisplay {
    SHORT,
    LONG,
    DATA,
}

const DATE_TIME_DISPLAY_OPTIONS: Record<EDateTimeDisplay, Intl.DateTimeFormatOptions> = {
    [EDateTimeDisplay.SHORT]: { timeStyle: 'short', dateStyle: 'medium' },
    [EDateTimeDisplay.LONG]: { timeStyle: 'short', dateStyle: 'full' },
    [EDateTimeDisplay.DATA]: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    },
};

export function formatDateTime(
    date: Date | string | number | null | undefined,
    variant = EDateTimeDisplay.SHORT,
): string {
    return date
        ? typeof date === 'string'
            ? new Date(date).toLocaleString(undefined, DATE_TIME_DISPLAY_OPTIONS[variant])
            : date.toLocaleString(undefined, DATE_TIME_DISPLAY_OPTIONS[variant])
        : '';
}

const DATE_DISPLAY_OPTIONS: Record<EDateTimeDisplay, Intl.DateTimeFormatOptions> = {
    [EDateTimeDisplay.SHORT]: { dateStyle: 'short' },
    [EDateTimeDisplay.LONG]: { dateStyle: 'full' },
    [EDateTimeDisplay.DATA]: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    },
};

export function formatDate(
    date: Date | string | number | null | undefined,
    variant = EDateTimeDisplay.SHORT,
): string {
    return date
        ? typeof date === 'string'
            ? new Date(date).toLocaleDateString(undefined, DATE_DISPLAY_OPTIONS[variant])
            : date.toLocaleString(undefined, DATE_DISPLAY_OPTIONS[variant])
        : '';
}

export function formatTime(time: string | null | undefined): string {
    if (!time) return '';
    const match = time.match(/^([0-9]{0,2}):?([0-9]{0,2}).*$/);
    return match ? `${match[1]}${match[2] ? ':' : ''}${match[2]}` : '';
}

export const makeSlug = (name: string): string =>
    name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)+/g, '');

export const float = (value: string | number | undefined | null) =>
    value ? parseFloat(Number(value).toFixed(2)) : 0;

export const price = (value: string | number | undefined | null) =>
    value
        ? Number(value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          })
        : '';
