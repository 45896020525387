import { hsvaToHsla, rgbaToHsva } from '@uiw/color-convert/src';
import type { Rgba } from '@makespace/graphql-generated/generated/webapp/schemas';
import type { RgbaColor } from '@uiw/react-color';

export type ColorDigitArray = [number, number, number];
type HSLSet = ColorDigitArray[];

export const generateHslString = ([h, s, l]: ColorDigitArray) => `hsl(${h}, ${s}%, ${l}%)`;
export const generateRgbaString = ({ r, g, b, a }: Rgba) => `rgba(${r}, ${g}, ${b}, ${a})`;
export const generateRgbaContrastString = (rgba: Rgba) => {
    const { h, s, l, a } = hsvaToHsla(rgbaToHsva(rgba as RgbaColor));
    return `hsl(${h}, ${s > 20 ? 20 : 2}%, ${getHslLuma([h, s, l]) > 0.6 ? 5 : 95}%)`;
};
export const generateHslContrastString = ([h, s, l]: ColorDigitArray) =>
    `hsl(${h}, ${s > 20 ? 20 : 2}%, ${getHslLuma([h, s, l]) > 0.6 ? 5 : 95}%)`;

export const hslToRgb2 = ([h, s, l]: ColorDigitArray): ColorDigitArray => {
    // IMPORTANT if s and l between 0,1 remove the next two lines:
    s /= 100;
    l /= 100;

    const k = (n: number) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return [Math.round(255 * f(0)), Math.round(255 * f(8)), Math.round(255 * f(4))];
};

export function rgbToLuma([r, g, b]: [number, number, number]): number {
    return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255; // SMPTE C, Rec. 709 weightings
}

export function getHslLuma(hsl: ColorDigitArray) {
    return rgbToLuma(hslToRgb2(hsl));
}

export const makeHslString = (hsl: ColorDigitArray) => `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;

export function generateHslSets(setsCount: number): HSLSet[] {
    const sets = setsCount || 10;
    const subSets = 10;
    const hueShift = 360 / sets;
    const hueVariance = 1.5;
    const saturationShift = 10;
    const luminanceShift = 15;
    const saturationStart = 80;
    const luminanceStart = 50;
    const hslSets: HSLSet[] = [];
    let subSet: HSLSet = [];
    for (let hue = 30, i = 0; i < sets; i++) {
        hue = hue + hueShift * i;
        for (let j = 0; j < subSets; j++)
            subSet.push([
                hue,
                saturationStart,
                j > 0 ? luminanceStart + luminanceShift * j : luminanceStart,
            ]);
        // subSet.push(`hsl(${hue}, ${sStart}%, ${j > 0 ? lStart - lShift * j : lStart}%)`);

        hslSets.push(subSet);
        subSet = [];
    }
    return hslSets;
}
