import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { GRAPHQL_URI, GRAPHQL_WEBSOCKET_URI } from '~constants/graphql';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { Kind, OperationTypeNode } from 'graphql/language';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { possibleTypes } from '@makespace/graphql-generated/webapp';

const apolloDevTools = process.env.REACT_APP_APOLLO_DEV_TOOLS === 'true';

const cache = new InMemoryCache({ possibleTypes });

const httpLink = createHttpLink({
    uri: GRAPHQL_URI,
    credentials: 'include',
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: GRAPHQL_WEBSOCKET_URI,
    }),
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === Kind.OPERATION_DEFINITION &&
            definition.operation === OperationTypeNode.SUBSCRIPTION
        );
    },
    wsLink,
    httpLink,
);

// not using authorization tokens as we use cookie based authentication
// const authLink = setContext((_, { headers }) => {
//     return {
//         headers: {
//             ...headers,
//             authorization: `Bearer ${authToken}`,
//         },
//     };
// });

const client = new ApolloClient({
    link: splitLink,
    cache,
    connectToDevTools: apolloDevTools,
});

export default client;
