import { Ei18nLanguage } from '~i18n';
import { Translation } from '@makespace/graphql-generated/webapp';
import { useTranslation } from 'react-i18next';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

export type ObjectWithTranslation = Record<string, unknown> & {
    translations?: (Translation | null)[] | null;
};

export interface ILanguageContext {
    changeLanguage: (language: Ei18nLanguage) => void;
    language: Ei18nLanguage;
    languages: Ei18nLanguage[];
    translate: (obj?: ObjectWithTranslation | null) => Translation | undefined;
}

const initialLanguageContext: ILanguageContext = {
    language: Ei18nLanguage.EN,
    languages: [Ei18nLanguage.EN],
    changeLanguage: () => {
        throw new Error('Error context not initialized');
    },
    translate: () => {
        throw new Error('Error context not initialized');
    },
};

const LanguageContext = createContext<ILanguageContext>(initialLanguageContext);
LanguageContext.displayName = 'language';

type ErrorProviderProps = {
    children: React.ReactNode;
};

function LanguageProvider(props: ErrorProviderProps) {
    const { children } = props;
    const { i18n } = useTranslation();
    const language = useMemo(() => i18n.language as Ei18nLanguage, [i18n.language]);

    const translate = useCallback(
        (obj?: ObjectWithTranslation | null): Translation | undefined => {
            return (
                obj?.translations?.find(
                    (translation) =>
                        translation?.language.toLocaleLowerCase() === language.toLocaleLowerCase(),
                ) ?? undefined
            );
        },
        [language],
    );

    const languages = Object.values(Ei18nLanguage);

    const changeLanguage = useCallback(
        (language: string) => {
            void i18n.changeLanguage(language);
        },
        [i18n],
    );

    const context = useMemo(
        () => ({
            language,
            languages,
            translate,
            changeLanguage,
        }),
        [changeLanguage, language, languages, translate],
    );

    return <LanguageContext.Provider value={context}>{children}</LanguageContext.Provider>;
}

function useLanguage(): ILanguageContext {
    const context = useContext(LanguageContext);
    if (!context) throw new Error('useLanguage must be used within LanguageProvider');
    return context;
}

export { LanguageProvider, LanguageContext, useLanguage };
