import * as yup from 'yup';
import { BusinessSchema } from '~schema/business';
import { ContactAddressSchema, ContactMethodSchema } from '~schema/contact';
import {
    EContactMethodSource,
    EUserRole,
} from '@makespace/graphql-generated/generated/webapp/schemas';
import type {
    GiUserCreate,
    GiUserUpdate,
} from '@makespace/graphql-generated/generated/webapp/schemas';

export const EMPTY_USER_INPUT = {
    category: '',
    contact: {
        methods: [{ source: EContactMethodSource.Email, value: '' }],
    },
    email: '',
    firstName: '',
    lastName: '',
    roles: [EUserRole.Guest],
    business: null,
};

export const UserCreateSchema: yup.ObjectSchema<GiUserCreate> = yup.object().shape({
    business: BusinessSchema.notRequired().nullable(),
    category: yup.string().required(),
    nationality: yup.string().notRequired(),
    passportNumber: yup.string().notRequired(),
    contact: yup
        .object()
        .shape({
            address: yup.array().of(ContactAddressSchema).notRequired(),
            methods: yup.array().of(ContactMethodSchema).required(),
        })
        .required(),
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    roles: yup
        .array()
        .of(yup.string().oneOf(Object.values(EUserRole)).required())
        .required(),
});

export const UserUpdateSchema: yup.ObjectSchema<GiUserUpdate> = UserCreateSchema.shape({
    _id: yup.string().required(),
});
