import { color, font } from '~styles/styles';
import React, { LabelHTMLAttributes } from 'react';
import styled from '@emotion/styled';

export type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
    required?: boolean;
};

export default function Label(props: LabelProps): JSX.Element {
    const { required, ...remainingProps } = props;
    return <StyledLabel className={required ? 'required' : undefined} {...remainingProps} />;
}

const StyledLabel = styled.label`
    color: ${color.greyDark};
    font-size: ${font.size.small};
    margin: 0;
    padding-left: 3px;

    &.required:after {
        content: '*';
        color: ${color.linkRed};
    }
`;
