import { InputMask } from '~types/forms';

export const date: InputMask = /[^0-9/-]/gi;
export const time: InputMask = /[^0-9:]/gi;
export const price: InputMask = /[^0-9.]/gi;
export const float: InputMask = /[^0-9.]/gi;
export const phone: InputMask = /[^0-9+]/gi;
export const postalCode: InputMask = /[^0-9]{4,6}/gi;

//
// export const phone: InputMask<string> = (input) => {
//     if (!input) return input;
//     const [add, plus, code, digits1, digits2, digits3] =
//         sanitize.phone(input).match(/^(\+)?(\d{1,2})?(\d{3,4})?(\d{3})?(\d+)?/) ?? [];
//     return (plus || '') + [code, digits1, digits2, digits3].filter((x) => !!x).join('');
// };
