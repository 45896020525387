export enum EAppRoutes {
    AUTHENTICATE = 'authenticate',
    BOOKINGS = 'bookings',
    CONSUMABLES = 'consumables',
    DASHBOARD = 'dashboard',
    INGREDIENTS = 'ingredients',
    INVENTORY = 'inventory',
    INVENTORY_ORDERS = 'inventoryOrders',
    MEDIA = 'media',
    ORDERS = 'orders',
    PRODUCTS = 'products',
    SPACES = 'spaces',
    SPACE_TYPES = 'spaceTypes',
    SUPPLIERS = 'suppliers',
    TAGS = 'tags',
    USERS = 'users',
}

export type RoutePath = Record<string, string> & {
    base: string;
    create?: string;
    update?: string;
};
export type RoutePaths = Record<EAppRoutes, RoutePath>;

const routePaths: RoutePaths = {
    [EAppRoutes.AUTHENTICATE]: {
        base: '/login',
        register: '/register',
    },
    [EAppRoutes.BOOKINGS]: {
        base: '/bookings/:spaceTypeId?/:spaceTypeVariantId?/:spaceId?/*',
        create: 'create/*',
        booking: ':bookingId',
    },
    [EAppRoutes.CONSUMABLES]: {
        base: '/consumable/*',
        create: 'create',
        consumable: ':consumableId',
    },
    [EAppRoutes.DASHBOARD]: {
        base: '/',
    },
    [EAppRoutes.INGREDIENTS]: {
        base: '/ingredients/*',
        create: 'create',
        ingredient: ':ingredientId',
    },
    [EAppRoutes.INVENTORY]: {
        base: '/inventory/*',
        create: 'create',
        inventoryRecord: ':inventoryRecordId',
        update: ':inventoryRecordId/update',
    },
    [EAppRoutes.INVENTORY_ORDERS]: {
        base: '/inventoryOrders/*',
        create: 'create',
        order: ':inventoryOrderId',
        update: ':inventoryOrderId/update',
    },
    [EAppRoutes.MEDIA]: {
        base: '/media/*',
        create: 'create',
        order: ':mediaId',
        update: ':mediaId/update',
    },
    [EAppRoutes.ORDERS]: {
        base: '/orders/*',
        create: 'create',
        order: ':orderId',
        update: ':orderId/update',
    },
    [EAppRoutes.PRODUCTS]: {
        base: '/products/*',
        create: 'create',
        product: ':productId',
        update: ':productId/update',
    },
    [EAppRoutes.SUPPLIERS]: {
        base: '/suppliers/*',
        create: 'create',
        supplier: ':supplierId',
        update: ':supplierId/update',
    },
    [EAppRoutes.SPACE_TYPES]: {
        base: '/spaceTypes/*',
        create: 'create',
        spaceType: ':spaceTypeId',
    },
    [EAppRoutes.SPACES]: {
        base: '/spaces/*',
        create: 'create',
        product: ':spaceId',
        update: ':spaceId/update',
    },
    [EAppRoutes.TAGS]: {
        base: '/tags/*',
        create: 'create',
        tag: ':tagId',
        update: ':tagId/update',
    },
    [EAppRoutes.USERS]: {
        base: '/users/*',
        create: 'create',
        user: ':userId',
    },
};

export const cleanPath = (url: string): string =>
    url
        .replaceAll('*', '')
        .replaceAll(/:[^/]+/g, '')
        .replaceAll('//', '/')
        .replace(/\/$/, '');

export const replaceRouteParams = (route: string, params?: Record<string, string>) =>
    params
        ? Object.entries(params).reduce(
              (path, [key, value]) => path.replace(`:${key}`, value),
              route,
          )
        : route;

export const addQueryParams = (
    route: string,
    queryParams?: Record<string, string | number | (string | number)[]>,
) =>
    queryParams
        ? `${route}?${Object.entries(queryParams)
              .map(
                  ([key, value]) =>
                      `${encodeURIComponent(key)}=${encodeURIComponent(
                          Array.isArray(value) ? value.join(',') : value,
                      )}`,
              )
              .join('&')}`.replace(/\?$/, '')
        : route;

export const fullPath = (
    route: RoutePath,
    subRoute?: string,
    params?: Record<string, string>,
    queryParams?: Record<string, string>,
): string => {
    return cleanPath(
        addQueryParams(
            replaceRouteParams(subRoute ? `${route.base}/${route[subRoute]}` : route.base, params),
            queryParams,
        ),
    );
};

export default routePaths;
