import { CustomEventTitle } from '~components/calendar/CalendarBigComponents';
import { EBookingAvailability } from '@makespace/graphql-generated/generated/webapp/schemas';
import { border, color } from '~styles/styles';
import { formatDateTime } from '~utils/formatters';
import { joinBookingName, splitBookingName } from '~hooks/useBookings';
import { spaceClassName, spaceTypeClassName, spaceTypeVariantClassName } from '~utils/css';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import type {
    Booking,
    BookingAvailability,
} from '@makespace/graphql-generated/generated/webapp/schemas';

export enum EBookingItemVariant {
    NEW = 'new',
    ORIGINAL = 'original',
}

export type BookingItemProps = {
    availability?: BookingAvailability;
    booking: Partial<Omit<Booking, 'user'> & { user: string }>;
    variant?: EBookingItemVariant;
};

export default function BookingItem(props: BookingItemProps): JSX.Element {
    const { availability, booking, variant } = props;

    return (
        <BookingItemContainer
            className={clsx(
                variant,
                spaceTypeClassName(booking.spaceType),
                spaceTypeVariantClassName(booking.spaceTypeVariant),
                spaceClassName(booking.space),
                availability?.availability,
            )}>
            <BookingItemContent className={clsx(variant)}>
                <span>
                    <span>{formatDateTime(booking.dateFrom)}</span>
                    <span> - </span>
                    <span>{formatDateTime(booking.dateTo)}</span>
                </span>
                <CustomEventTitle
                    name={
                        booking.name ? joinBookingName(splitBookingName(booking.name)) : undefined
                    }
                />
                <span>{booking.description}</span>
                <span>{booking.notes?.map((n, i) => <p key={i}>{n}</p>)}</span>
                <span>{booking.bookingNumber}</span>
            </BookingItemContent>
        </BookingItemContainer>
    );
}

const BookingItemContainer = styled.div`
    label: BookingItemContainer;
    display: flex;
    flex-direction: column;
    padding: 0.125rem;
    border-radius: ${border.radius.small};
    flex: 1;

    &.${EBookingAvailability.Unavailable} {
        background-color: ${color.red};
    }

    &.${EBookingAvailability.AvailableWithApproval} {
        background-color: ${color.orange};
    }
`;

const BookingItemContent = styled.div`
    label: BookingItemContent;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    &.${EBookingItemVariant.ORIGINAL} {
        background-color: rgba(255, 255, 255, 0.25);
    }
    &.${EBookingItemVariant.NEW} {
        background-color: rgba(255, 255, 255, 0.85);
    }
`;
