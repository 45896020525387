import { ScalarDateString } from '../../types/Scalars';
import { ScalarDateRangeString } from '../../types/Scalars';
import { ScalarDateTimeString } from '../../types/Scalars';
import { ScalarHSL } from '../../types/Scalars';
import { ScalarObjectIdString } from '../../types/Scalars';
import { ScalarTimeRange } from '../../types/Scalars';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
    [_ in K]?: never;
};
export type Incremental<T> =
    | T
    | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    Date: { input: ScalarDateString; output: ScalarDateString };
    DateRange: { input: ScalarDateRangeString; output: ScalarDateRangeString };
    DateTime: { input: ScalarDateTimeString; output: ScalarDateTimeString };
    HSL: { input: ScalarHSL; output: ScalarHSL };
    ObjectId: { input: ScalarObjectIdString; output: ScalarObjectIdString };
    TimeRange: { input: ScalarTimeRange; output: ScalarTimeRange };
};

export type Booking = {
    __typename?: 'Booking';
    _id: Scalars['ObjectId']['output'];
    bookingNumber: Scalars['String']['output'];
    createdAt: Scalars['Date']['output'];
    dateFrom: Scalars['Date']['output'];
    dateTo: Scalars['Date']['output'];
    description?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    notes?: Maybe<Array<Scalars['String']['output']>>;
    space?: Maybe<Scalars['ObjectId']['output']>;
    spaceType: Scalars['ObjectId']['output'];
    spaceTypeVariant: Scalars['ObjectId']['output'];
    status: EBookingStatus;
    updatedAt: Scalars['Date']['output'];
    user: User;
};

export type BookingAvailability = {
    __typename?: 'BookingAvailability';
    availability: EBookingAvailability;
    reason?: Maybe<EBookingUnavailabilityReason>;
};

export type BookingAvailableInput = {
    dateFrom: Scalars['String']['input'];
    dateTo: Scalars['String']['input'];
    space?: InputMaybe<Scalars['String']['input']>;
    spaceType: Scalars['String']['input'];
    spaceTypeVariant?: InputMaybe<Scalars['String']['input']>;
    user?: InputMaybe<Scalars['String']['input']>;
};

export type BookingChoice = {
    __typename?: 'BookingChoice';
    dateFrom: Scalars['Date']['output'];
    dateTo: Scalars['Date']['output'];
    space?: Maybe<Scalars['String']['output']>;
    spaceType: Scalars['String']['output'];
    spaceTypeVariant: Scalars['String']['output'];
};

export type BookingChoiceInput = {
    date: Scalars['String']['input'];
    space?: InputMaybe<Scalars['String']['input']>;
    spaceType: Scalars['String']['input'];
    spaceTypeVariant?: InputMaybe<Scalars['String']['input']>;
    userCategory?: InputMaybe<Scalars['String']['input']>;
};

export type BookingConfiguration = {
    __typename?: 'BookingConfiguration';
    availabilityDates: Array<Scalars['DateRange']['output']>;
    availabilityDays?: Maybe<Array<EBookingDayOfWeek>>;
    availabilityTimes: Array<Scalars['TimeRange']['output']>;
    bookingOptions: Array<BookingOption>;
    userCategories: Array<UserCategory>;
};

export type BookingConfigurationCreateUpdate = {
    availabilityDates: Array<Scalars['DateRange']['input']>;
    availabilityDays?: InputMaybe<Array<EBookingDayOfWeek>>;
    availabilityTimes: Array<Scalars['TimeRange']['input']>;
    bookingOptions: Array<BookingOptionCreateUpdate>;
    userCategories: Array<Scalars['ObjectId']['input']>;
};

export type BookingCreate = {
    bookingNumber?: InputMaybe<Scalars['String']['input']>;
    dateFrom: Scalars['Date']['input'];
    dateTo: Scalars['Date']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    notes: Array<Scalars['String']['input']>;
    space?: InputMaybe<Scalars['ObjectId']['input']>;
    spaceType: Scalars['ObjectId']['input'];
    spaceTypeVariant: Scalars['ObjectId']['input'];
    status: EBookingStatus;
    user: Scalars['ObjectId']['input'];
};

export type BookingOption = {
    __typename?: 'BookingOption';
    limits?: Maybe<BookingOptionLimits>;
    period?: Maybe<EBookingPeriod>;
    rates: Array<BookingOptionRates>;
};

export type BookingOptionCreateUpdate = {
    limits?: InputMaybe<BookingOptionLimitsCreateUpdate>;
    period?: InputMaybe<EBookingPeriod>;
    rates: Array<BookingOptionRatesCreateUpdate>;
};

export type BookingOptionLimits = {
    __typename?: 'BookingOptionLimits';
    increment: Scalars['Int']['output'];
    max: Scalars['Int']['output'];
    min: Scalars['Int']['output'];
};

export type BookingOptionLimitsCreateUpdate = {
    increment: Scalars['Int']['input'];
    max: Scalars['Int']['input'];
    min: Scalars['Int']['input'];
};

export type BookingOptionRates = {
    __typename?: 'BookingOptionRates';
    max: Scalars['Int']['output'];
    min: Scalars['Int']['output'];
    price?: Maybe<Scalars['Float']['output']>;
    rate?: Maybe<Scalars['Float']['output']>;
};

export type BookingOptionRatesCreateUpdate = {
    max: Scalars['Int']['input'];
    min: Scalars['Int']['input'];
    price?: InputMaybe<Scalars['Float']['input']>;
    rate?: InputMaybe<Scalars['Float']['input']>;
};

export type BookingSearchInput = {
    bookingNumber?: InputMaybe<Scalars['String']['input']>;
    dateFrom?: InputMaybe<Scalars['String']['input']>;
    dateTo?: InputMaybe<Scalars['String']['input']>;
    searchTerm?: InputMaybe<Scalars['String']['input']>;
    space?: InputMaybe<Scalars['String']['input']>;
    spaceType?: InputMaybe<Scalars['String']['input']>;
    spaceTypeVariant?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<EBookingStatus>;
    user?: InputMaybe<Scalars['String']['input']>;
};

export type BookingUpdate = {
    _id: Scalars['ObjectId']['input'];
    bookingNumber?: InputMaybe<Scalars['String']['input']>;
    dateFrom?: InputMaybe<Scalars['Date']['input']>;
    dateTo?: InputMaybe<Scalars['Date']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    notes: Array<Scalars['String']['input']>;
    space?: InputMaybe<Scalars['ObjectId']['input']>;
    spaceType?: InputMaybe<Scalars['ObjectId']['input']>;
    spaceTypeVariant?: InputMaybe<Scalars['ObjectId']['input']>;
    status?: InputMaybe<EBookingStatus>;
    user?: InputMaybe<Scalars['ObjectId']['input']>;
};

export type Business = {
    __typename?: 'Business';
    contact?: Maybe<BusinessContact>;
    country: Scalars['String']['output'];
    name: Scalars['String']['output'];
    registrationNumber?: Maybe<Scalars['String']['output']>;
    taxNumber?: Maybe<Scalars['String']['output']>;
};

export type BusinessContact = {
    __typename?: 'BusinessContact';
    address?: Maybe<Array<ContactAddress>>;
    methods?: Maybe<Array<ContactMethod>>;
};

export type Consumable = {
    __typename?: 'Consumable';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    quantity: Scalars['Int']['output'];
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type ConsumableCreate = {
    name: Scalars['String']['input'];
    quantity: Scalars['Int']['input'];
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type ConsumableUpdate = {
    _id: Scalars['String']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    quantity?: InputMaybe<Scalars['Int']['input']>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type Contact = {
    __typename?: 'Contact';
    address?: Maybe<ContactAddress>;
    description?: Maybe<Scalars['String']['output']>;
    methods?: Maybe<Array<ContactMethod>>;
    name: Scalars['String']['output'];
};

export type ContactAddress = {
    __typename?: 'ContactAddress';
    addressType: EContactAddressType;
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    line1: Scalars['String']['output'];
    line2?: Maybe<Scalars['String']['output']>;
    postalCode: Scalars['String']['output'];
    state: Scalars['String']['output'];
};

export type ContactAddressCreateUpdate = {
    addressType: EContactAddressType;
    city: Scalars['String']['input'];
    country: Scalars['String']['input'];
    line1: Scalars['String']['input'];
    line2?: InputMaybe<Scalars['String']['input']>;
    postalCode: Scalars['String']['input'];
    state: Scalars['String']['input'];
};

export type ContactCreateUpdate = {
    address?: InputMaybe<ContactAddressCreateUpdate>;
    description?: InputMaybe<Scalars['String']['input']>;
    methods?: InputMaybe<Array<ContactMethodCreateUpdate>>;
    name: Scalars['String']['input'];
};

export type ContactMethod = {
    __typename?: 'ContactMethod';
    source: EContactMethodSource;
    value: Scalars['String']['output'];
};

export type ContactMethodCreateUpdate = {
    source: EContactMethodSource;
    value: Scalars['String']['input'];
};

export enum EBookingAvailability {
    Available = 'AVAILABLE',
    AvailableWithApproval = 'AVAILABLE_WITH_APPROVAL',
    Unavailable = 'UNAVAILABLE',
}

export enum EBookingDayOfWeek {
    Friday = 'FRIDAY',
    Monday = 'MONDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY',
    Thursday = 'THURSDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
}

export enum EBookingPeriod {
    Minute = 'MINUTE',
}

export enum EBookingStatus {
    Cancelled = 'CANCELLED',
    Complete = 'COMPLETE',
    Confirmed = 'CONFIRMED',
    Reserved = 'RESERVED',
}

export enum EBookingUnavailabilityReason {
    DateNotAvailable = 'DATE_NOT_AVAILABLE',
    SpacesAlreadyBooked = 'SPACES_ALREADY_BOOKED',
    SpaceAlreadyBooked = 'SPACE_ALREADY_BOOKED',
    TimeNotAvailable = 'TIME_NOT_AVAILABLE',
    TimeRangeExceedsAllowed = 'TIME_RANGE_EXCEEDS_ALLOWED',
    UserCategoryNotAllowed = 'USER_CATEGORY_NOT_ALLOWED',
}

export enum EContactAddressType {
    Billing = 'BILLING',
    Business = 'BUSINESS',
    Home = 'HOME',
    Other = 'OTHER',
    Temporary = 'TEMPORARY',
}

export enum EContactMethodSource {
    Email = 'EMAIL',
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM',
    Line = 'LINE',
    Phone = 'PHONE',
    Website = 'WEBSITE',
    Wechat = 'WECHAT',
    Whatsapp = 'WHATSAPP',
}

export enum EInventoryItemType {
    Consumable = 'CONSUMABLE',
    Ingredient = 'INGREDIENT',
}

export enum EInventoryOrderStatus {
    Cancelled = 'CANCELLED',
    PartiallyReceived = 'PARTIALLY_RECEIVED',
    Pending = 'PENDING',
    Received = 'RECEIVED',
}

export enum ELanguage {
    En = 'EN',
    Th = 'TH',
}

export enum EMediaOrientation {
    Landscape = 'LANDSCAPE',
    Portrait = 'PORTRAIT',
    Square = 'SQUARE',
}

export enum EMediaType {
    Image = 'IMAGE',
    Video = 'VIDEO',
}

export enum EOrderLineItemType {
    Product = 'PRODUCT',
    ProductVariation = 'PRODUCT_VARIATION',
}

export enum EOrderStatus {
    Cancelled = 'CANCELLED',
    Completed = 'COMPLETED',
    Delivered = 'DELIVERED',
    Delivering = 'DELIVERING',
    InProgress = 'IN_PROGRESS',
    Pending = 'PENDING',
}

export enum EProductItemType {
    Consumable = 'CONSUMABLE',
    Ingredient = 'INGREDIENT',
}

export enum ETagSchema {
    Consumable = 'CONSUMABLE',
    Ingredient = 'INGREDIENT',
    Inventory = 'INVENTORY',
    InventoryOrder = 'INVENTORY_ORDER',
    Product = 'PRODUCT',
    Space = 'SPACE',
    Supplier = 'SUPPLIER',
}

export enum EThemeIcon {
    Icon_1 = 'ICON_1',
    Icon_2 = 'ICON_2',
}

export enum EUserRole {
    Admin = 'ADMIN',
    Guest = 'GUEST',
    Staff = 'STAFF',
}

export type EmptyOtherItem = {
    __typename?: 'EmptyOtherItem';
    pointless?: Maybe<Scalars['Boolean']['output']>;
};

export type GiBusinessContactCreateUpdate = {
    address?: InputMaybe<Array<ContactAddressCreateUpdate>>;
    methods?: InputMaybe<Array<ContactMethodCreateUpdate>>;
};

export type GiBusinessCreateUpdate = {
    contact?: InputMaybe<GiBusinessContactCreateUpdate>;
    country: Scalars['String']['input'];
    name: Scalars['String']['input'];
    registrationNumber?: InputMaybe<Scalars['String']['input']>;
    taxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GiRgba = {
    a: Scalars['Float']['input'];
    b: Scalars['Float']['input'];
    g: Scalars['Float']['input'];
    r: Scalars['Float']['input'];
};

export type GiUserCategoryCreate = {
    name: Scalars['String']['input'];
    roles: Array<EUserRole>;
};

export type GiUserCategoryUpdate = {
    _id: Scalars['ObjectId']['input'];
    name: Scalars['String']['input'];
    roles: Array<EUserRole>;
};

export type GiUserContactCreateUpdate = {
    address?: InputMaybe<Array<ContactAddressCreateUpdate>>;
    methods?: InputMaybe<Array<ContactMethodCreateUpdate>>;
};

export type GiUserCreate = {
    business?: InputMaybe<GiBusinessCreateUpdate>;
    category: Scalars['String']['input'];
    contact?: InputMaybe<GiUserContactCreateUpdate>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    nationality?: InputMaybe<Scalars['String']['input']>;
    passportNumber?: InputMaybe<Scalars['String']['input']>;
    roles?: InputMaybe<Array<EUserRole>>;
};

export type GiUserSearch = {
    categories?: InputMaybe<Array<Scalars['ObjectId']['input']>>;
    roles?: InputMaybe<Array<EUserRole>>;
    searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type GiUserUpdate = {
    _id: Scalars['ObjectId']['input'];
    business?: InputMaybe<GiBusinessCreateUpdate>;
    category: Scalars['String']['input'];
    contact?: InputMaybe<GiUserContactCreateUpdate>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    nationality?: InputMaybe<Scalars['String']['input']>;
    passportNumber?: InputMaybe<Scalars['String']['input']>;
    roles?: InputMaybe<Array<EUserRole>>;
};

export type IProduct = {
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    createdAt: Scalars['Date']['output'];
    includes?: Maybe<Array<ProductItem>>;
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    slug: Scalars['String']['output'];
    tags?: Maybe<Array<Maybe<Tag>>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type IProductVariation = {
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    costPrice?: Maybe<Scalars['Int']['output']>;
    createdAt: Scalars['Date']['output'];
    includes?: Maybe<Array<ProductItem>>;
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    product: Product;
    recipe?: Maybe<Array<ProductVariationRecipeItem>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type Ingredient = {
    __typename?: 'Ingredient';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    quantity: Scalars['Int']['output'];
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type IngredientCreate = {
    name: Scalars['String']['input'];
    quantity: Scalars['Int']['input'];
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type IngredientUpdate = {
    _id: Scalars['String']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    quantity?: InputMaybe<Scalars['Int']['input']>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type Inventory = {
    __typename?: 'Inventory';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    item: UInventoryItem;
    itemType: EInventoryItemType;
    quantity: Scalars['Int']['output'];
    updatedAt: Scalars['Date']['output'];
    volume?: Maybe<Scalars['Int']['output']>;
};

export type InventoryItem = {
    __typename?: 'InventoryItem';
    item: UInventoryItem;
    itemType: EInventoryItemType;
};

export type InventoryOrder = {
    __typename?: 'InventoryOrder';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    orderItems?: Maybe<Array<InventoryOrderItem>>;
    orderedAt: Scalars['Date']['output'];
    status: EInventoryOrderStatus;
    supplier: Supplier;
    updatedAt: Scalars['Date']['output'];
};

export type InventoryOrderCreate = {
    orderItems?: InputMaybe<Array<InventoryOrderItemCreateUpdate>>;
    orderedAt: Scalars['Date']['input'];
    status: EInventoryOrderStatus;
    supplier: Scalars['ID']['input'];
};

export type InventoryOrderItem = {
    __typename?: 'InventoryOrderItem';
    item: UInventoryItem;
    itemType: EInventoryItemType;
    quantity: Scalars['Int']['output'];
    received?: Maybe<Scalars['Boolean']['output']>;
    receivedAt?: Maybe<Scalars['Date']['output']>;
    receivedQuantity?: Maybe<Scalars['Int']['output']>;
};

export type InventoryOrderItemCreateUpdate = {
    item: Scalars['ID']['input'];
    itemType: EInventoryItemType;
    quantity: Scalars['Int']['input'];
    received?: InputMaybe<Scalars['Boolean']['input']>;
    receivedAt?: InputMaybe<Scalars['Date']['input']>;
    receivedQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryOrderSummary = {
    __typename?: 'InventoryOrderSummary';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    orderItemsCount: Scalars['Int']['output'];
    orderedAt: Scalars['Date']['output'];
    status: EInventoryOrderStatus;
    supplierId: Scalars['ObjectId']['output'];
    supplierName: Scalars['String']['output'];
    updatedAt: Scalars['Date']['output'];
};

export type InventoryOrderUpdate = {
    _id: Scalars['String']['input'];
    orderItems?: InputMaybe<Array<InventoryOrderItemCreateUpdate>>;
    orderedAt?: InputMaybe<Scalars['Date']['input']>;
    status?: InputMaybe<EInventoryOrderStatus>;
    supplier?: InputMaybe<Scalars['ID']['input']>;
};

export type InventoryRecordCreate = {
    item: Scalars['ID']['input'];
    itemType: EInventoryItemType;
    quantity: Scalars['Int']['input'];
    volume?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryRecordUpdate = {
    _id: Scalars['String']['input'];
    item?: InputMaybe<Scalars['ID']['input']>;
    itemType?: InputMaybe<EInventoryItemType>;
    quantity?: InputMaybe<Scalars['Int']['input']>;
    volume?: InputMaybe<Scalars['Int']['input']>;
};

export type Media = {
    __typename?: 'Media';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    description?: Maybe<Scalars['String']['output']>;
    mediaType: EMediaType;
    metadata: MediaMetadata;
    name: Scalars['String']['output'];
    s3: MediaS3;
    source?: Maybe<MediaSource>;
    tags?: Maybe<Array<Scalars['ObjectId']['output']>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type MediaMetadata = {
    __typename?: 'MediaMetadata';
    duration?: Maybe<Scalars['Int']['output']>;
    extension: Scalars['String']['output'];
    height?: Maybe<Scalars['Int']['output']>;
    mimeType: Scalars['String']['output'];
    orientation?: Maybe<EMediaOrientation>;
    size: Scalars['Int']['output'];
    width?: Maybe<Scalars['Int']['output']>;
};

export type MediaS3 = {
    __typename?: 'MediaS3';
    bucket: Scalars['String']['output'];
    eTag?: Maybe<Scalars['String']['output']>;
    key: Scalars['String']['output'];
};

export type MediaSource = {
    __typename?: 'MediaSource';
    large?: Maybe<Scalars['String']['output']>;
    medium?: Maybe<Scalars['String']['output']>;
    small?: Maybe<Scalars['String']['output']>;
    thumbnail?: Maybe<Scalars['String']['output']>;
};

export type MongoDocument = {
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    bookingCreate?: Maybe<Booking>;
    bookingUpdate?: Maybe<Booking>;
    consumableCreate?: Maybe<Consumable>;
    consumableUpdate?: Maybe<Consumable>;
    ingredientCreate?: Maybe<Ingredient>;
    ingredientUpdate?: Maybe<Ingredient>;
    inventoryOrderCreate?: Maybe<InventoryOrder>;
    inventoryOrderUpdate?: Maybe<InventoryOrder>;
    inventoryRecordCreate?: Maybe<Inventory>;
    inventoryRecordUpdate?: Maybe<Inventory>;
    orderCreate?: Maybe<Order>;
    orderUpdate?: Maybe<Order>;
    pageCreate?: Maybe<Page>;
    pageUpdate?: Maybe<Page>;
    productCreate?: Maybe<Product>;
    productUpdate?: Maybe<Product>;
    productVariationCreate?: Maybe<ProductVariation>;
    productVariationUpdate?: Maybe<ProductVariation>;
    productVariationsCreate?: Maybe<Array<ProductVariation>>;
    productVariationsUpdate?: Maybe<Array<ProductVariation>>;
    productVariationsUpdateCreate?: Maybe<Array<ProductVariation>>;
    spaceCreate?: Maybe<Space>;
    spaceTypeCreate?: Maybe<SpaceType>;
    spaceTypeUpdate?: Maybe<SpaceType>;
    spaceUpdate?: Maybe<Space>;
    supplierCreate?: Maybe<Supplier>;
    supplierUpdate?: Maybe<Supplier>;
    tagCreate?: Maybe<Tag>;
    tagUpdate?: Maybe<Tag>;
    tagsUpdate?: Maybe<Array<Tag>>;
    userCategoryCreate?: Maybe<UserCategory>;
    userCategoryUpdate?: Maybe<UserCategory>;
    userCreate?: Maybe<User>;
    userUpdate?: Maybe<User>;
};

export type MutationBookingCreateArgs = {
    input: BookingCreate;
};

export type MutationBookingUpdateArgs = {
    input: BookingUpdate;
};

export type MutationConsumableCreateArgs = {
    input: ConsumableCreate;
};

export type MutationConsumableUpdateArgs = {
    input: ConsumableUpdate;
};

export type MutationIngredientCreateArgs = {
    input: IngredientCreate;
};

export type MutationIngredientUpdateArgs = {
    input: IngredientUpdate;
};

export type MutationInventoryOrderCreateArgs = {
    input: InventoryOrderCreate;
};

export type MutationInventoryOrderUpdateArgs = {
    input: InventoryOrderUpdate;
};

export type MutationInventoryRecordCreateArgs = {
    input: InventoryRecordCreate;
};

export type MutationInventoryRecordUpdateArgs = {
    input: InventoryRecordUpdate;
};

export type MutationOrderCreateArgs = {
    input: OrderCreate;
};

export type MutationOrderUpdateArgs = {
    input: OrderUpdate;
};

export type MutationPageCreateArgs = {
    input: PageCreate;
};

export type MutationPageUpdateArgs = {
    input: PageUpdate;
};

export type MutationProductCreateArgs = {
    input: ProductCreate;
};

export type MutationProductUpdateArgs = {
    input: ProductUpdate;
};

export type MutationProductVariationCreateArgs = {
    input: ProductVariationCreateUpdate;
    productId: Scalars['ID']['input'];
};

export type MutationProductVariationUpdateArgs = {
    input: ProductVariationUpdate;
};

export type MutationProductVariationsCreateArgs = {
    inputs: Array<ProductVariationCreateUpdate>;
    productId: Scalars['ID']['input'];
};

export type MutationProductVariationsUpdateArgs = {
    inputs: Array<ProductVariationUpdate>;
};

export type MutationProductVariationsUpdateCreateArgs = {
    inputs: Array<ProductVariationCreateUpdate>;
    productId: Scalars['ID']['input'];
};

export type MutationSpaceCreateArgs = {
    input: SpaceCreate;
};

export type MutationSpaceTypeCreateArgs = {
    input: SpaceTypeCreate;
};

export type MutationSpaceTypeUpdateArgs = {
    input: SpaceTypeUpdate;
};

export type MutationSpaceUpdateArgs = {
    input: SpaceUpdate;
};

export type MutationSupplierCreateArgs = {
    input: SupplierCreate;
};

export type MutationSupplierUpdateArgs = {
    input: SupplierUpdate;
};

export type MutationTagCreateArgs = {
    input: TagCreate;
};

export type MutationTagUpdateArgs = {
    input: TagUpdate;
};

export type MutationTagsUpdateArgs = {
    input: Array<TagUpdate>;
};

export type MutationUserCategoryCreateArgs = {
    input: GiUserCategoryCreate;
};

export type MutationUserCategoryUpdateArgs = {
    input: GiUserCategoryUpdate;
};

export type MutationUserCreateArgs = {
    input: GiUserCreate;
};

export type MutationUserUpdateArgs = {
    input: GiUserUpdate;
};

export type Order = {
    __typename?: 'Order';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    deliveryTime: Scalars['Date']['output'];
    orderLines: Array<OrderLine>;
    orderNumber: Scalars['String']['output'];
    status: EOrderStatus;
    updatedAt: Scalars['Date']['output'];
};

export type OrderCreate = {
    deliveryTime: Scalars['Date']['input'];
    orderLines: Array<OrderLineCreateUpdate>;
    status: EOrderStatus;
};

export type OrderLine = {
    __typename?: 'OrderLine';
    item: UOrderLineItem;
    itemType: EOrderLineItemType;
    quantity: Scalars['Int']['output'];
};

export type OrderLineCreateUpdate = {
    item: Scalars['String']['input'];
    itemType: EOrderLineItemType;
    quantity: Scalars['Int']['input'];
};

export type OrderUpdate = {
    _id: Scalars['String']['input'];
    deliveryTime?: InputMaybe<Scalars['Date']['input']>;
    orderLines?: InputMaybe<Array<OrderLineCreateUpdate>>;
    status?: InputMaybe<EOrderStatus>;
};

export type Page = {
    __typename?: 'Page';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    slug: Scalars['String']['output'];
    title: Scalars['String']['output'];
    updatedAt: Scalars['Date']['output'];
};

export type PageCreate = {
    name: Scalars['String']['input'];
    slug: Scalars['String']['input'];
    title: Scalars['String']['input'];
};

export type PageUpdate = {
    _id: Scalars['String']['input'];
    name: Scalars['String']['input'];
    slug: Scalars['String']['input'];
    title: Scalars['String']['input'];
};

export type Product = IProduct & {
    __typename?: 'Product';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    createdAt: Scalars['Date']['output'];
    includes?: Maybe<Array<ProductItem>>;
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    slug: Scalars['String']['output'];
    tags?: Maybe<Array<Tag>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type ProductCreate = {
    active: Scalars['Boolean']['input'];
    includes?: InputMaybe<Array<ProductItemCreateUpdate>>;
    name: Scalars['String']['input'];
    price: Scalars['Float']['input'];
    slug: Scalars['String']['input'];
    tags?: InputMaybe<Array<Scalars['ID']['input']>>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type ProductItem = {
    __typename?: 'ProductItem';
    item: UProductItemUnion;
    itemType: EProductItemType;
};

export type ProductItemCreateUpdate = {
    item: Scalars['ID']['input'];
    itemType: EProductItemType;
};

export type ProductUpdate = {
    _id: Scalars['String']['input'];
    active?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    price: Scalars['Float']['input'];
    slug?: InputMaybe<Scalars['String']['input']>;
    tags?: InputMaybe<Array<Scalars['ID']['input']>>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type ProductVariation = IProductVariation & {
    __typename?: 'ProductVariation';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    costPrice?: Maybe<Scalars['Int']['output']>;
    createdAt: Scalars['Date']['output'];
    includes?: Maybe<Array<ProductItem>>;
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    product: Product;
    recipe?: Maybe<Array<ProductVariationRecipeItem>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type ProductVariationCreateUpdate = {
    _id?: InputMaybe<Scalars['String']['input']>;
    active: Scalars['Boolean']['input'];
    costPrice?: InputMaybe<Scalars['Int']['input']>;
    name: Scalars['String']['input'];
    price: Scalars['Float']['input'];
    product?: InputMaybe<Scalars['ObjectId']['input']>;
    recipe?: InputMaybe<Array<ProductVariationRecipeItemCreateUpdate>>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type ProductVariationRecipeItem = {
    __typename?: 'ProductVariationRecipeItem';
    ingredient: Ingredient;
    quantity: Scalars['Int']['output'];
};

export type ProductVariationRecipeItemCreateUpdate = {
    ingredient: Scalars['ID']['input'];
    quantity: Scalars['Int']['input'];
};

export type ProductVariationUpdate = {
    _id: Scalars['String']['input'];
    active: Scalars['Boolean']['input'];
    costPrice?: InputMaybe<Scalars['Int']['input']>;
    name: Scalars['String']['input'];
    price: Scalars['Float']['input'];
    product?: InputMaybe<Scalars['ObjectId']['input']>;
    recipe?: InputMaybe<Array<ProductVariationRecipeItemCreateUpdate>>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type ProductVariationWithFullProduct = {
    __typename?: 'ProductVariationWithFullProduct';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    costPrice?: Maybe<Scalars['Int']['output']>;
    createdAt: Scalars['Date']['output'];
    includes?: Maybe<Array<ProductItem>>;
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    product: Product;
    recipe?: Maybe<Array<ProductVariationRecipeItem>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type Query = {
    __typename?: 'Query';
    booking?: Maybe<Booking>;
    bookingAvailable?: Maybe<BookingAvailability>;
    bookingChoices?: Maybe<Array<BookingChoice>>;
    bookings?: Maybe<Array<Booking>>;
    consumable?: Maybe<Consumable>;
    consumables?: Maybe<Array<Consumable>>;
    ingredient?: Maybe<Ingredient>;
    ingredients?: Maybe<Array<Ingredient>>;
    inventory?: Maybe<Array<Inventory>>;
    inventoryByItem?: Maybe<Inventory>;
    inventoryItems?: Maybe<Array<InventoryItem>>;
    inventoryOrder?: Maybe<InventoryOrder>;
    inventoryOrders?: Maybe<Array<InventoryOrderSummary>>;
    inventoryOrdersByItem?: Maybe<Array<InventoryOrder>>;
    inventoryRecord?: Maybe<Inventory>;
    me?: Maybe<User>;
    media: Array<Media>;
    mediaRecord?: Maybe<Media>;
    order?: Maybe<Order>;
    orders?: Maybe<Array<Order>>;
    page?: Maybe<Page>;
    pageBySlug?: Maybe<Page>;
    pages?: Maybe<Array<Maybe<Page>>>;
    product?: Maybe<Product>;
    productBySlug?: Maybe<Product>;
    productVariation?: Maybe<ProductVariationWithFullProduct>;
    productVariations?: Maybe<Array<ProductVariation>>;
    productVariationsByProduct?: Maybe<Array<ProductVariation>>;
    products?: Maybe<Array<Product>>;
    productsByIngredient?: Maybe<Array<Product>>;
    productsByTag?: Maybe<Array<Product>>;
    space?: Maybe<Space>;
    spaceBySlug?: Maybe<Space>;
    spaceType?: Maybe<SpaceType>;
    spaceTypeBySlug?: Maybe<SpaceType>;
    spaceTypes?: Maybe<Array<SpaceType>>;
    spaceTypesTranslated?: Maybe<Array<SpaceTypeTranslated>>;
    spaces?: Maybe<Array<Space>>;
    spacesBySpaceType?: Maybe<Array<Space>>;
    supplier?: Maybe<Supplier>;
    suppliers?: Maybe<Array<Supplier>>;
    tag?: Maybe<Tag>;
    tags?: Maybe<Array<Tag>>;
    tagsBySchema?: Maybe<Array<Tag>>;
    user?: Maybe<User>;
    userCategories?: Maybe<Array<UserCategory>>;
    userCategory?: Maybe<UserCategory>;
    users?: Maybe<Array<User>>;
    webProductsWithVariationsByTagSchema?: Maybe<Array<WebProductWithVariations>>;
};

export type QueryBookingArgs = {
    id: Scalars['ID']['input'];
};

export type QueryBookingAvailableArgs = {
    input: BookingAvailableInput;
};

export type QueryBookingChoicesArgs = {
    input: BookingChoiceInput;
};

export type QueryBookingsArgs = {
    input?: InputMaybe<BookingSearchInput>;
};

export type QueryConsumableArgs = {
    id: Scalars['String']['input'];
};

export type QueryIngredientArgs = {
    id: Scalars['String']['input'];
};

export type QueryInventoryByItemArgs = {
    itemId: Scalars['ID']['input'];
};

export type QueryInventoryItemsArgs = {
    type?: InputMaybe<EInventoryItemType>;
};

export type QueryInventoryOrderArgs = {
    id: Scalars['ID']['input'];
};

export type QueryInventoryOrdersByItemArgs = {
    itemId: Scalars['ID']['input'];
};

export type QueryInventoryRecordArgs = {
    id: Scalars['ID']['input'];
};

export type QueryMediaRecordArgs = {
    id: Scalars['ID']['input'];
};

export type QueryOrderArgs = {
    id: Scalars['ID']['input'];
};

export type QueryPageArgs = {
    id: Scalars['ID']['input'];
};

export type QueryPageBySlugArgs = {
    slug: Scalars['String']['input'];
};

export type QueryProductArgs = {
    id: Scalars['ID']['input'];
};

export type QueryProductBySlugArgs = {
    slug: Scalars['String']['input'];
};

export type QueryProductVariationArgs = {
    id: Scalars['ID']['input'];
};

export type QueryProductVariationsByProductArgs = {
    productId: Scalars['ID']['input'];
};

export type QueryProductsByIngredientArgs = {
    ingredientId: Scalars['ID']['input'];
};

export type QueryProductsByTagArgs = {
    tagId: Scalars['ID']['input'];
};

export type QuerySpaceArgs = {
    id: Scalars['ID']['input'];
};

export type QuerySpaceBySlugArgs = {
    slug: Scalars['String']['input'];
};

export type QuerySpaceTypeArgs = {
    id: Scalars['ID']['input'];
};

export type QuerySpaceTypeBySlugArgs = {
    slug: Scalars['String']['input'];
};

export type QuerySpaceTypesTranslatedArgs = {
    language: ELanguage;
};

export type QuerySpacesBySpaceTypeArgs = {
    spaceTypeId: Scalars['ID']['input'];
};

export type QuerySupplierArgs = {
    id: Scalars['ID']['input'];
};

export type QueryTagArgs = {
    id: Scalars['ID']['input'];
};

export type QueryTagsBySchemaArgs = {
    schemas: Array<ETagSchema>;
};

export type QueryUserArgs = {
    id: Scalars['ID']['input'];
};

export type QueryUserCategoryArgs = {
    id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
    input?: InputMaybe<GiUserSearch>;
};

export type QueryWebProductsWithVariationsByTagSchemaArgs = {
    schemas: Array<ETagSchema>;
};

export type Rgba = {
    __typename?: 'Rgba';
    a: Scalars['Float']['output'];
    b: Scalars['Float']['output'];
    g: Scalars['Float']['output'];
    r: Scalars['Float']['output'];
};

export type Space = {
    __typename?: 'Space';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    slug: Scalars['String']['output'];
    theme?: Maybe<Theme>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type SpaceCreate = {
    active: Scalars['Boolean']['input'];
    name: Scalars['String']['input'];
    slug: Scalars['String']['input'];
    theme?: InputMaybe<ThemeCreateUpdate>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type SpaceTranslated = {
    __typename?: 'SpaceTranslated';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    createdAt: Scalars['Date']['output'];
    description?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    slug: Scalars['String']['output'];
    theme?: Maybe<Theme>;
    updatedAt: Scalars['Date']['output'];
};

export type SpaceType = {
    __typename?: 'SpaceType';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    priority?: Maybe<Scalars['Int']['output']>;
    slug: Scalars['String']['output'];
    theme?: Maybe<Theme>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
    variants?: Maybe<Array<SpaceTypeVariant>>;
};

export type SpaceTypeCreate = {
    active: Scalars['Boolean']['input'];
    name: Scalars['String']['input'];
    priority?: InputMaybe<Scalars['Int']['input']>;
    slug: Scalars['String']['input'];
    theme?: InputMaybe<ThemeCreateUpdate>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
    variants?: InputMaybe<Array<SpaceTypeVariantCreate>>;
};

export type SpaceTypeTranslated = {
    __typename?: 'SpaceTypeTranslated';
    _id: Scalars['ObjectId']['output'];
    active?: Maybe<Scalars['Boolean']['output']>;
    createdAt: Scalars['Date']['output'];
    description?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    priority?: Maybe<Scalars['Int']['output']>;
    slug: Scalars['String']['output'];
    theme?: Maybe<Theme>;
    updatedAt: Scalars['Date']['output'];
    variants?: Maybe<Array<SpaceTypeVariantTranslated>>;
};

export type SpaceTypeUpdate = {
    _id: Scalars['String']['input'];
    active?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    priority?: InputMaybe<Scalars['Int']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
    theme?: InputMaybe<ThemeCreateUpdate>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
    variants?: InputMaybe<Array<SpaceTypeVariantUpdate>>;
};

export type SpaceTypeVariant = {
    __typename?: 'SpaceTypeVariant';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    bookingConfigurations?: Maybe<Array<BookingConfiguration>>;
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    priority?: Maybe<Scalars['Int']['output']>;
    slug: Scalars['String']['output'];
    spaces?: Maybe<Array<Space>>;
    theme?: Maybe<Theme>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type SpaceTypeVariantCreate = {
    active: Scalars['Boolean']['input'];
    bookingConfigurations?: InputMaybe<Array<BookingConfigurationCreateUpdate>>;
    name: Scalars['String']['input'];
    price: Scalars['Float']['input'];
    priority?: InputMaybe<Scalars['Int']['input']>;
    slug: Scalars['String']['input'];
    spaces?: InputMaybe<Array<Scalars['ID']['input']>>;
    theme?: InputMaybe<ThemeCreateUpdate>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type SpaceTypeVariantNotPopulated = {
    __typename?: 'SpaceTypeVariantNotPopulated';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    bookingConfigurations?: Maybe<Array<BookingConfiguration>>;
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    priority?: Maybe<Scalars['Int']['output']>;
    slug: Scalars['String']['output'];
    spaces?: Maybe<Array<Scalars['ObjectId']['output']>>;
    theme?: Maybe<Theme>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type SpaceTypeVariantTranslated = {
    __typename?: 'SpaceTypeVariantTranslated';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    createdAt: Scalars['Date']['output'];
    description?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    price: Scalars['Float']['output'];
    priority?: Maybe<Scalars['Int']['output']>;
    slug: Scalars['String']['output'];
    spaces?: Maybe<Array<SpaceTranslated>>;
    theme?: Maybe<Theme>;
    updatedAt: Scalars['Date']['output'];
};

export type SpaceTypeVariantUpdate = {
    _id?: InputMaybe<Scalars['ID']['input']>;
    active: Scalars['Boolean']['input'];
    bookingConfigurations?: InputMaybe<Array<BookingConfigurationCreateUpdate>>;
    name: Scalars['String']['input'];
    price: Scalars['Float']['input'];
    priority?: InputMaybe<Scalars['Int']['input']>;
    slug: Scalars['String']['input'];
    spaces?: InputMaybe<Array<Scalars['ID']['input']>>;
    theme?: InputMaybe<ThemeCreateUpdate>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type SpaceUpdate = {
    _id: Scalars['String']['input'];
    active?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
    theme?: InputMaybe<ThemeCreateUpdate>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type Subscription = {
    __typename?: 'Subscription';
    mediaUploaded?: Maybe<Array<Media>>;
};

export type Supplier = {
    __typename?: 'Supplier';
    _id: Scalars['ObjectId']['output'];
    contacts?: Maybe<Array<Contact>>;
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    tags?: Maybe<Array<Tag>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type SupplierCreate = {
    contacts?: InputMaybe<Array<ContactCreateUpdate>>;
    name: Scalars['String']['input'];
    tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type SupplierUpdate = {
    _id: Scalars['String']['input'];
    contacts?: InputMaybe<Array<ContactCreateUpdate>>;
    name?: InputMaybe<Scalars['String']['input']>;
    tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type Tag = {
    __typename?: 'Tag';
    _id: Scalars['ObjectId']['output'];
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    schemas?: Maybe<Array<ETagSchema>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type TagCreate = {
    name: Scalars['String']['input'];
    schemas?: InputMaybe<Array<ETagSchema>>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type TagUpdate = {
    _id: Scalars['String']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    schemas?: InputMaybe<Array<ETagSchema>>;
    translations?: InputMaybe<Array<TranslationCreateUpdate>>;
};

export type Theme = {
    __typename?: 'Theme';
    colorPrimary?: Maybe<Rgba>;
    colorSecondary?: Maybe<Rgba>;
    icon?: Maybe<Scalars['String']['output']>;
};

export type ThemeCreateUpdate = {
    colorPrimary?: InputMaybe<GiRgba>;
    colorSecondary?: InputMaybe<GiRgba>;
    icon?: InputMaybe<Scalars['String']['input']>;
};

export type Translation = {
    __typename?: 'Translation';
    description?: Maybe<Scalars['String']['output']>;
    language: ELanguage;
    name?: Maybe<Scalars['String']['output']>;
};

export type TranslationCreateUpdate = {
    description?: InputMaybe<Scalars['String']['input']>;
    language: ELanguage;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UInventoryItem = Consumable | Ingredient;

export type UOrderLineItem = Product | ProductVariation;

export type UProductItemUnion = Consumable | Ingredient;

export type User = {
    __typename?: 'User';
    _id: Scalars['ObjectId']['output'];
    business?: Maybe<Business>;
    category: UserCategory;
    contact?: Maybe<UserContact>;
    createdAt: Scalars['Date']['output'];
    email: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    nationality?: Maybe<Scalars['String']['output']>;
    passportNumber?: Maybe<Scalars['String']['output']>;
    roles?: Maybe<Array<EUserRole>>;
    updatedAt: Scalars['Date']['output'];
};

export type UserCategory = {
    __typename?: 'UserCategory';
    _id: Scalars['ObjectId']['output'];
    name: Scalars['String']['output'];
    roles: Array<EUserRole>;
};

export type UserContact = {
    __typename?: 'UserContact';
    address?: Maybe<Array<ContactAddress>>;
    methods?: Maybe<Array<ContactMethod>>;
};

export type WebProductItem = {
    __typename?: 'WebProductItem';
    item: Scalars['String']['output'];
    itemType: EProductItemType;
};

export type WebProductVariation = {
    __typename?: 'WebProductVariation';
    _id: Scalars['ObjectId']['output'];
    active: Scalars['Boolean']['output'];
    costPrice?: Maybe<Scalars['Int']['output']>;
    createdAt: Scalars['Date']['output'];
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    product: Scalars['String']['output'];
    recipe?: Maybe<Array<WebProductVariationRecipeItem>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
};

export type WebProductVariationRecipeItem = {
    __typename?: 'WebProductVariationRecipeItem';
    _id: Scalars['String']['output'];
    createdAt: Scalars['Date']['output'];
    product: Scalars['String']['output'];
    quantity: Scalars['Int']['output'];
    updatedAt: Scalars['Date']['output'];
};

export type WebProductWithVariations = {
    __typename?: 'WebProductWithVariations';
    _id: Scalars['String']['output'];
    active: Scalars['Boolean']['output'];
    createdAt: Scalars['Date']['output'];
    includes?: Maybe<Array<ProductItem>>;
    name: Scalars['String']['output'];
    price: Scalars['Float']['output'];
    slug: Scalars['String']['output'];
    tags?: Maybe<Array<Tag>>;
    translations?: Maybe<Array<Translation>>;
    updatedAt: Scalars['Date']['output'];
    variations?: Maybe<Array<WebProductVariation>>;
};
