import {
    authLogout,
    checkAuthHealth,
    refreshAuthToken,
    useAuthLogin,
    useAuthRegister,
} from '@makespace/auth-utils/webapp';
import { useMeQuery } from '@makespace/graphql-generated/webapp';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { ApolloError } from '@apollo/client';
import type { User } from '@makespace/graphql-generated/webapp';
import type { WebAuthnUser } from '@makespace/global-types';

export interface IAuthenticationContext {
    clearError?: () => void;
    error?: Error | ApolloError;
    isAuthenticated: boolean;
    isAuthenticationReady: boolean;
    loading: boolean;
    login: (email: string) => Promise<void>;
    logout: () => Promise<void>;
    refresh: () => Promise<void>;
    register: (user: WebAuthnUser) => Promise<void>;
    user: User | null;
}

const AuthenticationContext = createContext<IAuthenticationContext>({
    clearError: () => {
        throw new Error('AuthenticationContext not initialized');
    },
    isAuthenticated: false,
    isAuthenticationReady: false,
    loading: false,
    login: () => {
        throw new Error('AuthenticationContext not initialized');
    },
    logout: () => {
        throw new Error('AuthenticationContext not initialized');
    },
    refresh: () => {
        throw new Error('AuthenticationContext not initialized');
    },
    register: () => {
        throw new Error('AuthenticationContext not initialized');
    },
    user: null,
});

AuthenticationContext.displayName = 'authentication';

export type AuthenticationProviderProps = {
    authUrl: string;
    children: React.ReactNode | React.ReactNode[];
};

function AuthenticationProvider(props: AuthenticationProviderProps) {
    const { children, authUrl } = props;
    const { loginBegin } = useAuthLogin({ authUrl });
    const { registerBegin } = useAuthRegister({ authUrl });
    const [loading, setLoading] = useState<boolean>(false);
    const [isAuthenticationReady, setIsAuthenticationReady] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
    const [tokenExpiry, setTokenExpiry] = useState<number | undefined>(undefined);
    const [error, setError] = useState<Error | ApolloError | undefined>(undefined);

    const {
        data,
        loading: userLoading,
        error: userError,
        refetch: userRefetch,
    } = useMeQuery({
        fetchPolicy: 'network-only',
        skip: !isAuthenticated,
    });

    const user = data?.me ?? null;

    const setAuthentication = (authenticated?: boolean, tokenExpiry?: number) => {
        setIsAuthenticated(authenticated ?? false);
        setTokenExpiry(tokenExpiry);
    };

    const refresh = useCallback(async () => {
        try {
            await refreshAuthToken(authUrl);
            const health = await checkAuthHealth(authUrl);
            if (health) setAuthentication(true, health.expires);
            else throw new Error('Failed to refresh token, health check failed');
        } catch (e) {
            console.error('Authentication:refresh', e);
            setAuthentication(false);
        }
    }, [authUrl]);

    const checkHealth = useCallback(async () => {
        setLoading(true);
        const health = await checkAuthHealth(authUrl);
        if (!health) await refresh();
        else setAuthentication(true, health.expires);
        setLoading(false);
    }, [authUrl, refresh]);

    const login = useCallback(
        async (email: string) => {
            try {
                setError(undefined);
                setLoading(true);
                await loginBegin(email);
            } catch (e) {
                setAuthentication(false);
                setError(e as Error);
            } finally {
                await checkHealth();
                setLoading(false);
            }
        },
        [checkHealth, loginBegin],
    );

    const logout = useCallback(async () => {
        setLoading(true);
        await authLogout(authUrl);
        void userRefetch();
        setAuthentication(false);
        setLoading(false);
    }, [authUrl, userRefetch]);

    const register = useCallback(
        async (user: WebAuthnUser) => {
            try {
                setError(undefined);
                setLoading(true);
                await registerBegin(user);
                await checkHealth();
            } catch (e) {
                setAuthentication(false);
                setError(e as Error);
            } finally {
                setLoading(false);
            }
        },
        [checkHealth, registerBegin],
    );

    useEffect(() => {
        async function init() {
            try {
                setError(undefined);
                await checkHealth();
            } catch (e) {
                console.error('Authentication:init', e);
                setError(e as Error);
            } finally {
                setIsAuthenticationReady(true);
            }
        }

        if (isAuthenticated === undefined) {
            setIsAuthenticated(false);
            void init();
        }
    }, [checkHealth, isAuthenticated]);

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined;
        if (tokenExpiry)
            timeout = setTimeout(
                () => {
                    console.log('refreshing token', tokenExpiry);
                    void refresh();
                },
                tokenExpiry - Date.now() - 5000,
            );

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [refresh, tokenExpiry]);

    const clearError = useCallback(() => setError(undefined), []);

    const context: IAuthenticationContext = useMemo(
        () => ({
            clearError,
            error: error ?? userError,
            isAuthenticated: !!(isAuthenticated && user),
            isAuthenticationReady,
            loading: loading || userLoading,
            login,
            logout,
            refresh,
            register,
            user: user || null,
        }),
        [
            clearError,
            error,
            isAuthenticated,
            isAuthenticationReady,
            loading,
            login,
            logout,
            refresh,
            register,
            user,
            userError,
            userLoading,
        ],
    );

    return (
        <AuthenticationContext.Provider value={context}>{children}</AuthenticationContext.Provider>
    );
}

function useAuthentication(): IAuthenticationContext {
    const context = useContext(AuthenticationContext);
    if (!context) throw new Error('useAuthentication must be used within AuthenticationProvider');
    return context;
}

export { AuthenticationContext, AuthenticationProvider, useAuthentication };
