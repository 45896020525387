export async function authLogout(authUrl: string): Promise<void> {
    try {
        const response = await fetch(`${authUrl}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (response.ok) return;
        else throw new Error('Logout failed');
    } catch (e) {
        console.error(e);
        throw e;
    }
}
