import React, { createContext, useContext, useState } from 'react';

export interface IFormControllerContext {
    edit: boolean;
    setEdit: (edit: boolean) => void;
}

const FormControllerContext = createContext<IFormControllerContext>({
    edit: false,
    setEdit: () => {
        throw new Error('FormControllerContext not initialized');
    },
});
FormControllerContext.displayName = 'formController';

export type FormControllerProviderProps = {
    children: React.ReactNode | React.ReactNode[];
    edit?: boolean;
};

function FormControllerProvider(props: FormControllerProviderProps) {
    const { children, edit: initialEdit = false } = props;
    const [edit, setEdit] = useState(initialEdit);

    const context: IFormControllerContext = {
        edit,
        setEdit,
    };

    return (
        <FormControllerContext.Provider value={context}>{children}</FormControllerContext.Provider>
    );
}

function useFormController(): IFormControllerContext {
    const context = useContext(FormControllerContext);
    if (!context) throw new Error('useFormController must be used within FormControllerProvider');
    return context;
}

export { FormControllerContext, FormControllerProvider, useFormController };
