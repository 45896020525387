import * as yup from 'yup';
import {
    EContactAddressType,
    EContactMethodSource,
} from '@makespace/graphql-generated/generated/webapp/schemas';
import { validatePhoneNumber } from '~utils/validators';
import type {
    ContactAddressCreateUpdate,
    ContactCreateUpdate,
    ContactMethodCreateUpdate,
} from '@makespace/graphql-generated/generated/webapp/schemas';

export const ContactMethodSchema: yup.ObjectSchema<ContactMethodCreateUpdate> = yup.object().shape({
    source: yup.string().oneOf(Object.values(EContactMethodSource)).required(),
    value: yup
        .string()
        .required()
        .test('contact-method-value', 'Invalid contact method value', (value, context) => {
            const source = (context.parent as ContactMethodCreateUpdate).source;
            switch (source) {
                case EContactMethodSource.Email: {
                    return yup.string().email().isValidSync(value);
                }
                case EContactMethodSource.Phone: {
                    return validatePhoneNumber(value);
                }
                default: {
                    return yup.string().isValidSync(value);
                }
            }
        }),
});

export const ContactAddressSchema: yup.ObjectSchema<ContactAddressCreateUpdate> = yup
    .object()
    .shape({
        addressType: yup.string().oneOf(Object.values(EContactAddressType)).required(),
        city: yup.string().required(),
        country: yup.string().required(),
        postalCode: yup.string().required(),
        state: yup.string().required(),
        line1: yup.string().required(),
        line2: yup.string().notRequired(),
    });

export const ContactSchema: yup.ObjectSchema<ContactCreateUpdate> = yup.object().shape({
    description: yup.string(),
    methods: yup.array().of(ContactMethodSchema),
    address: ContactAddressSchema.notRequired(),
    name: yup.string().required(),
});
