import { space } from '~styles/styles';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum EColumnsVariant {
    FOUR = 'four',
    ONE_QUARTER_THREE_QUARTERS = 'oneQuarterThreeQuarters',
    ONE_THIRD_TWO_THIRDS = 'oneThirdTwoThirds',
    THREE = 'three',
    THREE_QUARTERS_ONE_QUARTER = 'threeQuartersOneQuarter',
    TWO = 'two',
    TWO_THIRDS_ONE_THIRD = 'twoThirdsOneThird',
}

export type ColumnsProps = {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    variant: EColumnsVariant;
};

export default function Columns(props: ColumnsProps): JSX.Element {
    const { variant, children, className } = props;
    return <ColumnsContainer className={clsx(variant, className)}>{children}</ColumnsContainer>;
}

export enum EColumnSpacing {
    MEDIUM = 'medium',
    NONE = 'none',
    SMALL = 'small',
}

export type ColumnProps = {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    scrollable?: boolean;
    spacing?: EColumnSpacing;
};

export function Column(props: ColumnProps): JSX.Element {
    const { children, scrollable = false, spacing = EColumnSpacing.MEDIUM, className } = props;
    return (
        <ColumnContainer className={clsx('column', spacing, className, { scrollable })}>
            {children}
        </ColumnContainer>
    );
}

const ColumnsContainer = styled.div`
    label: ColumnsContainer;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    gap: ${space.gap.medium};
    flex: 1;

    &.${EColumnsVariant.TWO} > .column {
        flex: 1;
    }

    &.${EColumnsVariant.THREE} > .column {
        flex: 1;
    }

    &.${EColumnsVariant.ONE_THIRD_TWO_THIRDS} > .column {
        flex: 2;

        &:first-of-type {
            flex: 1;
        }
    }

    &.${EColumnsVariant.TWO_THIRDS_ONE_THIRD} > .column {
        flex: 1;
        width: 30%;

        &:first-of-type {
            flex: 2;
            width: 60%;
        }
    }

    &.${EColumnsVariant.ONE_QUARTER_THREE_QUARTERS} > .column {
        flex: 3;

        &:first-of-type {
            flex: 1;
        }
    }

    &.${EColumnsVariant.THREE_QUARTERS_ONE_QUARTER} > .column {
        flex: 1;

        &:first-of-type {
            flex: 3;
        }
    }
`;

export const ColumnContainer = styled.div`
    label: ColumnContainer;
    display: flex;
    flex-direction: column;
    gap: ${space.gap.medium};
    align-items: stretch;
    justify-content: stretch;
    //overflow: hidden;

    &.scrollable {
        overflow-y: auto;
    }

    &.${EColumnSpacing.NONE} {
        gap: 0;
    }

    &.${EColumnSpacing.SMALL} {
        gap: ${space.gap.small};
    }

    &.${EColumnSpacing.MEDIUM} {
        gap: ${space.gap.medium};
    }
`;
